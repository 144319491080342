import { Injectable } from '@angular/core';
import { LogData } from '../interfaces/LogData';
import { LogCard } from '../interfaces/LogCard';
import { LogDataConstants } from './LogDataConstants';
import { UserPhotoService } from '../../../core/ProfileService/user-photo.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ParseTimelineService {

  constructor
    (
      private userPhotoService: UserPhotoService,
      private translateService: TranslateService
    ) {

  }

  parseTimeline(logData: LogData, logCards: Array<LogCard>, start: number, firstName?: string, lastName?: string, userPhoto?: string, accessName?: string, numAccess?: string) {
    let index: number = 0;
    for (let i = start; i < logData.log.length + start; i++) {
      logCards[i] = {
        'id': logData.log[index].id,
        'title_top': "",
        'title_bottom': "",
        'date': logData.log[index].created,
        'icon': "",
        'image': {
          'src': "",
          'type': ""
        }
      }

      if (logData.log[index].lastFormat == undefined) {
        logData.log[index].lastFormat = JSON.parse(JSON.stringify(logData.log[index]));
        logData.log[index].lastFormat.board_access.firstName = firstName;
        logData.log[index].lastFormat.board_access.lastName = lastName;
        logData.log[index].photo = userPhoto;
        logData.log[index].lastFormat.board_access.name = accessName;
        logData.log[index].lastFormat.board_access.num_access = numAccess;
      }

      if (logData.log[index].state == LogDataConstants.state.intrusion) {
        logCards[i].title_top = this.translateService.instant('LOGBOOK.INTRUSION') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
        logCards[i].icon = 'report_problem';
        logCards[i].image.src = 'assets/images/avatars/intrusion.png';
        logCards[i].image.type = 'timeline-img-card';
      }

      if (logData.log[index].state == LogDataConstants.state.adding_an_object) {
        logCards[i].icon = 'add_circle_outline';
        logCards[i].image.src = 'assets/images/avatars/userAdd.png';
        logCards[i].image.type = 'timeline-img-card';
      }

      switch (logData.log[index].type) {
        case LogDataConstants.type.gateway:
          if (logData.log[index].state == LogDataConstants.state.adding_an_object) {
            if (logData.log[index].passerelle.name) {
              logCards[i].title_top = this.translateService.instant('LOGBOOK.ADD_BOX') + this.translateService.instant(':') + logData.log[index].lastFormat.passerelle.name;
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ADD') + this.translateService.instant(':') + logData.log[index].lastFormat.passerelle.name;
            } else {
              logCards[i].title_top = this.translateService.instant('LOGBOOK.ADD_BOX');
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ADD');
            }
          } else {
            logCards[i].image.src = 'assets/images/avatars/list-2.png';
            logCards[i].image.type = 'timeline-img-card';
            logCards[i].icon = 'help_outline';
            if (logData.log[index].passerelle.name) {
              logCards[i].title_top = this.translateService.instant('LOGBOOK.UNKNOWN_LOG') + this.translateService.instant(':') + logData.log[index].lastFormat.passerelle.name;
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNKNOWN_LOG') + this.translateService.instant(':') + logData.log[index].lastFormat.passerelle.name;
            } else {
              logCards[i].title_top = this.translateService.instant('LOGBOOK.UNKNOWN_LOG');
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNKNOWN_LOG');
            }
          }
          break;

        case LogDataConstants.type.lock:
          if (logData.log[index].state == LogDataConstants.state.intrusion) {
            logCards[i].title_bottom = this.translateService.instant('LOGBOOK.INTRUSION_LOCK') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
          }
          else if (logData.log[index].state == LogDataConstants.state.opening_request) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = 'Elocky Admin';
            }
            logCards[i].icon = 'bluetooth';
            logCards[i].title_top = 'Elocky Admin : ' + logData.log[index].lastFormat.board.name;
            logCards[i].image.src = 'assets/images/logos/icone.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.adding_an_object) {
            logCards[i].title_top = this.translateService.instant('LOGBOOK.ADD_LOCK') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ADD') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
          } else if (logData.log[index].state == LogDataConstants.state.update) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ADD_LOCK');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.ADD_LOCK') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'autorenew';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNKNOWN_LOG');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.UNKNOWN_LOG') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].image.src = 'assets/images/avatars/list-2.png';
            logCards[i].image.type = 'timeline-img-card';
            logCards[i].icon = 'help_outline';
          }
          break;

        case LogDataConstants.type.beacon:
          if (logData.log[index].state == LogDataConstants.state.intrusion) {
            logCards[i].title_bottom = this.translateService.instant('LOGBOOK.INTRUSION_BEACON') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
          } else if (logData.log[index].state == LogDataConstants.state.adding_an_object) {
            logCards[i].title_top = this.translateService.instant('LOGBOOK.ADD_BEACON') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ADD') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
          } else {
            logCards[i].title_top = logCards[i].title_top = this.translateService.instant('LOGBOOK.UNKNOWN_LOG') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNKNOWN_LOG');
            logCards[i].image.src = 'assets/images/avatars/list-2.png';
            logCards[i].image.type = 'timeline-img-card';
            logCards[i].icon = 'help_outline';
          }
          break;

        case LogDataConstants.type.access:
          if (logData.log[index].state == LogDataConstants.state.opening_request) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.LOCK_OPENED');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.LOCK_OPENED') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'bluetooth';
            logCards[i].image.src = this.userPhotoService.getNoAuthUserPhotoURLWithParameter(logData.log[index].photo);
            logCards[i].image.type = 'timeline-img-profile';
          } else if (logData.log[index].state == LogDataConstants.state.adding_an_object) {
            logCards[i].title_top = this.translateService.instant('LOGBOOK.ADD_ACCESS') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ADD_ACCESS') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
          } else if (logData.log[index].state == LogDataConstants.state.correct_card) {
            if (logData.log[index].lastFormat.board_access != undefined && logData.log[index].lastFormat.board_access.name != undefined) {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.BADGE') + this.translateService.instant(':') + logData.log[index].lastFormat.board_access.name;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.BADGE_OPENING');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.LOCK_OPENED') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'nfc';
            logCards[i].image.src = 'assets/images/avatars/correct_card.svg';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.unauthorized_card) {
            if (logData.log[index].lastFormat.board_access != undefined && logData.log[index].lastFormat.board_access.name != undefined) {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNAUTHORIZED_BADGE') + this.translateService.instant(':') + logData.log[index].lastFormat.board_access.name;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNAUTHORIZED_BADGE');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.OPENING_FAILURE') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'nfc';
            logCards[i].image.src = 'assets/images/avatars/unauthorized_card.svg';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.prohibited_card) {
            if (logData.log[index].lastFormat.board_access != undefined && logData.log[index].lastFormat.board_access.name != undefined) {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.PROHIBITED_BADGE') + this.translateService.instant(':') + logData.log[index].lastFormat.board_access.name;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.PROHIBITED_BADGE');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.OPENING_FAILURE') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'nfc';
            logCards[i].image.src = 'assets/images/avatars/prohibited_card.svg';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.unregistered_card) {
            if (logData.log[index].lastFormat.board_access != undefined && logData.log[index].lastFormat.board_access.name != undefined) {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNREGISTERED_BADGE') + this.translateService.instant(':') + logData.log[index].lastFormat.board_access.name;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNREGISTERED_BADGE');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.OPENING_FAILURE') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'nfc';
            logCards[i].image.src = 'assets/images/avatars/unregistered_card.svg';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.open_key) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.EMERGENCY_KEY') + this.translateService.instant(':') + logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.EMERGENCY_KEY');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.LOCK_OPENED') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'vpn_key';
            logCards[i].image.src = 'assets/images/avatars/key_active.svg';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.reboot) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.REBOOT');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.REBOOT') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.hard_reset) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.RESET');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.RESET') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.state_origine) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.DECONFIGURATION');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.DECONFIGURATION') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.state_config) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.CONFIGURATION');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.CONFIGURATION') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.normal_sleep) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.SLEEP_MODE.SLEEP_MODE') + this.translateService.instant(':') + this.translateService.instant('LOGBOOK.SLEEP_MODE.NORMAL');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.SLEEP_MODE.NORMAL_SLEEP') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.light_sleep) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.SLEEP_MODE.SLEEP_MODE') + this.translateService.instant(':') + this.translateService.instant('LOGBOOK.SLEEP_MODE.LIGHT');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.SLEEP_MODE.LIGHT_SLEEP') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.deep_sleep) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.SLEEP_MODE.SLEEP_MODE') + this.translateService.instant(':') + this.translateService.instant('LOGBOOK.SLEEP_MODE.DEEP');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.SLEEP_MODE.DEEP_SLEEP') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.sensitive_accelerometer) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.WAKEUP_MODE.WAKEUP_MODE') + this.translateService.instant(':') + this.translateService.instant('LOGBOOK.WAKEUP_MODE.SENSITIVE');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.WAKEUP_MODE.SENSITIVE_WAKEUP') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.normal_accelerometer) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.WAKEUP_MODE.WAKEUP_MODE') + this.translateService.instant(':') + this.translateService.instant('LOGBOOK.WAKEUP_MODE.NORMAL');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.WAKEUP_MODE.NORMAL_WAKEUP') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.hard_accelerometer) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.WAKEUP_MODE.WAKEUP_MODE') + this.translateService.instant(':') + this.translateService.instant('LOGBOOK.WAKEUP_MODE.HARD');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.WAKEUP_MODE.HARD_WAKEUP') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'settings';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.update) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = 'Mise à jour';
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.UPDATE') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'autorenew';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.update_DFU) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.SOFTWARE_UPDATE');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.SOFTWARE_UPDATE');
            logCards[i].icon = 'autorenew';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else if (logData.log[index].state == LogDataConstants.state.debug) {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.DEBUG');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.DEBUG') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'build';
            logCards[i].image.src = 'assets/images/avatars/configuration.png';
            logCards[i].image.type = 'timeline-img-card';
          } else {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNKNOWN_LOG');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.UNKNOWN_LOG') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'help_outline';
            logCards[i].image.src = 'assets/images/avatars/list-2.png';
            logCards[i].image.type = 'timeline-img-card';
          }
          break;

        case LogDataConstants.type.guest:
          if (logData.log[index].state == LogDataConstants.state.opening_request) {
            if (logData.log[index].lastFormat.board_access.name != undefined) {
              logCards[i].title_bottom = logData.log[index].lastFormat.board_access.name;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ACCESS_NUMBER') + this.translateService.instant(':') + logData.log[index].lastFormat.board_access.num_access;
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.GUEST_ACCESS') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'bluetooth';
            logCards[i].image.src = 'assets/images/avatars/guest.png';
            logCards[i].image.type = 'timeline-img-card';
          } else {
            if (logData.log[index].lastFormat.board_access != undefined) {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.ACCESS_NUMBER') + this.translateService.instant(':') + logData.log[index].lastFormat.board_access.num_access;
            } else {
              logCards[i].title_bottom = this.translateService.instant('LOGBOOK.UNKNOWN_LOG');
            }
            logCards[i].title_top = this.translateService.instant('LOGBOOK.UNKNOWN_LOG') + this.translateService.instant(':') + logData.log[index].lastFormat.board.name;
            logCards[i].icon = 'help_outline';
            logCards[i].image.src = 'assets/images/avatars/list-2.png';
            logCards[i].image.type = 'timeline-img-card';
          }
          break;

        default:
          if (logData.log[index].lastFormat.board_access != undefined) {
            logCards[i].title_bottom = logData.log[index].lastFormat.board_access.firstName + ' ' + logData.log[index].lastFormat.board_access.lastName;
          } else {
            logCards[i].title_bottom = this.translateService.instant('LOGBOOK.CONFIGURATION');
          }
          logCards[i].title_top = this.translateService.instant('LOGBOOK.CONFIGURATION');
          logCards[i].icon = 'settings';
          logCards[i].image.src = 'assets/images/avatars/configuration.png';
          logCards[i].image.type = 'timeline-img-card';
          break;

      }
      index += 1;
    }
    return logCards;
  }


}
