<div *ngIf="tabIndex == 3" class="scrollToTopButton_container">
    <button mat-icon-button class="scrollToTopButton warn theme-options-button mat-elevation-z2"
        (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </button>
    <div class="overlay"></div>
</div>

<div detect-scroll (onScroll)="onScroll($event)" [bottomOffset]="1" [topOffset]="1" id="accessManagement"
    class="page-layout simple tabbed scroll" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="space-between">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">{{'HEADER.TITLE_ACCESS' | translate}}</span>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span *ngIf="selectedAddress != undefined && selectedAddress.name == undefined"
                    class="secondary-text">{{selectedAddress.address}}</span>
                <span *ngIf="selectedAddress != undefined && selectedAddress.name != undefined"
                    class="secondary-text">{{selectedAddress.name}} - {{selectedAddress.address}}</span>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span *ngIf="selectedBoard != undefined" class="secondary-text">{{selectedBoard.name}}</span>
            </div>
            <div class="h1 mt-16" [@animate]="{value:'*',params:{x:'50px'}}">{{'HEADER.TITLE_ACCESS' | translate}}</div>

            <!-- USER OR OBJECT TYPE SELECTION -->

            <div fxLayout="row" class="menu" fxLayoutAlign="space-between">
                <div fxLayout="row" class="users-menu">

                    <div *ngIf="userOrObjectType == 'user'" class="selected-user-type">
                        {{'CONTENT.USER' | translate}}
                    </div>
                    <div *ngIf="userOrObjectType == 'guest'" class="selected-user-type">
                        {{'CONTENT.GUEST' | translate}}
                    </div>
                    <div *ngIf="userOrObjectType == 'badge'" class="selected-user-type">
                        {{'CONTENT.BADGE' | translate}}
                    </div>
                    <div *ngIf="userOrObjectType == 'key'" class="selected-user-type">
                        {{'CONTENT.KEY' | translate}}
                    </div>

                    <button mat-icon-button class="menu-selector" [matMenuTriggerFor]="userOrObjectTypesMenu"
                        aria-label="Select user or object type">
                        <mat-icon>expand_more</mat-icon>
                    </button>

                    <mat-menu #userOrObjectTypesMenu="matMenu">
                        <button mat-menu-item
                            (click)="onUserOrObjectTypeSelection('user')">{{'CONTENT.USER' | translate}}</button>
                        <button mat-menu-item *ngIf="accessData != undefined && accessData.invite[0] != undefined"
                            (click)="onUserOrObjectTypeSelection('guest')">{{'CONTENT.GUEST' | translate}}</button>
                        <button mat-menu-item
                            *ngIf="selectedBoard.type_board.id == 3 && badges != undefined && badges[0] != undefined"
                            (click)="onUserOrObjectTypeSelection('badge')">{{'CONTENT.BADGE' | translate}}</button>
                        <button mat-menu-item
                            *ngIf="selectedBoard.type_board.id == 3 && keys != undefined && keys[0] != undefined"
                            (click)="onUserOrObjectTypeSelection('key')">{{'CONTENT.KEY' | translate}}</button>
                    </mat-menu>

                    <!-- / USER OR OBJECT TYPE SELECTION -->

                    <!-- USER OR OBJECT SELECTION -->

                    <div *ngIf="userOrObjectType == 'user'" fxLayout="row">

                        <div class="selected-user-type" style="margin-left: 10px;">
                            {{selectedUserAccess.firstName}} {{selectedUserAccess.lastName}}</div>

                        <button mat-icon-button class="menu-selector" [matMenuTriggerFor]="usersMenu"
                            aria-label="Select user">
                            <mat-icon>expand_more</mat-icon>
                        </button>

                        <mat-menu #usersMenu="matMenu">
                            <button mat-menu-item *ngFor="let user of accessData.user"
                                (click)="onUserOrObjectSelection(user.id)">
                                <span>{{user.firstName}} {{user.lastName}}</span>
                            </button>
                        </mat-menu>

                    </div>

                    <div *ngIf="userOrObjectType == 'guest'" fxLayout="row">

                        <div *ngIf="selectedGuestAccess.name != undefined" class="selected-user-type"
                            style="margin-left: 10px;">
                            {{selectedGuestAccess.name}}</div>

                        <div *ngIf="selectedGuestAccess.name == undefined" class="selected-user-type"
                            style="margin-left: 10px;">
                            {{selectedGuestAccess.num_access}}</div>

                        <button mat-icon-button class="menu-selector" [matMenuTriggerFor]="guestsMenu"
                            aria-label="Select guest">
                            <mat-icon>expand_more</mat-icon>
                        </button>

                        <mat-menu #guestsMenu="matMenu">
                            <button mat-menu-item *ngFor="let guest of accessData.invite"
                                (click)="onUserOrObjectSelection(guest.id)">
                                <span *ngIf="guest.name != undefined">{{guest.name}}</span>
                                <span *ngIf="guest.name == undefined">{{guest.num_access}}</span>
                            </button>
                        </mat-menu>

                    </div>

                    <div *ngIf="userOrObjectType == 'badge'" fxLayout="row">

                        <div *ngIf="selectedBadge.name != undefined" class="selected-user-type"
                            style="margin-left: 10px;">
                            {{selectedBadge.name}}</div>

                        <div *ngIf="selectedBadge.name == undefined" class="selected-user-type"
                            style="margin-left: 10px;">
                            {{selectedBadge.num_card}}</div>

                        <button mat-icon-button class="menu-selector" [matMenuTriggerFor]="badgesMenu"
                            aria-label="Select badge">
                            <mat-icon>expand_more</mat-icon>
                        </button>

                        <mat-menu #badgesMenu="matMenu">
                            <button mat-menu-item *ngFor="let badge of badges"
                                (click)="onUserOrObjectSelection(badge.id)">
                                <span *ngIf="badge.name != undefined">{{badge.name}}</span>
                                <span *ngIf="badge.name == undefined">{{badge.num_card}}</span>
                            </button>
                        </mat-menu>

                    </div>

                    <div *ngIf="userOrObjectType == 'key'" fxLayout="row">

                        <div *ngIf="selectedKey.name != undefined" class="selected-user-type"
                            style="margin-left: 10px;">
                            {{selectedKey.name}}</div>

                        <div *ngIf="selectedKey.name == undefined" class="selected-user-type"
                            style="margin-left: 10px;">
                            {{selectedKey.reference}}</div>

                        <button mat-icon-button class="menu-selector" [matMenuTriggerFor]="keysMenu"
                            aria-label="Select key">
                            <mat-icon>expand_more</mat-icon>
                        </button>

                        <mat-menu #keysMenu="matMenu">
                            <button mat-menu-item *ngFor="let key of keys" (click)="onUserOrObjectSelection(key.id)">
                                <span *ngIf="key.name != undefined">{{key.name}}</span>
                                <span *ngIf="key.name == undefined">{{key.reference}}</span>
                            </button>
                        </mat-menu>

                    </div>

                    <!-- / USER OR OBJECT SELECTION -->

                </div>
            </div>
        </div>
        <ngx-spinner name="spinnerLoadingContent" class="spinnerLoadingContent" size="medium" color="#2eb4b3"
            bdColor="rgba(51,51,51,0)" type="ball-clip-rotate" [fullScreen]="false"></ngx-spinner>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <mat-tab-group dynamicHeight [(selectedIndex)]="tabIndex" (selectedIndexChange)="onTabIndexChange($event)">

            <mat-tab label="{{'ACCESS_MANAGEMENT.INFORMATION' | translate}}">
                <div class="p-24" fxLayout="row" fxLayout.xs="column">
                    <div class="information-form-wrapper">

                        <form *ngIf="userOrObjectType == 'user'" [formGroup]="userInformationForm"
                            (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <div fxLayout="column">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'POPUP.EMAIL' | translate}}</mat-label>
                                    <input matInput formControlName="email" readonly>
                                </mat-form-field>

                                <mat-form-field appearance="standard">
                                    <mat-label>{{'POPUP.AUTHORIZATION_LEVEL' | translate}}</mat-label>
                                    <mat-select [disabled]="selectedUserAccess.type_access.id == 1" matNativeControl
                                        id="access_grade" formControlName="access_grade">
                                        <mat-option *ngIf="selectedUserAccess.type_access.id == 1" value="1">
                                            {{'CONTENT.ADMINISTRATOR' | translate}}</mat-option>
                                        <mat-option value="2">{{'CONTENT.MODERATOR' | translate}}</mat-option>
                                        <mat-option value="3">{{'CONTENT.USER' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.ACCESS_CREATION_DATE' | translate}}</mat-label>
                                    <input matInput formControlName="creation_date" readonly>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.MODE_OF_OPERATION' | translate}}</mat-label>
                                    <input matInput formControlName="mode_of_operation" readonly>
                                </mat-form-field>

                                <mat-form-field appearance="standard" [floatLabel]="floatLabelControl.value">
                                    <mat-label>{{'POPUP.ACCESS_NAME' | translate}}</mat-label>
                                    <input matInput formControlName="access_name"
                                        placeholder="{{'ACCESS_MANAGEMENT.NO_NAME' | translate}}">
                                </mat-form-field>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between"
                                    class="informationForm-buttons-container">
                                    <button type="button" class="back-button" mat-raised-button
                                        [routerLink]="'/access'">
                                        <mat-icon>arrow_back</mat-icon>
                                        {{'ACCESS_MANAGEMENT.BACK' | translate}}
                                    </button>
                                    <div class="save-and-delete-buttons-container"
                                        *ngIf="this.selectedUserAccess.type_access.id != 1" fxLayout="row"
                                        fxLayout.xs="column">
                                        <button mat-raised-button type="button" (click)="openWantToDeleteAccess()"
                                            color="warn" class="submit-button">
                                            {{'ACCESS_MANAGEMENT.DELETE_ACCESS' | translate}}
                                        </button>
                                        <button mat-raised-button type="submit" color="accent" [disabled]="isLoading"
                                            class="submit-button save-button" aria-label="Modify information">
                                            {{'ACCESS_MANAGEMENT.SAVE' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngIf="userOrObjectType == 'guest'" [formGroup]="guestInformationForm"
                            (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <div fxLayout="column">
                                <mat-form-field appearance="standard" [floatLabel]="floatLabelControl.value">
                                    <mat-label>{{'POPUP.ACCESS_NAME' | translate}}</mat-label>
                                    <input matInput formControlName="access_name"
                                        placeholder="{{'ACCESS_MANAGEMENT.NO_NAME' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.LOGIN' | translate}}</mat-label>
                                    <input matInput formControlName="login" readonly>
                                    <mat-icon-button (click)="openShareGuestAccessDialog()" matSuffix
                                        class="primary-text" class="form-icon-button">
                                        <mat-icon color="accent">share</mat-icon>
                                    </mat-icon-button>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.ACCESS_CODE' | translate}}</mat-label>
                                    <input matInput formControlName="access_code" readonly>
                                    <mat-icon-button (click)="openWantToResetAccessCode()" matSuffix
                                        class="primary-text" class="form-icon-button">
                                        <mat-icon color="accent">autorenew</mat-icon>
                                    </mat-icon-button>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.ACCESS_CREATION_DATE' | translate}}</mat-label>
                                    <input matInput formControlName="creation_date" readonly>
                                </mat-form-field>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between"
                                    class="informationForm-buttons-container">
                                    <button type="button" class="back-button" mat-raised-button
                                        [routerLink]="'/access'">
                                        <mat-icon>arrow_back</mat-icon>
                                        {{'ACCESS_MANAGEMENT.BACK' | translate}}
                                    </button>
                                    <div class="save-and-delete-buttons-container" fxLayout="row" fxLayout.xs="column">
                                        <button mat-raised-button type="button" color="warn" class="submit-button"
                                            aria-label="Delete access" (click)="openWantToDeleteAccess()">
                                            {{'ACCESS_MANAGEMENT.DELETE_ACCESS' | translate}}
                                        </button>
                                        <button mat-raised-button type="submit" color="accent" [disabled]="isLoading"
                                            class="submit-button save-button" aria-label="Modify information">
                                            {{'ACCESS_MANAGEMENT.SAVE' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngIf="userOrObjectType == 'badge'" [formGroup]="badgeInformationForm"
                            (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <div fxLayout="column">
                                <mat-form-field appearance="standard" [floatLabel]="floatLabelControl.value">
                                    <mat-label>{{'ACCESS_MANAGEMENT.NAME' | translate}}</mat-label>
                                    <input matInput formControlName="badge_name"
                                        placeholder="{{'ACCESS_MANAGEMENT.NO_NAME' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.BADGE_NUMBER' | translate}}</mat-label>
                                    <input matInput formControlName="badge_number" readonly>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.STATE' | translate}}</mat-label>
                                    <input matInput formControlName="badge_state" readonly>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.CREATION_DATE' | translate}}</mat-label>
                                    <input matInput formControlName="creation_date" readonly>
                                </mat-form-field>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between"
                                    class="informationForm-buttons-container">
                                    <button type="button" class="back-button" mat-raised-button
                                        [routerLink]="'/access'">
                                        <mat-icon>arrow_back</mat-icon>
                                        {{'ACCESS_MANAGEMENT.BACK' | translate}}
                                    </button>
                                    <div class="save-and-delete-buttons-container" fxLayout="row" fxLayout.xs="column">
                                        <button mat-raised-button type="button" color="warn" class="submit-button"
                                            aria-label="disable badge" (click)="openFeatureComingSoonDialog()">
                                            {{'ACCESS_MANAGEMENT.DISABLE' | translate}}
                                        </button>
                                        <button mat-raised-button type="submit" color="accent" [disabled]="isLoading"
                                            class="submit-button save-button" aria-label="Modify information">
                                            {{'ACCESS_MANAGEMENT.SAVE' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngIf="userOrObjectType == 'key'" [formGroup]="keyInformationForm"
                            (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <div fxLayout="column">
                                <mat-form-field appearance="standard" [floatLabel]="floatLabelControl.value">
                                    <mat-label>{{'ACCESS_MANAGEMENT.NAME' | translate}}</mat-label>
                                    <input matInput formControlName="key_name"
                                        placeholder="{{'ACCESS_MANAGEMENT.NO_NAME' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.REFERENCE' | translate}}</mat-label>
                                    <input matInput formControlName="key_reference" readonly>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.STATE' | translate}}</mat-label>
                                    <input matInput formControlName="key_state" readonly>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.SECURITY_STATE' | translate}}</mat-label>
                                    <input matInput formControlName="key_security_state" readonly>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{'ACCESS_MANAGEMENT.CREATION_DATE' | translate}}</mat-label>
                                    <input matInput formControlName="creation_date" readonly>
                                </mat-form-field>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between"
                                    class="informationForm-buttons-container">
                                    <button type="button" class="back-button" mat-raised-button
                                        [routerLink]="'/access'">
                                        <mat-icon>arrow_back</mat-icon>
                                        {{'ACCESS_MANAGEMENT.BACK' | translate}}
                                    </button>
                                    <div class="save-and-delete-buttons-container" fxLayout="row" fxLayout.xs="column">
                                        <button mat-raised-button type="button" color="warn" class="submit-button"
                                            aria-label="activate key" (click)="openFeatureComingSoonDialog()">
                                            {{'ACCESS_MANAGEMENT.ENABLE' | translate}}
                                        </button>
                                        <button mat-raised-button type="submit" color="accent" [disabled]="isLoading"
                                            class="submit-button save-button" aria-label="Modify information">
                                            {{'ACCESS_MANAGEMENT.SAVE' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="img-container" fxLayout="column">
                        <img *ngIf="userOrObjectType == 'user'" class="img-side-profile user" src={{profilePhotoURL}}>
                        <img *ngIf="userOrObjectType == 'guest' || userOrObjectType == 'badge' || userOrObjectType == 'key'"
                            class="img-side-profile" src={{profilePhotoURL}}>
                        <img class="img-side-place" src={{placePhotoURL}}>
                    </div>
                </div>
            </mat-tab>

            <mat-tab [disabled]="userOrObjectType == 'user' && selectedUserAccess.type_access.id != 3"
                label="{{'SCHEDULES.RECURRENT_ACCESSES' | translate}}">

                <div *ngIf="userOrObjectType == 'user'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <!-- USER ACCESS_RECU WIDGET -->
                    <fuse-widget *ngFor="let access_recu of selectedUserAccess.access_recu" class="widget"
                        fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img class="img-widget" src="assets/images/avatars/picto_histo.png">
                                </div>

                                <div class="info-container bg-elocky-green" fxLayout="column">
                                    <div class="p-12">
                                        <div class="h3 info">
                                            <span
                                                *ngIf="access_recu.day.id == 1">{{'SCHEDULES.DAY.MONDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.START' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.start| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.start| date:"HH:mm" : "UTC"}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.END' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.stop| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.stop| date:"HH:mm" : "UTC"}}</span>
                                        </div>

                                        <button mat-icon-button style="color: white;"
                                            class="fuse-widget-flip-button delete-button"
                                            (click)="openWantToDeleteAccessRecuOrExepById(access_recu.id,'recu')"
                                            aria-label="more">
                                            <mat-icon>delete</mat-icon>
                                        </button>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / USER ACCESS_RECU WIDGET -->

                    <!-- ADD ACCESS_RECU WIDGET -->
                    <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front" style="cursor: pointer;" (click)="openAddAccessRecuDialog()">

                            <div fxLayout="row">

                                <div class="add-icon-container">
                                    <mat-icon class="add-icon">add_circle</mat-icon>
                                </div>

                                <div class="info-container" fxLayout="column">
                                    <div class="h1 add-text">
                                        {{'ACCESS_MANAGEMENT.ADD_A_RECURRENT_ACCESS' | translate}}</div>
                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / ADD ACCESS_RECU WIDGET -->

                </div>

                <div *ngIf="userOrObjectType == 'guest'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <!-- GUEST ACCESS_RECU WIDGET -->
                    <fuse-widget *ngFor="let access_recu of selectedGuestAccess.access_recu" class="widget"
                        fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img class="img-widget" src="assets/images/avatars/picto_histo.png">
                                </div>

                                <div class="info-container bg-elocky-green" fxLayout="column">
                                    <div class="p-12">
                                        <div class="h3 info">
                                            <span
                                                *ngIf="access_recu.day.id == 1">{{'SCHEDULES.DAY.MONDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.START' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.start| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.start| date:"HH:mm" : "UTC"}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.END' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.stop| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.stop| date:"HH:mm" : "UTC"}}</span>
                                        </div>

                                        <button mat-icon-button style="color: white;"
                                            class="fuse-widget-flip-button delete-button"
                                            (click)="openWantToDeleteAccessRecuOrExepById(access_recu.id,'recu')"
                                            aria-label="more">
                                            <mat-icon>delete</mat-icon>
                                        </button>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / GUEST ACCESS_RECU WIDGET -->

                    <!-- ADD ACCESS_RECU WIDGET -->
                    <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front" style="cursor: pointer;" (click)="openAddAccessRecuDialog()">

                            <div fxLayout="row">

                                <div class="add-icon-container">
                                    <mat-icon class="add-icon">add_circle</mat-icon>
                                </div>

                                <div class="info-container" fxLayout="column">
                                    <div class="h1 add-text">
                                        {{'ACCESS_MANAGEMENT.ADD_A_RECURRENT_ACCESS' | translate}}</div>
                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / ADD ACCESS_RECU WIDGET -->

                </div>

                <div *ngIf="userOrObjectType == 'badge'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <div *ngIf="selectedBadge.access_recu[0] == undefined" class="no-accesses" fxLayout="row">
                        <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                        <div class="h2 text-no-accesses">{{'SCHEDULES.THIS_BADGE' | translate}}
                            {{'SCHEDULES.NO_RECURRENT_ACCESS' | translate}} {{selectedBoard.name}}.</div>
                    </div>

                    <!-- BADGE ACCESS_RECU WIDGET -->
                    <fuse-widget *ngFor="let access_recu of selectedBadge.access_recu" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img class="img-widget" src="assets/images/avatars/picto_histo.png">
                                </div>

                                <div class="info-container bg-elocky-green" fxLayout="column">
                                    <div class="p-12">
                                        <div class="h3 info">
                                            <span
                                                *ngIf="access_recu.day.id == 1">{{'SCHEDULES.DAY.MONDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.START' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.start| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.start| date:"HH:mm" : "UTC"}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.END' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.stop| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.stop| date:"HH:mm" : "UTC"}}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / BADGE ACCESS_RECU WIDGET -->

                </div>

                <div *ngIf="userOrObjectType == 'key'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <div *ngIf="selectedKey.access_recu[0] == undefined" class="no-accesses" fxLayout="row">
                        <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                        <div class="h2 text-no-accesses">{{'SCHEDULES.THIS_KEY' | translate}}
                            {{'SCHEDULES.NO_RECURRENT_ACCESS' | translate}} {{selectedBoard.name}}.</div>
                    </div>

                    <!-- KEY ACCESS_RECU WIDGET -->
                    <fuse-widget *ngFor="let access_recu of selectedKey.access_recu" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img class="img-widget" src="assets/images/avatars/picto_histo.png">
                                </div>

                                <div class="info-container bg-elocky-green" fxLayout="column">
                                    <div class="p-12">
                                        <div class="h3 info">
                                            <span
                                                *ngIf="access_recu.day.id == 1">{{'SCHEDULES.DAY.MONDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.START' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.start| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.start| date:"HH:mm" : "UTC"}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.END' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.stop| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.stop| date:"HH:mm" : "UTC"}}</span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / KEY ACCESS_RECU WIDGET -->

                </div>

            </mat-tab>

            <mat-tab [disabled]="userOrObjectType == 'user' && selectedUserAccess.type_access.id !=3"
                label="{{'SCHEDULES.ONE-TIME_ACCESSES' | translate}}">

                <div *ngIf="userOrObjectType == 'user'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <!-- USER ACCESS_EXEP WIDGET -->
                    <fuse-widget *ngFor="let access_exep of selectedUserAccess.access_exep" class="widget"
                        fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img *ngIf="access_exep.is_dispo == 1" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_autorisation.png">
                                    <img *ngIf="access_exep.is_dispo == 0" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_interdiction.png">
                                </div>
                                <div class="info-container"
                                    [ngClass]="{'bg-elocky-green' : access_exep.is_dispo == 1, 'bg-not-dispo' : access_exep.is_dispo == 0}">
                                    <div class="p-12">
                                        <div class="info">
                                            <div class="h3">{{'SCHEDULES.START' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.start| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.start| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="h3">{{'SCHEDULES.END' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.stop| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.stop| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                        <button mat-icon-button style="color: white;"
                                            class="fuse-widget-flip-button delete-button"
                                            (click)="openWantToDeleteAccessRecuOrExepById(access_exep.id,'exep')"
                                            aria-label="more">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / USER ACCESS_EXEP WIDGET -->

                    <!-- ADD ACCESS_EXEP WIDGET -->
                    <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front" style="cursor: pointer;" (click)="openAddAccessExepDialog()">

                            <div fxLayout="row">

                                <div class="add-icon-container">
                                    <mat-icon class="add-icon">add_circle</mat-icon>
                                </div>

                                <div class="info-container" fxLayout="column">
                                    <div class="h1 add-text">
                                        {{'ACCESS_MANAGEMENT.ADD_A_ONE-TIME_ACCESS' | translate}}</div>
                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / ADD ACCESS_EXEP WIDGET -->

                </div>
                <div *ngIf="userOrObjectType == 'guest'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <!-- GUEST ACCESS_EXEP WIDGET -->
                    <fuse-widget *ngFor="let access_exep of selectedGuestAccess.access_exep" class="widget"
                        fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img *ngIf="access_exep.is_dispo == 1" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_autorisation.png">
                                    <img *ngIf="access_exep.is_dispo == 0" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_interdiction.png">
                                </div>
                                <div class="info-container"
                                    [ngClass]="{'bg-elocky-green' : access_exep.is_dispo == 1, 'bg-not-dispo' : access_exep.is_dispo == 0}">
                                    <div class="p-12">
                                        <div class="info">
                                            <div class="h3">{{'SCHEDULES.START' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.start| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.start| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="h3">{{'SCHEDULES.END' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.stop| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.stop| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                        <button mat-icon-button style="color: white;"
                                            class="fuse-widget-flip-button delete-button"
                                            (click)="openWantToDeleteAccessRecuOrExepById(access_exep.id,'exep')"
                                            aria-label="more">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / GUEST ACCESS_EXEP WIDGET -->

                    <!-- ADD ACCESS_EXEP WIDGET -->
                    <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front" style="cursor: pointer;" (click)="openAddAccessExepDialog()">

                            <div fxLayout="row">

                                <div class="add-icon-container">
                                    <mat-icon class="add-icon">add_circle</mat-icon>
                                </div>

                                <div class="info-container" fxLayout="column">
                                    <div class="h1 add-text">
                                        {{'ACCESS_MANAGEMENT.ADD_A_ONE-TIME_ACCESS' | translate}}</div>
                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / ADD ACCESS_EXEP WIDGET -->

                </div>

                <div *ngIf="userOrObjectType == 'badge'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <div *ngIf="selectedBadge.access_exep[0] == undefined" class="no-accesses" fxLayout="row">
                        <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                        <div class="h2 text-no-accesses">{{'SCHEDULES.THIS_BADGE' | translate}}
                            {{'SCHEDULES.NO_ONE-TIME_ACCESS' | translate}} {{selectedBoard.name}}.</div>
                    </div>

                    <!-- BADGE ACCESS_EXEP WIDGET -->
                    <fuse-widget *ngFor="let access_exep of selectedBadge.access_exep"
                        [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                        fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img *ngIf="access_exep.is_dispo == 1" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_autorisation.png">
                                    <img *ngIf="access_exep.is_dispo == 0" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_interdiction.png">
                                </div>
                                <div class="info-container"
                                    [ngClass]="{'bg-elocky-green' : access_exep.is_dispo == 1, 'bg-not-dispo' : access_exep.is_dispo == 0}">
                                    <div class="p-12">
                                        <div class="info">
                                            <div class="h3">{{'SCHEDULES.START' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.start| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.start| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="h3">{{'SCHEDULES.END' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.stop| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.stop| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / BADGE ACCESS_EXEP WIDGET -->

                </div>

                <div *ngIf="userOrObjectType == 'key'" class="widget-group p-12" fxLayout="row wrap" fxFlex="100"
                    [@animateStagger]="{value:'50'}">

                    <div *ngIf="selectedKey.access_exep[0] == undefined" class="no-accesses" fxLayout="row">
                        <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                        <div class="h2 text-no-accesses">{{'SCHEDULES.THIS_KEY' | translate}}
                            {{'SCHEDULES.NO_ONE-TIME_ACCESS' | translate}} {{selectedBoard.name}}.</div>
                    </div>

                    <!-- KEY ACCESS_EXEP WIDGET -->
                    <fuse-widget *ngFor="let access_exep of selectedKey.access_exep"
                        [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                        fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img *ngIf="access_exep.is_dispo == 1" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_autorisation.png">
                                    <img *ngIf="access_exep.is_dispo == 0" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_interdiction.png">
                                </div>
                                <div class="info-container"
                                    [ngClass]="{'bg-elocky-green' : access_exep.is_dispo == 1, 'bg-not-dispo' : access_exep.is_dispo == 0}">
                                    <div class="p-12">
                                        <div class="info">
                                            <div class="h3">{{'SCHEDULES.START' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.start| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.start| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="h3">{{'SCHEDULES.END' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.stop| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.stop| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / KEY ACCESS_EXEP WIDGET -->

                </div>

            </mat-tab>

            <mat-tab label="{{'ACCESS_MANAGEMENT.HISTORY' | translate}}">
                <div *ngIf="firstAccessLogsAlreadyLoaded == true">
                    <mgl-timeline [mobileWidthThreshold]="750">
                        <div class="no_logs_container" *ngIf="logCards.length == 0 && isLoading == false">
                            <div class="no_logs_text">{{'LOGBOOK.NO_LOGS_AVAILABLE' | translate}}</div>
                        </div>
                        <mgl-timeline-entry *ngFor="let logCard of logCards">
                            <mgl-timeline-entry-header>
                                <img class={{logCard.image.type}} src={{logCard.image.src}} alt={{logCard.title_top}}>
                                <div id="timeline-entry-header-text-container">
                                    <div id="timeline-entry-header-text">
                                        {{logCard.title_top}}
                                    </div>
                                </div>
                                <button mat-icon-button class="deleteLogButton"
                                    (click)="showWantToDeleteLogByIdDialog($event, logCard.id)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mgl-timeline-entry-header>
                            <mgl-timeline-entry-content>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>{{logCard.title_bottom}}</div>
                                    <div *ngIf="hourFormat == '12'">{{logCard.date| date:"MM/dd/yyyy"}}
                                        {{'AT' | translate }}
                                        {{logCard.date| date:"hh:mm a"}}</div>
                                    <div *ngIf="hourFormat == '24'">{{logCard.date| date:"dd/MM/yyyy"}}
                                        {{'AT' | translate }}
                                        {{logCard.date| date:"HH:mm"}}</div>
                                </div>
                            </mgl-timeline-entry-content>
                            <mgl-timeline-entry-dot *ngIf="logCard.icon == 'report_problem'"
                                style="background: #EFC41A !important;">
                                <mat-icon class="icon-center">{{logCard.icon}}</mat-icon>
                            </mgl-timeline-entry-dot>
                            <mgl-timeline-entry-dot *ngIf="logCard.icon != 'report_problem'">
                                <mat-icon class="icon-center">{{logCard.icon}}</mat-icon>
                            </mgl-timeline-entry-dot>
                            <mgl-timeline-entry-side>
                                <div *ngIf="hourFormat == '12'">{{logCard.date| date:"MM/dd/yyyy"}}
                                    {{'AT' | translate }}
                                    {{logCard.date| date:"hh:mm a"}}</div>
                                <div *ngIf="hourFormat == '24'">{{logCard.date| date:"dd/MM/yyyy"}}
                                    {{'AT' | translate }}
                                    {{logCard.date| date:"HH:mm"}}</div>
                            </mgl-timeline-entry-side>
                        </mgl-timeline-entry>
                    </mgl-timeline>
                </div>
                <ngx-spinner name="spinnerLoadingNewLogs" class="spinner" size="medium" color="#2eb4b3"
                    bdColor="rgba(51,51,51,0)" type="ball-clip-rotate" [fullScreen]="false"></ngx-spinner>

            </mat-tab>

        </mat-tab-group>

    </div>
    <!-- / CONTENT -->

</div>
<!-- END -->

<ng-template #PutAccessSuccessful>
    <h2 matDialogTitle>{{'ACCESS_MANAGEMENT.PUT_ACCESS_SUCCESSFUL' | translate}}</h2>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #NoChanges>
    <h2 matDialogTitle>{{'ACCESS_MANAGEMENT.NO_CHANGES' | translate}}</h2>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #WantToDeleteAccessById>
    <h2 *ngIf="userOrObjectType == 'user' && selectedBoard.board_access.id == selectedUserAccess.id" matDialogTitle>
        {{'POPUP.DELETE_OWN_ACCESS_DIALOG' | translate}}</h2>
    <h2 *ngIf="userOrObjectType == 'guest' || userOrObjectType == 'user' && selectedBoard.board_access.id != selectedUserAccess.id"
        matDialogTitle>{{'POPUP.DELETE_ACCESS_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'POPUP.NO' | translate}}</button>
            <button mat-button matDialogClose (click)="deleteAccessById()">{{'POPUP.YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #ShareGuestAccess>
    <h2 matDialogTitle>{{'POPUP.CHOOSE_A_LANGUAGE' | translate}}</h2>
    <mat-dialog-actions>
        <div class="share-guest-access">
            <div fxLayout="column" fxLayoutAlign="space-between">
                <a href="mailto:?body={{ShareGuestAccessMessage}}" (click)="getShareGuestAccessMessage('en')" mat-button
                    matDialogClose>{{'POPUP.ENGLISH' | translate}}</a>
                <a href="mailto:?body={{ShareGuestAccessMessage}}" (click)="getShareGuestAccessMessage('fr')" mat-button
                    matDialogClose>{{'POPUP.FRENCH' | translate}}</a>
                <a href="mailto:?body={{ShareGuestAccessMessage}}" (click)="getShareGuestAccessMessage('es')" mat-button
                    matDialogClose>{{'POPUP.SPANISH' | translate}}</a>
            </div>
            <button mat-button color="warn" class="cancel-button" matDialogClose>{{'POPUP.CANCEL' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #WantToResetAccessCode>
    <h2 matDialogTitle>{{'ACCESS_MANAGEMENT.RESET_ACCESS_CODE_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'POPUP.NO' | translate}}</button>
            <button mat-button matDialogClose (click)="resetAccessCode()">{{'POPUP.YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #WantToDeleteAccessRecuOrExepById>
    <h2 matDialogTitle>{{'POPUP.DELETE_ACCESS_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'POPUP.NO' | translate}}</button>
            <button mat-button matDialogClose
                (click)="deleteAccessRecuOrExepById()">{{'POPUP.YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #WantToDeleteLogById>
    <h2 matDialogTitle>{{'DELETE_LOG_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'NO' | translate}}</button>
            <button mat-button matDialogClose (click)="deleteLogById()">{{'YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #FeatureComingSoon>
    <h2 matDialogTitle>{{'POPUP.FEATURE_COMING_SOON' | translate}}</h2>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>