export const locale = {
    lang: 'es',
    data: {
        'HEADER': {
            'TITLE_ACCESS': 'ACCESOS',
            'NO_PLACE': 'No hay ningún lugar asociado a su cuenta.',
            'NO_LOCK':'Sin cerraduras',
        },
        'CONTENT': {
            'NO_LOCK':'No hay ninguna cerradura asociada a este lugar.',
            'NO_GUEST': 'No hay invitados para esta cerradura.',
			'NO_BADGE': 'No hay ningún gafete associado a esta cerradura.',
			'NO_KEY': 'No hay ninguna llave associada a esta cerradura.',
            'USERS': 'Usuarios',
            'GUESTS': 'Invitados',
            'GUEST' :'Invitado',
            'ADMINISTRATOR': 'Administrador',
            'MODERATOR': 'Moderador',
            'USER': 'Usuario',
            'COPY_ACCESS':'COPIAR ACCESO',
            'SHARE_ACCESS':'COMPARTIR ACCESO',
            'DELETE':'SUPRIMIR',
            'ADD_AN_ACCESS': 'Añadir un acceso',
			'BADGE':'Gafete',
			'KEY':'Llave',
			'BADGES':'Gafetes',
			'KEYS':'Llaves',
			'ACTIVE_BADGE':'Gafete activo',
			'ACTIVE_KEY':'llave activa',
			'PERMANENT_ACCESS':'Acceso permanente',
			'BADGE_DISABLED':'Gafete desactivado',
			'INITIALIZED_KEY':'Llave inicializada',
			'UNINITIALIZED_KEY':'Llave no inicializada',
			'BATTERY':'Batería :',
        },
        'SCHEDULES': {
            'AT':'a',
            'DAY':{
                'MONDAY': 'Lunes',
                'TUESDAY': 'Martes',
                'WEDNESDAY': 'Miércoles',
                'THURSDAY': 'Jueves',
                'FRIDAY': 'Viernes',
                'SATURDAY': 'Sábado',
                'SUNDAY': 'Domingo'
            },
            'START':'Inicio: ',
            'END': 'Fin: ',
            'RECURRENT_ACCESSES':'Accesos recurrentes',
            'ONE-TIME_ACCESSES': 'Accesos puntuales',
            'AS_AN_ADMINISTRATOR': 'Como administrador,',
            'AS_A_MODERATOR': 'Como moderador,',
            'HAS_A_PERMANENT_ACCESS_TO_THE_LOCK': 'tiene acceso permanente a la cerradura :',
            'ACCESSES_OF': 'Accesos de',
            'NO_RECURRENT_ACCESS': 'no tiene ningún acceso recurrente a la cerradura :',
            'NO_ONE-TIME_ACCESS': 'no tiene ningún acceso puntual a la cerradura :',
			'BADGE_ACCESS':'Accesos del gafete :',
			'THIS_BADGE':'Este gafete',
			'THIS_KEY':'Esta llave',
        },
        'POPUP': {
			'FEATURE_COMING_SOON':'¡Función disponible próximamente!',
            'EMAIL': 'Dirección de e-mail',
            'EMAIL_IS_REQUIRED': 'La dirección de e-mail es necesaria',
            'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS': 'Por favor, introduzca una dirección de e-mail válida',
			'USER_ACCOUNT_ACCESS': 'Acceso usuario',
			'GUEST_MODE_ACCESS': 'Acceso invitado',
			'AUTHORIZATION_LEVEL': 'Nivel de autorización',
			'ACCESS_NAME': 'Nombre del acceso',
			'VALIDATE': 'Validar',
			'USER_ALREADY_HAVE_ACCESS_1':'El usuario cuya dirección de correo electrónico es ',
			'USER_ALREADY_HAVE_ACCESS_2':' ya tiene acceso a esta cerradura.',
			'USER_NOT_EXIST_1':'La dirección de correo electrónico ',
			'USER_NOT_EXIST_2':' no es asociada a ninguna cuenta.',
			'DELETE_ACCESS_DIALOG': '¿Estás seguro de que quieres suprimir este acceso?',
			'DELETE_OWN_ACCESS_DIALOG': '¿Estás seguro de que quieres suprimir tu propio acceso?',
			'YES': 'SI',
            'NO': 'NO',
            'COPY_ACCESS':'Copiar acceso',
            'ACCESS_TO_COPY':'Acceso a copiar',
			'CHOOSE_A_LANGUAGE':'Elija un idioma',
			'ENGLISH':'Inglés',
			'FRENCH':'Francés',
			'SPANISH':'Español',
			'CANCEL': 'Cancelar',
        },
		'GUEST_MESSAGE': {
			'INTRO':'Has sido invitado a una cerradura Elocky. Aquí están tus credenciales de acceso :',
			'LOGIN':'Identificador : ',
			'ACCESS_CODE':'Código de acceso : ',
			'IOS_URL':'iOS: https://itunes.apple.com/us/app/elocky/id1154565981?mt=8',
			'ANDROID_URL':'Android: https://play.google.com/store/apps/details?id=com.elocky',
		},
		'ACCESS_MANAGEMENT': {
			'INFORMATION':'Informaciones',
			'HISTORY':'Histórico',
			'BLUETOOTH':'Bluetooth',
			'REMOTE_OPENING':'Apertura a distancia',
			'ACCESS_CREATION_DATE':'Fecha de creación del acceso',
			'MODE_OF_OPERATION':'Modo de operación',
			'BACK':'Volver',
			'DELETE_ACCESS':'Suprimir el acceso',
			'SAVE':'Guardar',
			'LOGIN':'Identificador',
			'ACCESS_CODE':'Código de acceso',
			'PUT_ACCESS_SUCCESSFUL':'Modificaciones guardadas con éxito.',
			'NO_CHANGES':'No se han hecho cambios.',
			'RESET_ACCESS_CODE_DIALOG':'¿Estás seguro de que quieres restablecer el código de acceso?',
			'ADD_A_RECURRENT_ACCESS': 'Añadir un acceso recurrente',
			'ADD_A_ONE-TIME_ACCESS': 'Añadir un acceso puntual',
			'RECURRENT_ACCESS':'Acceso recurrente',
			'PERMANENT_ACCESS':'Acceso permanente',
			'WARNING_PERMANENT_ACCESS':'¡Cuidado! Añadir un acceso permanente eliminará todos los accesos recurrentes existentes.',
			'TIME_HINT':'* usa las flechas (⇅) para cambiar la hora',
			'DAY':'Día',
			'DAY_IS_REQUIRED':'El día es necesario',
			'START_TIME':'Hora de inicio',
			'STOP_TIME':'Hora de fin',
			'START_TIME_IS_REQUIRED':'La hora de inicio es necesaria',
			'STOP_TIME_IS_REQUIRED':'La hora de fin es necesaria',
			'INVALID_HOURS':'Horas inválidas: la hora de inicio debe ser anterior a la hora de fin.',
			'AUTHORIZATION':'Autorización',
			'PROHIBITION':'Interdicción',
			'FROM':'Desde el',
			'TO':'Hasta el',
			'START_DATE':'Fecha de inicio',
			'STOP_DATE':'Fecha de fin',
			'START_DATE_IS_REQUIRED':'La fecha de inicio es necesaria',
			'STOP_DATE_IS_REQUIRED':'La fecha de fin es necesaria',
			'INVALID_DATES':'Fechas inválidas: la fecha de inicio debe ser anterior a la fecha de fin.',
			'NO_NAME':'Sin nombre',
			'NAME':'Nombre',
			'BADGE_NUMBER':'Número de gafete',
			'STATE':'Estado',
			'CREATION_DATE':'Fecha de creación',
			'REFERENCE':'Referencia',
			'DISABLE':'Desactivar',
			'ENABLE':'Activar',
			'SECURITY_STATE':'Estado de seguridad',
			}
    }
};
