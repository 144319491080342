import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';

import { EventEmitterService } from '../../services/event-emitter.service';
import { ProfileService } from '../../../../core/ProfileService/profile.service';

import { ModifyProfileInformationData } from '../../interfaces/ModifyProfileInformationData';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from '../../../authentication/i18n/en';
import { locale as french } from '../../../authentication/i18n/fr';
import { locale as spanish } from '../../../authentication/i18n/es';

import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './modify-profile-information-dialog.component.html',
  styleUrls: ['./modify-profile-information-dialog.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ModifyProfileInformationDialogComponent implements OnInit {

  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isLoading: boolean;

  modifyProfileInformationForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModifyProfileInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private eventEmitterService: EventEmitterService,
    private profileService: ProfileService

  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;

  }

  ngOnInit(): void {
    this.modifyProfileInformationForm = this._formBuilder.group({
      email: [this.email, [Validators.required, Validators.email]],
      lastname: [this.lastName, [Validators.required]],
      firstname: [this.firstName, [Validators.required]],
      phoneNumber: [this.phoneNumber, Validators.required]
    });
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    let newProfileInformation: ModifyProfileInformationData;
    newProfileInformation = {
      email: form.value.email,
      firstname: form.value.firstname,
      lastname: form.value.lastname,
      phone: form.value.phoneNumber
    }
    if (this.email == newProfileInformation.email && this.lastName == newProfileInformation.lastname && this.firstName == newProfileInformation.firstname && this.phoneNumber == newProfileInformation.phone) {
      this.isLoading = false;
      alert(this.translateService.instant('NO_CHANGES'));
      this.close();

    } else {
      this.profileService.modifyProfileInformation(newProfileInformation)
        .subscribe(
          () => {
            this.isLoading = false;
            localStorage.setItem('first_name', newProfileInformation.firstname);
            localStorage.setItem('last_name', newProfileInformation.lastname);
            localStorage.setItem('currentUser', newProfileInformation.email);

            this.eventEmitterService.onProfileInformationChanged(newProfileInformation);

            this.dialogRef.close(newProfileInformation);

          },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            if (err.error.error.code == 500) {
              alert(this.translateService.instant('EMAIL_ALREADY_EXISTS_CONTENT_1') + newProfileInformation.email + this.translateService.instant('EMAIL_ALREADY_EXISTS_CONTENT_2'));
            }
          }
        );
    }
  }

  close() {
    this.dialogRef.close();
  }

}
