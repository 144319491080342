export const locale = {
    lang: 'en',
    data: {
        'HEADER': {
            'TITLE_ACCESS': 'ACCESS',
            'NO_PLACE': 'No place is associated with your account.',
            'NO_LOCK':'No lock',
        },
        'CONTENT': {
            'NO_LOCK':'No lock is associated with this place.',
            'NO_GUEST': 'There is no guest for this lock.',
			'NO_BADGE': 'There is no badge associated with this lock.',
			'NO_KEY': 'There is no key associated with this lock.',
            'USERS': 'Users',
            'GUESTS': 'Guests',
            'GUEST':'Guest',
            'ADMINISTRATOR': 'Administrator',
            'MODERATOR': 'Moderator',
            'USER': 'User',
            'COPY_ACCESS':'COPY ACCESS',
            'SHARE_ACCESS':'SHARE ACCESS',
            'DELETE':'DELETE',
            'ADD_AN_ACCESS': 'Add an access',
			'BADGE':'Badge',
			'KEY':'Key',
			'BADGES':'Badges',
			'KEYS':'Keys',
			'ACTIVE_BADGE':'Active badge',
			'ACTIVE_KEY':'Active key',
			'DISABLED_KEY':'Disabled key',
			'PERMANENT_ACCESS':'Permanent access',
			'BADGE_DISABLED':'Badge disabled',
			'INITIALIZED_KEY':'Initialized key',
			'UNINITIALIZED_KEY':'Uninitialized key',
			'BATTERY':'Battery:',
        },
        'SCHEDULES': {
            'AT':'at',
            'DAY':{
                'MONDAY': 'Monday',
                'TUESDAY': 'Tuesday',
                'WEDNESDAY': 'Wednesday',
                'THURSDAY': 'Thursday',
                'FRIDAY': 'Friday',
                'SATURDAY': 'Saturday',
                'SUNDAY': 'Sunday'
            },
            'START':'Start: ',
            'END': 'End: ',
            'RECURRENT_ACCESSES':'Recurrent accesses',
            'ONE-TIME_ACCESSES': 'One-time accesses',
            'AS_AN_ADMINISTRATOR': 'As an administrator,',
            'AS_A_MODERATOR': 'As a moderator,',
            'HAS_A_PERMANENT_ACCESS_TO_THE_LOCK': 'has a permanent access to the lock:',
            'ACCESSES_OF': 'Accesses of',
            'NO_RECURRENT_ACCESS': 'hasn\'t got any recurrent access to the lock:',
            'NO_ONE-TIME_ACCESS': 'hasn\'t got any one-time access to the lock:',
			'BADGE_ACCESS':'Accesses of the badge:',
			'THIS_BADGE':'This badge',
			'THIS_KEY':'This key',
        },
        'POPUP': {
			'FEATURE_COMING_SOON':'Feature coming soon!',
            'EMAIL': 'Email',
            'EMAIL_IS_REQUIRED': 'Email is required',
            'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS': 'Please enter a valid email address',
			'USER_ACCOUNT_ACCESS': 'User account access',
			'GUEST_MODE_ACCESS': 'Guest mode access',
			'AUTHORIZATION_LEVEL': 'Authorization level',
			'ACCESS_NAME': 'Access name',
			'VALIDATE': 'Validate',
			'USER_ALREADY_HAVE_ACCESS_1':'The user whose email address is ',
			'USER_ALREADY_HAVE_ACCESS_2':' has already access to this lock.',
			'USER_NOT_EXIST_1':'The email address ',
			'USER_NOT_EXIST_2':' is not assiociated with any account.',
			'DELETE_ACCESS_DIALOG': 'Are you sure you want to delete this access?',
			'DELETE_OWN_ACCESS_DIALOG': 'Are you sure you want to delete your own this access?',
			'YES': 'YES',
            'NO': 'NO',
            'COPY_ACCESS':'Copy access',
            'ACCESS_TO_COPY':'Access to copy',
			'CHOOSE_A_LANGUAGE':'Choose a language',
			'ENGLISH':'English',
			'FRENCH':'French',
			'SPANISH':'Spanish',
			'CANCEL': 'Cancel',
        },
		'GUEST_MESSAGE': {
			'INTRO':'You have been invited to an Elocky lock. Use these credentials to log in:',
			'LOGIN':'Login: ',
			'ACCESS_CODE':'Access code: ',
			'IOS_URL':'iOS: https://itunes.apple.com/us/app/elocky/id1154565981?mt=8',
			'ANDROID_URL':'Android: https://play.google.com/store/apps/details?id=com.elocky',
		},
		'ACCESS_MANAGEMENT': {
			'INFORMATION':'Information',
			'HISTORY':'History',
			'BLUETOOTH':'Bluetooth',
			'REMOTE_OPENING':'Remote opening',
			'ACCESS_CREATION_DATE':'Access creation date',
			'MODE_OF_OPERATION':'Mode of operation',
			'BACK':'Back',
			'DELETE_ACCESS':'Delete access',
			'SAVE':'Save',
			'LOGIN':'Login',
			'ACCESS_CODE':'Access code',
			'PUT_ACCESS_SUCCESSFUL':'Changes saved successfully.',
			'NO_CHANGES':'No change has been made.',
			'RESET_ACCESS_CODE_DIALOG':'Are you sure you want to reset the access code?',
			'ADD_A_RECURRENT_ACCESS': 'Add a recurrent access',
			'ADD_A_ONE-TIME_ACCESS': 'Add a one-time access',
			'RECURRENT_ACCESS':'Recurrent access',
			'PERMANENT_ACCESS':'Permanent access',
			'WARNING_PERMANENT_ACCESS':'Warning! Adding a permanent access will remove all existing recurrent accesses.',
			'TIME_HINT':'* use arrows (⇅) to change the time',
			'DAY':'Day',
			'DAY_IS_REQUIRED':'Day is required',
			'START_TIME':'Start time',
			'STOP_TIME':'Stop time',
			'START_TIME_IS_REQUIRED':'Start time is required',
			'STOP_TIME_IS_REQUIRED':'Stop time is required',
			'INVALID_HOURS':'Invalid hours: start time must be earlier than stop time.',
			'AUTHORIZATION':'Authorization',
			'PROHIBITION':'Prohibition',
			'FROM':'From',
			'TO':'To',
			'START_DATE':'Start date',
			'STOP_DATE':'Stop date',
			'START_DATE_IS_REQUIRED':'Start date is required',
			'STOP_DATE_IS_REQUIRED':'Stop date is required',
			'INVALID_DATES':'Invalid dates: start date must be earlier than stop date.',
			'NO_NAME':'No name',
			'NAME':'Name',
			'BADGE_NUMBER':'Badge number',
			'STATE':'State',
			'CREATION_DATE':'Creation date',
			'REFERENCE':'Reference',
			'DISABLE':'Disable',
			'ENABLE':'Enable',
			'SECURITY_STATE':'Security state',
		}
    }
};
