import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { HttpErrorResponse, HttpClient } from '@angular/common/http';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { PlaceService } from '../../../core/PlacesService/place.service';
import { PlaceInfos } from "../../../core/PlacesService/interfaces/PlaceInfos";

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as spanish } from '../i18n/es';

@Component({
  selector: 'create-place',
  templateUrl: './create-place.component.html',
  styleUrls: ['./create-place.component.scss'],
  animations: fuseAnimations
})

export class CreatePlaceComponent implements OnInit {

  timezone = new Array<any>();
  country = new Array<any>();
  formAddress: PlaceInfos = {} as PlaceInfos;
  placeForm: FormGroup;
  submitted: boolean = false;

  @Input('dialogRef') dialogRef:MatDialogRef<TemplateRef<any>>;
  @Output() onAddPlace = new EventEmitter<any>();
  @ViewChild('FormAddPlace') FormAddPlace: TemplateRef<any>;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private placeService: PlaceService,
    private dialog: MatDialog) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    ngOnInit(): void {
      this.placeService.getListTimezoneCountry().subscribe(
        (response) => {
          this.timezone = response["timezone"];
          this.country = response["country"];
        },

        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
      this.placeForm = new FormGroup({
        name: new FormControl(""),
        complement: new FormControl(""),
        timezone: new FormControl("", Validators.required),
        address: new FormControl("", Validators.required),
        city: new FormControl("", Validators.required),
        country: new FormControl("", Validators.required),
        zipcode: new FormControl("", Validators.required),
        latitude: new FormControl(""),
        longitude: new FormControl("")
      })
    }

    get f() { return this.placeForm.controls; }

    onAutocompleteSelected(address: any) : void {
      this.placeForm.patchValue({address: address["name"] ? address["name"] : ""});
      this.placeForm.patchValue({city: address["address_components"][2] ? address["address_components"][2]["long_name"] : ""});
      this.placeForm.patchValue({country: address["address_components"][5] ? address["address_components"][5]["long_name"] : ""});
      this.placeForm.patchValue({zipcode: address["address_components"][6] ? address["address_components"][6]["long_name"] : ""});
    }

    onLocationSelected(address: any) : void {
      let location = address["latitude"] + "," + address["longitude"];
      this.placeService.getTimeZoneByLocation(location).subscribe(
        (response) => {
          this.placeForm.patchValue({timezone: response["timeZoneId"] ? response["timeZoneId"] : ""})
          this.placeForm.patchValue({latitude: address["latitude"]});
          this.placeForm.patchValue({longitude: address["longitude"]});
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    }

    onCloseDialog(): void {
      this.dialogRef.close();
    }
    OnSendForm(): void {
      this.submitted = true;
      if(this.placeForm.invalid) return;

      let newplace = this.placeForm.value as PlaceInfos;

      this.placeService.getCountryIdByName(newplace.country).subscribe(
        (id) => {
          newplace.country = id;
        }
      );
      this.placeService.getTimezoneIdByName(newplace.timezone).subscribe(
        (id) => {
          newplace.timezone = id;
        }
      );
      this.onAddPlace.emit(newplace);
      this.onCloseDialog();
    }
  }
