export const locale = {
	lang: 'es',
	data: {
		'CANCEL_BUTTON':'Cancel',
		'SAVE_BUTTON':'Save',
		'CLOSE_BUTTON':'Close',
		'RENAME_BUTTON':'Rename',
		'NO_BUTTON':'No',
		'YES_BUTTON':'Yes',
		'TITLE_PLACES':'PLACES',
		'TITLE_INFORMATIONS':'Informations',
		'TITLE_USER':'Users',
		'TITLE_ALARM':'Alarms',
		'TITLE_CHECK':'Check In/Check Out',
		'TITLE_MAP_MODE':'Map Mode',
		'RENAME_PLACE':'Rename place',
		'NEW_NAME_PLACE':'New name',
		'DELETE_PLACE':'Delete place ?',
		'TITLE_RENAME_PLACE':'Rename',
		'TITLE_CREATE_PLACE':'Create a new place',
		'TITLE_DELETE_PLACE':'Delete',
		'TITLE_DELETE_USER_PLACE':'Delete user',
		'REMOVE_YOU_PLACE':'Delete you from this place ?',
		'INFORMATIONS_PLACES':'Place informations',
		'INFORMATIONS_PLACES_NAME':'Place name',
		'INFORMATIONS_PLACES_ADDRESS':'Address',
		'INFORMATIONS_PLACES_COMPLEMENT':'Complement',
		'INFORMATIONS_PLACES_COUNTRY':'Country',
		'INFORMATIONS_PLACES_CITY':'City',
		'INFORMATIONS_PLACES_ZIP':'Zip code',
		'INFORMATIONS_PLACES_TIMEZONE':'Timezone',
		'INFORMATIONS_PLACES_PHOTO':'Photo',
		'INFORMATIONS_PLACES_PHOTO_SAVE':'Save photo',
		'USER_CHANGE_TYPE':'Change user type',
		'USER_DELETE':'Delete user ?',
		'USER_CHANGE_TYPE_TITLE':'Change user\' right',
		'ALARM_GENERAL':'General alarm',
		'ALARM_PERMANENT':'Permanent alarm',
		'ALARM_BEACON':'Beacon',
		'ALARM_LOCK_V1':'Lock v1',
		'ALARM_LOCK_V2':'Lock v2',
		'CHECK_DEFAULT':'Default',
		'CHECK_ENABLE':'Enable general alarm',
		'CHECK_IN':'Check in',
		'CHECK_OUT':'Check out',
		'CHECK_TIME':'Time',
		'CREATE_PLACE_SEARCH':'Search in Google Maps',
		'CREATE_PLACE_ADDRESS_REQUIRED':'An address is required !',
		'CREATE_PLACE_TIMEZONE_REQUIRED':'A timezone is required !',
		'CREATE_PLACE_CITY_REQUIRED':'A city is required !',
		'CREATE_PLACE_COUNTRY_REQUIRED':'A country is required !',
		'CREATE_PLACE_ZIP_REQUIRED':'A zip code is required !',
	}
}
