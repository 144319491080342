import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DashboardData } from './interfaces/DashboardData';
import { Observable } from 'rxjs';

import { ApiConfigData } from '../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  dashboardDataApi: DashboardData;

  DashboardApiUrl: string = this.api_endpoint + '/api/dashboard/elocky.json?bearer=';
  AlarmStateApiUrl: string = this.api_endpoint + '/api/address/alarm/edit/';

  constructor
    (
      private httpClient: HttpClient
    ) {

  }

  getDashboardData(): Observable<DashboardData> {
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<DashboardData>
      (
        this.DashboardApiUrl + token
      )

  }

  updateAlarmState(selectedPlaceId: number, alarmState: number) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.put
      (
        this.AlarmStateApiUrl + selectedPlaceId + '/' + alarmState + '?bearer=' + token,
        //  { headers : new HttpHeaders().set('Authorization','Bearer ' + token) }, 
        //  { headers: {'Authorization':'Bearer ' + token} },
        //  Le header ne fonctionne pas. Seul le << '?bearer=' + token >> dans l'url fonctionne ...
        { observe: 'response' }
      );
  }

}




