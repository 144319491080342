import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ModifyProfileInformationData } from '../interfaces/ModifyProfileInformationData';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeGetProfileInformation = new EventEmitter();
  invokeGetProfilePhoto = new EventEmitter();

  subsVarGetProfileInformation: Subscription;
  subsVarGetProfilePhoto: Subscription;

  constructor() { }

  onProfileInformationChanged(newProfileInformation: ModifyProfileInformationData) {
    this.invokeGetProfileInformation.emit(newProfileInformation);
  }

  onProfilePhotoChanged(newProfilePhoto: string) {
    this.invokeGetProfilePhoto.emit(newProfilePhoto);
  }

}






