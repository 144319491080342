import { Component, OnInit, Input } from '@angular/core';
import { PlaceData } from '../interfaces/PlaceData';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

interface Marker {
	latitude: number;
	longitude: number;
	label?: string;
	content: InfoContent;
}
interface InfoContent {
	name: string;
	photo: string;
	address: string;
}

@Component({
	selector: 'place-map',
	templateUrl: './place-map.component.html',
	styleUrls: ['./place-map.component.scss']
})
export class PlaceMapComponent implements OnInit {

	@Input("places") places: Array<PlaceData>;
	@Input("origin") origin;
	markers = new Array<Marker>();
	zoom: number = 15;

	constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private _fuseConfigService: FuseConfigService,
		private translateService: TranslateService) { }

		ngOnInit(): void {
			this.places.forEach((item, index) => {
				this.markers.push({
					latitude:item.latitude,
					longitude:item.longitude,
					label:index.toString(),
					content: {
						name: item.name,
						photo: item.photo,
						address: item.address
					}
				})
			})
			if(this.origin == null || this.origin == undefined){
				this.origin = {
					latitude:this.markers[0].latitude,
					longitude:this.markers[0].longitude
				}
			}
			this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {

			});
		}

	}
