<agm-map
  [latitude]="origin.latitude"
  [longitude]="origin.longitude"
  [zoom]="zoom"
  [disableDefaultUI]="false"
  [zoomControl]="false">

  <agm-marker
      *ngFor="let m of markers; let i = index"
      [latitude]="m.latitude"
      [longitude]="m.longitude"
      [label]="m.label">

    <agm-info-window>
      <div class="info-content">
        <div class="title">{{m.content.name}}</div>
        <div class="container-image">
          <img [src]="m.content.photo" />
        </div>
        <div class="address">{{m.content.address}}</div>
      </div>
    </agm-info-window>

  </agm-marker>
</agm-map>
