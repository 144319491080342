<div class="content_user" *ngIf="current_user != null">
  <div
  *ngFor="let u of current_user"
  class="container-user">
  <div class="container-photo">
    <div class="photo">
      <img [src]="u.photo" />
    </div>
  </div>
  <div class="container-infos">
    <div class="infos">
      <div class="name">{{ u.firstName }} {{ u.lastName }}</div>
      <div class="state">
        <span>
          {{ getStateNameById(u.state) }}
        </span>
      </div>
    </div>
    <div class="button" *ngIf="u.state != 0">
      <mat-icon (click)="onFormSettingOpen(u.id)">settings</mat-icon>
      <mat-icon (click)="onFormDeleteOpen(u.id)">delete</mat-icon>
    </div>
  </div>
</div>
</div>

<ng-template #FormSettingUser let-data>
  <form id="formState" [formGroup]="formState" (ngSubmit)="onSendForm()">
    <h2>{{'USER_CHANGE_TYPE_TITLE' | translate}}</h2>
    <mat-form-field>
      <mat-label>{{'USER_CHANGE_TYPE' | translate}}</mat-label>
      <mat-select formControlName="state" required>
        <ng-container *ngFor="let s of list_state">
          <mat-option  *ngIf="s.id != 0" [value]="s.name">
            {{ s.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <input formControlName="id" type="hidden" [value]="data.id_user">
    <button mat-raised-button color="primary" class="btn btn-primary" [disabled]="!hasFormChanged(data.id_user)" type="submit">Save</button>
    <button *ngIf="hasFormChanged(data.id_user)" mat-stroked-button color="primary" (click)="onCloseDialog()" type="button">Cancel</button>
  </form>
</ng-template>


<ng-template #FormDeleteUser let-data>
  <div id="formDeleteUser">
    <h2>{{'USER_DELETE' | translate}}</h2>
    <button mat-raised-button color="primary" (click)="onDeleteUser(data.id_user)">{{'SAVE_BUTTON' | translate}}</button>
    <button mat-stroked-button color="primary" (click)="onCloseDialog()">{{'CANCEL_BUTTON' | translate}}</button>
  </div>
</ng-template>
