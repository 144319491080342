<div id="places" class="page-layout simple tabbed " fxLayout="column">
  <!-- HEADER -->
  <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text s-18">home</mat-icon>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">{{'TITLE_PLACES' | translate}}</span>
      </div>
      <div class="h1 mt-16" [@animate]="{value:'*',params:{x:'50px'}}">{{'TITLE_PLACES' | translate}}</div>
      <span class="map-mode-title">{{'TITLE_MAP_MODE' | translate}}</span>
      <button *ngIf="isMapEnabled" class="map-mode-button"(click)="onClickMapMode()">OFF</button>
      <button *ngIf="!isMapEnabled" class="map-mode-button"(click)="onClickMapMode()">ON</button>
    </div>
  </div>

  <div class="content">
    <div class="card-container">
      <place-card [place]="place"
                  (onSelectRename)="openFormRename($event)"
                  (onSelectDelete)="openFormDelete($event)"
                  (onSelectDeleteUser)="openFormDeleteUser($event)"
                  (onSelectSetting)="onSelectSetting($event)"
                  (onClickOnCard)="onClickOnCard($event)"
                  *ngFor="let place of places; let i = index"
                  [ngClass]="{'mapOpen':isMapEnabled}">
      </place-card>
      <div class="add-card" [ngClass]="{'mapOpen':isMapEnabled}">
        <mat-card *ngIf="isAddButtonShow" class="place-card" (click)="onClickAddPlace()">
          <mat-card-content class="card-content">
            <mat-icon class="cst-icon-add">add_circle</mat-icon>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <place-map
      *ngIf="isMapEnabled"
      [places]="places"
      [origin]="origin_map">
    </place-map>
  </div>
</div>

<ng-template #FormActionPlaces let-data>
  <ng-container *ngIf="data.type == 'rename'">
    <h2 mat-dialog-title>{{ 'TITLE_RENAME_PLACE' | translate }}</h2>
  </ng-container>
  <ng-container *ngIf="data.type == 'delete'">
    <h2 mat-dialog-title>{{ 'TITLE_DELETE_PLACE' | translate }}</h2>
  </ng-container>
  <ng-container *ngIf="data.type == 'delete-user'">
    <h2 mat-dialog-title>{{ 'TITLE_DELETE_USER_PLACE' | translate }}</h2>
  </ng-container>

  <div *ngIf="data.type == 'rename'">
    <div mat-dialog-content>
      <p>{{'RENAME_PLACE' | translate}}</p>
      <mat-form-field>
        <mat-label>{{'NEW_NAME_PLACE' | translate}}</mat-label>
        <input matInput [(ngModel)]="dialog_newname">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCloseDialog()">{{'CLOSE_BUTTON' | translate}}</button>
      <button mat-button [mat-dialog-close]="dialog_newname">{{'RENAME_BUTTON' | translate}}</button>
    </div>
  </div>
  <div *ngIf="data.type == 'delete'">
    <div mat-dialog-content>
      <p>{{'DELETE_PLACE' | translate}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCloseDialog()">{{'NO_BUTTON' | translate}}</button>
      <button mat-button (click)="deletePlace(data.index)">{{'YES_BUTTON' | translate}}</button>
    </div>
  </div>
  <div *ngIf="data.type == 'delete-user'">
    <div mat-dialog-content>
      <p>{{'REMOVE_YOU_PLACE' | translate}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCloseDialog()">{{'NO_BUTTON' | translate}}</button>
      <button mat-button (click)="deleteUserPlace(data.index)">{{'YES_BUTTON' | translate}}</button>
    </div>
  </div>
</ng-template>
<ng-template #FormAddPlace>
  <create-place   [dialogRef]="dialogRef"
                  (onAddPlace)="addPlace($event)"></create-place>
</ng-template>
