import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeOnPlaceSelection = new EventEmitter();
  invokeOnBoardSelection = new EventEmitter();
  invokeChangeTabIndex = new EventEmitter();
  invokeGetAccessList = new EventEmitter();

  subsVarOnPlaceSelection: Subscription;
  subsVarOnBoardSelection: Subscription;
  subsVarChangeTabIndex: Subscription;
  subsVarGetAccessList: Subscription;

  constructor() { }

  onAddAccessDialogComponentPlaceSelection(placeId: number) {
    this.invokeOnPlaceSelection.emit(placeId); // invoque onPlaceSelection(placeId) de AccessComponent
  }

  onAddAccessDialogComponentBoardSelection(boardId: number) {
    this.invokeOnBoardSelection.emit(boardId); // invoque onBoardSelection(boardId) de AccessComponent
  }

  onAddAccessDialogComponentRadioButtonValueChange(selectedAccessType: string) {
    this.invokeChangeTabIndex.emit(selectedAccessType); // invoque changeTabIndex(selectedAccessType) de AccessComponent
  }

  onAddAccessDialogComponentAddAccess() {
    this.invokeGetAccessList.emit(); // invoque getAccessList de AccessComponent
  }
}