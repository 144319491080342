import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { locale as spanish } from './i18n/es';

import { NgxSpinnerService } from 'ngx-spinner';

import { HttpErrorResponse } from '@angular/common/http';

import { AutoRedirectionService } from '../../core/AutoRedirectionService/auto-redirection.service';
import { AccessService } from '../access/services/access.service';
import { ProfileService } from '../../core/ProfileService/profile.service';
import { PlacePhotoService } from '../../core/PlacesService/place-photo.service';
import { EventEmitterService } from './services/event-emitter.service';

import { ProfileDataApi } from '../../core/ProfileService/ProfileDataApi';
import { AccessListData } from '../access/interfaces/AccessListData';
import { UserAccessData } from '../access/interfaces/UserAccessData';
import { ModifyProfileInformationData } from './interfaces/ModifyProfileInformationData';

import { UserPhotoService } from '../../core/ProfileService/user-photo.service';

import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';

import { SchedulesDialogComponent } from '../access/dialogs/schedules-dialog/schedules-dialog.component';
import { ModifyProfileInformationDialogComponent } from './dialogs/modify-profile-information-dialog/modify-profile-information-dialog.component';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class ProfileComponent implements OnInit {

  profileData: ProfileDataApi;
  accessListData: AccessListData;
  userPhotoUrl: string;
  selectedAccessBoardName: string;
  accessToDeleteId: number;
  deleteOwnAccessButtonClicked: boolean = false;
  newProfileInformation: ModifyProfileInformationData;
  selectedFile: ImageSnippet;

  @ViewChild('WantToDeleteOwnAccess') WantToDeleteOwnAccess: TemplateRef<any>;
  @ViewChild('IncorrectFileType') IncorrectFileType: TemplateRef<any>;
  @ViewChild('FileTooBig') FileTooBig: TemplateRef<any>;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private autoRedirectionService: AutoRedirectionService,
    private profileService: ProfileService,
    private accessService: AccessService,
    private userPhotoService: UserPhotoService,
    private placePhotoService: PlacePhotoService,
    private dialog: MatDialog,
    private eventEmitterService: EventEmitterService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: false
        },
        toolbar: {
          hidden: false
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };

  }

  ngOnInit(): void {
    // If the user is not authenticated, he is redirected to the login page.
    // If the user is authenticated but the token has expired, he is redirected to the "session locked" page.
    this.autoRedirectionService.redirectUserIfNecessary();

    // On récupère les informations du profil de l'utilisateur
    this.profileService.getProfileData(localStorage.getItem('token'))
      .subscribe(
        (response) => {
          this.profileData = response.body;
          this.userPhotoUrl = this.userPhotoService.getNoAuthUserPhotoURLWithParameter(this.profileData.user[0].photo);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );

    // On récupère la liste des accès de l'utilisateur
    this.accessService.getAccessListByUser()
      .subscribe(
        (response) => {
          this.accessListData = response;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  onAccessWidgetClick(accessId: number) {
    // Cette condition règle le bug suivant : quand on appuyait sur l'icon delete de son propre accès, 
    // la fonction onUserWidgetClick s'éxecutait en même temps ce qui n'est pas voulu.
    if (this.deleteOwnAccessButtonClicked == false) {
      let selectedUserAccess: UserAccessData;
      let accessIndex: number;
      let admin_address: string;

      for (let i = 0; i < this.accessListData.access.length; i++) {
        if (this.accessListData.access[i].id == accessId) {
          accessIndex = i;
          selectedUserAccess = JSON.parse(JSON.stringify(this.accessListData.access[i]));
        }
      }

      selectedUserAccess.firstName = this.profileData.user[0].first_name;
      selectedUserAccess.lastName = this.profileData.user[0].last_name;

      for (let i = 0; i < this.accessListData.access[accessIndex].board.address.admin_address.length; i++) {
        if (this.accessListData.access[accessIndex].user_reference == this.accessListData.access[accessIndex].board.address.admin_address[i].user_reference) {
          admin_address = this.accessListData.access[accessIndex].board.address.admin_address[i].name;
        }
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        admin_address: admin_address,
        address: this.accessListData.access[accessIndex].board.address.address,
        board: this.accessListData.access[accessIndex].board.name,
        typeOfUserOrObject: 'user',
        selectedUserAccess: selectedUserAccess
      };

      if (selectedUserAccess.type_access.id == 3) {
        dialogConfig.width = '80%';
      }

      this.dialog.open(SchedulesDialogComponent, dialogConfig);
    }
  }

  openWantToDeleteOwnAccessDialog(boardName: string, accessId: number) {
    this.deleteOwnAccessButtonClicked = true;
    this.selectedAccessBoardName = boardName;
    this.accessToDeleteId = accessId;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.dialog.open(this.WantToDeleteOwnAccess, dialogConfig);
  }

  setDeleteOwnAccessButtonClickedToFalse() {
    this.deleteOwnAccessButtonClicked = false;
  }

  deleteAccessById() {
    this.accessService.deleteAccessById(this.accessToDeleteId)
      .subscribe(
        () => {
          for (let accessIndex = 0; accessIndex < this.accessListData.access.length; accessIndex++) {
            if (this.accessListData.access[accessIndex].id == this.accessToDeleteId) {
              this.accessListData.access.splice(accessIndex, 1);
            }
          }
          // Si on a supprimé le dernier accès, il faut que le message "NO_ACCESS" s'affiche
          if (this.accessListData.access[0] == undefined) {
            this.accessListData.access = undefined;
          }
          this.deleteOwnAccessButtonClicked = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.deleteOwnAccessButtonClicked = false;
          if (err.error.errors[0] == "Error not moderator") {
            alert("Error: not moderator");
          }
        }
      );
  }

  onModifyButtonClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      firstName: this.profileData.user[0].first_name,
      lastName: this.profileData.user[0].last_name,
      email: this.profileData.user[0].email,
      phoneNumber: this.profileData.user[0].phone
    }
    dialogConfig.minWidth = '20%';
    dialogConfig.disableClose = true;

    let dialogRef = this.dialog.open(ModifyProfileInformationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (data: ModifyProfileInformationData) => {
        if (data != undefined) {
          this.profileData.user[0].first_name = data.firstname;
          this.profileData.user[0].last_name = data.lastname;
          this.profileData.user[0].phone = data.phone;
          this.profileData.user[0].email = data.email;
        }
      }
    );

  }

  processFile(profilePhotoInput: any) {
    const file: File = profilePhotoInput.files[0];

    let fullFileType = file.type;
    let fileType = fullFileType.split("/");
    let fileSize = file.size;

    if (fileType[0] != 'image') {
      this.dialog.open(this.IncorrectFileType);
    } else if (fileType[0] == 'image' && fileSize > 10000000) { // 10 Mo
      this.dialog.open(this.FileTooBig);
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.profileService.uploadProfileImage(this.selectedFile.file)
          .subscribe(
            () => {
              this.userPhotoUrl = reader.result.toString();
              // We update toolbar profile photo and navbar profile photo
              this.eventEmitterService.onProfilePhotoChanged(this.userPhotoUrl);
              localStorage.setItem('photo', this.userPhotoUrl);
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              if (err.error.errors[0] == "format") {
                this.dialog.open(this.IncorrectFileType);
              }
            });
      });
      reader.readAsDataURL(file);
    }
  }

  onChangePasswordButtonClick(){
    this.dialog.open(ChangePasswordDialogComponent);
  }

}