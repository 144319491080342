import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { PlaceService } from '../../../../core/PlacesService/place.service';
import { SnackbarService } from '../../../../core/SnackbarService/snackbar.service';

import { HttpErrorResponse } from '@angular/common/http';

import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { PlaceData } from '../../interfaces/PlaceData';
import { CheckInOutData } from '../../interfaces/CheckInOutData';

import { Observable, Subscription } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

@Component({
  selector: 'check-in-out-lieux',
  templateUrl: './check-in-out.component.html',
  styleUrls: ['./check-in-out.component.scss'],
  animations: fuseAnimations
})
export class CheckInOutComponent implements OnInit {

  formCheckInOut: FormGroup = null;
  @Input('obsCurrent$') obsCurrent$:Observable<PlaceData>;
  subCurrentPlace: Subscription;
  current_place: PlaceData;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private placeService: PlaceService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    ngOnInit() : void {
      this.subCurrentPlace = this.obsCurrent$.subscribe(
        (response) => {
          this.current_place = response;
          this.formCheckInOut = new FormGroup({
            enableDefault:new FormControl(this.current_place.check["default"] == 1),
            checkIn:new FormControl(this.getDateToForm(this.current_place.check["in"])),
            checkOut:new FormControl(this.getDateToForm(this.current_place.check["out"]))
          })
        }
      )
    }

    getEnableDefaultValue() : string {
      return this.formCheckInOut.get("enableDefault").value;
    }
    onSendForm() : void {

      let value = this.formCheckInOut.value;
      // console.log(value);
      let data = {
        check_bool:value["enableDefault"] ? 1 : 0,
        check_in:value["checkIn"],
        check_out:value["checkOut"]
      }
      this.placeService.editCheckInOutByPlaceId(this.current_place.id, data).subscribe(
        (response) => {
          this.snackbarService.makeSimpleSnackBar("Check in / out update");
        },
        (err: HttpErrorResponse) => {
          this.snackbarService.makeSimpleSnackBar(SnackbarService.WRONG_MESSAGE);
          console.log(err);
        }
      )
    }
    hasFormChanged() : boolean {
      const newValue = this.formCheckInOut.value;
      const oldValue = this.current_place["check"];

      let timeIn = this.getDateToForm(oldValue["in"])
      let timeOut = this.getDateToForm(oldValue["out"])

      return (
        newValue["enableDefault"] != (oldValue["default"] == 1) ||
        newValue["checkIn"] != timeIn ||
        newValue["checkOut"] != timeOut
      );
    }
    cancelChoice() : void {
      const oldValue = this.current_place["check"];

      let timeIn = this.getDateToForm(oldValue["in"])
      let timeOut = this.getDateToForm(oldValue["out"])

      this.formCheckInOut.get("enableDefault").setValue(this.current_place["check"]["default"] == 1);
      this.formCheckInOut.get("checkIn").setValue(timeIn);
      this.formCheckInOut.get("checkOut").setValue(timeOut);
    }
    getDateToForm(value:string) : string {
      let date = new Date(value);
      let h = date.getHours().toString().length == 1 ? "0" + date.getHours() : date.getHours();
      let m = date.getMinutes().toString().length == 1 ? "0" + date.getMinutes() : date.getMinutes();
      return h + ":" + m;
    }
    ngOnDestroy() : void {
      this.subCurrentPlace.unsubscribe();
    }

  }
