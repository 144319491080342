import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { AccessData } from '../access/interfaces/AccessData';
import { Router } from '@angular/router';
import { AccessDataSaveService } from '../access/services/access-data-save.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, NgForm, FormControl } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AutoRedirectionService } from '../../core/AutoRedirectionService/auto-redirection.service';
import { PlacePhotoService } from '../../core/PlacesService/place-photo.service';
import { UserPhotoService } from '../../core/ProfileService/user-photo.service';
import { AccessManagementService } from './services/access-management.service';
import { AccessService } from '../access/services/access.service';
import { EventEmitterService } from './services/event-emitter.service';
import { ParseTimelineService } from '../timeline/services/parse-timeline.service';
import { TimelineService } from '../timeline/services/timeline.service';

import { locale as english } from '../access/i18n/en';
import { locale as french } from '../access/i18n/fr';
import { locale as spanish } from '../access/i18n/es';

import { locale as englishTimeline } from '../timeline/i18n/en';
import { locale as frenchTimeline } from '../timeline/i18n/fr';
import { locale as spanishTimeline } from '../timeline/i18n/es';

import { HttpErrorResponse } from '@angular/common/http';

import { fuseAnimations } from '@fuse/animations';

import { BoardData } from '../access/interfaces/BoardData';
import { UserAccessData } from '../access/interfaces/UserAccessData';
import { GuestAccessData } from '../access/interfaces/GuestAccessData';
import { PlaceData } from '../interfaces/PlaceData';
import { formatDate } from '@angular/common';
import { PutAccessData } from './interfaces/PutAccessData';
import { LogData } from '../timeline/interfaces/LogData';
import { LogCard } from '../timeline/interfaces/LogCard';
import { BadgeData } from '../access/interfaces/BadgeData';
import { ElectrokeyData } from '../access/interfaces/ElectrokeyData';
import { PutBadgeNameData } from './interfaces/PutBadgeNameData';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ResetAccessCodeData } from './interfaces/ResetAccessCodeData';
import { AddAccessRecuComponent } from './dialogs/add-access-recu/add-access-recu.component';
import { AddAccessExepComponent } from './dialogs/add-access-exep/add-access-exep.component';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class AccessManagementComponent implements OnInit, OnDestroy {

  @ViewChild('PutAccessSuccessful') PutAccessSuccessful: TemplateRef<any>;
  @ViewChild('NoChanges') NoChanges: TemplateRef<any>;
  @ViewChild('WantToDeleteAccessById') WantToDeleteAccessById: TemplateRef<any>;
  @ViewChild('ShareGuestAccess') ShareGuestAccess: TemplateRef<any>;
  @ViewChild('WantToResetAccessCode') WantToResetAccessCode: TemplateRef<any>;
  @ViewChild('WantToDeleteAccessRecuOrExepById') WantToDeleteAccessRecuOrExepById: TemplateRef<any>;
  @ViewChild('WantToDeleteLogById') WantToDeleteLogById: TemplateRef<any>;
  @ViewChild('FeatureComingSoon') FeatureComingSoon: TemplateRef<any>;

  selectedAddress: PlaceData;
  selectedBoard: BoardData;
  accessData: AccessData;
  badges = new Array<BadgeData>();
  keys = new Array<ElectrokeyData>();
  userOrObjectId: number;
  userOrObjectType: string;
  selectedUserAccess: UserAccessData;
  selectedGuestAccess: GuestAccessData;
  selectedBadge: BadgeData;
  selectedKey: ElectrokeyData;

  subsVarSelectedAddress: Subscription;
  subsVarSelectedBoard: Subscription;
  subsVarAccessData: Subscription;
  subsVarBadges: Subscription;
  subsVarKeys: Subscription;
  subsVarUserOrObjectId: Subscription;
  subsVarUserOrObjectType: Subscription;
  subsVarUserAccess: Subscription;
  subsVarGuestAccess: Subscription;
  subsVarBadge: Subscription;
  subsVarKey: Subscription;

  userInformationForm: FormGroup;
  guestInformationForm: FormGroup;
  badgeInformationForm: FormGroup;
  keyInformationForm: FormGroup;
  floatLabelControl = new FormControl('always');

  isLoading: boolean;

  placePhotoURL: string;
  profilePhotoURL: string;

  accessToDeleteId: number;
  ShareGuestAccessMessage: string;

  accessRecuOrExepToDeleteId: number;
  typeOfAccessToDelete: string;

  hourFormat: string;

  tabIndex: number;

  logData: LogData;
  logCards = new Array<LogCard>();
  totalLogs: number;
  currentLog: number;
  firstAccessLogsAlreadyLoaded: boolean;
  logToDeleteId: number;

  constructor
    (
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private translateService: TranslateService,
      private _fuseConfigService: FuseConfigService,
      private autoRedirectionService: AutoRedirectionService,
      private router: Router,
      private accessDataSaveService: AccessDataSaveService,
      private _formBuilder: FormBuilder,
      private placePhotoService: PlacePhotoService,
      private userPhotoService: UserPhotoService,
      private accessManagementService: AccessManagementService,
      private accessService: AccessService,
      private dialog: MatDialog,
      private eventEmitterService: EventEmitterService,
      private parseTimelineService: ParseTimelineService,
      private spinner: NgxSpinnerService,
      private timelineService: TimelineService
    ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish, englishTimeline, frenchTimeline, spanishTimeline);
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: false
        },
        toolbar: {
          hidden: false
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };
    this.hourFormat = this.accessService.getHourFormat(this.translateService.currentLang);

    this.firstAccessLogsAlreadyLoaded = false;
  }

  ngOnInit(): void {

    // If the user is not authenticated, he is redirected to the login page.
    // If the user is authenticated but the token has expired, he is redirected to the "session locked" page.
    this.autoRedirectionService.redirectUserIfNecessary();

    this.eventEmitterService.subsVarGetAccessList = this.eventEmitterService
      .invokeGetAccessList.subscribe(() => {
        this.getAccessList();
      });

    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.hourFormat = this.accessService.getHourFormat(langChangeEvent.lang);
      this.firstAccessLogsAlreadyLoaded = false;
      if (this.tabIndex == 3) {
        this.getAccessFirstLogs();
      }
    });

    // On récupère accessData, selectedBoard, userOrObjectId et userOrObjectType provenant de la page access
    this.subsVarSelectedAddress = this.accessDataSaveService.currentSelectedAddress.subscribe(selectedAddress => this.selectedAddress = selectedAddress);
    this.subsVarSelectedBoard = this.accessDataSaveService.currentSelectedBoard.subscribe(selectedBoard => this.selectedBoard = selectedBoard);
    this.subsVarAccessData = this.accessDataSaveService.currentAccessData.subscribe(accessData => this.accessData = accessData);
    this.subsVarBadges = this.accessDataSaveService.currentBadges.subscribe(badges => this.badges = badges);
    this.subsVarKeys = this.accessDataSaveService.currentKeys.subscribe(keys => this.keys = keys);
    this.subsVarUserOrObjectId = this.accessDataSaveService.currentUserOrObjectId.subscribe(userOrObjectId => this.userOrObjectId = userOrObjectId);
    this.subsVarUserOrObjectType = this.accessDataSaveService.currentUserOrObjectType.subscribe(userOrObjectType => this.userOrObjectType = userOrObjectType);

    /* On récupère les currentUserAccess et currentGuestAccess (pour que quand l'utilisateur accède à cette page via le bouton "revenir en arrière" de son navigateur, 
    la page se relance sur l'utilisateur ou l'invité qu'il était en train de visualiser */
    this.subsVarUserAccess = this.accessDataSaveService.currentUserAccess.subscribe(selectedUserAccess => this.selectedUserAccess = selectedUserAccess);
    this.subsVarGuestAccess = this.accessDataSaveService.currentGuestAccess.subscribe(selectedGuestAccess => this.selectedGuestAccess = selectedGuestAccess);
    this.subsVarBadge = this.accessDataSaveService.currentBadge.subscribe(selectedBadge => this.selectedBadge = selectedBadge);
    this.subsVarKey = this.accessDataSaveService.currentKey.subscribe(selectedKey => this.selectedKey = selectedKey);

    // Ici, on redirige vers la page accès les utilisateurs tapant le lien /access/accessManagement, 
    // ainsi que ceux qui n'ont pas un droit modérateur ou administrateur sur la serrure
    if (this.selectedBoard == null || this.accessData == null) {
      this.router.navigate(['access']);
    } else if (this.selectedBoard != null) {
      if (this.selectedBoard.board_access.type_access.id == 3) {
        // Si un simple utilisateur (type_access.id = 3) arrive à accéder à la page ...
        this.router.navigate(['access']);
      } else if (
        (this.userOrObjectType == 'user' && this.selectedUserAccess != null && this.selectedUserAccess.board.id != this.selectedBoard.id)
        || (this.userOrObjectType == 'guest' && this.selectedGuestAccess != null && this.selectedGuestAccess.board.id != this.selectedBoard.id)
        || (this.userOrObjectType == 'badge' && this.selectedBadge != null && this.selectedBadge.board.id != this.selectedBoard.id)
        || (this.userOrObjectType == 'key' && this.selectedKey != null && this.selectedKey.board.id != this.selectedBoard.id)
      ) {
        // Cela règle le bug suivant : Si l'utilisateur quittait AccessManagement, puis changeait le lieu ou la serrure et revenait sur AccessManagement 
        // via la flèche "retour", le selectedBoard était bien mis à jour mais pas les selectedUserAccess, selectedGuestAccess, selectedBadge ou selectedKey.
        // Ainsi, on avait les accès (selectedUserAccess, selectedGuestAccess, selectedBadge ou selectedKey) de l'ancienne serrure sur la nouvelle. 
        this.router.navigate(['access']);
      }
    }

    this.getSelectedUserOrObjectAccess(this.userOrObjectId, this.userOrObjectType);

    this.initializeInformationForm();

    this.translateService.onLangChange.subscribe(() => {
      this.initializeInformationForm();
    });

  }

  ngOnDestroy() {
    this.subsVarSelectedAddress.unsubscribe();
    this.subsVarSelectedBoard.unsubscribe();
    this.subsVarAccessData.unsubscribe();
    this.subsVarBadges.unsubscribe();
    this.subsVarKeys.unsubscribe();
    this.subsVarUserOrObjectId.unsubscribe();
    this.subsVarUserOrObjectType.unsubscribe();

    this.subsVarGuestAccess.unsubscribe();
    this.subsVarGuestAccess.unsubscribe();
    this.subsVarBadge.unsubscribe();
    this.subsVarKey.unsubscribe();

    this.eventEmitterService.subsVarGetAccessList.unsubscribe();
  }

  getAccessList() {
    this.accessService.getAccessListByLockId(this.selectedBoard.id)
      .subscribe(
        (response) => {
          this.accessData = response;
          this.getSelectedUserOrObjectAccess(this.userOrObjectId, this.userOrObjectType);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  getSelectedUserOrObjectAccess(userOrObjectId: number, userOrObjectType: string) {
    if (userOrObjectType == 'user') {
      for (let i = 0; i < this.accessData.user.length; i++) {
        if (this.accessData.user[i].id == userOrObjectId) {
          this.selectedUserAccess = this.accessData.user[i];
          this.accessDataSaveService.updateUserAccess(this.selectedUserAccess);
          this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedUserAccess.board.address.photo);
          this.profilePhotoURL = this.userPhotoService.getNoAuthUserPhotoURLWithParameter(this.selectedUserAccess.photo);
        }
      }
    } else if (userOrObjectType == 'guest') {
      for (let i = 0; i < this.accessData.invite.length; i++) {
        if (this.accessData.invite[i].id == userOrObjectId) {
          this.selectedGuestAccess = this.accessData.invite[i];
          this.accessDataSaveService.updateGuestAccess(this.selectedGuestAccess);
          this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedGuestAccess.board.address.photo);
          this.profilePhotoURL = "assets/images/avatars/guest.png";
        }
      }
    } else if (userOrObjectType == 'badge') {
      for (let i = 0; i < this.badges.length; i++) {
        if (this.badges[i].id == userOrObjectId) {
          this.selectedBadge = this.badges[i];
          this.accessDataSaveService.updateSelectedBadge(this.selectedBadge);
          this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedBadge.board.address.photo);
          if (this.selectedBadge.state == 1) {
            this.profilePhotoURL = "assets/images/avatars/correct_card.svg";
          } else {
            this.profilePhotoURL = "assets/images/avatars/unregistered_card.svg";
          }
        }
      }
    } else if (userOrObjectType == 'key') {
      for (let i = 0; i < this.keys.length; i++) {
        if (this.keys[i].id == userOrObjectId) {
          this.selectedKey = this.keys[i];
          this.accessDataSaveService.updateSelectedKey(this.selectedKey);
          this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedKey.board.address.photo);
          if (this.selectedKey.state == 1) {
            this.profilePhotoURL = "assets/images/avatars/key_active.svg";
          } else {
            this.profilePhotoURL = "assets/images/avatars/key_inactive.svg";
          }
        }
      }
    }
  }

  initializeUserOrGuest(userOrObjectType: string) {
    if (userOrObjectType == 'user') {

      this.selectedUserAccess = this.accessData.user[0];
      this.accessDataSaveService.updateUserAccess(this.selectedUserAccess);
      this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedUserAccess.board.address.photo);
      this.profilePhotoURL = this.userPhotoService.getNoAuthUserPhotoURLWithParameter(this.selectedUserAccess.photo);

      this.userOrObjectId = this.selectedUserAccess.id;
      this.accessDataSaveService.updateUserOrObjectId(this.userOrObjectId);

    } else if (userOrObjectType == 'guest') {
      if (this.accessData.invite[0] != undefined) {

        this.selectedGuestAccess = this.accessData.invite[0];
        this.accessDataSaveService.updateGuestAccess(this.selectedGuestAccess);
        this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedGuestAccess.board.address.photo);
        this.profilePhotoURL = "assets/images/avatars/guest.png";

        this.userOrObjectId = this.selectedGuestAccess.id;
        this.accessDataSaveService.updateUserOrObjectId(this.userOrObjectId);
      }
    } else if (userOrObjectType == 'badge') {
      if (this.badges[0] != undefined) {

        this.selectedBadge = this.badges[0];
        this.accessDataSaveService.updateSelectedBadge(this.selectedBadge);
        this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedBadge.board.address.photo);
        if (this.selectedBadge.state == 1) {
          this.profilePhotoURL = "assets/images/avatars/correct_card.svg";
        } else {
          this.profilePhotoURL = "assets/images/avatars/unregistered_card.svg";
        }

        this.userOrObjectId = this.selectedBadge.id;
        this.accessDataSaveService.updateUserOrObjectId(this.userOrObjectId);
      }
    } else if (userOrObjectType == 'key') {
      if (this.keys[0] != undefined) {

        this.selectedKey = this.keys[0];
        this.accessDataSaveService.updateSelectedKey(this.selectedKey);
        this.placePhotoURL = this.placePhotoService.getPlacePhotoURL(this.selectedKey.board.address.photo);
        if (this.selectedKey.state == 1) {
          this.profilePhotoURL = "assets/images/avatars/key_active.svg";
        } else {
          this.profilePhotoURL = "assets/images/avatars/key_inactive.svg";
        }

        this.userOrObjectId = this.selectedKey.id;
        this.accessDataSaveService.updateUserOrObjectId(this.userOrObjectId);
      }
    }
  }

  onUserOrObjectTypeSelection(userOrObjectType: string) {
    this.userOrObjectType = userOrObjectType;
    this.accessDataSaveService.updateUserOrObjectType(userOrObjectType);
    this.initializeUserOrGuest(userOrObjectType);
    this.initializeInformationForm();
    this.firstAccessLogsAlreadyLoaded = false;
    if (this.userOrObjectType == 'user' && this.selectedUserAccess.type_access.id != 3 && this.tabIndex != 3) {
      this.tabIndex = 0;
    } else if (this.tabIndex == 3) {
      this.getAccessFirstLogs();
    }
  }

  onUserOrObjectSelection(userOrObjectId: number) {
    this.userOrObjectId = userOrObjectId;
    this.accessDataSaveService.updateUserOrObjectId(userOrObjectId);
    this.getSelectedUserOrObjectAccess(userOrObjectId, this.userOrObjectType);
    this.initializeInformationForm();
    this.firstAccessLogsAlreadyLoaded = false;
    if (this.userOrObjectType == 'user' && this.selectedUserAccess.type_access.id != 3 && this.tabIndex != 3) {
      this.tabIndex = 0;
    } else if (this.tabIndex == 3) {
      this.getAccessFirstLogs();
    }
  }

  initializeInformationForm() {
    if (this.userOrObjectType == 'user') {
      let modeOfOperation: string;
      if (this.selectedUserAccess.distance != undefined && this.selectedUserAccess.distance == 1) {
        modeOfOperation = this.translateService.instant('ACCESS_MANAGEMENT.REMOTE_OPENING');
      } else {
        modeOfOperation = this.translateService.instant('ACCESS_MANAGEMENT.BLUETOOTH');
      }

      let accessName: string;
      if (this.selectedUserAccess.name != undefined) {
        accessName = this.selectedUserAccess.name;
      }

      this.userInformationForm = this._formBuilder.group({
        email: [this.selectedUserAccess.email],
        access_grade: [this.selectedUserAccess.type_access.id.toString()],
        creation_date: [formatDate(this.selectedUserAccess.created, 'fullDate', this.translateService.currentLang)],
        mode_of_operation: [modeOfOperation],
        access_name: [accessName]
      });

      if (this.selectedUserAccess.type_access.id == 1) {
        // On ne peut pas changer le niveau d'autorisation des administrateurs
        this.userInformationForm.controls['access_grade'].disable();
      }

      if (this.selectedUserAccess.type_access.id == 1 && this.selectedBoard.board_access.type_access.id == 2) {
        // Les modérateurs ne peuvent pas changer le nom de l'accès des administrateurs
        this.userInformationForm.controls['access_name'].disable();
      }

    } else if (this.userOrObjectType == 'guest') {
      let accessName: string;
      if (this.selectedGuestAccess.name != undefined) {
        accessName = this.selectedGuestAccess.name;
      }

      this.guestInformationForm = this._formBuilder.group({
        access_name: [accessName],
        creation_date: [formatDate(this.selectedGuestAccess.created, 'fullDate', this.translateService.currentLang)],
        login: [this.selectedGuestAccess.num_access],
        access_code: [this.selectedGuestAccess.code_access]
      });

    } else if (this.userOrObjectType == 'badge') {
      let badgeName: string;
      if (this.selectedBadge.name != undefined) {
        badgeName = this.selectedBadge.name;
      }

      let badgeState: string;
      if (this.selectedBadge.state == 1 && this.selectedBadge.hasPermanentAccess == true) {
        badgeState = this.translateService.instant('CONTENT.ACTIVE_BADGE') + ' - ' + this.translateService.instant('CONTENT.PERMANENT_ACCESS');
      } else if (this.selectedBadge.state == 1 && this.selectedBadge.hasPermanentAccess == false) {
        badgeState = this.translateService.instant('CONTENT.ACTIVE_BADGE');
      } else {
        badgeState = this.translateService.instant('CONTENT.BADGE_DISABLED');
      }

      this.badgeInformationForm = this._formBuilder.group({
        badge_name: [badgeName],
        badge_number: [this.selectedBadge.num_card],
        badge_state: [badgeState],
        creation_date: [formatDate(this.selectedBadge.created, 'fullDate', this.translateService.currentLang)]
      });
    } else if (this.userOrObjectType == 'key') {
      let keyName: string;
      if (this.selectedKey.name != undefined) {
        keyName = this.selectedKey.name;
      }

      let keyState: string;
      if (this.selectedKey.state == 1) {
        keyState = this.translateService.instant('CONTENT.ACTIVE_KEY');
      } else {
        keyState = this.translateService.instant('CONTENT.DISABLED_KEY');
      }

      let keySecurityState: string;
      if (this.selectedKey.security_state == 1) {
        keySecurityState = this.translateService.instant('CONTENT.INITIALIZED_KEY');
      } else {
        keySecurityState = this.translateService.instant('CONTENT.UNINITIALIZED_KEY');
      }

      this.keyInformationForm = this._formBuilder.group({
        key_name: [keyName],
        key_reference: [this.selectedKey.reference],
        key_state: [keyState],
        key_security_state: [keySecurityState],
        creation_date: [formatDate(this.selectedKey.created, 'fullDate', this.translateService.currentLang)]
      });
    }
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    let putAccessData: PutAccessData;

    if (this.userOrObjectType == 'user') {
      putAccessData = {
        'nameAccess': form.value.access_name,
        'typeAccess': parseInt(form.value.access_grade)
      }
      if (putAccessData.nameAccess == this.selectedUserAccess.name && putAccessData.typeAccess == this.selectedUserAccess.type_access.id) {
        // Si aucune modification n'a été effectuée
        this.dialog.open(this.NoChanges);
        this.isLoading = false;
      } else {
        this.accessManagementService.putAccessById(this.selectedUserAccess.id, putAccessData)
          .subscribe(
            () => {
              this.selectedUserAccess.name = putAccessData.nameAccess;
              this.selectedUserAccess.type_access.id = putAccessData.typeAccess;
              this.initializeInformationForm();
              this.dialog.open(this.PutAccessSuccessful);
              this.isLoading = false;
            },
            (err: HttpErrorResponse) => {
              this.isLoading = false;
              console.log(err);
            }
          );
      }

    } else if (this.userOrObjectType == 'guest') {
      putAccessData = {
        'nameAccess': form.value.access_name,
        'typeAccess': 4
      }
      if (putAccessData.nameAccess == this.selectedGuestAccess.name) {
        // Si aucune modification n'a été effectuée
        this.dialog.open(this.NoChanges);
        this.isLoading = false;
      } else {
        this.accessManagementService.putAccessById(this.selectedGuestAccess.id, putAccessData)
          .subscribe(
            () => {
              this.selectedGuestAccess.name = putAccessData.nameAccess;
              this.initializeInformationForm();
              this.dialog.open(this.PutAccessSuccessful);
              this.isLoading = false;
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              this.isLoading = false;
            }
          );
      }
    } else if (this.userOrObjectType == 'badge') {
      let putBadgeNameData: PutBadgeNameData;
      putBadgeNameData = {
        'name': form.value.badge_name
      }
      if (putBadgeNameData.name == '') {
        putBadgeNameData.name = null;
      }
      if (putBadgeNameData.name == this.selectedBadge.name) {
        // Si aucune modification n'a été effectuée
        this.dialog.open(this.NoChanges);
        this.isLoading = false;
      } else {
        this.accessManagementService.putBadgeNameById(this.selectedBadge.id, putBadgeNameData)
          .subscribe(
            () => {
              this.selectedBadge.name = putBadgeNameData.name;
              this.initializeInformationForm();
              this.dialog.open(this.PutAccessSuccessful);
              this.isLoading = false;
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              this.isLoading = false;
            }
          );
      }
    } else if (this.userOrObjectType == 'key') {
      let putKeyNameData: PutBadgeNameData;
      putKeyNameData = {
        'name': form.value.key_name
      }
      if (putKeyNameData.name == '') {
        putKeyNameData.name = null;
      }
      if (putKeyNameData.name == this.selectedKey.name) {
        // Si aucune modification n'a été effectuée
        this.dialog.open(this.NoChanges);
        this.isLoading = false;
      } else {
        this.accessManagementService.putBadgeNameById(this.selectedKey.id, putKeyNameData)
          .subscribe(
            () => {
              this.selectedKey.name = putKeyNameData.name;
              this.initializeInformationForm();
              this.dialog.open(this.PutAccessSuccessful);
              this.isLoading = false;
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              this.isLoading = false;
            }
          );
      }
    }
  }

  openWantToDeleteAccess() {
    this.accessToDeleteId = this.userOrObjectId;
    this.dialog.open(this.WantToDeleteAccessById);
  }

  deleteAccessById() {
    this.accessService.deleteAccessById(this.accessToDeleteId)
      .subscribe(
        () => {
          this.router.navigate(['/access']);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  openShareGuestAccessDialog() {
    this.dialog.open(this.ShareGuestAccess);
  }

  getShareGuestAccessMessage(language: string) {
    let currentLang: string = this.translateService.currentLang;

    this.translateService.use(language);

    this.ShareGuestAccessMessage =
      this.translateService.instant('GUEST_MESSAGE.INTRO')
      + '%0D%0A%0D%0A' + this.translateService.instant('GUEST_MESSAGE.LOGIN') + this.selectedGuestAccess.num_access
      + '%0D%0A' + this.translateService.instant('GUEST_MESSAGE.ACCESS_CODE') + this.selectedGuestAccess.code_access
      + '%0D%0A%0D%0A' + this.translateService.instant('GUEST_MESSAGE.IOS_URL')
      + '%0D%0A' + this.translateService.instant('GUEST_MESSAGE.ANDROID_URL')

    this.translateService.use(currentLang);
  }

  openWantToResetAccessCode() {
    this.dialog.open(this.WantToResetAccessCode);
  }

  resetAccessCode() {
    let resetAccessCodeData: ResetAccessCodeData;
    resetAccessCodeData = {
      'idAccess': this.selectedGuestAccess.id
    }
    this.accessManagementService.resetAccessCode(this.selectedGuestAccess.id, resetAccessCodeData)
      .subscribe(
        (newAccessCode: string) => {
          this.selectedGuestAccess.code_access = newAccessCode;
          this.initializeInformationForm();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
  }

  openWantToDeleteAccessRecuOrExepById(accessRecuOrExepId: number, typeOfAccessToDelete: string) {
    this.accessRecuOrExepToDeleteId = accessRecuOrExepId;
    this.typeOfAccessToDelete = typeOfAccessToDelete;
    this.dialog.open(this.WantToDeleteAccessRecuOrExepById);
  }

  deleteAccessRecuOrExepById() {
    if (this.typeOfAccessToDelete == 'recu') {
      let accessRecuIndex: number;
      if (this.userOrObjectType == 'user') {
        for (let i = 0; i < this.selectedUserAccess.access_recu.length; i++) {
          if (this.selectedUserAccess.access_recu[i].id == this.accessRecuOrExepToDeleteId) {
            accessRecuIndex = i;
          }
        }
      } else if (this.userOrObjectType == 'guest') {
        for (let i = 0; i < this.selectedGuestAccess.access_recu.length; i++) {
          if (this.selectedGuestAccess.access_recu[i].id == this.accessRecuOrExepToDeleteId) {
            accessRecuIndex = i;
          }
        }
      }
      this.accessManagementService.deleteAccessRecuById(this.accessRecuOrExepToDeleteId)
        .subscribe(
          () => {
            if (this.userOrObjectType == 'user') {
              this.selectedUserAccess.access_recu.splice(accessRecuIndex, 1);

            } else if (this.userOrObjectType == 'guest') {
              this.selectedGuestAccess.access_recu.splice(accessRecuIndex, 1);
            }
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        );
    } else if (this.typeOfAccessToDelete == 'exep') {
      let accessExepIndex: number;
      if (this.userOrObjectType == 'user') {
        for (let i = 0; i < this.selectedUserAccess.access_exep.length; i++) {
          if (this.selectedUserAccess.access_exep[i].id == this.accessRecuOrExepToDeleteId) {
            accessExepIndex = i;
          }
        }
      } else if (this.userOrObjectType == 'guest') {
        for (let i = 0; i < this.selectedGuestAccess.access_exep.length; i++) {
          if (this.selectedGuestAccess.access_exep[i].id == this.accessRecuOrExepToDeleteId) {
            accessExepIndex = i;
          }
        }
      }
      this.accessManagementService.deleteAccessExepById(this.accessRecuOrExepToDeleteId)
        .subscribe(
          () => {
            if (this.userOrObjectType == 'user') {
              this.selectedUserAccess.access_exep.splice(accessExepIndex, 1);

            } else if (this.userOrObjectType == 'guest') {
              this.selectedGuestAccess.access_exep.splice(accessExepIndex, 1);
            }
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        );
    }
  }

  openAddAccessRecuDialog() {
    let listAccessRecuId = new Array<number>();
    const dialogConfig = new MatDialogConfig();
    if (this.userOrObjectType == 'user') {
      for (let i = 0; i < this.selectedUserAccess.access_recu.length; i++) {
        listAccessRecuId[i] = this.selectedUserAccess.access_recu[i].id;
      }
    } else if (this.userOrObjectType == 'guest') {
      for (let i = 0; i < this.selectedGuestAccess.access_recu.length; i++) {
        listAccessRecuId[i] = this.selectedGuestAccess.access_recu[i].id;
      }
    }
    dialogConfig.data = {
      userOrGuestId: this.userOrObjectId,
      listAccessRecuId: listAccessRecuId
    }
    dialogConfig.disableClose = true;
    this.dialog.open(AddAccessRecuComponent, dialogConfig);
  }

  openAddAccessExepDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      userOrGuestId: this.userOrObjectId,
    }
    this.dialog.open(AddAccessExepComponent, dialogConfig);
  }

  getAccessFirstLogs() {
    if (this.firstAccessLogsAlreadyLoaded == false) {
      this.isLoading = true;
      this.spinner.show("spinnerLoadingContent");

      let firstName: string;
      let lastName: string;
      let userPhoto: string;
      let accessName: string;
      let numAccess: string;

      if (this.userOrObjectType == 'user') {
        firstName = this.selectedUserAccess.firstName;
        lastName = this.selectedUserAccess.lastName;
        userPhoto = this.selectedUserAccess.photo;
      } else if (this.userOrObjectType == 'guest') {
        accessName = this.selectedGuestAccess.name;
        numAccess = this.selectedGuestAccess.num_access;
      } else if (this.userOrObjectType == 'badge') {
        accessName = this.selectedBadge.name;
      }
      this.logCards = new Array<LogCard>();
      this.accessManagementService.getAccessLogs(this.userOrObjectId, 1)
        .subscribe(
          (response) => {
            this.logData = response;
            this.totalLogs = this.logData.count;
            this.currentLog = 1;
            this.logCards = this.parseTimelineService.parseTimeline(this.logData, this.logCards, this.currentLog - 1, firstName, lastName, userPhoto, accessName, numAccess);
            this.firstAccessLogsAlreadyLoaded = true;
            this.spinner.hide("spinnerLoadingContent");
            this.isLoading = false;
          },
          (err: HttpErrorResponse) => {
            console.log(err);
            this.firstAccessLogsAlreadyLoaded = false;
            this.spinner.hide("spinnerLoadingContent");
            this.isLoading = false;
          }
        );
    }
  }

  loadMoreLogs() {
    this.isLoading = true;

    let firstName: string;
    let lastName: string;
    let userPhoto: string;
    let accessName: string;
    let numAccess: string;

    if (this.userOrObjectType == 'user') {
      firstName = this.selectedUserAccess.firstName;
      lastName = this.selectedUserAccess.lastName;
      userPhoto = this.selectedUserAccess.photo;
    } else if (this.userOrObjectType == 'guest') {
      accessName = this.selectedGuestAccess.name;
      numAccess = this.selectedGuestAccess.num_access;
    } else if (this.userOrObjectType == 'badge') {
      accessName = this.selectedBadge.name;
    }
    this.currentLog += 50;
    if (this.currentLog <= this.totalLogs) {
      this.spinner.show("spinnerLoadingNewLogs");
      this.accessManagementService.getAccessLogs(this.userOrObjectId, this.currentLog)
        .subscribe(
          (response) => {
            this.logData = response;
            this.logCards = this.parseTimelineService.parseTimeline(this.logData, this.logCards, this.currentLog - 1, firstName, lastName, userPhoto, accessName, numAccess);
            this.spinner.hide("spinnerLoadingNewLogs");
            this.isLoading = false;
          },
          (err: HttpErrorResponse) => {
            console.log(err);
            this.spinner.hide("spinnerLoadingNewLogs");
            this.isLoading = false;
          }
        );
    } else {
      this.isLoading = false;
    }
  }

  showWantToDeleteLogByIdDialog(event, logId: number) {
    event.stopPropagation(); // Pour bloquer l'animation de déroulage des informations du log lorsque l'utilisateur clique sur l'icon "delete"
    this.logToDeleteId = logId;
    this.dialog.open(this.WantToDeleteLogById);
  }

  deleteLogById() {
    let logIndex: number;
    for (let i = 0; i < this.logCards.length; i++) {
      if (this.logCards[i].id == this.logToDeleteId) {
        logIndex = i;
      }
    }
    this.timelineService.deleteLogById(this.logToDeleteId)
      .subscribe(
        () => {
          this.currentLog -= 1;
          this.totalLogs -= 1;
          // On fait disparaître le log de l'écran
          this.logCards.splice(logIndex, 1);
          if (this.isLoading == false && this.logCards.length <= 10) {
            this.loadMoreLogs();
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          alert(this.translateService.instant('ERROR'));
          this.getAccessFirstLogs();
        }
      );
  }

  onTabIndexChange(event: number) {
    this.tabIndex = event;
    if (this.tabIndex == 3) {
      this.getAccessFirstLogs();
    }
  }

  onScroll(event: ScrollEvent) {
    if (event.isReachingBottom && this.isLoading == false && this.tabIndex == 3) {
      this.loadMoreLogs();
    }
  }

  scrollToTop() {
    document.getElementById('accessManagement').scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  openFeatureComingSoonDialog() {
    this.dialog.open(this.FeatureComingSoon);
  }

}
