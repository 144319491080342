import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlaceService } from '../../core/PlacesService/place.service';

@Injectable({
  providedIn: 'root'
})
export class ManagementGuardGuard implements CanActivate {

  constructor(private placeService:PlaceService,
              private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let placeId = next.params.id;
      if(this.placeService.getPlacesById(placeId) != null)
        return true;
      else {
        this.router.navigate(['/places']);
      }
  }

}
