<form id="formCheckInOut" [formGroup]="formCheckInOut" (ngSubmit)="onSendForm()" *ngIf="formCheckInOut != null">
  <h2>{{'CHECK_DEFAULT' | translate }}</h2>
  <mat-checkbox
    formControlName="enableDefault"
    [checked]="current_place.check.default == 1"
    labelPosition="before">
    {{'CHECK_ENABLE' | translate }}
  </mat-checkbox>
  <ng-container *ngIf="getEnableDefaultValue()">
    <div>
      <h2>{{'CHECK_IN' | translate }}</h2>
      <mat-form-field>
        <mat-label>{{'CHECK_TIME' | translate }}</mat-label>
        <input formControlName="checkIn" matInput type="time">
      </mat-form-field>
    </div>
    <div>
      <h2>{{'CHECK_OUT' | translate }}</h2>
      <mat-form-field>
        <mat-label>{{'CHECK_TIME' | translate }}</mat-label>
        <input formControlName="checkOut" matInput type="time">
      </mat-form-field>
    </div>
  </ng-container>
  <div mat-dialog-actions class="buttons">
    <button mat-raised-button type="submit" [disabled]="!hasFormChanged()" color="primary">{{'SAVE_BUTTON' | translate }}</button>
    <button *ngIf="hasFormChanged()" mat-stroked-button type="button" (click)="cancelChoice()" color="primary">{{'CANCEL_BUTTON' | translate }}</button>
  </div>
</form>
