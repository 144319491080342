import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PutAccessData } from '../interfaces/PutAccessData';
import { ResetAccessCodeData } from '../interfaces/ResetAccessCodeData';
import { AccessRecuData } from '../interfaces/AccessRecuData';
import { AccessExepData } from '../interfaces/AccessExepData';
import { LogData } from '../../timeline/interfaces/LogData';
import { PutBadgeNameData } from '../interfaces/PutBadgeNameData';

import * as moment from 'moment';

import { ApiConfigData } from '../../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class AccessManagementService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  putAccessApiUrl: string = this.api_endpoint + "/api/access/";
  resetAccessCodeApiUrl: string = this.api_endpoint + "/api/access/generate-code/";
  accessRecuApiUrl: string = this.api_endpoint + "/api/access/recu/";
  accessPermanentApiUrl: string = this.api_endpoint + "/api/access/permanent/";
  accessExepApiUrl: string = this.api_endpoint + "/api/access/exep/";
  accessLogsApiUrl: string = this.api_endpoint + '/api/log/access/';
  putBadgeNameApiUrl: string = this.api_endpoint + '/api/lock/badge/rename/';

  constructor(
    private httpClient: HttpClient
  ) {

  }

    /**
    * Appels API
    */

  putAccessById(AccessId: number, putAccessData: PutAccessData) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.put(this.putAccessApiUrl + AccessId + ".json?bearer=" + token, putAccessData);
  }

  resetAccessCode(AccessId: number, resetAccessCodeData: ResetAccessCodeData) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.put(this.resetAccessCodeApiUrl + AccessId + ".json?bearer=" + token, resetAccessCodeData);
  }

  deleteAccessRecuById(AccessRecuId: number) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.delete(this.accessRecuApiUrl + AccessRecuId + ".json?bearer=" + token);
  }

  deleteAccessExepById(AccessExepId: number) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.delete(this.accessExepApiUrl + AccessExepId + ".json?bearer=" + token);
  }

  addAccessRecu(UserId: number, accessRecuData: AccessRecuData){
    let token: string = localStorage.getItem('token');

    return this.httpClient.post(this.accessRecuApiUrl + UserId + ".json?bearer=" + token, accessRecuData);
  }

  addAccessExep(UserId: number, accessExepData: AccessExepData){
    let token: string = localStorage.getItem('token');

    return this.httpClient.post(this.accessExepApiUrl + UserId + ".json?bearer=" + token, accessExepData);
  }

  addPermanentAccess(UserId: number){
    let token: string = localStorage.getItem('token');

    return this.httpClient.post(this.accessPermanentApiUrl + UserId + ".json?bearer=" + token, {});
  }

  getAccessLogs(accessId: number, start: number): Observable<LogData>{
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<LogData>
    (
      this.accessLogsApiUrl + accessId + '/' + start + '.json?bearer=' + token
    )
  }

  putBadgeNameById(BadgeId: number, putBadgeNameData: PutBadgeNameData) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.put(this.putBadgeNameApiUrl + BadgeId + ".json?bearer=" + token, putBadgeNameData);
  }

  /**
   * Fonctions
  */

  parseDayOrMonthNumber(dayOrMonthToParse: number) {
    let dayOrMonthParsed: string;
    if (dayOrMonthToParse < 10) {
      dayOrMonthParsed = '0' + dayOrMonthToParse.toString();
    } else {
      dayOrMonthParsed = dayOrMonthToParse.toString();
    }
    return dayOrMonthParsed;
  }

  parseTime(timeToParse: string, hourFormat: string) {
    let timeParsed: string;
    if (hourFormat == '12') {
      timeParsed = moment.utc(timeToParse, ["h:mm A"]).format("HH:mm");
    }
    else if (hourFormat == '24') {
      timeParsed = timeToParse;
    }
    return timeParsed;
  }
  
}
