<div *ngIf="!isLoading && invoices == undefined" class="no-invoice" fxLayout="row">
    <img class="img-no-invoice" src="assets/images/avatars/list-2.png">
    <div class="h2 text-no-invoice">{{'NO_INVOICE' | translate}}</div>
</div>

<table mat-table #table *ngIf="invoices != undefined" [dataSource]="dataSource" [@animateStagger]="{value:'50'}"
    class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="row-header">{{'INVOICE_NUMBER' | translate}}</th>
        <td mat-cell *matCellDef="let invoice; let even = even;" [ngClass]="{'white-text': even}">
            {{invoice.id_transformed}}</td>
        <td mat-footer-cell *matFooterCellDef>{{'TOTAL_OF_INVOICES' | translate}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{'DATE' | translate}}</th>
        <td mat-cell *matCellDef="let invoice; let even = even;" [ngClass]="{'white-text': even}">
            <div *ngIf="hourFormat == '24'">{{invoice.created| date:"dd/MM/yyyy"}}
                {{invoice.created| date:"HH:mm" : "UTC"}}</div>
            <div *ngIf="hourFormat == '12'">{{invoice.created| date:"MM/dd/yyyy"}}
                {{invoice.created| date:"hh:mm a" : "UTC"}}</div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="price_excl_VAT">
        <th mat-header-cell *matHeaderCellDef>{{'TOTAL_EXCL_VAT' | translate}}</th>
        <td mat-cell *matCellDef="let invoice; let even = even;" [ngClass]="{'white-text': even}">
            {{invoice.amount_excl_vat}} €</td>
        <td mat-footer-cell *matFooterCellDef>{{getTotalCost('excl_vat')}} €</td>
    </ng-container>

    <ng-container matColumnDef="price_incl_VAT">
        <th mat-header-cell *matHeaderCellDef>{{'TOTAL_INCL_VAT' | translate}}</th>
        <td mat-cell *matCellDef="let invoice; let even = even;" [ngClass]="{'white-text': even}">{{invoice.amount}} €
        </td>
        <td mat-footer-cell *matFooterCellDef>{{getTotalCost('incl_vat')}} €</td>
    </ng-container>

    <ng-container matColumnDef="type_of_payment">
        <th mat-header-cell *matHeaderCellDef>{{'TYPE_OF_PAYMENT' | translate}}</th>
        <td mat-cell *matCellDef="let invoice; let even = even;" [ngClass]="{'white-text': even}">
            <div *ngIf="invoice.type_invoice.id == 1">{{'SUBSCRIPTION' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 2">{{'VIA_ELOCKY_WEBSITE' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 3">Kickstarter</div>
            <div *ngIf="invoice.type_invoice.id == 4">{{'BANK_TRANSFER' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 5">{{'DUTY_FREE_ORDER' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 6">{{'CASH' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 7">{{'CHEQUE' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 8">{{'CREDIT_CARD' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 9">{{'MONEY_ORDER' | translate}}</div>
            <div *ngIf="invoice.type_invoice.id == 10">Paypal</div>
            <div *ngIf="invoice.type_invoice.id == 11">{{'OTHER' | translate}}</div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{'STATUS' | translate}}</th>
        <td mat-cell *matCellDef="let invoice; let even = even;" [ngClass]="{'white-text': even}">
            <div *ngIf="invoice.state == 0">{{'VALIDATED_ORDER' | translate}}</div>
            <div *ngIf="invoice.state == 1">{{'IN_PAYMENT' | translate}}</div>
            <div *ngIf="invoice.state == 2">{{'IN_THE_COURSE_OF_MANUFACTURE' | translate}}</div>
            <div *ngIf="invoice.state == 3">{{'IN_THE_COURSE_OF_DELIVERY' | translate}}</div>
            <div *ngIf="invoice.state == 4">{{'WAITING' | translate}}</div>
            <div *ngIf="invoice.state == 5">{{'DELIVERED' | translate}}</div>
            <div *ngIf="invoice.state == 6">{{'CANCELED' | translate}}</div>
            <div *ngIf="invoice.state == 7">{{'PARTIAL_DELIVERY' | translate}}</div>
            <div *ngIf="invoice.state == 8">{{'MALFUNCTION_AFTER_SALE_SERVICE' | translate}}</div>
            <div *ngIf="invoice.state == 9">{{'STANDARD_EXCHANGE' | translate}}</div>
            <div *ngIf="invoice.state == 10">{{'WAITING_FOR_CUSTOMER_RETURN' | translate}}</div>
            <div *ngIf="invoice.state == 11">{{'TO_BE_REFUNDED' | translate}}</div>
            <div *ngIf="invoice.state == 12">{{'REFUNDED' | translate}}</div>
            <div *ngIf="invoice.state == 13">{{'PARCEL_RECEPTION_AFTER_SALES_SERVICE' | translate}}</div>
            <div *ngIf="invoice.state == 14">{{'IN_PREPARATION' | translate}}</div>
            <div *ngIf="invoice.state == 15">{{'IN_REPARATION' | translate}}</div>
            <div *ngIf="invoice.state == 16">{{'PAYMENT_VALIDATED' | translate}}</div>
            <div *ngIf="invoice.state == 17">{{'REPAIRS_COMPLETED' | translate}}</div>
            <div *ngIf="invoice.state == 18">{{'CUSTOMER_RELATIONS' | translate}}</div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>{{'DOWNLOAD' | translate}}</th>
        <td mat-cell *matCellDef="let invoice; let even = even;" class="download-icon"
            [ngClass]="{'white-text': even, 'green-elocky': !even}">
            <button mat-icon-button (click)="onDownloadButtonClick()">
                <mat-icon>get_app</mat-icon>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="
    let row; 
    let even = even; 
    columns: displayedColumns;" [ngClass]="{'row-elocky-green': even}" [@animate]="{value:'*',params:{y:'100%'}}"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

</table>

<ngx-spinner size="medium" color="#2eb4b3" bdColor="rgba(51,51,51,0)" type="ball-clip-rotate" [fullScreen]="false">
</ngx-spinner>

<ng-template #FeatureComingSoon>
    <h2 matDialogTitle>{{'FEATURE_COMING_SOON' | translate}}</h2>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>