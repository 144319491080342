import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
        this.isAuthenticated$,
        this.isDoneLoading$
    ]).pipe(map(values => values.every(b => b)));

    private navigateToLoginPage() {
        this.router.navigateByUrl('/login');
    }

    constructor(private oauthService: OAuthService, private router: Router) {
        // Todo: Remove after debug
        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                console.error(event);
            } else {
                console.warn(event);
            }
        });

        window.addEventListener('storage', (event) => {
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.oauthService.hasValidAccessToken()) {
                this.navigateToLoginPage();
            }
        });

        this.oauthService.events
            .subscribe(_ => {
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        });

        this.oauthService.events
            .pipe(filter(e => ['token_received'].includes(e.type)))
            .subscribe(e => this.oauthService.loadUserProfile());

        this.oauthService.events
            .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
            .subscribe(e => this.navigateToLoginPage());

        this.oauthService.setupAutomaticSilentRefresh();
    }

    public runLoginWithUsingPassword(username, password): Promise<void> {
        // 0. LOAD CONFIGURATION
        // Load all data necessary for authenticating using password.
        this.oauthService.tokenEndpoint = "https://www.elocky.com/oauth/v2/token";
        // this.oauthService.userinfoEndpoint = "";
        this.oauthService.clientId = "32_2bd3cxjdkg2scg44ogco4ow8k0ocg0ggw0kg4wc44kgooc8gks";
        this.oauthService.scope = "openid profile email";
        this.oauthService.dummyClientSecret = "18jsd5wdj0ro8wkccgckwsgkwsc48c8os04c0c880gkw0844g0";

        // 1. FETCH TOKEN AND USER PROFILE
        // Fetch the token using username and password and load the corresponding user profile.
        return this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password).then(() => {
            let claims = this.oauthService.getIdentityClaims();
            if (claims) {
                console.debug('claims:', claims);
            }
        });
    }

    public runInitialLoginSequence(): Promise<void> {
        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table...');
            console.table(location.hash.substring(1).split('&').map(kvp => kvp.split('=')));
        }

        // 0. LOAD CONFIGURATION
        // First we have to check to see how the IdServer is currently configured:
        return this.oauthService.loadDiscoveryDocument()

            // 1. HASH LOGIN:
            // Try to log in via hash fragment after redirect back from IdServer from initImplicitFlow:
            .then(() => this.oauthService.tryLogin())

            .then(() => {
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }

                // 2. SILENT LOGIN:
                // Try to log in via refresh because then we can prevent needing to redirect the user:
                return this.oauthService.silentRefresh()
                    .then(() => Promise.resolve())
                    .catch(result => {
                        // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                        // Only the ones where it's reasonably sure that sending the user to the IdServer will help.
                        const errorResponsesRequiringUserInteraction = [
                            'interaction_required',
                            'login_required',
                            'account_selection_required',
                            'consent_required'
                        ];

                        if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

                            // 3. ASK FOR LOGIN:
                            // At this point we know for sure that we have to ask the user to log in so we redirect them to the IdServer to enter credentials.
                            // Enable this to ALWAYS force a user to login.
                            // this.oauthService.initImplicitFlow();

                            // Instead, we'll do this:
                            console.warn('User interaction is needed to log in, we will wait for the user to manually log in.')
                            return Promise.resolve();
                        }

                        // We can't handle, pass on the problem to the next handler.
                        return Promise.reject(result);
                    });
            })

            .then(() => {
                this.isDoneLoadingSubject$.next(true);

                // Check for the strings 'undefined' and 'null' just to be sure.
                // Our current login(...) should never have this, but in case someone ever calls initImplicitFlow(undefined | null) this could happen.
                if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                    let stateUrl = this.oauthService.state;
                    if (stateUrl.startsWith('/') === false) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
                    this.router.navigateByUrl(stateUrl);
                }
            })
            .catch(() => this.isDoneLoadingSubject$.next(true));
    }

    public login(targetUrl?: string): void {
        // Note: before version 9.1.0 of the library you needed to call encodeURIComponent on the argument to the method.
        this.oauthService.initLoginFlow(targetUrl || this.router.url);
    }

    public logout(): void { this.oauthService.logOut(); }
    public refresh(): void { this.oauthService.silentRefresh(); }
    public refreshToken(): void { this.oauthService.refreshToken(); }
    public hasValidToken(): void { this.oauthService.hasValidAccessToken(); }
}
