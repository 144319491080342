import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AutoRedirectionService {

  helper = new JwtHelperService();

  constructor
  (
    private router: Router
  ) 
  {

  }

  // If the user is not authenticated, he is redirected to the login page.
  // If the user is authenticated but the token has expired, he is redirected to the "session locked" page.      

  redirectUserIfNecessary(){

  if(localStorage.getItem('isAuthenticated') != 'true'){
    this.router.navigate(['auth/login']);
  } 
  else if(localStorage.getItem('isAuthenticated') == 'true' && this.helper.isTokenExpired(localStorage.getItem('token')) == true){
    this.router.navigate(['auth/lock']);
  }
} 
}
