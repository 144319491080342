export const locale = {
    lang: 'es',
    data: {
        'HEADER': {
            'TITLE_INVOICES': 'FACTURAS'
        },
        'FEATURE_COMING_SOON': '¡Función disponible próximamente!',
        'NO_INVOICE': 'No tienes ninguna factura.',
        'INVOICE_NUMBER': 'Factura n.°',
        'DATE': 'Fecha',
        'TOTAL_EXCL_VAT': 'Precio sin IVA',
        'TOTAL_INCL_VAT': 'Precio con IVA',
        'STATUS': 'Estado',
        'DOWNLOAD': 'Descargar',
        'TOTAL_OF_INVOICES': 'Total de las facturas',
        'VALIDATED_ORDER': 'Pedido validado',
        'IN_PAYMENT': 'En proceso de pago',
        'IN_THE_COURSE_OF_MANUFACTURE': 'En proceso de fabricación',
        'IN_THE_COURSE_OF_DELIVERY': 'En vías de entrega ',
        'WAITING': 'Pendiente',
        'DELIVERED': 'Entregada',
        'CANCELED': 'Cacelada',
        'PARTIAL_DELIVERY': 'Entrega parcial',
        'MALFUNCTION_AFTER_SALE_SERVICE': 'Avería (servicio técnico)',
        'WAITING_FOR_CUSTOMER_RETURN': 'Esperando la devolución de cliente ',
        'STANDARD_EXCHANGE': 'Intercambio estándar',
        'REFUNDED': 'Reembolsada',
        'TO_BE_REFUNDED': 'Será reembolsada',
        'PARCEL_RECEPTION_AFTER_SALES_SERVICE': 'Recepción del paquete del cliente (servicio técnico)',
        'IN_PREPARATION': 'En preparación',
        'IN_REPARATION': 'En reparación',
        'PAYMENT_VALIDATED': 'Pago validado',
        'REPAIRS_COMPLETED': 'Réparaciones terminadas',
        'CUSTOMER_RELATIONS': 'Relación con el cliente',
        'TYPE_OF_PAYMENT': 'Tipo de pago',
        'SUBSCRIPTION': 'Suscripción',
        'VIA_ELOCKY_WEBSITE': 'A través del sitio web de Elocky',
        'BANK_TRANSFER': 'Transferencia',
        'DUTY_FREE_ORDER': 'Pedido sin IVA',
        'CASH': 'Efectivo',
        'CHEQUE': 'Cheque',
        'CREDIT_CARD': 'Tarjeta bancaria',
        'MONEY_ORDER': 'Giro',
        'OTHER': 'Otro',
    }
}