export const locale = {
    lang: 'es',
    data: {
        'TITLE': {
            'THEME_OPTIONS': 'Opciones de personalización',
            'COLOR_THEMES': 'Temas',
            'LAYOUT_STYLES': 'Estilos',
            'LAYOUT_WIDTH': 'Ancho',
            'NAVBAR': 'Barra de navegación',
            'TOOLBAR': 'Barra de herramientas',
            'FOOTER': 'Pie de página',
            'SIDE_PANEL': 'Panel lateral',
            'CUSTOM_SCROLLBARS': 'Barras de desplazamiento'
        },
        'PARAMETER':{
            'POSITION': 'Posición :',
            'VARIANT': 'Variante :',
            'VARIANT_VERTICAL': 'Variante (Vertical) :',
            'PRIMARY_BACKGROUND': 'Color de fondo 1',
            'SECONDARY_BACKGROUND': 'Color de fondo 2',
            'SECONDARY_BACKGROUND_VERTICAL':'Color de fondo 2 (Vertical) :',
            'BACKGROUND_COLOR': 'Color de fondo :',
            'COLOR': 'Color :'
        },
        'OPTION':{
            'ELOCKY_LIGHT': 'Elocky Claro',
            'ELOCKY_DARK': 'Elocky Oscuro',
            'VERTICAL_LAYOUT_1': 'Vertical #1',
            'VERTICAL_LAYOUT_2': 'Vertical #2',
            'VERTICAL_LAYOUT_3': 'Vertical #3',
            'HORIZONTAL_LAYOUT_1': 'Horizontal #1',
            'FULLWIDTH': 'Ancho total',
            'BOXED': 'Encuadrado',
            'HIDE': 'Esconder',
            'FOLDED': 'Doblar',
            'LEFT': 'Izquierda',
            'RIGHT': 'Derecha',
            'STYLE_1': 'Estilo 1',
            'STYLE_2': 'Estilo 2',
            'ABOVE': 'Arriba',
            'BELOW_STATIC': 'Abajo y estático',
            'BELOW_FIXED': 'Abajo y fijado',
            'USE_CUSTOM_BACKGROUND_COLOR': 'Color de fondo personalizada',
            'ENABLE_CUSTOM_SCROLLBARS': 'Barras de desplazamiento personalizadas',
        }
    }
};