import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RegisterService } from './register.service';

import { HttpErrorResponse } from '@angular/common/http';

import { NewUser } from './newUser';

import { Router } from '@angular/router';

import { ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as spanish } from '../i18n/es';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector     : 'register',
    templateUrl  : './register.component.html',
    styleUrls    : ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy
{
    newUserEmailAddress: string;
    registerForm: FormGroup;

    @ViewChild('IncorrectPhoneNumber') IncorrectPhoneNumber: TemplateRef<any>; 
    @ViewChild('IncorrectPhoneNumber2') IncorrectPhoneNumber2: TemplateRef<any>; 
    @ViewChild('RegistrationCompletedSuccessfully') RegistrationCompletedSuccessfully: TemplateRef<any>; 
    @ViewChild('EmailAlreadyExists') EmailAlreadyExists: TemplateRef<any>; 
    @ViewChild('TryLater') TryLater: TemplateRef<any>; 

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog,
        private registerService: RegisterService,
        private router: Router,
        private spinner: NgxSpinnerService

    )
    {   
        this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.registerForm = this._formBuilder.group({
            lastname       : ['', Validators.required],
            firstname      : ['', Validators.required],
            email          : ['', [Validators.required, Validators.email]],
            phoneNumber      : ['', Validators.required],
            password       : ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
            acceptTermsAndConditions:['',[Validators.requiredTrue]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.registerForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onCountryChangedEvent(event){
        phoneCountry = event.name;
        phoneCode = "+" + event.dialCode;
    }

    onSubmit(form: NgForm) {
        const newUser: NewUser = {
        'firstName': form.value.firstname,
        'lastName': form.value.lastname,
        'phoneCode': phoneCode,
        'phoneCountry': phoneCountry,
        'phone': getPhone(form.value.phoneNumber),
        'email': form.value.email,
        'password' : form.value.password,
        'whitelabel' : "elocky"
    }

    /**
     * Solution temporaire : 
     * 
     * Quand l'utilisateur tape un numéro de téléphone avec l'indicatif téléphonique international d'un autre pays (ex : +34 ...), 
     * le pays change automatiquement mais ce changement n'est pas détécté. Ainsi, lors de l'inscription, 
     * un mauvais pays est enregistré dans la base de donnée. Pour contrer cela, on demande à l'utilisateur de 
     * ne pas entrer d'indicatif téléphonique manuellement.
     */

    if (newUser.phoneCode + newUser.phone  != form.value.phoneNumber){
        this.dialog.open(this.IncorrectPhoneNumber);
    } else {
        this.spinner.show();
        this.newUserEmailAddress = newUser.email;
        this.registerService.addNewUser(newUser)
        .subscribe(
            () => {
              this.spinner.hide();
              this.dialog.open(this.RegistrationCompletedSuccessfully);
              this.router.navigate(['auth/login']);
            },
              (err: HttpErrorResponse) => {
                  this.spinner.hide();
                  if (err.error.errors[0] == "email") {				 
                    this.dialog.open(this.EmailAlreadyExists);
                  } else if (err.error.errors[0] == "phone"){
                    this.dialog.open(this.IncorrectPhoneNumber2);
                 } else {
                    this.dialog.open(this.TryLater);
                } 
              }
          ); 
        }
    }
}       
    
/**
 * Phone
**/

let phoneCode: string = "";
let phoneCountry: string = "";

function getPhone(phoneNumber:string){
    return parseInt(phoneNumber.slice(phoneCode.length,phoneNumber.length));
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};
