import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccessData } from '../interfaces/AccessData';
import { PlaceDataAccess } from '../interfaces/PlaceDataAccess';
import { BoardData } from '../interfaces/BoardData';
import { NewAccess } from '../interfaces/NewAccess';
import { CopyAccessData } from '../interfaces/CopyAccessData';
import { BadgeData } from '../interfaces/BadgeData';
import { ElectrokeyData } from '../interfaces/ElectrokeyData';
import { AccessListData } from '../interfaces/AccessListData';

import { ApiConfigData } from '../../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  AccessListByUserApiUrl: string = this.api_endpoint + '/api/access/list/user';
  AccessListByLockApiUrl: string = this.api_endpoint + '/api/access/lock/';
  AddAccessApiUrl: string = this.api_endpoint + '/api/access/.json?bearer=';
  DeleteAccessApiUrl: string = this.api_endpoint + '/api/access/';
  CopyAccessApiUrl: string = this.api_endpoint + '/api/access/copy.json?bearer=';
  BadgeListApiUrl: string = this.api_endpoint + '/api/access/list/badge/';
  ElectrokeyListApiUrl: string = this.api_endpoint + '/api/access/list/electrokey/';

  constructor
    (
      private httpClient: HttpClient
    ) {

  }

  /*
    APPELS API
  */

  getAccessListByLockId(lockId: number): Observable<AccessData> {
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<AccessData>
      (
        this.AccessListByLockApiUrl + lockId + '.json?bearer=' + token
      )
  }

  getAccessListByUser(): Observable<AccessListData> {
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<AccessListData>
      (
        this.AccessListByUserApiUrl + '.json?bearer=' + token
      )
  }

  addAccess(newAccess: NewAccess) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.post(this.AddAccessApiUrl + token, newAccess);
  }

  deleteAccessById(AccessId: number) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.delete(this.DeleteAccessApiUrl + AccessId + '.json?bearer=' + token);
  }

  copyAccess(copyAccessData: CopyAccessData) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.post(this.CopyAccessApiUrl + token, copyAccessData);
  }

  getBadgeListByLockId(lockId: number): Observable<Array<BadgeData>> {
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<Array<BadgeData>>
      (
        this.BadgeListApiUrl + lockId + '.json?bearer=' + token
      )
  }

  getElectrokeyListByLockId(lockId: number): Observable<Array<ElectrokeyData>> {
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<Array<ElectrokeyData>>
      (
        this.ElectrokeyListApiUrl + lockId + '.json?bearer=' + token
      )
  }

  /*
    FONCTIONS
  */

  initializeBoard(selectedAddress: PlaceDataAccess) {
    let boardsExist: boolean;
    let selectedBoard: BoardData;

    if (selectedAddress.board[0] == null) {
      boardsExist = false;
      selectedBoard = null;
      localStorage.removeItem('currentBoard');
    } else {
      boardsExist = true;
      if (localStorage.getItem('currentBoard') != undefined) {
        // Si l'utilisateur a sélectionné une serrure auparavant, on initialise le menu sur cette serrure
        selectedBoard = selectedAddress.board[this.getBoardIndexFromBoardId(parseInt(localStorage.getItem('currentBoard')), selectedAddress)];
      } else {
        selectedBoard = selectedAddress.board[0];
        localStorage.setItem('currentBoard', selectedBoard.id.toString());
      }
    }
    return { selectedBoard, boardsExist };
  }


  getBoardIndexFromBoardId(boardId: number, selectedAddress: PlaceDataAccess) {
    for (let i = 0; i < selectedAddress.board.length; i++) {
      if (selectedAddress.board[i].id == boardId) {
        return i;
      }
    }
    // Si on ne trouve pas la serrure correspondant à l'id, on retourne l'index correspondant à la première serrure du lieu
    return 0;
  }

  getPlaceIndexFromPlaceId(placeId: number, addresses: Array<PlaceDataAccess>) {
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].id == placeId) {
        return i;
      }
    }
    // Si on ne trouve pas le lieu correspondant à l'id, on retourne l'index correspondant au premier lieu de l'utilisateur
    return 0;
  }

  getHourFormat(currentLang: string) {
    if (currentLang == 'en') {
      return "12";
    } else {
      return "24";
    }
  }

}




