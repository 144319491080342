export const locale = {
	lang: 'fr',
	data: {
		'CANCEL_BUTTON':'Annuler',
		'SAVE_BUTTON':'Sauvegarder',
		'CLOSE_BUTTON':'Fermer',
		'RENAME_BUTTON':'Renommer',
		'NO_BUTTON':'Non',
		'YES_BUTTON':'Oui',
		'TITLE_PLACES':'LIEUX',
		'TITLE_INFORMATIONS':'Informations',
		'TITLE_USER':'Utilisateurs',
		'TITLE_ALARM':'Alarmes',
		'TITLE_CHECK':'Check In/Check Out',
		'TITLE_MAP_MODE':'Map Mode',
		'RENAME_PLACE':'Renommer le lieu',
		'NEW_NAME_PLACE':'Nouveau nom',
		'DELETE_PLACE':'Supprimer le lieu ?',
		'TITLE_RENAME_PLACE':'Renommer',
		'TITLE_CREATE_PLACE':'Créer un nouveau lieu',
		'TITLE_DELETE_PLACE':'Supprimer',
		'TITLE_DELETE_USER_PLACE':'Supprimer utilisateur',
		'REMOVE_YOU_PLACE':'Vous supprimer de ce lieu ?',
		'INFORMATIONS_PLACES':'Informations Lieux',
		'INFORMATIONS_PLACES_NAME':'Nom du lieux',
		'INFORMATIONS_PLACES_ADDRESS':'Addresse',
		'INFORMATIONS_PLACES_COMPLEMENT':'Complément',
		'INFORMATIONS_PLACES_COUNTRY':'Pays',
		'INFORMATIONS_PLACES_CITY':'Ville',
		'INFORMATIONS_PLACES_ZIP':'Code postal',
		'INFORMATIONS_PLACES_TIMEZONE':'Fuseau horaire',
		'INFORMATIONS_PLACES_PHOTO':'Photo',
		'INFORMATIONS_PLACES_PHOTO_SAVE':'Sauvegarder la photo',
		'USER_CHANGE_TYPE':'Changer le type',
		'USER_DELETE':'Supprimer utilisateur ?',
		'USER_CHANGE_TYPE_TITLE':'Modifier le type',
		'ALARM_GENERAL':'Alarme général',
		'ALARM_PERMANENT':'Alarme permanent',
		'ALARM_BEACON':'Beacon',
		'ALARM_LOCK_V1':'Serrure version 1',
		'ALARM_LOCK_V2':'Serrure version 2',
		'CHECK_DEFAULT':'Default',
		'CHECK_ENABLE':'Activer l\'alarme générale',
		'CHECK_IN':'Check in',
		'CHECK_OUT':'Check out',
		'CHECK_TIME':'Horaires',
		'CREATE_PLACE_SEARCH':'Rechercher via Google Maps',
		'CREATE_PLACE_ADDRESS_REQUIRED':'Une address est requise !',
		'CREATE_PLACE_TIMEZONE_REQUIRED':'Un fuseau horaire est requis !',
		'CREATE_PLACE_CITY_REQUIRED':'Une ville est requise !',
		'CREATE_PLACE_COUNTRY_REQUIRED':'Un pays est requis !',
		'CREATE_PLACE_ZIP_REQUIRED':'Un code postal est requis !',
	}
}
