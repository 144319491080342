import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { locale as spanish } from './i18n/es';

import { FuseConfigService } from '@fuse/services/config.service';

import { AutoRedirectionService } from '../../core/AutoRedirectionService/auto-redirection.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TimelineService } from './services/timeline.service';
import { ParseTimelineService } from './services/parse-timeline.service';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccessService } from '../access/services/access.service';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { PlaceData } from '../interfaces/PlaceData';
import { AddressData } from './interfaces/AddressData';
import { LogData } from './interfaces/LogData';
import { LogCard } from './interfaces/LogCard';

import { EditExpiryLogsComponent } from './dialogs/edit-expiry-logs/edit-expiry-logs.component';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class TimelineComponent implements OnInit {

  userAddresses: AddressData;
  addresses = new Array<PlaceData>();
  selectedAddress: PlaceData;
  placesExist: boolean;
  logData: LogData;
  logCards = new Array<LogCard>();
  totalLogs: number;
  currentLog: number;
  isLoading: boolean;
  logToDeleteId: number;
  hourFormat: string;

  @ViewChild('WantToDeleteLogById') WantToDeleteLogById: TemplateRef<any>;
  @ViewChild('WantToDeleteAllLogs') WantToDeleteAllLogs: TemplateRef<any>;

  /**
    * Constructor
    *
    * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
    */
  constructor
    (
      private _fuseConfigService: FuseConfigService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private translateService: TranslateService,
      private autoRedirectionService: AutoRedirectionService,
      private timelineService: TimelineService,
      private parseTimelineService: ParseTimelineService,
      private spinner: NgxSpinnerService,
      private dialog: MatDialog,
      private accessService: AccessService
    ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: false
        },
        toolbar: {
          hidden: false
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };
    this.hourFormat = this.accessService.getHourFormat(this.translateService.currentLang);
  }

  ngOnInit(): void {
    let selectedPlaceIndex: number;

    // If the user is not authenticated, he is redirected to the login page.
    // If the user is authenticated but the token has expired, he is redirected to the "session locked" page.
    this.autoRedirectionService.redirectUserIfNecessary();

    this.timelineService.getUserAddresses()
      .subscribe(
        (response) => {
          this.userAddresses = response;
          if (this.userAddresses.address == undefined) { // Si l'utilisateur n'a pas de lieu
            this.placesExist = false;
          } else {
            this.placesExist = true;
            // On récupère le nom de tous les lieux de l'utilisateur pour les afficher dans le menu
            for (let i = 0; i < this.userAddresses.address.length; i++) {
              if (this.userAddresses.address[i].admin_address[0] != undefined) {
                this.addresses[i] = {
                  'name': this.userAddresses.address[i].admin_address[0].name,
                  'address': this.userAddresses.address[i].address,
                  'id': this.userAddresses.address[i].id
                }
              } else {
                this.addresses[i] = {
                  'name': undefined,
                  'address': this.userAddresses.address[i].address,
                  'id': this.userAddresses.address[i].id
                }
              }
            }
            if (localStorage.getItem('currentPlace') != null) {
              // Si l'utilisateur a selectionné un lieu précédemment (dans la même page ou dans une autre page), 
              // On initialise la page timeline sur ce lieu. 
              selectedPlaceIndex = this.getPlaceIndexFromPlaceId(parseInt(localStorage.getItem('currentPlace')));
            } else {
              // Sinon, on initialise le lieu sur le premier lieu de l'utilisateur 
              selectedPlaceIndex = 0;
            }
            this.selectedAddress = this.addresses[selectedPlaceIndex];
            // On récupère les 50 premiers logs de ce lieu 
            this.getPlaceFirstLogs(this.selectedAddress.id);
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
    this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.hourFormat = this.accessService.getHourFormat(langChangeEvent.lang);
      this.getPlaceFirstLogs(this.selectedAddress.id);
    });
  }

  getPlaceIndexFromPlaceId(placeId: number) {
    for (let i = 0; i < this.addresses.length; i++) {
      if (this.addresses[i].id == placeId) {
        return i;
      }
    }
    // Si on ne trouve pas le lieu correspondant à l'id, on retourne l'index correspondant au premier lieu de l'utilisateur
    return 0;
  }

  // Lorsque l'utilisateur sélectionne un lieu, on récupère l'index du lieu sélectionné
  // et on va chercher les 50 premiers logs associés à ce lieu avec getPlaceFirstLogs
  onPlaceSelection(index: number) {
    localStorage.removeItem('currentBoard');
    this.selectedAddress = this.addresses[index];
    localStorage.setItem('currentPlace', this.selectedAddress.id.toString());
    this.getPlaceFirstLogs(this.selectedAddress.id);
  }

  getPlaceFirstLogs(placeId: number) {
    this.isLoading = true;
    this.spinner.show("spinnerLoadingNewLogs");
    this.logCards = new Array<LogCard>();
    this.timelineService.getAddressLogs(placeId, 1)
      .subscribe(
        (response) => {
          this.logData = response;
          this.totalLogs = this.logData.total;
          this.currentLog = 1;
          this.logCards = this.parseTimelineService.parseTimeline(this.logData, this.logCards, this.currentLog - 1);
          this.spinner.hide("spinnerLoadingNewLogs");
          this.isLoading = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.spinner.hide("spinnerLoadingNewLogs");
          this.isLoading = false;
        }
      );
  }

  loadMoreLogs(placeId: number) {
    this.isLoading = true;
    this.currentLog += 50;
    if (this.currentLog <= this.totalLogs) {
      this.spinner.show("spinnerLoadingNewLogs");
      this.timelineService.getAddressLogs(placeId, this.currentLog)
        .subscribe(
          (response) => {
            this.logData = response;
            this.logCards = this.parseTimelineService.parseTimeline(this.logData, this.logCards, this.currentLog - 1);
            this.spinner.hide("spinnerLoadingNewLogs");
            this.isLoading = false;
          },
          (err: HttpErrorResponse) => {
            console.log(err);
            this.spinner.hide("spinnerLoadingNewLogs");
            this.isLoading = false;
          }
        );
    } else {
      this.isLoading = false;
    }
  }

  deleteLogById() {
    let logIndex: number;
    for (let i = 0; i < this.logCards.length; i++) {
      if (this.logCards[i].id == this.logToDeleteId) {
        logIndex = i;
      }
    }
    this.timelineService.deleteLogById(this.logToDeleteId)
      .subscribe(
        () => {
          this.currentLog -= 1;
          this.totalLogs -= 1;
          // On fait disparaître le log de l'écran
          this.logCards.splice(logIndex, 1);
          if (this.isLoading == false && this.logCards.length <= 10) {
            this.loadMoreLogs(this.selectedAddress.id);
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          alert(this.translateService.instant('ERROR'));
          this.getPlaceFirstLogs(this.selectedAddress.id);
        }
      );
  }

  deleteAllLogs() {
    this.isLoading = true;
    this.spinner.show("spinnerDeleteAllLogs");
    this.timelineService.deleteAllLogs(this.selectedAddress.id)
      .subscribe(
        () => {
          this.logCards = new Array<LogCard>();
          this.logData = null;
          this.spinner.hide("spinnerDeleteAllLogs");
          this.isLoading = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.spinner.hide("spinnerDeleteAllLogs");
          this.isLoading = false;
        }
      );
  }

  showWantToDeleteLogByIdDialog(event, logId: number) {
    event.stopPropagation(); // Pour bloquer l'animation de déroulage des informations du log lorsque l'utilisateur clique sur l'icon "delete"
    this.logToDeleteId = logId;
    this.dialog.open(this.WantToDeleteLogById);
  }

  showWantToDeleteAllLogsDialog() {
    this.dialog.open(this.WantToDeleteAllLogs);
  }

  showEditExpiryLogsDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      addressId: this.selectedAddress.id
    }
    this.dialog.open(EditExpiryLogsComponent, dialogConfig);
  }

  onScroll(event: ScrollEvent) {
    if (event.isReachingBottom) {
      if (this.isLoading == false) {
        this.loadMoreLogs(this.selectedAddress.id);
      }
    }
  }

  scrollToTop() {
    document.getElementById('log').scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
}
