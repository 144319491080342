import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InvoicesData } from '../interfaces/InvoicesData';

import { ApiConfigData } from '../../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  api_bearer: string = '.json?bearer=';

  getInvoicesApiUrl: string = this.api_endpoint + '/api/order/invoice';

  constructor(
    private httpClient: HttpClient
  ) { }

  getInvoices(): Observable<InvoicesData>{
    return this.httpClient.get<InvoicesData>(this.getInvoicesApiUrl + this.api_bearer + localStorage.getItem('token'));
  }
}
