export const locale = {
    lang: 'en',
    data: {
		'GENERAL_INFORMATION':'General information',
		'FIRST_NAME':'First name',
		'LAST_NAME':'Last name',
		'PHONE_NUMBER': 'Phone Number',
		'EMAIL': 'Email',
		'MODIFY':'Modify',
		'ACCESSES':'Accesses',
		'ADMINISTRATOR': 'Administrator',
		'MODERATOR': 'Moderator',
		'USER': 'User',
		'LOCK':'Lock:',
		'DELETE_OWN_ACCESS_DIALOG': 'Are you sure you want to delete your own this access to the lock:',
		'YES': 'YES',
		'NO': 'NO',
		'NO_ACCESS':'You haven\'t got any access.',
		'?': '?',
		'INCORRECT_FILE_TYPE_TITLE':'Incorrect file type',
		'INCORRECT_FILE_TYPE_CONTENT':'Please, select an image file.',
		'FILE_TOO_BIG_TITLE':'File too big',
		'FILE_TOO_BIG_CONTENT':'Image size must be less than 10 MB.',
		'CANCEL':'Cancel',
		'CHOOSE_ANOTHER_FILE':'Select another file',
		'PASSWORD': 'Password',  
		'CHANGE_PASSWORD':'Change password',
    }
};