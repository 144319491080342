export const locale = {
    lang: 'fr',
    data: {
        'TITLE': {
            'THEME_OPTIONS': 'Options de personnalisation',
            'COLOR_THEMES': 'Thèmes',
            'LAYOUT_STYLES': 'Styles',
            'LAYOUT_WIDTH': 'Largeur',
            'NAVBAR': 'Barre de navigation',
            'TOOLBAR': 'Barre d\'outils',
            'FOOTER': 'Pied de page',
            'SIDE_PANEL': 'Panneau Latéral',
            'CUSTOM_SCROLLBARS': 'Barres de défilement'
        },
        'PARAMETER':{
            'POSITION': 'Position :',
            'VARIANT': 'Variante :',
            'VARIANT_VERTICAL': 'Variante (Vertical) :',
            'PRIMARY_BACKGROUND': 'Fond primaire :',
            'SECONDARY_BACKGROUND': 'Fond secondaire :',
            'SECONDARY_BACKGROUND_VERTICAL':'Fond secondaire (Vertical) :',
            'BACKGROUND_COLOR': 'Couleur de fond :',
            'COLOR': 'Couleur :'
        },
        'OPTION':{
            'ELOCKY_LIGHT': 'Elocky Clair',
            'ELOCKY_DARK': 'Elocky Sombre',
            'VERTICAL_LAYOUT_1': 'Vertical #1',
            'VERTICAL_LAYOUT_2': 'Vertical #2',
            'VERTICAL_LAYOUT_3': 'Vertical #3',
            'HORIZONTAL_LAYOUT_1': 'Horizontal #1',
            'FULLWIDTH': 'Pleine largeur',
            'BOXED': 'Encadré',
            'HIDE': 'Cacher',
            'FOLDED': 'Rabattre',
            'LEFT': 'Gauche',
            'RIGHT': 'Droite',
            'STYLE_1': 'Style 1',
            'STYLE_2': 'Style 2',
            'ABOVE': 'Au-dessus',
            'BELOW_STATIC': 'En-dessous et statique',
            'BELOW_FIXED': 'En-dessous et fixé',
            'USE_CUSTOM_BACKGROUND_COLOR': 'Couleur de fond personnalisée',
            'ENABLE_CUSTOM_SCROLLBARS': 'Barres de défilement personnalisées',
        }
    }
};
