import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfigData } from '../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  api_endpoint: string = ApiConfigData.api_endpoint;
  api_bearer: string = ".json?bearer=";

  api_editAlarmBoard: string = this.api_endpoint + "/api/object/alarm/edit";

  constructor(private httpClient: HttpClient) { }

  editAlarmBoard(addressId: number, data: any): Observable<any> {
    return this.httpClient.put(this.api_editAlarmBoard + "/" + addressId + this.api_bearer + localStorage.getItem('token'),
      data
    );
  }
}
