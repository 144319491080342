import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AccessData } from '../interfaces/AccessData';
import { BoardData } from './../interfaces/BoardData';
import { UserAccessData } from '../interfaces/UserAccessData';
import { GuestAccessData } from '../interfaces/GuestAccessData';
import { PlaceData } from 'app/main/interfaces/PlaceData';
import { BadgeData } from '../interfaces/BadgeData';
import { ElectrokeyData } from '../interfaces/ElectrokeyData';

@Injectable({
  providedIn: 'root'
})

export class AccessDataSaveService {

  private selectedAddress = new BehaviorSubject(null);
  currentSelectedAddress = this.selectedAddress.asObservable();

  private selectedBoard = new BehaviorSubject(null);
  currentSelectedBoard = this.selectedBoard.asObservable();

  private accessData = new BehaviorSubject(null);
  currentAccessData = this.accessData.asObservable();

  private badges = new BehaviorSubject(null);
  currentBadges = this.badges.asObservable();

  private keys = new BehaviorSubject(null);
  currentKeys = this.keys.asObservable();

  private userOrObjectType = new BehaviorSubject(null);
  currentUserOrObjectType = this.userOrObjectType.asObservable();

  private userOrObjectId = new BehaviorSubject(null);
  currentUserOrObjectId = this.userOrObjectId.asObservable();

  private selectedUserAccess = new BehaviorSubject(null);
  currentUserAccess = this.selectedUserAccess.asObservable();

  private selectedGuestAccess = new BehaviorSubject(null);
  currentGuestAccess = this.selectedGuestAccess.asObservable();

  private selectedBadge = new BehaviorSubject(null);
  currentBadge = this.selectedBadge.asObservable();

  private selectedkey = new BehaviorSubject(null);
  currentKey = this.selectedkey.asObservable();

  private tabIndex = new BehaviorSubject(null);
  currentTabIndex = this.tabIndex.asObservable();

  constructor() { }

  updateSelectedAddress(newSelectedAddress: PlaceData) {
    this.selectedAddress.next(newSelectedAddress);
  }

  updateSelectedBoard(newSelectedBoard: BoardData) {
    this.selectedBoard.next(newSelectedBoard);
  }

  updateAccessData(newAccessData: AccessData) {
    this.accessData.next(newAccessData);
  }

  updateBadges(newBadges: Array<BadgeData>) {
    this.badges.next(newBadges);
  }

  updateKeys(newKeys: Array<ElectrokeyData>) {
    this.keys.next(newKeys);
  }

  updateUserOrObjectType(newUserOrObjectType: string) {
    this.userOrObjectType.next(newUserOrObjectType);
  }

  updateUserOrObjectId(newUserOrObjectId: number) {
    this.userOrObjectId.next(newUserOrObjectId);
  }

  updateUserAccess(newUserAccess: UserAccessData) {
    this.selectedUserAccess.next(newUserAccess);
  }

  updateGuestAccess(newGuestAccess: GuestAccessData) {
    this.selectedGuestAccess.next(newGuestAccess);
  }

  updateSelectedBadge(newBadge: BadgeData) {
    this.selectedBadge.next(newBadge);
  }

  updateSelectedKey(newKey: ElectrokeyData) {
    this.selectedkey.next(newKey);
  }

  updateTabIndex(newTabIndex: number) {
    this.tabIndex.next(newTabIndex);
  }

}
