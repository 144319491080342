import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { HttpErrorResponse } from '@angular/common/http';

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { PlaceService } from '../../../../core/PlacesService/place.service';
import { ProfileService } from '../../../../core/ProfileService/profile.service';

import { PlaceData } from '../../interfaces/PlaceData';
import { UserData } from '../../interfaces/UserData';

import { Subscription, Observable } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

interface StateData {
  id:number,
  name:string
}

@Component({
  selector: 'user-lieux',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  animations: fuseAnimations
})
export class UserComponent implements OnInit {

  @Input("obsCurrent$") obsCurrent$:Observable<PlaceData>
  subCurrentPlace:Subscription;
  subCurrentAdminAddress:Subscription;
  current_place:PlaceData;

  current_user:Array<UserData> = null;

  @ViewChild('FormSettingUser') FormSettingUser: TemplateRef<any>;
  @ViewChild('FormDeleteUser') FormDeleteUser: TemplateRef<any>;
  dialogRef:MatDialogRef<TemplateRef<any>>;
  formState:FormGroup;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private placeService: PlaceService,
    private profileService: ProfileService,
    private dialog: MatDialog) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    list_state:StateData[] = [
      {
        id:0,
        name:"Administrateur"
      },
      {
        id:1,
        name:"Modérateur"
      },
      {
        id:2,
        name:"User"
      },
      {
        id:3,
        name:"Guest"
      },
    ]
    ngOnInit() : void {
      this.subCurrentPlace = this.obsCurrent$.subscribe(
        (current) => {
          this.current_place = current;
          this.placeService.getAdminAddressByAdressId(this.current_place.id);
          this.subCurrentAdminAddress = this.placeService.getListAdminAddress().subscribe(
            (adminAddress) => { this.current_user = adminAddress; }
          )
        }
      )
    }
    ngOnDestroy() : void {
      this.subCurrentPlace.unsubscribe();
      this.subCurrentAdminAddress.unsubscribe();
    }
    getStateIdByName(name:string) : number {
      let rname = name.toLowerCase();
      for (let i = 0; i < this.list_state.length; i++) {
        const e = this.list_state[i];
        if(e.name.toLowerCase() == rname) return e.id;
      }
      return null;
    }
    getStateNameById(id:number) : string {
      for (let i = 0; i < this.list_state.length; i++) {
        const e = this.list_state[i];
        if(e.id == id) return e.name;
      }
      return null;
    }
    getUserIndexById(id:number) : number {
      for (let i = 0; i < this.current_user.length; i++) {
        const e = this.current_user[i];
        if(e.id == id) return i;
      }
    }
    hasFormChanged(id_user:number) : boolean {
      let newValue = this.getStateIdByName(this.formState.value.state);
      let user_state = this.current_user[this.getUserIndexById(id_user)].state;
      return newValue != user_state;
    }
    onFormDeleteOpen(id:number) : void {
      this.dialogRef = this.dialog.open(this.FormDeleteUser, {
        data: {
          id_user:id
        },
      })
    }
    onFormSettingOpen(id:number) : void {
      let currentStateName = this.getStateNameById(this.current_user[this.getUserIndexById(id)].state);
      this.formState = new FormGroup({
        state:new FormControl(currentStateName),
        id:new FormControl(id)
      });
      this.dialogRef = this.dialog.open(this.FormSettingUser, {
        data: {
          id_user:id
        },
      })
    }
    onSendForm() : void {
      this.onCloseDialog();
      let value = this.formState.value;
      console.log(value);
      this.placeService.editAdminAddressState(value.id, this.getStateIdByName(value.state)).subscribe(
        (response) => {

        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    }
    onDeleteUser(id:number) : void {
      this.onCloseDialog();
      this.placeService.deleteAdminAddress(id).subscribe(
        (response) => {

        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    }
    onCloseDialog() : void {
      this.dialogRef.close();
    }

  }
