import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { locale as spanish } from './i18n/es';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { FuseConfigService } from '@fuse/services/config.service';
import { AutoRedirectionService } from '../../core/AutoRedirectionService/auto-redirection.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { InvoicesService } from './services/invoices.service';

import { InvoicesData } from './interfaces/InvoicesData';

@Component({
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class InvoicesComponent implements OnInit {

  invoices: InvoicesData;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private autoRedirectionService: AutoRedirectionService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private invoicesService: InvoicesService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: false
        },
        toolbar: {
          hidden: false
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: false
        }
      }
    };
  }

  ngOnInit(): void {
    // If the user is not authenticated, he is redirected to the login page.
    // If the user is authenticated but the token has expired, he is redirected to the "session locked" page.
    this.autoRedirectionService.redirectUserIfNecessary();

  }
}
