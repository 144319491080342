export const locale = {
    lang: 'fr',
    data: {
        'THE_MANAGEMENT_WEBSITE': 'Le site de gestion',
        'LOGIN_TO_YOUR_ACCOUNT': 'SE CONNECTER',
        'EMAIL': 'Adresse Mail',
        'EMAIL_IS_REQUIRED': 'L\'adresse mail est requise ',
        'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS': 'Veuillez entrer une adresse mail valide',
        'PASSWORD': 'Mot de Passe',
        'PASSWORD_IS_REQUIRED': 'Le mot de passe est requis',
        'PASSWORD_CONFIRM': 'Mot de Passe (Confirmation)',
        'PASSWORD_CONFIRMATION_IS_REQUIRED': 'La confirmation du mot de passe est requise',
        'PASSWORDS_MUST_MATCH': 'Les mots de passe doivent être identiques',
        'REMEMBER_ME': 'Se souvenir de moi',
        'FORGOT_PASSWORD': 'Mot de passe oublié ?',
        'LOGIN_UPPERCASE': 'SE CONNECTER',
        'LOGIN_LOWERCASE': 'Se connecter',
        'OR': 'OU',
        'LOGIN_WITH_GOOGLE': 'Se connecter avec Google',
        'LOGIN_WITH_FACEBOOK': 'Se connecter avec Facebook',
        'DONT_HAVE_AN_ACCOUNT': 'Vous n\'avez pas de compte ?',
        'CREATE_AN_ACCOUNT_LOWERCASE': 'Créer un compte',
        'CREATE_AN_ACCOUNT_UPPERCASE': 'CRÉER UN COMPTE',
        'LAST_NAME': 'Nom',
        'FIRST_NAME': 'Prénom',
        'LAST_NAME_IS_REQUIRED': 'Le nom est requis',
        'FIRST_NAME_IS_REQUIRED': 'Le prénom est requis',
        'ACCEPT': 'J\'accepte',
        'TERMS_AND_CONDITIONS': 'les conditions générales',
        'ALREADY_HAVE_AN_ACCOUNT': 'Vous possédez déjà un compte ?',
        'PHONE_NUMBER': 'Numéro de téléphone',
        'PHONE_NUMBER_IS_REQUIRED': 'Le numéro de téléphone est requis',
        'PLEASE_ENTER_A_VALID_PHONE_NUMBER': 'Veuillez entrer un numéro de téléphone valide',
        'RECOVER_YOUR_PASSWORD': 'RÉCUPÉREZ VOTRE MOT DE PASSE',
        'SEND_RESET_LINK': 'ENVOYER LE LIEN DE RÉINITIALISATION',
        'GO_BACK_TO_LOGIN': 'Retour à la connexion',
        'INVALID_PHONE_NUMBER_1': 'Numéro de téléphone invalide',
        'INVALID_PHONE_NUMBER_2': 'Le numéro de téléphone que vous avez saisi est invalide.',
        'EXPLANATION_INVALID_PHONE_NUMBER': 'Merci de sélectionner votre pays depuis la liste (cliquez sur le drapeau), puis de saisir votre numéro de téléphone.',
        'REGISTRATION_COMPLETED_SUCCESSFULLY_TITLE': 'Inscription effectuée avec succès !',
        'REGISTRATION_COMPLETED_SUCCESSFULLY_CONTENT': 'Nous vous avons envoyé une confirmation par mail à l\'adresse ',
        'EMAIL_ALREADY_EXISTS_TITLE': 'Adresse mail existante',
        'EMAIL_ALREADY_EXISTS_CONTENT_1': 'L\'adresse mail ',
        'EMAIL_ALREADY_EXISTS_CONTENT_2': ' est déjà associée à un compte.',
        'TRY_LATER_TITLE': 'Une erreur est survenue',
        'TRY_LATER_CONTENT': 'Une erreur est survenue. Veuillez réessayer ultérieurement.',
        'RESET_YOUR_PASSWORD_TITLE': 'Réinitialisez votre mot de passe',
        'RESET_YOUR_PASSWORD_CONTENT': 'Nous vous avons envoyé un mail pour réinitialiser votre mot de passe à l\'adresse ',
        'EMAIL_ERROR_TITLE': 'Erreur',
        'EMAIL_ERROR_CONTENT_1': 'L\'adresse mail ',
        'EMAIL_ERROR_CONTENT_2': ' n\'est associée à aucun compte.',
        'BAD_CREDENTIALS_TITLE': 'Erreur d\'authentification',
        'BAD_CREDENTIALS_CONTENT': 'L\'adresse mail ou le mot de passe que vous avez saisi est incorrect.',
        'BAD_CREDENTIALS_LOCK_SCREEN_CONTENT': 'Le mot de passe que vous avez saisi est incorrect.',
        'YOUR_SESSION_IS_LOCKED': 'SESSION VERROUILLÉE',
        'YOUR_SESSION_IS_LOCKED_EXPLANATION': 'En raison de l\'inactivité, votre session est verrouillée. Entrez votre mot de passe pour continuer.',
        'UNLOCK': 'DÉVERROUILLER',
        'ARE_YOU_NOT': 'Vous n\'êtes pas',
        '?': ' ?',
        'SAVE': 'Enregistrer',
        'NO_CHANGES': 'Aucune modification n\'a été effectuée.',
        'PROFILE': 'Profil',
        'CHANGE_PASSWORD': 'Changer le mot de passe',
        'OLD_PASSWORD': 'Ancien mot de passe',
        'OLD_PASSWORD_IS_REQUIRED': 'L\'ancien mot de passe est requis',
        'NEW_PASSWORD': 'Nouveau mot de passe',
        'NEW_PASSWORD_IS_REQUIRED': 'Le nouveau mot de passe est requis',
        'NEW_PASSWORD_CONFIRM': 'Nouveau mot de passe (Confirmation)',
        'CHANGE_PASSWORD_SUCCESS': 'Modification enregistrée avec succès.',
        'WRONG_OLD_PASSWORD': 'L\'ancien mot de passe est incorrect.',
    }
};