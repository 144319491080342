<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 matDialogTitle>{{'ACCESS_MANAGEMENT.ADD_A_RECURRENT_ACCESS' | translate}}</h2>
    <button class="closeDialogButton" (click)="close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">

    <form [formGroup]="addAccessRecuForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>

        <div fxLayout="column">

            <div fxLayout="row" class="add-access-form-field">
                <mat-radio-group formControlName="access_recu_type">
                    <mat-radio-button (change)="onRadioButtonValueChange($event)" id="permanent_access"
                        class="add-access-radio-button" value="permanent_access">
                        {{'ACCESS_MANAGEMENT.PERMANENT_ACCESS' | translate}}
                    </mat-radio-button>
                    <mat-radio-button (change)="onRadioButtonValueChange($event)" id="recurrent_access"
                        class="add-access-radio-button" value="recurrent_access">
                        {{'ACCESS_MANAGEMENT.RECURRENT_ACCESS' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="accessRecuType == 'permanent_access'" class="warning-message" fxLayout="row">
                <mat-icon class="warning-icon" color="warn">warning</mat-icon>
                <p class="warning-text">{{'ACCESS_MANAGEMENT.WARNING_PERMANENT_ACCESS' | translate}}</p>
            </div>

            <mat-form-field *ngIf="accessRecuType == 'recurrent_access'" appearance="outline">
                <mat-label>{{'ACCESS_MANAGEMENT.DAY' | translate}}</mat-label>
                <mat-select matNativeControl id="day" formControlName="day">
                    <mat-option value="1">{{'SCHEDULES.DAY.MONDAY' | translate}}</mat-option>
                    <mat-option value="2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</mat-option>
                    <mat-option value="3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</mat-option>
                    <mat-option value="4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</mat-option>
                    <mat-option value="5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</mat-option>
                    <mat-option value="6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</mat-option>
                    <mat-option value="7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</mat-option>
                </mat-select>
                <mat-error *ngIf="addAccessRecuForm.get('day').hasError('required')">
                    {{'ACCESS_MANAGEMENT.DAY_IS_REQUIRED' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="accessRecuType == 'recurrent_access'">
                <mat-label>{{'ACCESS_MANAGEMENT.START_TIME' | translate}}</mat-label>
                <input *ngIf="hourFormat == '12'" matInput [ngxTimepicker]="start" [format]="12"
                    formControlName="start_time" readonly />
                <input *ngIf="hourFormat == '24'" matInput [ngxTimepicker]="start" [format]="24"
                    formControlName="start_time" readonly />
                <mat-icon matSuffix class="secondary-text">schedule</mat-icon>
                <mat-error *ngIf="addAccessRecuForm.get('start_time').hasError('required')">
                    {{'ACCESS_MANAGEMENT.START_TIME_IS_REQUIRED' | translate}}
                </mat-error>
                <ngx-material-timepicker #start [enableKeyboardInput]="true" [editableHintTmpl]="dialHint"
                    [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
                </ngx-material-timepicker>
            </mat-form-field>


            <mat-form-field appearance="outline" *ngIf="accessRecuType == 'recurrent_access'">
                <mat-label>{{'ACCESS_MANAGEMENT.STOP_TIME' | translate}}</mat-label>
                <input *ngIf="hourFormat == '12'" matInput [ngxTimepicker]="stop" [format]="12"
                    formControlName="stop_time" readonly />
                <input *ngIf="hourFormat == '24'" matInput [ngxTimepicker]="stop" [format]="24"
                    formControlName="stop_time" readonly />
                <mat-icon matSuffix class="secondary-text">schedule</mat-icon>
                <mat-error *ngIf="addAccessRecuForm.get('stop_time').hasError('required')">
                    {{'ACCESS_MANAGEMENT.STOP_TIME_IS_REQUIRED' | translate}}
                </mat-error>
                <ngx-material-timepicker #stop [enableKeyboardInput]="true" [editableHintTmpl]="dialHint"
                    [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
                </ngx-material-timepicker>
            </mat-form-field>

            <button mat-raised-button type="submit" color="accent" class="submit-button" aria-label="CREATE ACCESS RECU"
                [disabled]="addAccessRecuForm.invalid || isLoading">
                {{'POPUP.VALIDATE' | translate}}
            </button>

        </div>

    </form>

</mat-dialog-content>


<ng-template #dialHint>
    <p style="color: white;">{{'ACCESS_MANAGEMENT.TIME_HINT' | translate}}</p>
</ng-template>
<ng-template #cancelBtn>
    <button mat-raised-button style="margin-right: 10px;">{{'POPUP.CANCEL' | translate}}</button>
</ng-template>
<ng-template #confirmBtn>
    <button mat-raised-button>{{'POPUP.VALIDATE' | translate}}</button>
</ng-template>