<div class="theme-options-panel" fusePerfectScrollbar>

    <div class="header">

        <span class="title">{{'TITLE.THEME_OPTIONS' | translate}}</span>

        <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('themeOptionsPanel')">
            <mat-icon>close</mat-icon>
        </button>

    </div>

    <form [formGroup]="form">

        <!-- COLOR THEME -->
        <div class="group">

            <h2>{{'TITLE.COLOR_THEMES' | translate}}</h2>

            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="colorTheme">
                <mat-radio-button class="mb-12" value="theme-default">{{'OPTION.ELOCKY_LIGHT' | translate}}</mat-radio-button>
                <mat-radio-button class="mb-12" value="theme-elocky-dark">{{'OPTION.ELOCKY_DARK' | translate}}</mat-radio-button>
            </mat-radio-group>

        </div>

        <!-- LAYOUT STYLES -->
        <div class="group" formGroupName="layout">

            <h2>{{'TITLE.LAYOUT_STYLES' | translate}}</h2>

            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="style">

                <mat-radio-button class="mb-12" value="vertical-layout-1">
                    {{'OPTION.VERTICAL_LAYOUT_1' | translate}}
                </mat-radio-button>
                <!--
                <mat-radio-button class="mb-12" value="vertical-layout-2">
                    {{'OPTION.VERTICAL_LAYOUT_2' | translate}}
                </mat-radio-button>
                -->
                <mat-radio-button class="mb-12" value="vertical-layout-3">
                    {{'OPTION.VERTICAL_LAYOUT_2' | translate}}
                </mat-radio-button>

                <mat-radio-button class="mb-12" value="horizontal-layout-1">
                    {{'OPTION.HORIZONTAL_LAYOUT_1' | translate}}
                </mat-radio-button>

            </mat-radio-group>

            <!-- DIFFERENT FORMS BASED ON LAYOUT STYLES -->
            <ng-container [ngSwitch]="fuseConfig.layout.style">

                <!-- VERTICAL LAYOUT #1 -->
                <ng-container *ngSwitchCase="'vertical-layout-1'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-32">

                        <h2>{{'TITLE.LAYOUT_WIDTH' | translate}}</h2>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                            <mat-radio-button class="mb-12" value="fullwidth">{{'OPTION.FULLWIDTH' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="boxed">{{'OPTION.BOXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>{{'TITLE.NAVBAR' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <mat-slide-toggle class="mt-24" formControlName="folded">
                            {{'OPTION.FOLDED' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-16" value="left">{{'OPTION.LEFT' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="right">{{'OPTION.RIGHT' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-8">{{'PARAMETER.VARIANT' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                            <mat-radio-button class="mb-16" value="vertical-style-1">{{'OPTION.STYLE_1' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="vertical-style-2">{{'OPTION.STYLE_2' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-16 mb-8">{{'PARAMETER.PRIMARY_BACKGROUND' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="primaryBackground"></fuse-material-color-picker>

                        <h3 class="mt-16 mb-8">{{'PARAMETER.SECONDARY_BACKGROUND' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="secondaryBackground"></fuse-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>{{'TITLE.TOOLBAR' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'PARAMETER.BACKGROUND_COLOR' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>{{'TITLE.FOOTER' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'PARAMETER.COLOR' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>{{'TITLE.SIDE_PANEL' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">{{'OPTION.LEFT' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">{{'OPTION.RIGHT' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- VERTICAL LAYOUT #2 -->
                <ng-container *ngSwitchCase="'vertical-layout-2'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-32">

                        <h2>{{'TITLE.LAYOUT_WIDTH' | translate}}</h2>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                            <mat-radio-button class="mb-12" value="fullwidth">{{'OPTION.FULLWIDTH' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="boxed">{{'OPTION.BOXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>{{'TITLE.NAVBAR' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <mat-slide-toggle class="mt-24" formControlName="folded">
                            {{'OPTION.FOLDED' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-16" value="left">{{'OPTION.LEFT' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="right">{{'OPTION.RIGHT' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-8">{{'PARAMETER.VARIANT' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                            <mat-radio-button class="mb-16" value="vertical-style-1">{{'OPTION.STYLE_1' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="vertical-style-2">{{'OPTION.STYLE_2' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-16 mb-8">{{'PARAMETER.PRIMARY_BACKGROUND' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="primaryBackground"></fuse-material-color-picker>

                        <h3 class="mt-16 mb-8">{{'PARAMETER.SECONDARY_BACKGROUND' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="secondaryBackground"></fuse-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>{{'TITLE.TOOLBAR' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'PARAMETER.BACKGROUND_COLOR' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>{{'TITLE.FOOTER' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'PARAMETER.COLOR' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>{{'TITLE.SIDE_PANEL' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">{{'OPTION.LEFT' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">{{'OPTION.RIGHT' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                 <!-- VERTICAL LAYOUT #3 -->
                <ng-container *ngSwitchCase="'vertical-layout-3'">
    
                        <!-- LAYOUT WIDTH -->
                        <div class="group mt-32">
    
                            <h2>{{'TITLE.LAYOUT_WIDTH' | translate}}</h2>
                            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                                <mat-radio-button class="mb-12" value="fullwidth">{{'OPTION.FULLWIDTH' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-12" value="boxed">{{'OPTION.BOXED' | translate}}</mat-radio-button>
                            </mat-radio-group>
    
                        </div>
    
                        <!-- NAVBAR -->
                        <div class="group" formGroupName="navbar">
    
                            <h2>{{'TITLE.NAVBAR' | translate}}</h2>
    
                            <mat-slide-toggle formControlName="hidden">
                                {{'OPTION.HIDE' | translate}}
                            </mat-slide-toggle>
    
                            <mat-slide-toggle class="mt-24" formControlName="folded">
                                {{'OPTION.FOLDED' | translate}}
                            </mat-slide-toggle>
    
                            <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                                <mat-radio-button class="mb-16" value="left">{{'OPTION.LEFT' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-16" value="right">{{'OPTION.RIGHT' | translate}}</mat-radio-button>
                            </mat-radio-group>
    
                            <h3 class="mt-8">{{'PARAMETER.VARIANT' | translate}}</h3>
                            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                                <mat-radio-button class="mb-16" value="vertical-style-1">{{'OPTION.STYLE_1' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-16" value="vertical-style-2">{{'OPTION.STYLE_2' | translate}}</mat-radio-button>
                            </mat-radio-group>
    
                            <h3 class="mt-16 mb-8">{{'PARAMETER.PRIMARY_BACKGROUND' | translate}}</h3>
                            <fuse-material-color-picker class="mb-16"
                                                        formControlName="primaryBackground"></fuse-material-color-picker>
    
                        <h3 class="mt-16 mb-8">{{'PARAMETER.SECONDARY_BACKGROUND' | translate}}</h3>
                           <fuse-material-color-picker class="mb-16"
                                                       formControlName="secondaryBackground"></fuse-material-color-picker>
    
                        </div>
    
                        <!-- TOOLBAR -->
                        <div class="group" formGroupName="toolbar">
    
                            <h2>{{'TITLE.TOOLBAR' | translate}}</h2>
    
                            <mat-slide-toggle formControlName="hidden">
                                {{'OPTION.HIDE' | translate}}
                            </mat-slide-toggle>
    
                            <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                                <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                            </mat-radio-group>
    
                            <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                                {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                            </mat-checkbox>
    
                            <h3 class="mt-24 mb-8">{{'PARAMETER.BACKGROUND_COLOR' | translate}}</h3>
                            <fuse-material-color-picker class="mb-16"
                                                        formControlName="background"></fuse-material-color-picker>
    
                        </div>
    
                        <!-- FOOTER -->
                        <div class="group" formGroupName="footer">
    
                            <h2>{{'TITLE.FOOTER' | translate}}</h2>
    
                            <mat-slide-toggle formControlName="hidden">
                                {{'OPTION.HIDE' | translate}}
                            </mat-slide-toggle>
    
                            <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                                <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                            </mat-radio-group>
    
                            <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                                {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                            </mat-checkbox>
    
                            <h3 class="mt-24 mb-8">{{'PARAMETER.COLOR' | translate}}</h3>
                            <fuse-material-color-picker class="mb-16"
                                                        formControlName="background"></fuse-material-color-picker>
    
                        </div>
    
                        <!-- SIDE PANEL -->
                        <div class="group" formGroupName="sidepanel">
    
                            <h2>{{'TITLE.SIDE_PANEL' | translate}}</h2>
    
                            <mat-slide-toggle formControlName="hidden">
                                {{'OPTION.HIDE' | translate}}
                            </mat-slide-toggle>
    
                            <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                                <mat-radio-button class="mb-12" value="left">{{'OPTION.LEFT' | translate}}</mat-radio-button>
                                <mat-radio-button class="mb-12" value="right">{{'OPTION.RIGHT' | translate}}</mat-radio-button>
                            </mat-radio-group>
    
                        </div>
    
                    </ng-container>

                <!-- HORIZONTAL LAYOUT #1 -->
                <ng-container *ngSwitchCase="'horizontal-layout-1'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-32">

                        <h2>{{'TITLE.LAYOUT_WIDTH' | translate}}</h2>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="width">
                            <mat-radio-button class="mb-12" value="fullwidth">{{'OPTION.FULLWIDTH' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="boxed">{{'OPTION.BOXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>{{'TITLE.NAVBAR' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-8">{{'PARAMETER.VARIANT_VERTICAL' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="variant">
                            <mat-radio-button class="mb-16" value="vertical-style-1">{{'OPTION.STYLE_1' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-16" value="vertical-style-2">{{'OPTION.STYLE_2' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-16 mb-8">{{'PARAMETER.PRIMARY_BACKGROUND' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="primaryBackground">
                        </fuse-material-color-picker>

                        <h3 class="mt-16 mb-8">{{'PARAMETER.SECONDARY_BACKGROUND_VERTICAL' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="secondaryBackground">
                        </fuse-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>{{'TITLE.TOOLBAR' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'PARAMETER.BACKGROUND_COLOR' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>{{'TITLE.FOOTER' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="above">{{'OPTION.ABOVE' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-static">{{'OPTION.BELOW_STATIC' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="below-fixed">{{'OPTION.BELOW_FIXED' | translate}}</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox class="mt-24" formControlName="customBackgroundColor">
                            {{'OPTION.USE_CUSTOM_BACKGROUND_COLOR' | translate}}
                        </mat-checkbox>

                        <h3 class="mt-24 mb-8">{{'PARAMETER.COLOR' | translate}}</h3>
                        <fuse-material-color-picker class="mb-16"
                                                    formControlName="background"></fuse-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>{{'TITLE.SIDE_PANEL' | translate}}</h2>

                        <mat-slide-toggle formControlName="hidden">
                            {{'OPTION.HIDE' | translate}}
                        </mat-slide-toggle>

                        <h3 class="mt-24">{{'PARAMETER.POSITION' | translate}}</h3>
                        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="position">
                            <mat-radio-button class="mb-12" value="left">{{'OPTION.LEFT' | translate}}</mat-radio-button>
                            <mat-radio-button class="mb-12" value="right">{{'OPTION.RIGHT' | translate}}</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

            </ng-container>

        </div>

        <!-- CUSTOM SCROLLBARS -->
        <div class="group">

            <h2>{{'TITLE.CUSTOM_SCROLLBARS' | translate}}</h2>

            <mat-slide-toggle class="mb-12" formControlName="customScrollbars">
                {{'OPTION.ENABLE_CUSTOM_SCROLLBARS' | translate}}
            </mat-slide-toggle>

        </div>

    </form>

</div>
