export const locale = {
	lang: 'es',
	data: {
		'TITLE_NOTIFICATIONS': 'NOTIFICACIONES',
		'NO_PLACE': 'No hay ningún lugar asociado a su cuenta.',
		':': ' : ',
		'AT': 'a',
		'ERROR': 'Ha ocurrido un error. Por favor, inténtelo más tarde.',
		'OPTIONS': 'Opciones',
		'DELETE_HISTORY': 'Borrar el historial',
		'DELETE_LOG_DIALOG': '¿Estás seguro de que quieres borrar este registro?',
		'DELETE_HISTORY_DIALOG': '¿Estás seguro de que quieres borrar todo el historial?',
		'YES': 'SI',
		'NO': 'NO',
		'CONFIGURATION': 'Configuración',
		'EDIT_EXPIRY_LOGS': {
			'TITLE': 'Suprimir el historial después de',
			'VALIDATE': 'Validar',
			'CANCEL': 'Cancelar',
			'SUCCESS': 'Modificación guardada con éxito.',
			'3_YEARS': '3 años',
			'1_YEAR': '1 año',
			'6_MONTHS': '6 meses',
			'3_MONTHS': '3 meses',
			'1_MONTH': '1 mes',
			'1_WEEK': '1 semana',
		},
		'LOGBOOK': {
			'NO_LOGS_AVAILABLE': 'No log disponible',
			'UNKNOWN_LOG': 'Log desconocido',
			'INTRUSION': 'Intrusión',
			'INTRUSION_LOCK': 'Movimiento detectado - Cerradura',
			'INTRUSION_BEACON': 'Movimiento detectado - Beacon',
			'ADD': 'Adición',
			'ADD_BOX': 'Elocky Box añadida',
			'ADD_LOCK': 'Cerradura añadida',
			'ADD_BEACON': 'Beacon añadido',
			'ADD_ACCESS': 'Acceso añadido',
			'UPDATE': 'Actualización',
			'SOFTWARE_UPDATE': 'Actualización del programa',
			'LOCK_OPENED': 'Apertura',
			'OPENING_FAILURE': 'Fallo de apertura',
			'BADGE': 'Gafete',
			'BADGE_OPENING': 'Apertura con gafete',
			'UNAUTHORIZED_BADGE': 'Gafete no autorizado',
			'PROHIBITED_BADGE': 'Gafete prohibido',
			'UNREGISTERED_BADGE': 'Gafete no registrado',
			'EMERGENCY_KEY': 'Llave de emergencia',
			'REBOOT': 'Reinicio',
			'RESET': 'Restablecimiento',
			'DECONFIGURATION': 'Desconfiguración',
			'CONFIGURATION': 'Configuración',
			'DEBUG': 'Depuración',
			'ACCESS_NUMBER': 'Número de acceso',
			'GUEST_ACCESS': 'Acceso de invitados',
			'SLEEP_MODE': {
				'SLEEP_MODE': 'Modo de espera',
				'NORMAL_SLEEP': 'Espera normal',
				'LIGHT_SLEEP': 'Espera ligera',
				'DEEP_SLEEP': 'Espera profunda',
				'NORMAL': 'normal',
				'LIGHT': 'ligero',
				'DEEP': 'profundo',
			},
			'WAKEUP_MODE': {
				'WAKEUP_MODE': 'Modo de despertar',
				'SENSITIVE_WAKEUP': 'Despertar sensible',
				'NORMAL_WAKEUP': 'Despertar normal',
				'HARD_WAKEUP': 'Despertar duro',
				'SENSITIVE': 'sensible',
				'NORMAL': 'normal',
				'HARD': 'duro',
			}

		}

	}
};