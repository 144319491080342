import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';

import { HttpErrorResponse } from '@angular/common/http';

import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { AccessManagementService } from '../../services/access-management.service';
import { EventEmitterService } from '../../services/event-emitter.service';
import { AccessService } from '../../../access/services/access.service';

import { locale as english } from '../../../access/i18n/en';
import { locale as french } from '../../../access/i18n/fr';
import { locale as spanish } from '../../../access/i18n/es';
import { AccessRecuData } from '../../interfaces/AccessRecuData';
import * as moment from 'moment';

@Component({
  templateUrl: './add-access-recu.component.html',
  styleUrls: ['./add-access-recu.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddAccessRecuComponent {

  userOrGuestId: number;

  addAccessRecuForm: FormGroup;
  accessRecuType: string;
  listAccessRecuId = new Array<number>();

  hourFormat: string;

  isLoading: boolean;

  constructor
    (
      public dialogRef: MatDialogRef<AddAccessRecuComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,

      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private translateService: TranslateService,
      private _formBuilder: FormBuilder,
      private accessManagementService: AccessManagementService,
      private eventEmitterService: EventEmitterService,
      private accessService: AccessService,
  ) {

    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    this.userOrGuestId = data.userOrGuestId;
    this.listAccessRecuId = data.listAccessRecuId;

    this.accessRecuType = 'recurrent_access';
    this.initializeAddAccessRecuForm(this.accessRecuType);

    this.hourFormat = this.accessService.getHourFormat(this.translateService.currentLang);
  }

  initializeAddAccessRecuForm(accessRecuType: string) {
    if (accessRecuType == 'recurrent_access') {
      this.addAccessRecuForm = this._formBuilder.group({
        access_recu_type: ['recurrent_access'],
        day: ['', Validators.required],
        start_time: ['', Validators.required],
        stop_time: ['', Validators.required]

      });
    } else if (accessRecuType == 'permanent_access') {
      this.addAccessRecuForm = this._formBuilder.group({
        access_recu_type: ['permanent_access'],
      });
    }
  }

  onRadioButtonValueChange(event: MatRadioChange) {
    this.accessRecuType = event.value;
    this.initializeAddAccessRecuForm(event.value);
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    if (this.accessRecuType == 'recurrent_access') {
      let accessRecuData: AccessRecuData;
      accessRecuData = {
        'day': form.value.day,
        'start': this.accessManagementService.parseTime(form.value.start_time, this.hourFormat),
        'stop': this.accessManagementService.parseTime(form.value.stop_time, this.hourFormat)
      }
      if ((moment(accessRecuData.start,'HH:mm').isAfter(moment(accessRecuData.stop,'HH:mm'))) || accessRecuData.start == accessRecuData.stop) {
        alert(this.translateService.instant('ACCESS_MANAGEMENT.INVALID_HOURS'));
        this.isLoading = false;
      } else {
        this.accessManagementService.addAccessRecu(this.userOrGuestId, accessRecuData)
          .subscribe(
            () => {
              this.isLoading = false;
              this.close();
              // On invoque la fonction getAccessList() de AccessManagementComponent pour voir apparaître le nouvel accès
              this.eventEmitterService.onAddAccessRecuOrExep();
            },
            (err: HttpErrorResponse) => {
              this.isLoading = false;
              console.log(err);
            }
          );
      }
    } else if (this.accessRecuType == 'permanent_access') {
      for (let i = 0; i < this.listAccessRecuId.length; i++) {
        this.accessManagementService.deleteAccessRecuById(this.listAccessRecuId[i])
          .subscribe(
            () => {
            },
            (err: HttpErrorResponse) => {
              console.log(err);
            }
          );
      }
      this.accessManagementService.addPermanentAccess(this.userOrGuestId)
        .subscribe(
          () => {
            this.isLoading = false;
            this.close();
            // On invoque la fonction getAccessList() de AccessManagementComponent pour voir apparaître les nouveaux accès
            this.eventEmitterService.onAddAccessRecuOrExep();
          },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            console.log(err);
          }
        );
    }
  }

  close() {
    this.dialogRef.close();
  }

}
