import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { PlacePhotoService } from '../../../core/PlacesService/place-photo.service';
import { PlaceService } from '../../../core/PlacesService/place.service';

import { PlaceData } from '../interfaces/PlaceData';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as spanish } from '../i18n/es';

@Component({
  selector: 'place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['./place-card.component.scss'],
  animations: fuseAnimations
})
export class PlaceCardComponent implements OnInit {

  @Input('place') place: PlaceData;

  @Output() onSelectRename = new EventEmitter<any>();
  @Output() onSelectDelete = new EventEmitter<any>();
  @Output() onSelectSetting = new EventEmitter<any>();
  @Output() onSelectDeleteUser = new EventEmitter<any>();
  @Output() onClickOnCard = new EventEmitter<any>();

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private placeService: PlaceService) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }
    get ps() { return this.placeService; }

    ngOnInit() : void {
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {

      });
    }

    rename(id:number) : void {
      this.onSelectRename.emit(id);
    }
    deletePlace(id:number) : void {
      this.onSelectDelete.emit(id);
    }
    deleteUserOfPlace(id:number) : void {
      this.onSelectDeleteUser.emit(id);
    }
    setting(id:number) : void {
      this.onSelectSetting.emit(id);
    }
    clickOnCard(id: number) : void {
      this.onClickOnCard.emit(id);
    }
  }
