<mat-card class="place-card">
  <mat-card-content class="card-content" (click)="clickOnCard(place.id)">
    <img mat-card-image [src]="place.photo" />
  </mat-card-content>
  <mat-card-footer class="card-footer">
    <div>{{ place.name }}</div>
    <div *ngIf="ps.isUserHadRightByState(place.state)" class="container-icon">
      <a [routerLink]="['./gestion/', place.id]" class="footer-icon"><mat-icon>settings</mat-icon></a>
      <mat-icon (click)="deletePlace(place.id)" class="footer-icon">delete</mat-icon>
    </div>
    <div *ngIf="!ps.isUserHadRightByState(place.state)" class="container-icon">
      <mat-icon (click)="rename(place.id)" class="footer-icon">create</mat-icon>
      <mat-icon (click)="deleteUserOfPlace(place.id)" class="footer-icon">person_remove</mat-icon>
    </div>
  </mat-card-footer>
</mat-card>
