<form id="alarmForm" [formGroup]="alarmForm" (ngSubmit)="onSendForm()" *ngIf="current_board != null">
  <div class="alarm-full">
    <h2>{{'ALARM_GENERAL' | translate}}</h2>
    <mat-checkbox
      formControlName="alarmFull"
      [checked]="alarm_full == 1"
      labelPosition="before">
      {{'ALARM_PERMANENT' | translate}}
    </mat-checkbox>
  </div>
  <div class="alarm-boards">
    <div class="alarm-board" *ngIf="current_board.beacon.length > 0">
      <h2>{{'ALARM_BEACON' | translate}}</h2>
      <div class="alarm-list">
        <div *ngFor="let e of getAlarmBeacon().controls; let i = index" formArrayName="alarmBeacon">
          <mat-checkbox
            [checked]="current_board.beacon[i].alarm"
            labelPosition="before"
            [formControlName]="i">
            {{ current_board.beacon[i].name }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="alarm-board" *ngIf="current_board.lock_v1.length > 0">
      <h2>{{'ALARM_LOCK_V1' | translate}}</h2>
      <div class="alarm-list">
        <div *ngFor="let e of getAlarmLockv1().controls; let i = index" formArrayName="alarmLockv1">
          <mat-checkbox
            [checked]="current_board.lock_v1[i].alarm == 1"
            labelPosition="before"
            [formControlName]="i">
            {{ current_board.lock_v1[i].name }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="alarm-board" *ngIf="current_board.lock_v2.length > 0">
      <h2>{{'ALARM_LOCK_V2' | translate}}</h2>
      <div class="alarm-list">
        <div *ngFor="let e of getAlarmLockv2().controls; let i = index" formArrayName="alarmLockv2">
          <mat-checkbox
            [checked]="current_board.lock_v2[i].alarm == 1"
            labelPosition="before"
            [formControlName]="i">
            {{ current_board.lock_v2[i].name }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="buttons">
    <button mat-raised-button type="submit" color="primary" [disabled]="!hasFormChanged()">{{'SAVE_BUTTON' | translate}}</button>
    <button *ngIf="hasFormChanged()" type="button" mat-stroked-button (click)="cancelChoice()" color="primary">{{'CANCEL_BUTTON' | translate}}</button>
  </div>
</form>
