import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSidebarModule } from '@fuse/components/sidebar/sidebar.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { ScrollEventModule } from 'ngx-scroll-event';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MglTimelineModule } from 'angular-mgl-timeline';

import { AccessManagementComponent } from './access-management.component';
import { AddAccessRecuComponent } from './dialogs/add-access-recu/add-access-recu.component';
import { AddAccessExepComponent } from './dialogs/add-access-exep/add-access-exep.component';

const routes = [
  {
    path: 'access/accessManagement',
    component: AccessManagementComponent
  }
];

@NgModule({
  declarations: [
    AccessManagementComponent,
    AddAccessRecuComponent,
    AddAccessExepComponent
  ],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule,

    FuseSidebarModule,
    FuseWidgetModule,

    TranslateModule,

    FuseSharedModule,
    NgxSpinnerModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    ScrollEventModule,
    MglTimelineModule
  ],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AccessManagementModule { }
