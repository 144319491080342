export const LogDataConstants = {
  type: {
    gateway: 0,
    lock: 1,
    beacon: 2,
    access: 3,
    guest: 4
  },
  state: {
    ok: 0,
    intrusion: 1,
    opening_request: 2,
    adding_an_object: 3,
    reboot: 10,
    hard_reset: 11,
    state_origine: 12,
    state_config: 13,
    normal_sleep: 14,
    light_sleep: 15,
    deep_sleep: 16,
    sensitive_accelerometer: 17,
    normal_accelerometer: 18,
    hard_accelerometer: 19,
    update_DFU: 60,
    update: 80,
    debug: 99,
    correct_card: 102,
    unauthorized_card: 103,
    prohibited_card: 104,
    unregistered_card: 107,
    open_key: 202
  }
}