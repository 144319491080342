<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 *ngIf="admin_address != undefined" matDialogTitle>{{admin_address}} - {{address}} - {{board}}</h2>
    <h2 *ngIf="admin_address == undefined" matDialogTitle>{{address}} - {{board}}</h2>
    <button class="closeDialogButton" (click)="close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
</div>

<div *ngIf="typeOfUserOrObject == 'user'">

    <div *ngIf="selectedUserAccess.type_access.id == 3" class="user-name-schedule">
        {{'SCHEDULES.ACCESSES_OF' | translate}}
        {{selectedUserAccess.firstName}} {{selectedUserAccess.lastName}}</div>

    <div *ngIf="selectedUserAccess.type_access.id == 1" class="as-an-admin" fxLayout="row">
        <img class="img-as-an-admin" src="assets/images/avatars/picto_histo.png">
        <div class="h2 text-as-an-admin">{{'SCHEDULES.AS_AN_ADMINISTRATOR' | translate}}
            {{selectedUserAccess.firstName}}
            {{selectedUserAccess.lastName}} {{'SCHEDULES.HAS_A_PERMANENT_ACCESS_TO_THE_LOCK' | translate}} {{board}}.
        </div>
    </div>

    <div *ngIf="selectedUserAccess.type_access.id == 2" class="as-an-admin" fxLayout="row">
        <img class="img-as-an-admin" src="assets/images/avatars/picto_histo.png">
        <div class="h2 text-as-an-admin">{{'SCHEDULES.AS_A_MODERATOR' | translate}}
            {{selectedUserAccess.firstName}}
            {{selectedUserAccess.lastName}} {{'SCHEDULES.HAS_A_PERMANENT_ACCESS_TO_THE_LOCK' | translate}} {{board}}.
        </div>
    </div>

    <div *ngIf="selectedUserAccess.type_access.id == 3" matDialogContent>

        <mat-tab-group dynamicHeight>

            <mat-tab label="{{'SCHEDULES.RECURRENT_ACCESSES' | translate}}">

                <div *ngIf="selectedUserAccess.access_recu[0] == undefined" class="no-accesses" fxLayout="row">
                    <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                    <div class="h2 text-no-accesses">{{selectedUserAccess.firstName}} {{selectedUserAccess.lastName}}
                        {{'SCHEDULES.NO_RECURRENT_ACCESS' | translate}} {{board}}.</div>
                </div>

                <div *ngIf="selectedUserAccess.access_recu[0] != undefined">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <!-- ACCESS_RECU WIDGET -->
                        <fuse-widget *ngFor="let access_recu of selectedUserAccess.access_recu"
                            [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                            fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img class="img-widget" src="assets/images/avatars/picto_histo.png">
                                    </div>

                                    <div class="info-container bg-elocky-green" fxLayout="column">
                                        <div class="p-12">
                                            <div class="h3 info">
                                                <span
                                                    *ngIf="access_recu.day.id == 1">{{'SCHEDULES.DAY.MONDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</span>
                                            </div>
                                            <div class="info">
                                                <span class="h3">{{'SCHEDULES.START' | translate}}</span>
                                                <span
                                                    *ngIf="hourFormat == '12'">{{access_recu.start| date:"hh:mm a" : "UTC"}}</span>
                                                <span
                                                    *ngIf="hourFormat == '24'">{{access_recu.start| date:"HH:mm" : "UTC"}}</span>
                                            </div>
                                            <div class="info">
                                                <span class="h3">{{'SCHEDULES.END' | translate}}</span>
                                                <span
                                                    *ngIf="hourFormat == '12'">{{access_recu.stop| date:"hh:mm a" : "UTC"}}</span>
                                                <span
                                                    *ngIf="hourFormat == '24'">{{access_recu.stop| date:"HH:mm" : "UTC"}}</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / ACCESS_RECU WIDGET -->

                    </div>

                </div>

            </mat-tab>

            <mat-tab label="{{'SCHEDULES.ONE-TIME_ACCESSES' | translate}}">

                <div *ngIf="selectedUserAccess.access_exep[0] == undefined" class="no-accesses" fxLayout="row">
                    <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                    <div class="h2 text-no-accesses">{{selectedUserAccess.firstName}} {{selectedUserAccess.lastName}}
                        {{'SCHEDULES.NO_ONE-TIME_ACCESS' | translate}} {{board}}.</div>
                </div>

                <div *ngIf="selectedUserAccess.access_exep[0] != undefined">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <!-- ACCESS_EXEP WIDGET -->
                        <fuse-widget *ngFor="let access_exep of selectedUserAccess.access_exep"
                            [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                            fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img *ngIf="access_exep.is_dispo == 1" class="img-widget"
                                            src="assets/images/avatars/picto_calendar_autorisation.png">
                                        <img *ngIf="access_exep.is_dispo == 0" class="img-widget"
                                            src="assets/images/avatars/picto_calendar_interdiction.png">
                                    </div>
                                    <div class="info-container"
                                        [ngClass]="{'bg-elocky-green' : access_exep.is_dispo == 1, 'bg-not-dispo' : access_exep.is_dispo == 0}">
                                        <div class="p-12">
                                            <div class="info">
                                                <div class="h3">{{'SCHEDULES.START' | translate}}</div>
                                                <div *ngIf="hourFormat =='12'">{{access_exep.start| date:"MM/dd/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.start| date:"hh:mm a" : "UTC"}}
                                                </div>
                                                <div *ngIf="hourFormat =='24'">{{access_exep.start| date:"dd/MM/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.start| date:"HH:mm" : "UTC"}}
                                                </div>
                                            </div>
                                            <div>
                                                <div class="h3">{{'SCHEDULES.END' | translate}}</div>
                                                <div *ngIf="hourFormat =='12'">{{access_exep.stop| date:"MM/dd/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.stop| date:"hh:mm a" : "UTC"}}
                                                </div>
                                                <div *ngIf="hourFormat =='24'">{{access_exep.stop| date:"dd/MM/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.stop| date:"HH:mm" : "UTC"}}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / ACCESS_EXEP WIDGET -->

                    </div>

                </div>

            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<div *ngIf="typeOfUserOrObject == 'guest'">

    <div *ngIf="selectedGuestAccess.name != undefined" class="user-name-schedule">
        {{'SCHEDULES.ACCESSES_OF' | translate}}
        {{selectedGuestAccess.name}}</div>
    <div *ngIf="selectedGuestAccess.name == undefined" class="user-name-schedule">
        {{'SCHEDULES.ACCESSES_OF' | translate}}
        {{selectedGuestAccess.num_access}}</div>

    <mat-tab-group dynamicHeight>

        <mat-tab label="{{'SCHEDULES.RECURRENT_ACCESSES' | translate}}">

            <div *ngIf="selectedGuestAccess.access_recu[0] == undefined" class="no-accesses" fxLayout="row">
                <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                <div *ngIf="selectedGuestAccess.name != undefined" class="h2 text-no-accesses">
                    {{selectedGuestAccess.name}}
                    {{'SCHEDULES.NO_RECURRENT_ACCESS' | translate}} {{board}}.</div>
                <div *ngIf="selectedGuestAccess.name == undefined" class="h2 text-no-accesses">
                    {{selectedGuestAccess.num_access}}
                    {{'SCHEDULES.NO_RECURRENT_ACCESS' | translate}} {{board}}.</div>
            </div>

            <div *ngIf="selectedGuestAccess.access_recu[0] != undefined">

                <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                    <!-- ACCESS_RECU WIDGET -->
                    <fuse-widget *ngFor="let access_recu of selectedGuestAccess.access_recu"
                        [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                        fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img class="img-widget" src="assets/images/avatars/picto_histo.png">
                                </div>

                                <div class="info-container bg-elocky-green" fxLayout="column">
                                    <div class="p-12">
                                        <div class="h3 info">
                                            <span
                                                *ngIf="access_recu.day.id == 1">{{'SCHEDULES.DAY.MONDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</span>
                                            <span
                                                *ngIf="access_recu.day.id == 7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.START' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.start| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.start| date:"HH:mm" : "UTC"}}</span>
                                        </div>
                                        <div class="info">
                                            <span class="h3">{{'SCHEDULES.END' | translate}}</span>
                                            <span
                                                *ngIf="hourFormat == '12'">{{access_recu.stop| date:"hh:mm a" : "UTC"}}</span>
                                            <span
                                                *ngIf="hourFormat == '24'">{{access_recu.stop| date:"HH:mm" : "UTC"}}</span>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / ACCESS_RECU WIDGET -->

                </div>

            </div>

        </mat-tab>

        <mat-tab label="{{'SCHEDULES.ONE-TIME_ACCESSES' | translate}}">

            <div *ngIf="selectedGuestAccess.access_exep[0] == undefined" class="no-accesses" fxLayout="row">
                <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                <div *ngIf="selectedGuestAccess.name != undefined" class="h2 text-no-accesses">
                    {{selectedGuestAccess.name}}
                    {{'SCHEDULES.NO_ONE-TIME_ACCESS' | translate}} {{board}}.</div>
                <div *ngIf="selectedGuestAccess.name == undefined" class="h2 text-no-accesses">
                    {{selectedGuestAccess.num_access}}
                    {{'SCHEDULES.NO_ONE-TIME_ACCESS' | translate}} {{board}}.</div>
            </div>

            <div *ngIf="selectedGuestAccess.access_exep[0] != undefined">

                <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                    <!-- ACCESS_EXEP WIDGET -->
                    <fuse-widget *ngFor="let access_exep of selectedGuestAccess.access_exep"
                        [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                        fxFlex.gt-xs="50" fxFlex.gt-md="25">

                        <!-- Front -->
                        <div class="fuse-widget-front">

                            <div fxLayout="row">

                                <div class="img-container">
                                    <img *ngIf="access_exep.is_dispo == 1" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_autorisation.png">
                                    <img *ngIf="access_exep.is_dispo == 0" class="img-widget"
                                        src="assets/images/avatars/picto_calendar_interdiction.png">
                                </div>
                                <div class="info-container"
                                    [ngClass]="{'bg-elocky-green' : access_exep.is_dispo == 1, 'bg-not-dispo' : access_exep.is_dispo == 0}">
                                    <div class="p-12">
                                        <div class="info">
                                            <div class="h3">{{'SCHEDULES.START' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.start| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.start| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.start| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="h3">{{'SCHEDULES.END' | translate}}</div>
                                            <div *ngIf="hourFormat =='12'">{{access_exep.stop| date:"MM/dd/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"hh:mm a" : "UTC"}}
                                            </div>
                                            <div *ngIf="hourFormat =='24'">{{access_exep.stop| date:"dd/MM/yyyy"}}
                                                {{'SCHEDULES.AT' | translate }}
                                                {{access_exep.stop| date:"HH:mm" : "UTC"}}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <!-- / Front -->

                    </fuse-widget>
                    <!-- / ACCESS_EXEP WIDGET -->

                </div>

            </div>

        </mat-tab>

    </mat-tab-group>

</div>

<div *ngIf="typeOfUserOrObject == 'badge'">

    <div *ngIf="selectedBadge.name != undefined" class="user-name-schedule">
        {{'SCHEDULES.BADGE_ACCESS' | translate}} {{selectedBadge.name}}</div>

    <div *ngIf="selectedBadge.name == undefined" class="user-name-schedule">
        {{'SCHEDULES.BADGE_ACCESS' | translate}} {{selectedBadge.num_card}}</div>

    <div matDialogContent>

        <mat-tab-group dynamicHeight>

            <mat-tab label="{{'SCHEDULES.RECURRENT_ACCESSES' | translate}}">

                <div *ngIf="selectedBadge.access_recu[0] == undefined" class="no-accesses" fxLayout="row">
                    <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                    <div class="h2 text-no-accesses">{{'SCHEDULES.THIS_BADGE' | translate}}
                        {{'SCHEDULES.NO_RECURRENT_ACCESS' | translate}} {{board}}.</div>
                </div>

                <div *ngIf="selectedBadge.access_recu[0] != undefined">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <!-- ACCESS_RECU WIDGET -->
                        <fuse-widget *ngFor="let access_recu of selectedBadge.access_recu"
                            [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                            fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img class="img-widget" src="assets/images/avatars/picto_histo.png">
                                    </div>

                                    <div class="info-container bg-elocky-green" fxLayout="column">
                                        <div class="p-12">
                                            <div class="h3 info">
                                                <span
                                                    *ngIf="access_recu.day.id == 1">{{'SCHEDULES.DAY.MONDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 2">{{'SCHEDULES.DAY.TUESDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 3">{{'SCHEDULES.DAY.WEDNESDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 4">{{'SCHEDULES.DAY.THURSDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 5">{{'SCHEDULES.DAY.FRIDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 6">{{'SCHEDULES.DAY.SATURDAY' | translate}}</span>
                                                <span
                                                    *ngIf="access_recu.day.id == 7">{{'SCHEDULES.DAY.SUNDAY' | translate}}</span>
                                            </div>
                                            <div class="info">
                                                <span class="h3">{{'SCHEDULES.START' | translate}}</span>
                                                <span
                                                    *ngIf="hourFormat == '12'">{{access_recu.start| date:"hh:mm a" : "UTC"}}</span>
                                                <span
                                                    *ngIf="hourFormat == '24'">{{access_recu.start| date:"HH:mm" : "UTC"}}</span>
                                            </div>
                                            <div class="info">
                                                <span class="h3">{{'SCHEDULES.END' | translate}}</span>
                                                <span
                                                    *ngIf="hourFormat == '12'">{{access_recu.stop| date:"hh:mm a" : "UTC"}}</span>
                                                <span
                                                    *ngIf="hourFormat == '24'">{{access_recu.stop| date:"HH:mm" : "UTC"}}</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / ACCESS_RECU WIDGET -->

                    </div>

                </div>

            </mat-tab>

            <mat-tab label="{{'SCHEDULES.ONE-TIME_ACCESSES' | translate}}">

                <div *ngIf="selectedBadge.access_exep[0] == undefined" class="no-accesses" fxLayout="row">
                    <img class="img-no-accesses" src="assets/images/avatars/stopwatch-24.svg">
                    <div class="h2 text-no-accesses">{{'SCHEDULES.THIS_BADGE' | translate}}
                        {{'SCHEDULES.NO_ONE-TIME_ACCESS' | translate}} {{board}}.</div>
                </div>

                <div *ngIf="selectedBadge.access_exep[0] != undefined">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <!-- ACCESS_EXEP WIDGET -->
                        <fuse-widget *ngFor="let access_exep of selectedBadge.access_exep"
                            [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                            fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img *ngIf="access_exep.is_dispo == 1" class="img-widget"
                                            src="assets/images/avatars/picto_calendar_autorisation.png">
                                        <img *ngIf="access_exep.is_dispo == 0" class="img-widget"
                                            src="assets/images/avatars/picto_calendar_interdiction.png">
                                    </div>
                                    <div class="info-container"
                                        [ngClass]="{'bg-elocky-green' : access_exep.is_dispo == 1, 'bg-not-dispo' : access_exep.is_dispo == 0}">
                                        <div class="p-12">
                                            <div class="info">
                                                <div class="h3">{{'SCHEDULES.START' | translate}}</div>
                                                <div *ngIf="hourFormat =='12'">{{access_exep.start| date:"MM/dd/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.start| date:"hh:mm a" : "UTC"}}
                                                </div>
                                                <div *ngIf="hourFormat =='24'">{{access_exep.start| date:"dd/MM/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.start| date:"HH:mm" : "UTC"}}
                                                </div>
                                            </div>
                                            <div>
                                                <div class="h3">{{'SCHEDULES.END' | translate}}</div>
                                                <div *ngIf="hourFormat =='12'">{{access_exep.stop| date:"MM/dd/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.stop| date:"hh:mm a" : "UTC"}}
                                                </div>
                                                <div *ngIf="hourFormat =='24'">{{access_exep.stop| date:"dd/MM/yyyy"}}
                                                    {{'SCHEDULES.AT' | translate }}
                                                    {{access_exep.stop| date:"HH:mm" : "UTC"}}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / ACCESS_EXEP WIDGET -->

                    </div>

                </div>

            </mat-tab>

        </mat-tab-group>

    </div>

</div>