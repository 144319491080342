export const locale = {
    lang: 'fr',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'HOME'        : {
                'TITLE': 'Accueil'
            },
            'NOTIFICATIONS'        : {
                'TITLE': 'Notifications'
            },
            'PLACES'        : {
                'TITLE': 'Lieux'
            },
            'ACCESS'        : {
                'TITLE': 'Accès'
            },
            'INVOICES'        : {
                'TITLE': 'Factures'
            }
        }
    }
};
