import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSidebarModule } from '@fuse/components/sidebar/sidebar.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';

import { ProfileComponent } from './profile.component';
import { ModifyProfileInformationDialogComponent } from './dialogs/modify-profile-information-dialog/modify-profile-information-dialog.component';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';

const routes = [
  {
      path     : 'profile',
      component: ProfileComponent
  }
];


@NgModule({
  declarations: [
    ProfileComponent,
    ModifyProfileInformationDialogComponent,
    ChangePasswordDialogComponent
  ],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule,

    FuseSidebarModule,
    FuseWidgetModule,

    TranslateModule,

    FuseSharedModule,
    NgxSpinnerModule,
    NgxMatIntlTelInputModule
  ]
})
export class ProfileModule { }

