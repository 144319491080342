export const locale = {
    lang: 'en',
    data: {
        'THE_MANAGEMENT_WEBSITE': 'The management website',
        'LOGIN_TO_YOUR_ACCOUNT': 'LOGIN TO YOUR ACCOUNT',
        'EMAIL': 'Email',
        'EMAIL_IS_REQUIRED': 'Email is required',
        'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS': 'Please enter a valid email address',
        'PASSWORD': 'Password',
        'PASSWORD_IS_REQUIRED': 'Password is required',
        'PASSWORD_CONFIRM': 'Password (Confirm)',
        'PASSWORD_CONFIRMATION_IS_REQUIRED': 'Password confirmation is required',
        'PASSWORDS_MUST_MATCH': 'Passwords must match',
        'REMEMBER_ME': 'Remember me',
        'FORGOT_PASSWORD': 'Forgot Password?',
        'LOGIN_UPPERCASE': 'LOGIN',
        'LOGIN_LOWERCASE': 'Login',
        'OR': 'OR',
        'LOGIN_WITH_GOOGLE': 'Login with Google',
        'LOGIN_WITH_FACEBOOK': 'Login with Facebook',
        'DONT_HAVE_AN_ACCOUNT': 'Don\'t have an account?',
        'CREATE_AN_ACCOUNT_LOWERCASE': 'Create an account',
        'CREATE_AN_ACCOUNT_UPPERCASE': 'CREATE AN ACCOUNT',
        'LAST_NAME': 'Last Name',
        'FIRST_NAME': 'First Name',
        'LAST_NAME_IS_REQUIRED': 'Last name is required',
        'FIRST_NAME_IS_REQUIRED': 'First name is required',
        'ACCEPT': 'Accept',
        'TERMS_AND_CONDITIONS': 'terms and conditions',
        'ALREADY_HAVE_AN_ACCOUNT': 'Already have an account?',
        'PHONE_NUMBER': 'Phone Number',
        'PHONE_NUMBER_IS_REQUIRED': 'Phone number is required',
        'PLEASE_ENTER_A_VALID_PHONE_NUMBER': 'Please enter a valid phone number',
        'RECOVER_YOUR_PASSWORD': 'RECOVER YOUR PASSWORD',
        'SEND_RESET_LINK': 'SEND RESET LINK',
        'GO_BACK_TO_LOGIN': 'Go back to login',
        'INVALID_PHONE_NUMBER_1': 'Invalid phone number',
        'INVALID_PHONE_NUMBER_2': 'The phone number you entered is invalid.',
        'EXPLANATION_INVALID_PHONE_NUMBER': 'Please, first select your country from the list (click on the flag), then, enter your phone number.',
        'REGISTRATION_COMPLETED_SUCCESSFULLY_TITLE': 'Registration completed successfully!',
        'REGISTRATION_COMPLETED_SUCCESSFULLY_CONTENT': 'We have sent you an email confirmation to the email address ',
        'EMAIL_ALREADY_EXISTS_TITLE': 'Email already exists',
        'EMAIL_ALREADY_EXISTS_CONTENT_1': 'The email address ',
        'EMAIL_ALREADY_EXISTS_CONTENT_2': ' is already associated with an account.',
        'TRY_LATER_TITLE': 'An error occurred',
        'TRY_LATER_CONTENT': 'An error occured, please try later.',
        'RESET_YOUR_PASSWORD_TITLE': 'Reset your password',
        'RESET_YOUR_PASSWORD_CONTENT': 'We have sent you an email to reset your password to the email address ',
        'EMAIL_ERROR_TITLE': 'Email error',
        'EMAIL_ERROR_CONTENT_1': 'The email address ',
        'EMAIL_ERROR_CONTENT_2': ' is not associated with any account.',
        'BAD_CREDENTIALS_TITLE': 'Log in error',
        'BAD_CREDENTIALS_CONTENT': 'The email address or password you entered is incorrect.',
        'BAD_CREDENTIALS_LOCK_SCREEN_CONTENT': 'The password you entered is incorrect.',
        'YOUR_SESSION_IS_LOCKED': 'YOUR SESSION IS LOCKED',
        'YOUR_SESSION_IS_LOCKED_EXPLANATION': 'Due to inactivity, your session is locked. Enter your password to continue.',
        'UNLOCK': 'UNLOCK',
        'ARE_YOU_NOT': 'Are you not',
        '?': '?',
        'SAVE': 'Save',
        'NO_CHANGES': 'No change has been made.',
        'PROFILE': 'Profile',
        'CHANGE_PASSWORD': 'Change password',
        'OLD_PASSWORD': 'Old password',
        'OLD_PASSWORD_IS_REQUIRED': 'Old password is required',
        'NEW_PASSWORD': 'New password',
        'NEW_PASSWORD_IS_REQUIRED': 'New password is required',
        'NEW_PASSWORD_CONFIRM': 'New password (Confirm)',
        'CHANGE_PASSWORD_SUCCESS': 'Change saved successfully.',
        'WRONG_OLD_PASSWORD': 'The old password is incorrect.',
    }
};