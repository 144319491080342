export const locale = {
    lang: 'fr',
    data: {
        'HEADER': {
            'TITLE_ACCESS': 'ACCÈS',
            'NO_PLACE': 'Aucun lieu n\'est associé à votre compte.',
            'NO_LOCK':'Aucune serrure',
        },
        'CONTENT': {
            'NO_LOCK':'Aucune serrure n\'est associée à ce lieu.',
            'NO_GUEST': 'Il n\'y a aucun invité sur cette serrure.',
			'NO_BADGE': 'Aucun badge n\'est associé à cette serrure.',
			'NO_KEY': 'Aucune clé n\'est associée à cette serrure.',
            'USERS': 'Utilisateurs',
            'GUESTS': 'Invités',
            'GUEST' :'Invité',
            'ADMINISTRATOR': 'Administrateur',
            'MODERATOR': 'Modérateur',
            'USER': 'Utilisateur',
            'COPY_ACCESS':'COPIER L\'ACCÈS',
            'SHARE_ACCESS':'PARTAGER L\'ACCÈS',
            'DELETE':'SUPPRIMER',
            'ADD_AN_ACCESS': 'Ajouter un accès',
			'BADGE':'Badge',
			'KEY':'Clé',
			'BADGES':'Badges',
			'KEYS':'Clés',
			'ACTIVE_BADGE':'Badge actif',
			'ACTIVE_KEY':'Clé active',
			'PERMANENT_ACCESS':'Accès permanent',
			'BADGE_DISABLED':'Badge désactivé',
			'INITIALIZED_KEY':'Clé initialisée',
			'UNINITIALIZED_KEY':'Clé non initialisée',
			'BATTERY':'Batterie :',
        },
        'SCHEDULES': {
            'AT':'à',
            'DAY':{
                'MONDAY': 'Lundi',
                'TUESDAY': 'Mardi',
                'WEDNESDAY': 'Mercredi',
                'THURSDAY': 'Jeudi',
                'FRIDAY': 'Vendredi',
                'SATURDAY': 'Samedi',
                'SUNDAY': 'Dimanche'
            },
            'START':'Début : ',
            'END': 'Fin : ',
            'RECURRENT_ACCESSES':'Accès récurrents',
            'ONE-TIME_ACCESSES': 'Accès ponctuels',
            'AS_AN_ADMINISTRATOR': 'En tant qu\'administrateur,',
            'AS_A_MODERATOR': 'En tant que modérateur,',
            'HAS_A_PERMANENT_ACCESS_TO_THE_LOCK': 'a un accès permanent à la serrure :',
            'ACCESSES_OF': 'Accès de',
            'NO_RECURRENT_ACCESS': 'n\'a aucun accès récurrent à la serrure :',
            'NO_ONE-TIME_ACCESS': 'n\'a aucun accès ponctuel à la serrure :',
			'BADGE_ACCESS':'Accès du badge :',
			'THIS_BADGE':'Ce badge',
			'THIS_KEY':'Cette clé',
        },
        'POPUP': {
			'FEATURE_COMING_SOON':'Fonctionnalité bientôt disponible !',
            'EMAIL': 'Adresse Mail',
            'EMAIL_IS_REQUIRED': 'L\'adresse mail est requise ',
            'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS': 'Veuillez entrer une adresse mail valide',
			'USER_ACCOUNT_ACCESS': 'Accès compte utilisateur',
			'GUEST_MODE_ACCESS': 'Accès mode invité',
			'AUTHORIZATION_LEVEL': 'Niveau d\'autorisation',
			'ACCESS_NAME': 'Nom de l\'accès',
			'VALIDATE': 'Valider',
			'USER_ALREADY_HAVE_ACCESS_1':'L\'utilisateur dont l\'adresse mail est ',
			'USER_ALREADY_HAVE_ACCESS_2':' a déjà un accès a cette serrure.',
			'USER_NOT_EXIST_1':'L\'adresse mail ',
			'USER_NOT_EXIST_2':' n\'est associée à aucun compte.',
			'DELETE_ACCESS_DIALOG': 'Êtes-vous sûr de vouloir supprimer cet accès ?',
			'DELETE_OWN_ACCESS_DIALOG': 'Êtes-vous sûr de vouloir supprimer votre propre accès ?',
			'YES': 'OUI',
            'NO': 'NON',
            'COPY_ACCESS':'Copier l\'accès',
            'ACCESS_TO_COPY':'L\'accès à copier',
			'CHOOSE_A_LANGUAGE':'Choisissez une langue',
			'ENGLISH':'Anglais',
			'FRENCH':'Français',
			'SPANISH':'Espagnol',
			'CANCEL': 'Annuler',
        },
		'GUEST_MESSAGE': {
			'INTRO':'Vous avez été invité sur une serrure Elocky. Voici vos identifiants de connexion :',
			'LOGIN':'Identifiant : ',
			'ACCESS_CODE':'Code d\'accès : ',
			'IOS_URL':'iOS: https://itunes.apple.com/us/app/elocky/id1154565981?mt=8',
			'ANDROID_URL':'Android: https://play.google.com/store/apps/details?id=com.elocky',
		},
		'ACCESS_MANAGEMENT': {
			'INFORMATION':'Informations',
			'HISTORY':'Historique',
			'BLUETOOTH':'Bluetooth',
			'REMOTE_OPENING':'Ouverture à distance',
			'ACCESS_CREATION_DATE':'Date de création de l\'accès',
			'MODE_OF_OPERATION':'Mode d\'action',
			'BACK':'Retour',
			'DELETE_ACCESS':'Supprimer l\'accès',
			'SAVE':'Enregistrer',
			'LOGIN':'Identifiant',
			'ACCESS_CODE':'Code d\'accès',
			'PUT_ACCESS_SUCCESSFUL':'Modifications enregistrées avec succès.',
			'NO_CHANGES':'Aucune modification n\'a été effectuée.',
			'RESET_ACCESS_CODE_DIALOG':'Êtes-vous sûr de vouloir réinitialiser le code d\'accès ?',
			'ADD_A_RECURRENT_ACCESS': 'Ajouter un accès récurrent',
			'ADD_A_ONE-TIME_ACCESS': 'Ajouter un accès ponctuel',
			'RECURRENT_ACCESS':'Accès récurrent',
			'PERMANENT_ACCESS':'Accès permanent',
			'WARNING_PERMANENT_ACCESS':'Attention ! L\'ajout d\'un accès permanent supprimera tous les accès récurrents existants.',
			'TIME_HINT':'* utilisez les flèches (⇅) pour changer l\'heure',
			'DAY':'Jour',
			'DAY_IS_REQUIRED':'Le jour est requis',
			'START_TIME':'Heure de début',
			'STOP_TIME':'Heure de fin',
			'START_TIME_IS_REQUIRED':'L\'heure de début est requise',
			'STOP_TIME_IS_REQUIRED':'L\'heure de fin est requise',
			'INVALID_HOURS':'Horaires invalides : L\'heure de début doit être antérieure à l\'heure de fin.',
			'AUTHORIZATION':'Autorisation',
			'PROHIBITION':'Interdiction',
			'FROM':'À partir du',
			'TO':'Jusqu\'au',
			'START_DATE':'Date de début',
			'STOP_DATE':'Date de fin',
			'START_DATE_IS_REQUIRED':'La date de début est requise',
			'STOP_DATE_IS_REQUIRED':'La date de fin est requise',
			'INVALID_DATES':'Dates invalides : La date de début doit être antérieure à la date de fin.',
			'NO_NAME':'Pas de nom',
			'NAME':'Nom',
			'BADGE_NUMBER':'Numéro de badge',
			'STATE':'État',
			'CREATION_DATE':'Date de création',
			'REFERENCE':'Référence',
			'DISABLE':'Désactiver',
			'ENABLE':'Activer',
			'SECURITY_STATE':'État de sécurité',
		}
    }
};
