import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { WeatherData } from './WeatherData';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  appId: string = 'ecb1f756686518281c429bf5b7498d70'

  apiUrl_1: string = 'http://api.openweathermap.org/data/2.5/weather?q='
  apiUrl_2: string = '&appid=' + this.appId;
  apiUrl_3: string = '&lang=';

  constructor
  (
    private httpClient: HttpClient
  )   
  {

  }

  getWeather(city: string, language: string):Observable<WeatherData>{
    return this.httpClient.get<WeatherData>
    (
      this.apiUrl_1 + city + this.apiUrl_2 + this.apiUrl_3 + language
    );
  }
  
}




