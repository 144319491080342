import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpErrorResponse } from '@angular/common/http';

import { PlaceService } from '../../../../core/PlacesService/place.service';
import { PlacePhotoService } from '../../../../core/PlacesService/place-photo.service';
import { SnackbarService } from '../../../../core/SnackbarService/snackbar.service';
import { PlaceData } from '../../interfaces/PlaceData';
import { PlaceInfos } from '../../../../core/PlacesService/interfaces/PlaceInfos';

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

@Component({
  selector: 'information-lieux',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  animations: fuseAnimations
})
export class InformationComponent implements OnInit {

  @Input("obsCurrent$") obsCurrent:Observable<PlaceData>;
  current_place:PlaceData;
  current_place_photo:string;
  photoChange:boolean = false;

  informationsForm: FormGroup;
  photoForm: FormGroup;
  country = new Array();
  timezone = new Array();

  subCurrentPlace:Subscription;

  subArray = new Array<Subscription>();

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private placePhotoService: PlacePhotoService,
    private placeService: PlaceService,
    private snackbarService: SnackbarService) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    ngOnInit() : void {
      this.subCurrentPlace = this.obsCurrent.subscribe(
        (response) => {
          this.current_place = response;
          this.current_place_photo = this.current_place["photo"];
          this.informationsForm = new FormGroup({
            name:new FormControl(this.current_place["name"]),
            address:new FormControl(this.current_place["address"]),
            complement:new FormControl(this.current_place["complement"]),
            country:new FormControl(this.current_place["country"].name),
            city:new FormControl(this.current_place["city"]),
            zipcode:new FormControl(this.current_place["zipcode"]),
            timezone:new FormControl(this.current_place["timezone"].name)
          });
          this.photoForm = new FormGroup({
            photo:new FormControl(null)
          })
          this.photoChange = false;
        }
      )
      this.photoForm.controls['photo'].valueChanges.subscribe(
        (response) => {
          if(!response) return;
          let file = response.files[0];
          let reader = new FileReader();
          reader.onloadend = () => {
            this.current_place_photo = reader.result.toString();
            this.photoChange = true;
          }
          reader.readAsDataURL(file);
        }
      )
      this.placeService.getListTimezoneCountry().subscribe(
        (response) => {
          // need this on template to fill the select option
          this.timezone = response["timezone"];
          this.country = response["country"];
        },

        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    }
    ngOnDestroy() : void {
      this.subCurrentPlace.unsubscribe();
    }
    hasFormChanged() : boolean {
      let newValue = this.informationsForm.value;
      return (
        newValue["name"] != this.current_place["name"] ||
        newValue["address"] != this.current_place["address"] ||
        newValue["complement"] != this.current_place["complement"] ||
        newValue["country"] != this.current_place["country"].name ||
        newValue["city"] != this.current_place["city"] ||
        newValue["zipcode"] != this.current_place["zipcode"] ||
        newValue["timezone"] != this.current_place["timezone"].name
      );
    }
    hasFormPhotoChanged() : boolean {
      return this.photoChange;
    }
    cancelChoice() : void {
      this.informationsForm.get("name").setValue(this.current_place["name"]);
      this.informationsForm.get("address").setValue(this.current_place["address"]);
      this.informationsForm.get("complement").setValue(this.current_place["complement"]);
      this.informationsForm.get("country").setValue(this.current_place["country"].name);
      this.informationsForm.get("city").setValue(this.current_place["city"]);
      this.informationsForm.get("zipcode").setValue(this.current_place["zipcode"]);
      this.informationsForm.get("timezone").setValue(this.current_place["timezone"].name);
    }
    cancelPhotoChoice() : void {
      console.log("cancel photo choice");
      this.photoForm.get('photo').reset();
      this.current_place_photo = this.current_place["photo"];
      this.photoChange = false;
    }
    onSendForm() : void {
      let data = Object.assign({}, this.informationsForm.value);

      this.placeService.getCountryIdByName(data.country).subscribe(
        (id) => { data.country = { id:id, name:data.country }}
      );
      this.placeService.getTimezoneIdByName(data.timezone).subscribe(
        (id) => { data.timezone = { id:id, name:data.timezone }}
      );
      data["state"] = this.current_place["state"];
      data["latitude"] = this.current_place["latitude"];
      data["longitude"] = this.current_place["longitude"];
      data["id"] = this.current_place.id;
      data["check"] = {
        default:this.current_place["check"].default
      }
      data["photo"] = this.current_place["photo"];
      if(this.current_place.check["in"]) data.check["in"] = this.current_place.check["in"];
      if(this.current_place.check["out"]) data.check["out"] = this.current_place.check["out"];

      this.placeService.editInfoPlaceByPlaceData(data as PlaceData).subscribe(
        (response) => {
          this.snackbarService.makeSimpleSnackBar("Changes save :)");
        },
        (err: HttpErrorResponse) => {
          console.error("Envoie des nouvelles informations échoué");
          console.log(err);
          this.snackbarService.makeSimpleSnackBar(SnackbarService.WRONG_MESSAGE);
        }
      );
    }
    onSendPhotoForm() : void {
      let data = Object.assign({}, this.photoForm.value);
      this.placePhotoService.uploadPlacePhoto(this.current_place.id, data["photo"]).subscribe(
        (response) => {
          this.placeService.places[this.placeService.getPlacesIndexById(this.current_place.id)].photo = this.current_place_photo;
          this.snackbarService.makeSimpleSnackBar("Photo changes :)");
          this.photoChange = false;
        },
        (err: HttpErrorResponse) => {
          console.error("Envoie des nouvelles informations échoué");
          console.log(err);
          this.snackbarService.makeSimpleSnackBar(SnackbarService.WRONG_MESSAGE);
        }
      )
    }
  }
