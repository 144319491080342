import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { HttpErrorResponse } from '@angular/common/http';

import { PlaceService } from '../../../../core/PlacesService/place.service';
import { BoardService } from '../../../../core/BoardService/board.service';
import { SnackbarService } from '../../../../core/SnackbarService/snackbar.service';

import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';

import { PlaceData } from '../../interfaces/PlaceData';
import { BoardData } from '../../interfaces/BoardData';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

@Component({
  selector: 'alarm-lieux>',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss'],
  animations: fuseAnimations
})
export class AlarmComponent implements OnInit {

  @Input("obsCurrent$") obsCurrent$:Observable<PlaceData>
  subCurrentPlace: Subscription;
  current_place:PlaceData;
  current_board:any = null;
  alarm_full:number;

  alarmForm:FormGroup;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private placeService: PlaceService,
    private boardService: BoardService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    ngOnInit() : void {
      this.subCurrentPlace = this.obsCurrent$.subscribe(
        (current) => {
          this.current_place = current;
          this.placeService.getListAlarm(this.current_place.id).subscribe(
            (response) => {
              response["address"].forEach(address => {
                if(address.id == this.current_place.id){
                  this.alarm_full = address.alarm_full || 0;
                  this.alarmForm = this.formBuilder.group({
                    alarmFull:[this.alarm_full == 1],
                    alarmBeacon:this.formBuilder.array([]),
                    alarmLockv1:this.formBuilder.array([]),
                    alarmLockv2:this.formBuilder.array([])
                  })
                  this.current_board = {
                    beacon:new Array<BoardData>(),
                    lock_v1:new Array<BoardData>(),
                    lock_v2:new Array<BoardData>()
                  }
                  address.board.forEach(element => {
                    let bd:BoardData = {
                      id:element.id,
                      alarm:element.alarm,
                      name:element.name
                    }
                    const newFormControl = this.formBuilder.control(bd.alarm == 1);

                    switch(element.type_board.id){
                      case 1:
                      this.current_board.lock_v1.push(bd);
                      this.getAlarmLockv1().push(newFormControl);
                      break;
                      case 2:
                      this.current_board.beacon.push(bd);
                      this.getAlarmBeacon().push(newFormControl);
                      break;
                      case 3:
                      this.current_board.lock_v2.push(bd);
                      this.getAlarmLockv2().push(newFormControl);
                      break;
                      default:
                    }
                  });
                }
              });
            }
          )
        }
      )
    }
    getAlarmBeacon() : FormArray {
      return this.alarmForm.get('alarmBeacon') as FormArray;
    }
    getAlarmLockv1() : FormArray {
      return this.alarmForm.get('alarmLockv1') as FormArray;
    }
    getAlarmLockv2() : FormArray {
      return this.alarmForm.get('alarmLockv2') as FormArray;
    }
    onSendForm() : void {
      const value = this.alarmForm.value;
      let data = {
        boards:new Array(),
        alarmFull:value["alarmFull"] ? 1 : 0
      }
      this.alarm_full = value["alarmFull"] ? 1 : 0;
      value["alarmBeacon"].forEach((element, index) => {
        data["boards"].push({
          id:this.current_board.beacon[index].id,
          state:element ? 1 : 0
        })
        this.current_board.beacon[index].alarm = element ? 1 : 0;
      });
      value["alarmLockv1"].forEach((element, index) => {
        data["boards"].push({
          id:this.current_board.lock_v1[index].id,
          state:element ? 1 : 0
        })
        this.current_board.lock_v1[index].alarm = element ? 1 : 0;
      });
      value["alarmLockv2"].forEach((element, index) => {
        data["boards"].push({
          id:this.current_board.lock_v2[index].id,
          state:element ? 1 : 0
        })
        this.current_board.lock_v2[index].alarm = element ? 1 : 0;
      });
      this.boardService.editAlarmBoard(this.current_place.id, data).subscribe(
        (response) => {
          this.snackbarService.makeSimpleSnackBar("Changes save :)");
        },
        (err: HttpErrorResponse) => {
          this.snackbarService.makeSimpleSnackBar(SnackbarService.WRONG_MESSAGE);
        }
      )
    }

    hasFormChanged() : boolean {
      let oldBeacon = this.current_board["beacon"];
      let oldLockv1 = this.current_board["lock_v1"];
      let oldLockv2 = this.current_board["lock_v2"];

      let alarmFull = this.alarmForm.value["alarmFull"];
      let newBeacon = this.alarmForm.value["alarmBeacon"];
      let newLockv1 = this.alarmForm.value["alarmLockv1"];
      let newLockv2 = this.alarmForm.value["alarmLockv2"];

      if(alarmFull != (this.alarm_full == 1)) return true;

      for (let i = 0; i < oldBeacon.length; i++) {
        const element = oldBeacon[i];
        let statut = element.alarm == 1;
        if(statut != newBeacon[i]) return true;
      }
      for (let i = 0; i < oldLockv1.length; i++) {
        const element = oldLockv1[i];
        let statut = element.alarm == 1;
        if(statut != newLockv1[i]) return true;
      }
      for (let i = 0; i < oldLockv2.length; i++) {
        const element = oldLockv2[i];
        let statut = element.alarm == 1;
        if(statut != newLockv2[i]) return true;
      }
      return false;
    }
    cancelChoice() : void {
      let oldBeacon = this.current_board["beacon"];
      let oldLockv1 = this.current_board["lock_v1"];
      let oldLockv2 = this.current_board["lock_v2"];

      this.alarmForm.get("alarmFull").setValue(this.alarm_full == 1);
      for (let i = 0; i < this.getAlarmBeacon().controls.length; i++) {
        const element = this.getAlarmBeacon().controls[i];
        element.setValue(oldBeacon[i].alarm == 1);
      }
      for (let i = 0; i < this.getAlarmLockv1().controls.length; i++) {
        const element = this.getAlarmLockv1().controls[i];
        element.setValue(oldLockv1[i].alarm == 1);
      }
      for (let i = 0; i < this.getAlarmLockv2().controls.length; i++) {
        const element = this.getAlarmLockv2().controls[i];
        element.setValue(oldLockv2[i].alarm == 1);
      }
    }
    ngOnDestroy() : void {
      this.subCurrentPlace.unsubscribe();
    }

  }
