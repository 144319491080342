export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'HOME'        : {
                'TITLE': 'Home'
            },
            'NOTIFICATIONS'        : {
                'TITLE': 'Notifications'
            },
            'PLACES'        : {
                'TITLE': 'Places'
            },
            'ACCESS'        : {
                'TITLE': 'Access'
            },
            'INVOICES'        : {
                'TITLE': 'Invoices'
            }
        }
    }
};
