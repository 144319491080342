export const locale = {
	lang: 'fr',
	data: {
		'TITLE_NOTIFICATIONS': 'NOTIFICATIONS',
		'NO_PLACE': 'Aucun lieu n\'est associé à votre compte.',
		':': ' : ',
		'AT': 'à',
		'ERROR': 'Une erreur est survenue. Veuillez réessayer ultérieurement.',
		'OPTIONS': 'Options',
		'DELETE_HISTORY': 'Supprimer l\'historique',
		'DELETE_LOG_DIALOG': 'Êtes-vous sûr de vouloir supprimer ce log ?',
		'DELETE_HISTORY_DIALOG': 'Êtes-vous sûr de vouloir supprimer tout l\'historique ?',
		'YES': 'OUI',
		'NO': 'NON',
		'CONFIGURATION': 'Configuration',
		'EDIT_EXPIRY_LOGS': {
			'TITLE': 'Supprimer l\'historique après',
			'VALIDATE': 'Valider',
			'CANCEL': 'Annuler',
			'SUCCESS': 'Modification enregistrée avec succès.',
			'3_YEARS': '3 ans',
			'1_YEAR': '1 an',
			'6_MONTHS': '6 mois',
			'3_MONTHS': '3 mois',
			'1_MONTH': '1 mois',
			'1_WEEK': '1 semaine',
		},
		'LOGBOOK': {
			'NO_LOGS_AVAILABLE': 'Aucun log disponible',
			'UNKNOWN_LOG': 'Log inconnu',
			'INTRUSION': 'Intrusion',
			'INTRUSION_LOCK': 'Mouvement détecté - Serrure',
			'INTRUSION_BEACON': 'Mouvement détecté - Beacon',
			'ADD': 'Ajout',
			'ADD_BOX': 'Ajout d\'une Elocky Box',
			'ADD_LOCK': 'Ajout d\'une serrure',
			'ADD_BEACON': 'Ajout d\'un beacon',
			'ADD_ACCESS': 'Ajout d\'un accès',
			'UPDATE': 'Mise à jour',
			'SOFTWARE_UPDATE': 'Mise à jour du programme',
			'LOCK_OPENED': 'Ouverture',
			'OPENING_FAILURE': 'Échec d\'ouverture',
			'BADGE': 'Badge',
			'BADGE_OPENING': 'Ouverture par badge',
			'UNAUTHORIZED_BADGE': 'Badge non autorisé',
			'PROHIBITED_BADGE': 'Badge interdit',
			'UNREGISTERED_BADGE': 'Badge non enregistré',
			'EMERGENCY_KEY': 'Clé de secours',
			'REBOOT': 'Redémarrage',
			'RESET': 'Réinitialisation',
			'DECONFIGURATION': 'Déconfiguration',
			'CONFIGURATION': 'Configuration',
			'DEBUG': 'Débogage',
			'ACCESS_NUMBER': 'Numéro d\'accès',
			'GUEST_ACCESS': 'Accès invité',
			'SLEEP_MODE': {
				'SLEEP_MODE': 'Mode de veille',
				'NORMAL_SLEEP': 'Veille normale',
				'LIGHT_SLEEP': 'Veille légère',
				'DEEP_SLEEP': 'Veille profonde',
				'NORMAL': 'normal',
				'LIGHT': 'léger',
				'DEEP': 'profond',
			},
			'WAKEUP_MODE': {
				'WAKEUP_MODE': 'Mode de réveil',
				'SENSITIVE_WAKEUP': 'Réveil sensible',
				'NORMAL_WAKEUP': 'Réveil normal',
				'HARD_WAKEUP': 'Réveil dur',
				'SENSITIVE': 'sensible',
				'NORMAL': 'normal',
				'HARD': 'dur',
			}

		}
	}
};