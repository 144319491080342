<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="register-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/logo-elocky-en-ligne-BLANC.png">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            {{'THE_MANAGEMENT_WEBSITE' | translate}}
        </div>
<!--
        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            Open your future
        </div>
-->
    </div>

    <div id="register-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="register-form">
<!--
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/logo-elocky-en-ligne-BLANC.png">
            </div>
-->
            <div class="title">{{'CREATE_AN_ACCOUNT_UPPERCASE' | translate}}</div>

            <form name="registerForm" [formGroup]="registerForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>{{'LAST_NAME' | translate}}</mat-label>
                    <input matInput formControlName="lastname">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>
                        {{'LAST_NAME_IS_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
                    <input matInput formControlName="firstname">
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>
                        {{'FIRST_NAME_IS_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'EMAIL' | translate}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="registerForm.get('email').hasError('required')">
                        {{'EMAIL_IS_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('email').hasError('email')">
                        {{'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'PHONE_NUMBER' | translate}}</mat-label>
                    <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                    <ngx-mat-intl-tel-input
                      [preferredCountries]="['fr','gb', 'es','it','de','be']" 
                      [enableSearch]="true"
                      name="phone"
                      (countryChanged)="onCountryChangedEvent($event)"
                      formControlName="phoneNumber" #phoneNumber>
                    </ngx-mat-intl-tel-input>
                    <mat-error *ngIf= "registerForm.get('phoneNumber').hasError('required')">
                        {{'PHONE_NUMBER_IS_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf= "registerForm.get('phoneNumber').hasError('validatePhoneNumber')">
                        {{'PLEASE_ENTER_A_VALID_PHONE_NUMBER' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'PASSWORD' | translate}}</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        {{'PASSWORD_IS_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'PASSWORD_CONFIRM' | translate}}</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="registerForm.get('passwordConfirm').hasError('required')">
                        {{'PASSWORD_CONFIRMATION_IS_REQUIRED' | translate}}
                    </mat-error>
                    <mat-error *ngIf="!registerForm.get('passwordConfirm').hasError('required') &&
                                       registerForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                                       {{'PASSWORDS_MUST_MATCH' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="Accept" formControlName="acceptTermsAndConditions" required>
                        <span>{{'ACCEPT' | translate}}</span>
                    </mat-checkbox>
                    <a href="https://elocky.com/common/Conditions_G%C3%A9n%C3%A9rales_de_Vente_fr.pdf">{{'TERMS_AND_CONDITIONS' | translate}}</a>
                </div>

                <button mat-raised-button type="submit" color="accent" class="submit-button" aria-label="CREATE AN ACCOUNT"
                        [disabled]="registerForm.invalid">
                        {{'CREATE_AN_ACCOUNT_UPPERCASE' | translate}}
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">{{'ALREADY_HAVE_AN_ACCOUNT' | translate}}</span>
                <a class="link" [routerLink]="'/auth/login'">{{'LOGIN_LOWERCASE' | translate}}</a>
            </div>
        </div>
    </div>
</div>

  <ng-template #IncorrectPhoneNumber>
    <h2 matDialogTitle>{{'INVALID_PHONE_NUMBER_1' | translate}}</h2>
    <p matDialogContent>{{'EXPLANATION_INVALID_PHONE_NUMBER' | translate}}</p>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #IncorrectPhoneNumber2>
    <h2 matDialogTitle>{{'INVALID_PHONE_NUMBER_1' | translate}}</h2>
    <p matDialogContent>{{'INVALID_PHONE_NUMBER_2' | translate}}</p>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #RegistrationCompletedSuccessfully>
    <h2 matDialogTitle>{{'REGISTRATION_COMPLETED_SUCCESSFULLY_TITLE' | translate}}</h2>
    <p matDialogContent>{{'REGISTRATION_COMPLETED_SUCCESSFULLY_CONTENT' | translate}} {{newUserEmailAddress}}</p>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #EmailAlreadyExists>
    <h2 matDialogTitle>{{'EMAIL_ALREADY_EXISTS_TITLE' | translate}}</h2>
    <p matDialogContent>{{'EMAIL_ALREADY_EXISTS_CONTENT_1' | translate}} {{newUserEmailAddress}} {{'EMAIL_ALREADY_EXISTS_CONTENT_2' | translate}}</p>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #TryLater>
    <h2 matDialogTitle>{{'TRY_LATER_TITLE' | translate}}</h2>
    <p matDialogContent>{{'TRY_LATER_CONTENT' | translate}}</p>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose>OK</button>
    </mat-dialog-actions>
</ng-template>

<ngx-spinner size = "large" color = "#2EB4B3" type = "ball-clip-rotate-pulse" ></ngx-spinner>