export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'APPLICATIONS': 'Aplicaciones',
            'HOME'        : {
                'TITLE': 'Inicio'
            },
            'NOTIFICATIONS'        : {
                'TITLE': 'Notificaciones'
            },
            'PLACES'        : {
                'TITLE': 'Lugares'
            },
            'ACCESS'        : {
                'TITLE': 'Accesos'
            },
            'INVOICES'        : {
                'TITLE': 'Facturas'
            }
        }
    }
};
