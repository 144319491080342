import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { HttpErrorResponse, HttpClient } from '@angular/common/http';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { PlacePhotoService } from '../../core/PlacesService/place-photo.service';
import { PlaceService } from '../../core/PlacesService/place.service';
import { PlaceInfos } from "../../core/PlacesService/interfaces/PlaceInfos";
import { DashboardData } from '../dashboard/interfaces/DashboardData';
import { DashboardService } from '../dashboard/dashboard.service';

import { Subscription } from 'rxjs';

import { PlaceData } from "./interfaces/PlaceData";

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { locale as spanish } from './i18n/es';

@Component({
  selector: 'places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss'],
  animations: fuseAnimations
})

export class PlacesComponent implements OnInit {

  dashboardData: DashboardData;
  placesExist: boolean;
  places = new Array<PlaceData>();
  timezone = new Array<any>();
  country = new Array<any>();
  dialog_newname: string = "";
  isMapEnabled: boolean;
  isAddButtonShow: boolean = false;
  origin_map: any;
  subListPlaces: Subscription;

  @ViewChild('FormActionPlaces') FormActionPlaces: TemplateRef<any>;
  @ViewChild('FormAddPlace') FormAddPlace: TemplateRef<any>;

  dialogRef:MatDialogRef<TemplateRef<any>>;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private dashboardService: DashboardService,
    private httpClient: HttpClient,
    private placePhotoService: PlacePhotoService,
    private placeService: PlaceService,
    private dialog: MatDialog) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    ngOnInit(): void {
      this.placeService.updateListPlace();
      this.subListPlaces = this.placeService.getListPlaces().subscribe(
        places => {
          if(places != null && places.length > 0) {
            this.places = places;
          } else {
            this.placeService.fillPlaces();
          }
          this.isAddButtonShow = true;
        }
      )

      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {});
      // Le mapmode est désactivé de base lorsque l'on arrive sur la page
      this.isMapEnabled = false;
    }
    ngOnDestroy(): void {
      this.subListPlaces.unsubscribe();
    }

    // Open form functions :
    openFormRename(id: number): void {
      this.subListPlaces.unsubscribe();
      let index = this.placeService.getPlacesIndexById(id);
      this.dialog_newname = this.places[index].name;
      this.dialogRef = this.dialog.open(this.FormActionPlaces, {
        data: {
          type:"rename"
        },
      })
      this.dialogRef.afterClosed().subscribe(name => {
        this.dialog_newname = "";
        if(name == "" || name == null || name == undefined) return;
        this.renamePlace(this.places[index].id, name);
      })
    }
    openFormDelete(id: number): void {
      let index = this.placeService.getPlacesIndexById(id);
      this.dialogRef = this.dialog.open(this.FormActionPlaces, {
        data: {
          type:"delete",
          index: index
        },
      })
    }
    openFormDeleteUser(id: number): void {
      let index = this.placeService.getPlacesIndexById(id);
      this.dialogRef = this.dialog.open(this.FormActionPlaces, {
        data: {
          type:"delete-user",
          index: index
        }
      });
    }

    // Events functions :
    onCloseDialog(): void {
      this.dialogRef.close();
    }
    onClickAddPlace() : void {
      this.dialogRef = this.dialog.open(this.FormAddPlace, {})
    }
    onClickMapMode() : void {
      this.isMapEnabled = !this.isMapEnabled;
    }
    onClickOnCard(id: number) : void {
      let place = this.places[this.placeService.getPlacesIndexById(id)];
      this.origin_map = {
        latitude:place.latitude,
        longitude:place.longitude
      }
      if(!this.isMapEnabled)
        this.isMapEnabled = true;
    }

    // Utilities fuunctions :
    renamePlace(id: number, newname: string): void {
      this.placeService.renamePlacesById(id, newname).subscribe(
        (response) => {

        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      );
    }
    addPlace(object:PlaceInfos) : void {
      this.placeService.addPlaces(object).subscribe(
        (response) => {
          this.placeService.addPlacesInArray(object, response["id"])
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    }
    deletePlace(index:number) : void {
      this.placeService.deletePlace(this.places[index].id).subscribe(
        (response) => {
          this.onCloseDialog();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    }
    deleteUserPlace(index: number) : void {
      this.placeService.deleteUserOfPlaceByAddressId(this.places[index].id).subscribe(
        (response) => {
          this.onCloseDialog();
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    }
  }
