<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 matDialogTitle>{{'CONTENT.ADD_AN_ACCESS' | translate}}</h2>
    <button class="closeDialogButton" (click)="close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
</div>

<!-- ADDRESS SELECTION -->

<div fxLayout="row" class="menu" fxLayoutAlign="space-between">
    <div fxLayout="row" class="places-menu">
        <div *ngIf="selectedAddress.name != undefined" class="selected-place">
            {{selectedAddress.name}}</div>
        <div *ngIf="selectedAddress.name != undefined" class="selected-place-with-name">
            {{selectedAddress.address}}</div>
        <div *ngIf="selectedAddress.name == undefined" class="selected-place">
            {{selectedAddress.address}}
        </div>

        <button mat-icon-button class="place-selector" [matMenuTriggerFor]="placesMenu" aria-label="Select place">
            <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #placesMenu="matMenu">
            <button mat-menu-item *ngFor="let address of addressesAddAccess" (click)="onPlaceSelection(address.id)">
                <span *ngIf="address.name != undefined">{{address.name}}</span>
                <span *ngIf="address.name == undefined">{{address.address}}</span>
            </button>
        </mat-menu>

        <!-- / ADDRESS SELECTION -->

        <!-- BOARD SELECTION -->

        <div class="selected-place" style="margin-left: 10px;">
            {{selectedBoard.name}}</div>

        <div>
            <button mat-icon-button class="place-selector" [matMenuTriggerFor]="boardsMenu" aria-label="Select board">
                <mat-icon>expand_more</mat-icon>
            </button>

            <mat-menu #boardsMenu="matMenu">
                <button mat-menu-item *ngFor="let board of selectedAddress.board" (click)="onBoardSelection(board.id)">
                    <span>{{board.name}}</span>
                </button>
            </mat-menu>
        </div>

        <!-- / BOARD SELECTION -->

    </div>
</div>

<mat-dialog-content class="dialog-content">

    <form [formGroup]="addAccessForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>

        <div fxLayout="column">

            <div fxLayout="row" class="add-access-form-field">
                <mat-radio-group formControlName="access_type">
                    <mat-radio-button (change)="onRadioButtonValueChange($event)" id="user"
                        class="add-access-radio-button" value="user">{{'POPUP.USER_ACCOUNT_ACCESS' | translate}}
                    </mat-radio-button>
                    <mat-radio-button (change)="onRadioButtonValueChange($event)" id="guest"
                        class="add-access-radio-button" value="guest">{{'POPUP.GUEST_MODE_ACCESS' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <mat-form-field *ngIf="accessType == 'user'" appearance="outline">
                <mat-label>{{'POPUP.AUTHORIZATION_LEVEL' | translate}}</mat-label>
                <mat-select matNativeControl id="access_grade" formControlName="access_grade">
                    <mat-option value="3">{{'CONTENT.USER' | translate}}</mat-option>
                    <mat-option value="2">{{'CONTENT.MODERATOR' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="accessType == 'user'" appearance="outline">
                <mat-label>{{'POPUP.EMAIL' | translate}}</mat-label>
                <input matInput formControlName="email">
                <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                <mat-error *ngIf="addAccessForm.get('email').hasError('required')">
                    {{'POPUP.EMAIL_IS_REQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="addAccessForm.get('email').hasError('email')">
                    {{'POPUP.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'POPUP.ACCESS_NAME' | translate}}</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>

            <button mat-raised-button type="submit" color="accent" class="submit-button" aria-label="CREATE ACCESS"
                [disabled]="addAccessForm.invalid || isLoading">
                {{'POPUP.VALIDATE' | translate}}
            </button>

        </div>

    </form>

</mat-dialog-content>