import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NewUser } from './newUser';

import { ApiConfigData } from '../../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  apiURL: string = this.api_endpoint + '/noauth/whitelabel/registration';

  constructor(
    private httpClient: HttpClient
  ) {

  }

  addNewUser(newUser: NewUser) {
    return this.httpClient.post(this.apiURL, newUser);
  }
}
