export const locale = {
    lang: 'es',
    data: {
		'GENERAL_INFORMATION':'Informaciones generales',
		'FIRST_NAME':'Nombre',
		'LAST_NAME':'Apellido',
		'PHONE_NUMBER': 'Número de teléfono',
		'EMAIL': 'Dirección de e-mail',
		'MODIFY':'Modificar',
		'ACCESSES':'Accesos',
		'ADMINISTRATOR': 'Administrador',
        'MODERATOR': 'Moderador',
        'USER': 'Usuario',
		'LOCK':'Cerradura :',
		'DELETE_OWN_ACCESS_DIALOG': '¿Estás seguro de que quieres suprimir tu propio acceso a la cerradura :',
		'YES': 'SI',
		'NO': 'NO',
		'NO_ACCESS':'No tienes ningún acceso.',
		'?': '?',
		'INCORRECT_FILE_TYPE_TITLE':'Tipo de archivo incorrecto',
		'INCORRECT_FILE_TYPE_CONTENT':'Por favor, seleccione un archivo de imagen.',
		'FILE_TOO_BIG_TITLE':'Archivo demasiado pesado',
		'FILE_TOO_BIG_CONTENT':'El tamaño de la imagen debe ser inferior a 10 MB.',
		'CANCEL':'Cancelar',
		'CHOOSE_ANOTHER_FILE':'Seleccionar otro archivo', 
		'PASSWORD': 'Contraseña', 
		'CHANGE_PASSWORD':'Cambiar la contraseña',
    }
};