import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { DateTranslationService } from '../../core/DateTranslationService/date-translation.service';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { locale as spanish } from './i18n/es';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { HttpErrorResponse } from '@angular/common/http';
import { AutoRedirectionService } from '../../core/AutoRedirectionService/auto-redirection.service';
import { DashboardService } from './dashboard.service';
import { PlacePhotoService } from '../../core/PlacesService/place-photo.service';

import { DashboardData } from './interfaces/DashboardData';
import { PlaceData } from '../interfaces/PlaceData';
import { GatewayData } from './interfaces/GatewayData';
import { LockData } from './interfaces/LockData';
import { BeaconData } from './interfaces/BeaconData';

import { fuseAnimations } from '@fuse/animations';

import { getTimezone } from 'countries-and-timezones';

import { WeatherService } from '../../core/WeatherService/weather.service';
import { WeatherData } from '../../core/WeatherService/WeatherData';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
    dashboardData: DashboardData;
    weatherData: WeatherData;

    userName: string;
    placesExist: boolean;

    addresses = new Array<PlaceData>();
    locks = new Array<LockData>();
    beacons = new Array<BeaconData>();
    gateways = new Array<GatewayData>();

    selectedAddress: PlaceData;

    selectedPlaceIndex: number;
    selectedPlaceAddressPhoto: string;
    selectedPlaceDateNow = Date.now();
    selectedPlaceTimezone: string;
    selectedPlaceCity: string;
    selectedPlaceTemperature: string;
    selectedPlaceWindDirection: number;
    selectedPlaceWindSpeed: number;
    selectedPlaceHumidity: number;
    selectedPlaceWeatherDescription: string;
    selectedPlaceAlarmState: number;

    language: string;

    @ViewChild('FullSizePlacePhoto') FullSizePlacePhoto: TemplateRef<any>;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private spinner: NgxSpinnerService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translateService: TranslateService,
        private dateTranslationService: DateTranslationService,
        private autoRedirectionService: AutoRedirectionService,
        private dashboardService: DashboardService,
        private placePhotoService: PlacePhotoService,
        private weatherService: WeatherService,
        private dialog: MatDialog

    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

        setInterval(() => {
            this.selectedPlaceDateNow = Date.now();
        }, 1000);
    }

    ngOnInit(): void {
        // If the user is not authenticated, he is redirected to the login page.
        // If the user is authenticated but the token has expired, he is redirected to the "session locked" page.
        this.autoRedirectionService.redirectUserIfNecessary();

        this.language = this.dateTranslationService.setDateLanguage(this.translateService.currentLang);

        this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            this.language = this.dateTranslationService.setDateLanguage(langChangeEvent.lang);
            this.getWeatherData(this.selectedPlaceIndex, this.language);
        });

        this.userName = localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name');

        // Appel api dashboard pour récupérer toutes les données de l’utilisateur
        this.dashboardService.getDashboardData()
            .subscribe(
                (response) => {
                    // On stocke la réponse dans un objet de type DashboardData pour s'en servir par la suite
                    this.dashboardData = response;

                    if (this.dashboardData.address == undefined) { // Si l'utilisateur n'a pas de lieu
                        this.placesExist = false;
                    } else {
                        this.placesExist = true;
                        // On récupère le nom de tous les lieux de l'utilisateur pour les afficher dans le menu
                        for (let i = 0; i < this.dashboardData.address.length; i++) {
                            if (this.dashboardData.address[i].admin_address[0] != undefined) {
                                this.addresses[i] = {
                                    'name': this.dashboardData.address[i].admin_address[0].name,
                                    'address': this.dashboardData.address[i].address,
                                    'id': this.dashboardData.address[i].id
                                }
                            } else {
                                this.addresses[i] = {
                                    'name': undefined,
                                    'address': this.dashboardData.address[i].address,
                                    'id': this.dashboardData.address[i].id
                                }
                            }
                        }
                        if (localStorage.getItem('currentPlace') != null) {
                            // Si l'utilisateur a selectionné un lieu précédemment (dans la même page ou dans une autre page),
                            // On initialise la page dashboard sur ce lieu.
                            this.selectedPlaceIndex = this.getPlaceIndexFromPlaceId(parseInt(localStorage.getItem('currentPlace')));
                        } else {
                            // Sinon, on initialise le lieu sur le premier lieu de l'utilisateur
                            this.selectedPlaceIndex = 0;
                        }
                        // On récupère les données de ce lieu
                        this.getPlaceData(this.selectedPlaceIndex);
                    }
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                }
            );
    }

    // Lorsque l'utilisateur sélectionne un lieu, on récupère l'index du lieu sélectionné
    // et on va chercher les data relatives à ce lieu avec getPlaceData(index)
    onPlaceSelection(index: number) {
        localStorage.removeItem('currentBoard');
        localStorage.setItem('currentPlace', this.dashboardData.address[index].id.toString());
        this.selectedPlaceIndex = index;
        this.getPlaceData(index);
    }

    getPlaceIndexFromPlaceId(placeId: number) {
        for (let i = 0; i < this.addresses.length; i++) {
            if (this.addresses[i].id == placeId) {
                return i;
            }
        }
        // Si on ne trouve pas le lieu correspondant à l'id, on retourne l'index correspondant au premier lieu de l'utilisateur
        return 0;
    }

    getPlaceData(placeIndex: number) {
        this.selectedAddress = this.addresses[placeIndex];
        // On affiche la photo du lieu sélectionné
        this.selectedPlaceAddressPhoto = this.placePhotoService.getPlacePhotoURL(this.dashboardData.address[placeIndex].photo);
        // On récupère la timezone du lieu sélectionné
        this.selectedPlaceTimezone = getTimezone(this.dashboardData.address[placeIndex].timezone.name).dstOffsetStr;
        // On récupère la météo du lieu sélectionné
        this.getWeatherData(placeIndex, this.language);
        // On récupère les serrurres et beacon du lieu sélectionné
        this.getLocksAndBeacons(placeIndex);
        // On récupère les passerelles du lieu sélectionné
        this.getGateways(placeIndex);
        // On récupère l'état de l'alarme du lieu sélectionné
        this.selectedPlaceAlarmState = this.dashboardData.address[placeIndex].alarm;
    }

    getWeatherData(placeIndex: number, language: string) {
        this.weatherService.getWeather(this.dashboardData.address[placeIndex].city, language)
            .subscribe(
                (response) => {
                    this.weatherData = response;
                    this.selectedPlaceCity = this.weatherData.name;
                    this.selectedPlaceTemperature = (this.weatherData.main.temp - 273.15).toFixed(0);
                    this.selectedPlaceWindDirection = this.weatherData.wind.deg;
                    this.selectedPlaceWindSpeed = this.weatherData.wind.speed;
                    this.selectedPlaceHumidity = this.weatherData.main.humidity;
                    this.selectedPlaceWeatherDescription = this.weatherData.weather[0].description;
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                }
            );
    }

    getGateways(placeIndex: number) {
        this.gateways = new Array<GatewayData>();
        for (let i = 0; i < this.dashboardData.address[placeIndex].passerelle.length; i++) {
            this.gateways[i] = {
                'nbObject': this.dashboardData.address[placeIndex].passerelle[i].nbObject,
                'connectionInternet': this.dashboardData.address[placeIndex].passerelle[i].connectionInternet,
                'id': this.dashboardData.address[placeIndex].passerelle[i].id,
                'name': this.dashboardData.address[placeIndex].passerelle[i].name,
                'address_ip_local': this.dashboardData.address[placeIndex].passerelle[i].address_ip_local
            }
        }
    }

    getLocksAndBeacons(placeIndex: number) {
        this.locks = new Array<LockData>();
        this.beacons = new Array<BeaconData>();
        let lockIndex = 0;
        let beaconIndex = 0;
        for (let i = 0; i < this.dashboardData.address[placeIndex].board.length; i++) {
            // Si l'objet est une serrure (type_board.id == 1 --> serrure V1), (type_board.id == 3 --> serrure V2)
            if (this.dashboardData.address[placeIndex].board[i].type_board.id == 1 || this.dashboardData.address[placeIndex].board[i].type_board.id == 3) {
                this.locks[lockIndex] = {
                    'colorConnection': this.dashboardData.address[placeIndex].board[i].colorConnection,
                    'name': this.dashboardData.address[placeIndex].board[i].name,
                    'passerelle': {
                        'connectionInternet': null,
                        'name': null
                    },
                    'type_board': {
                        'id': this.dashboardData.address[placeIndex].board[i].type_board.id
                    },
                    'data': this.dashboardData.address[placeIndex].board[i].data,
                    'veille': this.dashboardData.address[placeIndex].board[i].veille,
                    'connection': this.dashboardData.address[placeIndex].board[i].connection,
                    'date_battery': this.dashboardData.address[placeIndex].board[i].date_battery,
                    'battery': this.dashboardData.address[placeIndex].board[i].battery
                }
                if (this.dashboardData.address[placeIndex].board[i].passerelle != undefined) {
                    // Si la serrure est associée à une passerelle
                    this.locks[lockIndex].passerelle.connectionInternet = this.dashboardData.address[placeIndex].board[i].passerelle.connectionInternet;
                    this.locks[lockIndex].passerelle.name = this.dashboardData.address[placeIndex].board[i].passerelle.name;
                }
                if (this.locks[lockIndex].battery < 0) {
                    // Si l'API renvoie un pourcentage de batterie négatif
                    this.locks[lockIndex].battery = 0;
                }
                lockIndex += 1;
            } else { // Si l'objet est un beacon (type_board.id == 2 --> Beacon)
                this.beacons[beaconIndex] = {
                    'name': this.dashboardData.address[placeIndex].board[i].name,
                    'temperature': this.dashboardData.address[placeIndex].board[i].data,
                    'battery': this.dashboardData.address[placeIndex].board[i].battery
                }
                beaconIndex += 1;
            }
        }
    }

    onPlacePhotoClick() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        this.dialog.open(this.FullSizePlacePhoto, dialogConfig);
    }

    editAlarmState() {
        this.spinner.show();
        let state: number;
        if (this.selectedPlaceAlarmState == 0) {
            state = 1;
        } else {
            state = 0;
        }
        this.dashboardService.updateAlarmState(this.dashboardData.address[this.selectedPlaceIndex].id, state)
            .subscribe(
                () => {
                    this.dashboardData.address[this.selectedPlaceIndex].alarm = state;
                    this.selectedPlaceAlarmState = this.dashboardData.address[this.selectedPlaceIndex].alarm;
                    this.spinner.hide();
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                    this.spinner.hide();
                }
            );
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

}
