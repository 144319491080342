import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';

import { HttpErrorResponse } from '@angular/common/http';

import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { AccessManagementService } from '../../services/access-management.service';
import { EventEmitterService } from '../../services/event-emitter.service';
import { AccessService } from '../../../access/services/access.service';

import { locale as english } from '../../../access/i18n/en';
import { locale as french } from '../../../access/i18n/fr';
import { locale as spanish } from '../../../access/i18n/es';
import { AccessRecuData } from '../../interfaces/AccessRecuData';
import * as _moment from 'moment';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AccessExepData } from '../../interfaces/AccessExepData';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  templateUrl: './add-access-exep.component.html',
  styleUrls: ['./add-access-exep.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddAccessExepComponent implements OnInit {

  userOrGuestId: number;
  hourFormat: string;

  isLoading: boolean;

  addAccessExepForm: FormGroup;

  minDate: Date;
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<AddAccessExepComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private accessManagementService: AccessManagementService,
    private eventEmitterService: EventEmitterService,
    private accessService: AccessService,
    private _adapter: DateAdapter<any>
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    this.userOrGuestId = data.userOrGuestId;

    this.initializeAddAccessExepForm();

    this.hourFormat = this.accessService.getHourFormat(this.translateService.currentLang);

    const currentYear = new Date().getFullYear();
    // Date minimum : 1er Janvier de l'année en cours
    this.minDate = new Date(currentYear, 0, 1);
    // Date maximum : 31 décembre de l'année en cours + 10
    this.maxDate = new Date(currentYear + 10, 11, 31);
  }

  ngOnInit(): void {
    this._adapter.setLocale(this.translateService.currentLang);
  }

  initializeAddAccessExepForm() {
    this.addAccessExepForm = this._formBuilder.group({
      access_exep_type: ['1'],
      start_date: ['', Validators.required],
      start_time: ['', Validators.required],
      stop_date: ['', Validators.required],
      stop_time: ['', Validators.required],
    });
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;

    let startDate: string;
    let stopDate: string;

    startDate = form.value.start_date._i.year
      + "-" + this.accessManagementService.parseDayOrMonthNumber(form.value.start_date._i.month + 1)
      + "-" + this.accessManagementService.parseDayOrMonthNumber(form.value.start_date._i.date)
      + "T" + this.accessManagementService.parseTime(form.value.start_time, this.hourFormat)
      + ":00"
      + "Z";

    stopDate = form.value.stop_date._i.year
      + "-" + this.accessManagementService.parseDayOrMonthNumber(form.value.stop_date._i.month + 1)
      + "-" + this.accessManagementService.parseDayOrMonthNumber(form.value.stop_date._i.date)
      + "T" + this.accessManagementService.parseTime(form.value.stop_time, this.hourFormat)
      + ":00"
      + "Z";

    if ((_moment(startDate).isAfter(_moment(stopDate))) || (startDate == stopDate)) {
      this.isLoading = false;
      alert(this.translateService.instant('ACCESS_MANAGEMENT.INVALID_DATES'));
    } else {
      let accessExepData: AccessExepData;
      accessExepData = {
        'isDispo': parseInt(form.value.access_exep_type),
        'start': startDate,
        'stop': stopDate
      }
      this.accessManagementService.addAccessExep(this.userOrGuestId, accessExepData)
      .subscribe(
        () => {
          this.isLoading = false;
          this.close();
          // On invoque la fonction getAccessList() de AccessManagementComponent pour voir apparaître le nouvel accès
          this.eventEmitterService.onAddAccessRecuOrExep();
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(err);
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

}
