import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';

import { FuseSharedModule } from '@fuse/shared.module';

import { InvoicesComponent } from './invoices.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';


const routes = [
  {
    path: 'invoices',
    component: InvoicesComponent,
  }
];

@NgModule({
  declarations: [InvoicesComponent, InvoiceListComponent],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    NgxSpinnerModule,
    CommonModule,

    FuseSharedModule,
    MatDialogModule,
    MatTableModule
  ]
})
export class InvoicesModule { }
