import { Injectable } from '@angular/core';
import { PlaceService } from '../PlacesService/place.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  constructor(
    private placeService: PlaceService,
    private router: Router
  ) { }

  logout() : void {
    this.placeService.clearData();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['auth/login']);
  }
}
