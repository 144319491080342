import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProfileDataApi } from './ProfileDataApi';
import { Observable } from 'rxjs';
import { ModifyProfileInformationData } from 'app/main/profile/interfaces/ModifyProfileInformationData';
import { FileInput } from 'ngx-material-file-input';

import { ApiConfigData } from '../../../ApiConfigData';
import { ChangePasswordData } from 'app/main/profile/interfaces/ChangePasswordData';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  api_base: string = this.api_endpoint + '/api';
  api_bearer: string = ".json?bearer=";

  api_url: string = this.api_base + '/user/profile';
  api_photo: string = this.api_base + '/user/photo';
  api_modify: string = this.api_base + '/user/user';
  api_upload_photo: string = this.api_base + '/user/photo/upload';
  api_change_password: string = this.api_base + '/user/change-password';

  constructor(private httpClient: HttpClient) { }

  getProfileData(token: string): Observable<any> {
    return this.httpClient.get<ProfileDataApi>(
      this.api_url + this.api_bearer + token,
      { observe: 'response' }
    );
  }
  getPhotoProfile(name: string): string {
    return this.api_photo + "/" + name + "/download" + this.api_bearer + localStorage.getItem('token');
  }

  modifyProfileInformation(newProfileInformation: ModifyProfileInformationData) {
    return this.httpClient.put(this.api_modify + this.api_bearer + localStorage.getItem('token'), newProfileInformation);
  }

  uploadProfileImage(photo: File): Observable<any> {
    const formData = new FormData();
    formData.append('photo', photo);
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    return this.httpClient.post(
      this.api_upload_photo + this.api_bearer + localStorage.getItem('token'),
      formData,
      {
        headers: headers
      }
    );
  }

  changePassword(changePasswordData: ChangePasswordData) {
    return this.httpClient.put(this.api_change_password + this.api_bearer + localStorage.getItem('token'), changePasswordData);
  }
}
