export const locale = {
    lang: 'en',
    data: {
        'HEADER': {
            'TITLE_INVOICES': 'INVOICES'
        },
        'FEATURE_COMING_SOON': 'Feature coming soon!',
        'NO_INVOICE': 'You haven\'t got any invoice.',
        'INVOICE_NUMBER': 'Invoice no',
        'DATE': 'Date',
        'TOTAL_EXCL_VAT': 'Total excl. VAT',
        'TOTAL_INCL_VAT': 'Total incl. VAT',
        'STATUS': 'Status',
        'DOWNLOAD': 'Download',
        'TOTAL_OF_INVOICES': 'Total of invoices',
        'VALIDATED_ORDER': 'Validated order',
        'IN_PAYMENT': 'In payment',
        'IN_THE_COURSE_OF_MANUFACTURE': 'In the course of manufacture',
        'IN_THE_COURSE_OF_DELIVERY': 'In the course of delivery',
        'WAITING': 'Waiting',
        'DELIVERED': 'Delivered',
        'CANCELED': 'Canceled',
        'PARTIAL_DELIVERY': 'Partial delivery',
        'MALFUNCTION_AFTER_SALE_SERVICE': 'Malfunction (After-sales service)',
        'STANDARD_EXCHANGE': 'Standard exchange',
        'WAITING_FOR_CUSTOMER_RETURN': 'Waiting for customer return',
        'TO_BE_REFUNDED': 'To be refunded',
        'REFUNDED': 'Refunded',
        'PARCEL_RECEPTION_AFTER_SALES_SERVICE': 'Parcel reception (After-sales service)',
        'IN_PREPARATION': 'In preparation',
        'IN_REPARATION': 'In reparation',
        'PAYMENT_VALIDATED': 'Payment Validated',
        'REPAIRS_COMPLETED': 'Repairs completed',
        'CUSTOMER_RELATIONS': 'Customer relations',
        'TYPE_OF_PAYMENT': 'Type of payment',
        'SUBSCRIPTION': 'Subscription',
        'VIA_ELOCKY_WEBSITE': 'Via Elocky website',
        'BANK_TRANSFER': 'Bank transfer',
        'DUTY_FREE_ORDER': 'Duty free order',
        'CASH': 'Cash',
        'CHEQUE': 'Cheque',
        'CREDIT_CARD': 'Credit card',
        'MONEY_ORDER': 'Money order',
        'OTHER': 'Other',
    }
}