<form id="formInformations" [formGroup]="informationsForm" (ngSubmit)="onSendForm()">
  <h2>{{'INFORMATIONS_PLACES' | translate}}</h2>
  <div class="w50">
    <mat-form-field>
      <mat-label>{{'INFORMATIONS_PLACES_NAME' | translate}}</mat-label>
      <input formControlName="name" matInput>
    </mat-form-field>
  </div>
  <div class="w50">
    <mat-form-field>
      <mat-label>{{'INFORMATIONS_PLACES_ADDRESS' | translate}}</mat-label>
      <input formControlName="address" matInput>
    </mat-form-field>
  </div>
  <div class="w100">
    <mat-form-field>
      <mat-label>{{'INFORMATIONS_PLACES_COMPLEMENT' | translate}}</mat-label>
      <input formControlName="complement" matInput>
    </mat-form-field>
  </div>
  <div class="w50">
    <mat-form-field>
      <mat-label>{{'INFORMATIONS_PLACES_COUNTRY' | translate}}</mat-label>
      <mat-select formControlName="country" required>
        <mat-option *ngFor="let country of country" [value]="country.name">
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="w50">
    <mat-form-field>
      <mat-label>{{'INFORMATIONS_PLACES_CITY' | translate}}</mat-label>
      <input formControlName="city" matInput>
    </mat-form-field>
  </div>
  <div class="w50">
    <mat-form-field>
      <mat-label>{{'INFORMATIONS_PLACES_ZIP' | translate}}</mat-label>
      <input formControlName="zipcode" matInput>
    </mat-form-field>
  </div>
  <div class="w50">
    <mat-form-field>
      <mat-label>{{'INFORMATIONS_PLACES_TIMEZONE' | translate}}</mat-label>
      <mat-select formControlName="timezone" required>
        <mat-option *ngFor="let zone of timezone" [value]="zone.name">
          {{ zone.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="buttons">
    <button mat-raised-button type="submit" [disabled]="!hasFormChanged()" color="primary">{{'SAVE_BUTTON' | translate}}</button>
    <button *ngIf="hasFormChanged()" mat-stroked-button type="button" (click)="cancelChoice()" color="primary">Annuler</button>
  </div>
</form>

<form id="formPhoto" [formGroup]="photoForm" (ngSubmit)="onSendPhotoForm()">
  <div class="w100 container-image">
    <img [src]="current_place_photo"/>
  </div>
  <div class="w100">
    <mat-form-field appearance="outline">
      <mat-label>{{'INFORMATIONS_PLACES_PHOTO' | translate}}</mat-label>
      <ngx-mat-file-input formControlName="photo" placeholder="Photo" ></ngx-mat-file-input>
      <mat-icon matSuffix>folder</mat-icon>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="buttons">
    <button mat-raised-button type="submit" [disabled]="!hasFormPhotoChanged()" color="primary">{{'INFORMATIONS_PLACES_PHOTO_SAVE' | translate}}</button>
    <button *ngIf="hasFormPhotoChanged()" mat-stroked-button type="button" (click)="cancelPhotoChoice()" color="primary">{{'CANCEL_BUTTON' | translate}}</button>
  </div>
</form>
