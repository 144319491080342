import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { AccessService } from '../../services/access.service';

import { fuseAnimations } from '@fuse/animations';

import { EventEmitterService } from '../../services/event-emitter.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

import { UserAccessData } from '../../interfaces/UserAccessData';
import { CopyAccessData } from '../../interfaces/CopyAccessData';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './copy-access-dialog.component.html',
  styleUrls: ['./copy-access-dialog.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class CopyAccessDialogComponent implements OnInit {

  selectedUserAccess: UserAccessData;

  copyAccessForm: FormGroup;

  isLoading: boolean;

  constructor
  (
    public dialogRef: MatDialogRef<CopyAccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private accessService: AccessService,
    private _formBuilder: FormBuilder,
    private eventEmitterService: EventEmitterService

  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    this.selectedUserAccess = data.selectedUserAccess;
   }

  ngOnInit(): void {
    this.isLoading = false;

    this.copyAccessForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    let copyAccessData: CopyAccessData;
    copyAccessData = {
      'accessId': this.selectedUserAccess.id,
      'nameAccess': null,
      'referenceInvite': form.value.email,
    }
    if(this.selectedUserAccess.name != undefined){
      copyAccessData.nameAccess = this.selectedUserAccess.name;
    }
    this.accessService.copyAccess(copyAccessData)
    .subscribe(
      () => {
        this.isLoading = false;
        this.close();
        // On invoque la fonction getAccessList() de AccessComponent pour voir apparaître le nouvel accès
        this.eventEmitterService.onAddAccessDialogComponentAddAccess();
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        if (err.error.errors[0] == "Error") {// Si l'addresse mail déjà existante (l'utilisateur a déjà un accès)
          alert(this.translateService.instant('POPUP.USER_ALREADY_HAVE_ACCESS_1') + form.value.email + this.translateService.instant('POPUP.USER_ALREADY_HAVE_ACCESS_2'));

        } else if (err.error.errors[0] == "user not exist") {// Si l'addresse mail n'est associée à aucun compte
          alert(this.translateService.instant('POPUP.USER_NOT_EXIST_1') + form.value.email + this.translateService.instant('POPUP.USER_NOT_EXIST_2'));
        }
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
