import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  static DEFAULT_DURATION:number = 3000;
  public static WRONG_MESSAGE:string = "Oups, something went wrong...";

  constructor(private snackBar: MatSnackBar) {}

  makeSimpleSnackBar(message:string, duration:number = SnackbarService.DEFAULT_DURATION) : void {
    this.snackBar.open(message, "", {
      duration:duration
    });
  }
}
