<h2 matDialogTitle>{{'EDIT_EXPIRY_LOGS.TITLE' | translate}}</h2>
<div>
    <form [formGroup]="expiryLogsForm" (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div fxLayout="column">
            <mat-form-field appearance="outline">
                <mat-select matNativeControl formControlName="expiryDays">
                    <mat-option value="1095">{{'EDIT_EXPIRY_LOGS.3_YEARS' | translate}}</mat-option>
                    <mat-option value="365">{{'EDIT_EXPIRY_LOGS.1_YEAR' | translate}}</mat-option>
                    <mat-option value="182">{{'EDIT_EXPIRY_LOGS.6_MONTHS' | translate}}</mat-option>
                    <mat-option value="91">{{'EDIT_EXPIRY_LOGS.3_MONTHS' | translate}}</mat-option>
                    <mat-option value="30">{{'EDIT_EXPIRY_LOGS.1_MONTH' | translate}}</mat-option>
                    <mat-option value="7">{{'EDIT_EXPIRY_LOGS.1_WEEK' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <button mat-raised-button type="button" color="warn"
                    (click)="close()">{{'EDIT_EXPIRY_LOGS.CANCEL' | translate}}</button>
                <button mat-raised-button type="submit" color="accent"
                    [disabled]="isLoading">{{'EDIT_EXPIRY_LOGS.VALIDATE' | translate}}</button>
            </div>
        </div>
    </form>
</div>