import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PlacePhotoService } from './place-photo.service';
import { ProfileService } from '../ProfileService/profile.service';
import { PlaceInfos } from './interfaces/PlaceInfos';
import { PlaceData } from '../../main/places/interfaces/PlaceData';
import { UserData } from '../../main/places/interfaces/UserData';
import { CheckInOutData } from '../../main/places/interfaces/CheckInOutData';
import { FileInput } from 'ngx-material-file-input';

import { Observable, BehaviorSubject } from 'rxjs';
import { from, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiConfigData } from '../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  public static DEFAULT_PLACE_DATA: PlaceData = {
    id: -1,
    photo: "loft.jpg",
    state: 3,
    latitude: 0,
    longitude: 0,
    address: "",
    city: "",
    country: {
      id: -1,
      name: ""
    },
    timezone: {
      id: -1,
      name: ""
    },
    zipcode: null,
    check: {
      default: 0,
    }
  }
  public static LOCAL_STORAGE_NAME_ADDRESS: string = "currentAddress";

  api_endpoint: string = ApiConfigData.api_endpoint;

  api_base: string = this.api_endpoint + "/api";
  api_bearer: string = ".json?bearer=";

  api_renameUrl1: string = this.api_base + "/address/name/edit";
  api_listAddress: string = this.api_base + "/address/list";
  api_listTimezone: string = this.api_base + "/address/country";
  api_listAdminAdress: string = this.api_base + "/address/adminAddress/list";

  api_addPlace: string = this.api_base + "/address/";
  api_editInfoPlace: string = this.api_base + "/address/info/edit";
  api_deletePlace: string = this.api_base + "/address/delete";
  api_deleteUserOfPlace: string = this.api_base + "/address/delete-user";
  api_editAdminAddressState: string = this.api_base + "/address/adminAddress/edit";
  api_listAlarmByAddress: string = this.api_base + "/address/alarm";
  api_editCheckInOut: string = this.api_base + "/address/check/edit";

  googleapi_timezone: string = "https://maps.googleapis.com/maps/api/timezone";

  list_places = new Array<Array<PlaceData>>();
  list_adminAddress = new Array<Array<UserData>>();
  list_country = new Array();
  list_timezone = new Array();

  obsCountry: Observable<any>;
  obsTimezone: Observable<any>;

  private behaviorPlace = new BehaviorSubject(null);
  currentBehaviorPlace = this.behaviorPlace.asObservable();

  private behaviorAdminAddress = new BehaviorSubject(null);
  currentBehaviorAdminAddress = this.behaviorAdminAddress.asObservable();


  constructor(private httpClient: HttpClient,
    private placePhotoService: PlacePhotoService,
    private profileService: ProfileService) {
    // On stocke les datas dans le local storage afin de pouvoir gérer le "Management Guards"
    this.list_places[0] = JSON.parse(localStorage.getItem(PlaceService.LOCAL_STORAGE_NAME_ADDRESS)) || new Array<PlaceData>();
    this.list_country = JSON.parse(localStorage.getItem('list_country')) || new Array();
    this.list_timezone = JSON.parse(localStorage.getItem('list_timezone')) || new Array();
    this.fillPlaces();

    if (this.list_country.length <= 0 || this.list_country.length <= 0) {
      this.getListTimezoneCountry().subscribe(
        (response) => {
          this.list_country = response["country"];
          this.list_timezone = response["timezone"];

          this.obsCountry = from(this.list_country);
          this.obsTimezone = from(this.list_timezone);
        }
      )
    } else {
      this.obsCountry = from(this.list_country);
      this.obsTimezone = from(this.list_timezone);
    }
  }

  get places() { return this.list_places[0]; }
  set places(data: PlaceData[]) { this.list_places[0] = data; }
  get adminAddress() { return this.list_adminAddress[0]; }

  fillPlaces(): void {
    this.getListAdress().subscribe(
      (response) => {
        this.initPlacesArray(response["address"]);
        this.updateListPlace();
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      }
    )
  }

  initPlacesArray(address): void {
    this.list_places[0] = new Array<PlaceData>();
    localStorage.removeItem(PlaceService.LOCAL_STORAGE_NAME_ADDRESS);
    address.forEach((item, index) => {
      this.places[index] = {
        name: item["admin_address"][0]["name"] || item["address"] || PlaceService.DEFAULT_PLACE_DATA["address"],
        id: item["id"] || -1,
        photo: this.placePhotoService.getPlacePhotoURL(item["photo"]) || PlaceService.DEFAULT_PLACE_DATA["photo"],
        state: item["admin_address"][0]["state"] != undefined ? item["admin_address"][0]["state"] : PlaceService.DEFAULT_PLACE_DATA["state"],
        latitude: item["latitude"] || PlaceService.DEFAULT_PLACE_DATA["latitude"],
        longitude: item["longitude"] || PlaceService.DEFAULT_PLACE_DATA["longitude"],
        address: item["address"] || PlaceService.DEFAULT_PLACE_DATA["address"],
        complement: item["complement"] || "",
        city: item["city"] || PlaceService.DEFAULT_PLACE_DATA["city"],
        country: item["country"] == undefined ? {
          id: PlaceService.DEFAULT_PLACE_DATA["country"]["id"],
          name: PlaceService.DEFAULT_PLACE_DATA["country"]["name"]
        } : {
            id: item["country"].id,
            name: item["country"].name
          },
        zipcode: item["zip_code"] || PlaceService.DEFAULT_PLACE_DATA["zipcode"],
        timezone: item["timezone"] == undefined ? {
          id: PlaceService.DEFAULT_PLACE_DATA["timezone"]["id"],
          name: PlaceService.DEFAULT_PLACE_DATA["timezone"]["name"]
        } : {
            id: item["timezone"].id,
            name: item["timezone"].name
          },
        check: {
          default: item["check_bool"] || PlaceService.DEFAULT_PLACE_DATA["check"]["default"],
          in: item["check_in"] || "",
          out: item["check_out"] || ""
        }
      }
    })
    localStorage.setItem(PlaceService.LOCAL_STORAGE_NAME_ADDRESS, JSON.stringify(this.places));
  }
  clearData(): void {
    this.places = new Array<PlaceData>();
  }
  getListPlaces(): Observable<any> {
    return this.currentBehaviorPlace;
  }
  updateListPlace(): void {
    localStorage.setItem(PlaceService.LOCAL_STORAGE_NAME_ADDRESS, JSON.stringify(this.places));
    this.behaviorPlace.next(this.places);
  }

  initAdminAddressArray(adminAddress): void {
    adminAddress.forEach(element => {
      this.adminAddress.push({
        id: element.id,
        firstName: element.firstName,
        lastName: element.lastName,
        photo: this.profileService.getPhotoProfile(element.photo),
        state: element.state
      })
    })
  }
  getListAdminAddress(): Observable<any> {
    return this.currentBehaviorAdminAddress;
  }
  updateListAdminAddress(): void {
    this.behaviorAdminAddress.next(this.adminAddress);
  }

  getListAdress(): Observable<any> {
    return this.httpClient.get(this.api_listAddress + this.api_bearer + localStorage.getItem('token'));
  }
  getListAlarm(id: number): Observable<any> {
    return this.httpClient.get(this.api_listAlarmByAddress + this.api_bearer + localStorage.getItem('token'));
  }
  getListTimezoneCountry(): Observable<any> {
    return this.httpClient.get(this.api_listTimezone + this.api_bearer + localStorage.getItem('token'));
  }
  getTimeZoneByLocation(location: string): Observable<any> {
    let request = this.googleapi_timezone + "/json?location=" + location + "&timestamp=" + (Date.now() / 1000) + "&key=AIzaSyDFLPCGnnEgTn3Yx6rkkhkaH0CWCFLpe7Y";
    return this.httpClient.get(request);
  }
  getAdminAddressByAdressId(addressId: number): void {

    let request = this.api_listAdminAdress + "/" + addressId + this.api_bearer + localStorage.getItem('token');
    this.httpClient.get(request).subscribe(
      (response) => {
        this.list_adminAddress[0] = new Array<UserData>();
        this.initAdminAddressArray(response["adminAddress"]);
        this.updateListAdminAddress();
      }
    );
  }

  editInfoPlaceByPlaceData(data: PlaceData): Observable<any> {
    const data_clone = Object.assign({}, data);
    this.places[this.getPlacesIndexById(data.id)] = data_clone;
    this.updateListPlace();
    let infos = this.getPlaceInfosByPlaceData(data);
    return this.httpClient.put(this.api_editInfoPlace + "/" + data.id + this.api_bearer + localStorage.getItem('token'), infos);
  }
  editCheckInOutByPlaceId(id: number, data: CheckInOutData): Observable<any> {
    let index = this.getPlacesIndexById(id);
    this.places[index].check["default"] = data["check_bool"];

    let inD = new Date();
    inD.setHours(parseInt(data["check_in"].split(":")[0]));
    inD.setMinutes(parseInt(data["check_in"].split(":")[1]));

    let outD = new Date();
    outD.setHours(parseInt(data["check_out"].split(":")[0]));
    outD.setMinutes(parseInt(data["check_out"].split(":")[1]));

    this.places[index].check["in"] = inD.toString();
    this.places[index].check["out"] = outD.toString();
    this.updateListPlace();
    return this.httpClient.put(this.api_editCheckInOut + "/" + id + this.api_bearer + localStorage.getItem('token'), data)
  }
  renamePlacesById(placeId: number, newName: string): Observable<any> {
    this.places[this.getPlacesIndexById(placeId)].name = newName;
    this.updateListPlace();
    return this.httpClient.put(this.api_renameUrl1 + "/" + placeId + this.api_bearer + localStorage.getItem('token'),
      { name: newName }
    );
  }
  addPlaces(infos: PlaceInfos): Observable<any> {
    return this.httpClient.post(this.api_addPlace + this.api_bearer + localStorage.getItem('token'), infos);
  }
  addPlacesInArray(infos: PlaceInfos, id: number): void {
    this.places.push(this.createNewPlaceDataByPlaceInfos(infos, id));
    this.updateListPlace();
  }

  deletePlace(id: number): Observable<any> {
    this.places.splice(this.getPlacesIndexById(id), 1);
    this.updateListPlace();
    return this.httpClient.delete(this.api_deletePlace + "/" + id + this.api_bearer + localStorage.getItem('token'));
  }
  deleteUserOfPlaceByAddressId(id: number): Observable<any> {
    this.places.splice(this.getPlacesIndexById(id), 1);
    this.updateListPlace();
    return this.httpClient.delete(this.api_deleteUserOfPlace + "/" + id + this.api_bearer + localStorage.getItem('token'));
  }
  editAdminAddressState(admin_address_id: number, newstate: number): Observable<any> {
    this.adminAddress[this.getAdminAddressIndexById(admin_address_id)].state = newstate;
    this.updateListAdminAddress();
    return this.httpClient.put(
      this.api_editAdminAddressState + "/" + admin_address_id + this.api_bearer + localStorage.getItem('token'),
      { type: newstate }
    )
  }
  deleteAdminAddress(admin_address_id: number): Observable<any> {
    this.adminAddress.splice(this.getAdminAddressIndexById(admin_address_id), 1);
    this.updateListAdminAddress();
    return this.httpClient.put(
      this.api_editAdminAddressState + "/" + admin_address_id + this.api_bearer + localStorage.getItem('token'),
      { type: 4 }
    )
  }

  createNewPlaceDataByPlaceInfos(infos: PlaceInfos, id: number): PlaceData {
    let countryname, timezonename;
    this.getCountryNameById(parseInt(infos.country)).subscribe(
      (name) => { countryname = name }
    )
    this.getTimezoneNameById(parseInt(infos.timezone)).subscribe(
      (name) => { timezonename = name }
    )
    return {
      name: infos.name || infos.address || PlaceService.DEFAULT_PLACE_DATA["address"],
      id: id || PlaceService.DEFAULT_PLACE_DATA["id"],
      photo: this.placePhotoService.getPlacePhotoURL(null) || PlaceService.DEFAULT_PLACE_DATA["photo"],
      state: 1,
      latitude: infos["latitude"] || PlaceService.DEFAULT_PLACE_DATA["latitude"],
      longitude: infos["longitude"] || PlaceService.DEFAULT_PLACE_DATA["longitude"],
      address: infos.address || PlaceService.DEFAULT_PLACE_DATA["address"],
      complement: infos.complement || "",
      city: infos.city || PlaceService.DEFAULT_PLACE_DATA["city"],
      country: {
        id: parseInt(infos.country) || PlaceService.DEFAULT_PLACE_DATA["country"]["id"],
        name: countryname || PlaceService.DEFAULT_PLACE_DATA["country"]["name"]
      },
      zipcode: infos.zipcode || PlaceService.DEFAULT_PLACE_DATA["zipcode"],
      timezone: {
        id: parseInt(infos.timezone) || PlaceService.DEFAULT_PLACE_DATA["timezone"]["id"],
        name: timezonename || PlaceService.DEFAULT_PLACE_DATA["timezone"]["name"]
      },
      check: {
        default: PlaceService.DEFAULT_PLACE_DATA["check"]["default"]
      }
    } as PlaceData;
  }
  getPlaceInfosByPlaceData(data: PlaceData): PlaceInfos {
    return {
      name: data["name"],
      address: data["address"],
      city: data["city"],
      zipcode: data['zipcode'],
      country: data["country"].id.toString(),
      complement: data["complement"],
      timezone: data["timezone"].id.toString()
    } as PlaceInfos;
  }
  getCountryNameById(id: number): Observable<number> {
    return this.obsCountry.pipe(
      filter(country => country.id == id),
      map(country => country.name)
    );
  }
  getCountryIdByName(name: string): Observable<string> {
    return this.obsCountry.pipe(
      filter(country => country.name == name),
      map(country => country.id)
    )
  }
  getTimezoneNameById(id: number): Observable<number> {
    return this.obsTimezone.pipe(
      filter(timezone => timezone.id == id),
      map(timezone => timezone.name)
    );
  }
  getTimezoneIdByName(name: string): Observable<string> {
    return this.obsTimezone.pipe(
      filter(timezone => timezone.name == name),
      map(timezone => timezone.id)
    );
  }
  getPlacesIndexById(id: number): number {
    let result = -1;
    this.places.forEach((item, index) => {
      if (item.id == id) {
        result = index;
      }
    })
    return result;
  }
  getAdminAddressIndexById(id: number): number {
    let result = -1;
    this.adminAddress.forEach((item, index) => {
      if (item.id == id) {
        result = index;
      }
    })
    return result;
  }
  getPlacesById(id: number): PlaceData {
    let result = null;
    this.places.forEach((item, index) => {
      if (item.id == id) {
        result = item;
      }
    })
    return result;
  }
  isUserHadRightByState(state: number): boolean {
    return state == 0 || state == 1;
  }
}
