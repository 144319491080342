import { Component, OnInit } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

@Component({
  selector: 'iftt-lieux',
  templateUrl: './iftt.component.html',
  styleUrls: ['./iftt.component.scss'],
  animations: fuseAnimations
})
export class IfttComponent implements OnInit {

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    ngOnInit(): void {
    }

  }
