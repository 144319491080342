<div id="access" class="page-layout simple tabbed " fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="space-between">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">{{'HEADER.TITLE_ACCESS' | translate}}</span>
            </div>
            <div class="h1 mt-16" [@animate]="{value:'*',params:{x:'50px'}}">{{'HEADER.TITLE_ACCESS' | translate}}</div>

            <!-- ADDRESS SELECTION -->

            <div fxLayout="row" class="menu" fxLayoutAlign="space-between">
                <div fxLayout="row" class="places-menu">
                    <div *ngIf="placesExist && selectedAddress.name != undefined" class="selected-place">
                        {{selectedAddress.name}}</div>

                    <div *ngIf="placesExist && selectedAddress.name != undefined" class="selected-place-with-name">
                        {{selectedAddress.address}}</div>
                    <div *ngIf="placesExist && selectedAddress.name == undefined" class="selected-place">
                        {{selectedAddress.address}}
                    </div>
                    <div *ngIf="!placesExist" class="selected-place">{{'HEADER.NO_PLACE' | translate}}</div>

                    <button *ngIf="placesExist" mat-icon-button class="place-selector" [matMenuTriggerFor]="placesMenu"
                        aria-label="Select place">
                        <mat-icon>expand_more</mat-icon>
                    </button>

                    <button *ngIf="!placesExist" mat-icon-button class="place-selector-disabled"
                        [matMenuTriggerFor]="placesMenu" aria-label="Select place" [disabled]="!placesExist">
                        <mat-icon>expand_more</mat-icon>
                    </button>

                    <mat-menu #placesMenu="matMenu">
                        <button mat-menu-item *ngFor="let address of addresses" (click)="onPlaceSelection(address.id)">
                            <span *ngIf="address.name != undefined">{{address.name}}</span>
                            <span *ngIf="address.name == undefined">{{address.address}}</span>
                        </button>
                    </mat-menu>

                    <!-- / ADDRESS SELECTION -->

                    <!-- BOARD SELECTION -->

                    <div *ngIf="placesExist && boardsExist" class="selected-place" style="margin-left: 10px;">
                        {{selectedBoard.name}}</div>

                    <div *ngIf="placesExist && !boardsExist" class="selected-place" style="margin-left: 10px;">
                        {{'HEADER.NO_LOCK' | translate}}</div>

                    <button *ngIf="placesExist && !boardsExist" mat-icon-button class="place-selector-disabled"
                        aria-label="Select board" [disabled]="placesExist && !boardsExist">
                        <mat-icon>expand_more</mat-icon>
                    </button>

                    <div *ngIf="placesExist && boardsExist">
                        <button mat-icon-button class="place-selector" [matMenuTriggerFor]="boardsMenu"
                            aria-label="Select board">
                            <mat-icon>expand_more</mat-icon>
                        </button>

                        <mat-menu #boardsMenu="matMenu">
                            <button mat-menu-item *ngFor="let board of selectedAddress.board"
                                (click)="onBoardSelection(board.id)">
                                <span>{{board.name}}</span>
                            </button>
                        </mat-menu>
                    </div>

                    <!-- / BOARD SELECTION -->

                </div>
            </div>
        </div>
        <ngx-spinner name="spinnerLoadingContent" class="spinnerLoadingContent" size="medium" color="#2eb4b3"
            bdColor="rgba(51,51,51,0)" type="ball-clip-rotate" [fullScreen]="false"></ngx-spinner>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <mat-tab-group dynamicHeight [(selectedIndex)]="tabIndex" (selectedIndexChange)="onTabIndexChange($event)">

            <mat-tab label="{{'CONTENT.USERS' | translate}}">

                <div class="h2 p-12" *ngIf="!boardsExist && isLoading == false">{{'CONTENT.NO_LOCK' | translate}}</div>

                <div *ngIf="accessData != undefined && selectedBoard != undefined">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <!-- USERS WIDGET -->
                        <fuse-widget *ngFor="let user of accessData.user" [@animate]="{value:'*',params:{y:'100%'}}"
                            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front" style="cursor: pointer;"
                                (click)="onUserOrObjectWidgetClick(user.id, 'user')">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img class="img-widget profile-img"
                                            src={{profileService.getPhotoProfile(user.photo)}}>
                                    </div>

                                    <div class="info-container" fxLayout="column">
                                        <!-- Si l'utilisateur est un admin, il peut avoir accès aux fonctionnalités au dos de la carte des modérateurs et utilisateurs. 
                                             En revanche, personne ne peut avoir accès aux fonctionnalités au dos de la carte des administrateurs car on ne peut ni copier un accès
                                             administrateur (un seul admin par serrure) ni le supprimer (pas de serrure sans admin) -->
                                        <button
                                            *ngIf="selectedBoard.board_access.type_access.id == 1 && user.type_access.id != 1"
                                            mat-icon-button fuseWidgetToggle style="color: white;"
                                            class="fuse-widget-flip-button details-button" aria-label="more">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <!-- Si l'utilisateur est un modérateur, il peut avoir accès aux fonctionnalités au dos de la carte des autres modérateurs et des utilisateurs -->
                                        <div *ngIf="selectedBoard.board_access.type_access.id == 2">
                                            <button *ngIf="user.type_access.id == 2 || user.type_access.id == 3"
                                                mat-icon-button fuseWidgetToggle style="color: white;"
                                                class="fuse-widget-flip-button details-button" aria-label="more">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                        </div>

                                        <div class="access-widget-information">
                                            <div class="h3">{{user.firstName}} {{user.lastName}}</div>
                                            <div>
                                                <span
                                                    *ngIf="user.type_access.id == 1">{{'CONTENT.ADMINISTRATOR' | translate}}</span>
                                                <span
                                                    *ngIf="user.type_access.id == 2">{{'CONTENT.MODERATOR' | translate}}</span>
                                                <span
                                                    *ngIf="user.type_access.id == 3">{{'CONTENT.USER' | translate}}</span>
                                            </div>
                                        </div>

                                        <!-- ATTENTION -->

                                        <!-- Normalement, le bouton delete n'apparaît que sur la carte de l'utilisateur qui est connecté (sauf si cet utilisateur est administrateur de la serrure) et la
                                        condition pour afficher ce bouton est : "selectedBoard.board_access.id == user.id && selectedBoard.board_access.type_access.id != 1".
                                        Or, il est impossible pour un simple utilisateur de supprimer son propre accès puisque l'API delete (/access/{id}) ne permet cela qu'aux modérateurs.
                                        Ainsi, on cache provisoirement le bouton delete sur la carte du simple utilisateur. -->
                                        <button
                                            *ngIf="selectedBoard.board_access.id == user.id && selectedBoard.board_access.type_access.id == 2"
                                            mat-icon-button style="color: white;"
                                            class="fuse-widget-flip-button delete-button" aria-label="more"
                                            (click)="openWantToDeleteOwnAccess(user.id)">
                                            <mat-icon>delete</mat-icon>
                                        </button>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                            <!-- Back -->
                            <div class="fuse-widget-back">

                                <div fxLayout="row">

                                    <div class="info-container-back" fxLayout="column">

                                        <div class="access-widget-information">
                                            <div class="h3">{{user.firstName}} {{user.lastName}}</div>
                                            <div>
                                                <span
                                                    *ngIf="user.type_access.id == 1">{{'CONTENT.ADMINISTRATOR' | translate}}</span>
                                                <span
                                                    *ngIf="user.type_access.id == 2">{{'CONTENT.MODERATOR' | translate}}</span>
                                                <span
                                                    *ngIf="user.type_access.id == 3">{{'CONTENT.USER' | translate}}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div fxLayout="column" class="back-buttons-container">

                                        <button mat-icon-button fuseWidgetToggle
                                            class="fuse-widget-flip-button close-details-button"
                                            aria-label="Flip widget">
                                            <mat-icon class="s-16">close</mat-icon>
                                        </button>

                                        <button mat-button class="back-button" (click)="openCopyAccessDialog(user.id)">
                                            <div fxLayout="column">
                                                <mat-icon class="back-button-icon">
                                                    file_copy</mat-icon>
                                                <span>{{'CONTENT.COPY_ACCESS' | translate}}</span>
                                            </div>
                                        </button>

                                        <button *ngIf="selectedBoard.board_access.id != user.id" mat-button
                                            class="back-button" (click)="openWantToDeleteAccess(user.id)">
                                            <div fxLayout="column">
                                                <mat-icon class="back-button-icon">delete</mat-icon>
                                                <span>{{'CONTENT.DELETE' | translate}}</span>
                                            </div>
                                        </button>
                                        <button *ngIf="selectedBoard.board_access.id == user.id" mat-button
                                            class="back-button" (click)="openWantToDeleteOwnAccess(user.id)">
                                            <div fxLayout="column">
                                                <mat-icon class="back-button-icon">delete</mat-icon>
                                                <span>{{'CONTENT.DELETE' | translate}}</span>
                                            </div>
                                        </button>

                                    </div>

                                </div>

                            </div>
                            <!-- / Back -->

                        </fuse-widget>
                        <!-- / USERS WIDGET -->

                        <!-- ADD ACCESS WIDGET -->

                        <!-- Les administrateurs et les modérateurs peuvent ajouter un accès à la serrure. Un widget apparaît à la fin de la liste pour leur permettre cela -->
                        <fuse-widget
                            *ngIf="selectedBoard.board_access.type_access.id == 1 || selectedBoard.board_access.type_access.id == 2"
                            [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                            fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front" style="cursor: pointer;" (click)="openAddAccessDialog()">

                                <div fxLayout="row">

                                    <div class="add-icon-container">
                                        <mat-icon class="add-icon">add_circle</mat-icon>
                                    </div>

                                    <div class="info-container" fxLayout="column">
                                        <div class="h1 add-text">{{'CONTENT.ADD_AN_ACCESS' | translate}}</div>
                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / ADD ACCESS WIDGET -->

                    </div>

                </div>

            </mat-tab>

            <mat-tab label="{{'CONTENT.GUESTS' | translate}}">

                <div class="h2 p-12" *ngIf="!boardsExist">{{'CONTENT.NO_LOCK' | translate}}</div>

                <div *ngIf="accessData != undefined">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <div class="h2"
                            *ngIf="accessData.invite[0] == undefined && selectedBoard.board_access.type_access.id == 3">
                            {{'CONTENT.NO_GUEST' | translate}}
                        </div>

                        <!-- GUESTS WIDGET -->
                        <fuse-widget *ngFor="let guest of accessData.invite" [@animate]="{value:'*',params:{y:'100%'}}"
                            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front access-widget" style="cursor: pointer;"
                                (click)="onUserOrObjectWidgetClick(guest.id, 'guest')">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img class="img-widget" src="assets/images/avatars/guest.png">
                                    </div>

                                    <div class="info-container" fxLayout="column">
                                        <!-- Les administrateurs et les modérateurs peuvent avoir accès aux fonctionnalités au dos de la carte des invités -->
                                        <button
                                            *ngIf="selectedBoard.board_access.type_access.id == 1 || selectedBoard.board_access.type_access.id == 2"
                                            mat-icon-button fuseWidgetToggle style="color: white;"
                                            class="fuse-widget-flip-button details-button" aria-label="more">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>

                                        <div class="access-widget-information">
                                            <div *ngIf="guest.name != undefined" class="h3">{{guest.name}}</div>
                                            <div *ngIf="guest.name == undefined" class="h3">{{guest.num_access}}</div>
                                            <div>{{'CONTENT.GUEST' | translate}}</div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                            <!-- Back -->
                            <div class="fuse-widget-back">

                                <div fxLayout="row">

                                    <div class="info-container-back" fxLayout="column">

                                        <div class="access-widget-information">
                                            <div *ngIf="guest.name != undefined" class="h3">{{guest.name}}</div>
                                            <div *ngIf="guest.name == undefined" class="h3">{{guest.num_access}}</div>
                                            <div>{{'CONTENT.GUEST' | translate}}</div>
                                        </div>

                                    </div>

                                    <div fxLayout="column" class="back-buttons-container">

                                        <button mat-icon-button fuseWidgetToggle
                                            class="fuse-widget-flip-button close-details-button"
                                            aria-label="Flip widget">
                                            <mat-icon class="s-16">close</mat-icon>
                                        </button>

                                        <button mat-button class="back-button"
                                            (click)="openShareGuestAccessDialog(guest.id)">
                                            <div fxLayout="column">
                                                <mat-icon class="back-button-icon">share</mat-icon>
                                                <span>{{'CONTENT.SHARE_ACCESS' | translate}}</span>
                                            </div>
                                        </button>
                                        <button mat-button class="back-button"
                                            (click)="openWantToDeleteAccess(guest.id)">
                                            <div fxLayout="column">
                                                <mat-icon class="back-button-icon">delete</mat-icon>
                                                <span>{{'CONTENT.DELETE' | translate}}</span>
                                            </div>
                                        </button>

                                    </div>

                                </div>

                            </div>
                            <!-- / Back -->

                        </fuse-widget>
                        <!-- / GUESTS WIDGET -->

                        <!-- ADD ACCESS WIDGET -->

                        <!-- Les administrateurs et les modérateurs peuvent ajouter un accès à la serrure. Un widget apparaît à la fin de la liste pour leur permettre cela-->
                        <fuse-widget
                            *ngIf="selectedBoard.board_access.type_access.id == 1 || selectedBoard.board_access.type_access.id == 2"
                            [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                            fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front" style="cursor: pointer;" (click)="openAddAccessDialog()">

                                <div fxLayout="row">

                                    <div class="add-icon-container">
                                        <mat-icon class="add-icon">add_circle</mat-icon>
                                    </div>

                                    <div class="info-container" fxLayout="column">
                                        <div class="h1 add-text">{{'CONTENT.ADD_AN_ACCESS' | translate}}</div>
                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / ADD ACCESS WIDGET -->

                    </div>

                </div>

            </mat-tab>

            <mat-tab
                *ngIf="boardsExist && selectedBoard.type_board.id == 3 && selectedBoard.board_access.type_access.id != 3"
                label="{{'CONTENT.BADGES' | translate}}">
                <!-- Si la serrure sélectionnée est une V2 et que l'utilisateur est soit modérateur ou administrateur -->

                <div *ngIf="!badgesExist && isLoading == false" class="no-object" fxLayout="row">
                    <img class="img-no-object" src="assets/images/avatars/unregistered_card.svg">
                    <div class="h2 text-no-object">{{'CONTENT.NO_BADGE' | translate}}</div>
                </div>

                <div *ngIf="badgesExist">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <!-- BADGES WIDGET -->
                        <fuse-widget *ngFor="let badge of badges" [@animate]="{value:'*',params:{y:'100%'}}"
                            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front" style="cursor: pointer;"
                                (click)="onUserOrObjectWidgetClick(badge.id, 'badge')">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img *ngIf="badge.state == 1" class="img-widget"
                                            src="assets/images/avatars/correct_card.svg">
                                        <img *ngIf="badge.state == 0" class="img-widget"
                                            src="assets/images/avatars/unregistered_card.svg">
                                    </div>

                                    <div class="info-container" fxLayout="column">

                                        <div class="access-widget-information">
                                            <div *ngIf="badge.name != undefined" class="h3">{{badge.name}}</div>
                                            <div *ngIf="badge.name == undefined" class="h3">{{badge.num_card}}</div>
                                            <div *ngIf="badge.state == 1 && badge.hasPermanentAccess == false">
                                                {{'CONTENT.ACTIVE_BADGE' | translate}}</div>
                                            <div *ngIf="badge.hasPermanentAccess == true">
                                                {{'CONTENT.PERMANENT_ACCESS' | translate}}</div>
                                            <div *ngIf="badge.state == 0">{{'CONTENT.BADGE_DISABLED' | translate}}</div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / BADGES WIDGET -->

                    </div>

                </div>

            </mat-tab>

            <mat-tab
                *ngIf="boardsExist && selectedBoard.type_board.id == 3 && selectedBoard.board_access.type_access.id != 3"
                label="{{'CONTENT.KEYS' | translate}}">
                <!-- Si la serrure sélectionnée est une V2 et que l'utilisateur est soit modérateur ou administrateur -->

                <div *ngIf="!keysExist && isLoading == false" class="no-object" fxLayout="row">
                    <img class="img-no-object" src="assets/images/avatars/key_inactive.svg">
                    <div class="h2 text-no-object">{{'CONTENT.NO_KEY' | translate}}</div>
                </div>

                <div *ngIf="keysExist">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <!-- KEYS WIDGET -->
                        <fuse-widget *ngFor="let key of keys" [@animate]="{value:'*',params:{y:'100%'}}" class="widget"
                            fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front" style="cursor: pointer;"
                                (click)="onUserOrObjectWidgetClick(key.id, 'key')">

                                <div fxLayout="row">

                                    <div class="img-container">
                                        <img *ngIf="key.state == 1" class="img-widget"
                                            src="assets/images/avatars/key_active.svg">
                                        <img *ngIf="key.state == 0" class="img-widget"
                                            src="assets/images/avatars/key_inactive.svg">
                                    </div>

                                    <div class="info-container" fxLayout="column">

                                        <div class="access-widget-information">
                                            <div *ngIf="key.name != undefined" class="h3">{{key.name}}</div>
                                            <div *ngIf="key.name == undefined" class="h3">{{key.reference}}</div>
                                            <div *ngIf="key.security_state == 1">
                                                {{'CONTENT.INITIALIZED_KEY' | translate}}</div>
                                            <div *ngIf="key.security_state == 0 || key.security_state == 2">
                                                {{'CONTENT.UNINITIALIZED_KEY' | translate}}</div>
                                        </div>
                                        <div *ngIf="key.data_parsed != undefined" class="border-top widget-footer"
                                            [ngClass]="{'battery-blue' : key.data_parsed.electricity>=50, 'battery-purple' : key.data_parsed.electricity<50 && key.data_parsed.electricity>=10, 'battery-orange' : key.data_parsed.electricity<10}">
                                            <div class="key-battery-text">{{'CONTENT.BATTERY' | translate}}
                                                {{key.data_parsed.electricity}} %
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / KEYS WIDGET -->

                    </div>

                </div>

            </mat-tab>

        </mat-tab-group>

    </div>
    <!-- / CONTENT -->

</div>
<!-- / END -->

<ng-template #WantToDeleteAccessById>
    <h2 matDialogTitle>{{'POPUP.DELETE_ACCESS_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'POPUP.NO' | translate}}</button>
            <button mat-button matDialogClose
                (click)="deleteAccessById('deleteOtherUserAccess')">{{'POPUP.YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #WantToDeleteOwnAccess>
    <h2 matDialogTitle>{{'POPUP.DELETE_OWN_ACCESS_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose
                (click)="setDeleteOwnAccessButtonClickedToFalse()">{{'POPUP.NO' | translate}}</button>
            <button mat-button matDialogClose
                (click)="deleteAccessById('deleteOwnAccess')">{{'POPUP.YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #ShareGuestAccess>
    <h2 matDialogTitle>{{'POPUP.CHOOSE_A_LANGUAGE' | translate}}</h2>
    <mat-dialog-actions>
        <div class="share-guest-access">
            <div fxLayout="column" fxLayoutAlign="space-between">
                <a href="mailto:?body={{ShareGuestAccessMessage}}" (click)="getShareGuestAccessMessage('en')" mat-button
                    matDialogClose>{{'POPUP.ENGLISH' | translate}}</a>
                <a href="mailto:?body={{ShareGuestAccessMessage}}" (click)="getShareGuestAccessMessage('fr')" mat-button
                    matDialogClose>{{'POPUP.FRENCH' | translate}}</a>
                <a href="mailto:?body={{ShareGuestAccessMessage}}" (click)="getShareGuestAccessMessage('es')" mat-button
                    matDialogClose>{{'POPUP.SPANISH' | translate}}</a>
            </div>
            <button mat-button color="warn" class="cancel-button" matDialogClose>{{'POPUP.CANCEL' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>