<div id="profile" class="page-layout simple" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160">

        <div fxLayout="row" class="img-and-name-container">
            <div class="img-container">
                <img class="profile-img" src={{userPhotoUrl}}>
                <div class="overlay" (click)="ProfilePhotoInput.click()">
                    <input style="display: none" type="file" accept="image/*" (change)="processFile(ProfilePhotoInput)"
                        #ProfilePhotoInput>
                    <mat-icon class="upload-photo">add_a_photo</mat-icon>
                </div>
            </div>
            <div *ngIf="profileData != undefined" class="mat-display-1 my-0 my-sm-24 user-name"
                [@animate]="{value:'*',params:{x:'50px'}}">
                {{profileData.user[0].first_name}} {{profileData.user[0].last_name}}
            </div>
        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div *ngIf="profileData != undefined" class="content" fxLayout="column">

        <div class="information-container mt-30" fxLayout="column">
            <div class="title h2 p-12">
                {{'GENERAL_INFORMATION' | translate}}
            </div>
            <div class="general-information" fxLayout="row" fxLayout.md="column" fxLayout.sm="column"
                fxLayout.xs="column">

                <div class="general-information-element" fxLayout="column">
                    <div class="general-information-element-title h3">{{'FIRST_NAME' | translate}} -
                        {{'LAST_NAME' | translate}}</div>
                    <div class="general-information-element-content">{{profileData.user[0].first_name}}
                        {{profileData.user[0].last_name}}</div>
                </div>

                <div class="general-information-element" fxLayout="column">
                    <div class="general-information-element-title h3">{{'PHONE_NUMBER' | translate}}</div>
                    <div class="general-information-element-content">{{profileData.user[0].phone}}</div>
                </div>

                <div class="general-information-element" fxLayout="column">
                    <div class="general-information-element-title h3">{{'EMAIL' | translate}}</div>
                    <div class="general-information-element-content">{{profileData.user[0].email}}</div>
                </div>

                <div class="general-information-element" fxLayout="column">
                    <div class="general-information-element-title h3">{{'PASSWORD' | translate}}</div>
                    <button mat-button color="accent"
                        (click)="onChangePasswordButtonClick()">{{'CHANGE_PASSWORD' | translate}}</button>
                </div>

                <button mat-raised-button color="accent"
                    (click)="onModifyButtonClick()">{{'MODIFY' | translate}}</button>

            </div>

        </div>

        <div class="information-container" fxLayout="column">
            <div class="title h2 p-12">
                {{'ACCESSES' | translate}}
            </div>
            <div *ngIf="accessListData != undefined && accessListData.access == undefined" class="no-access"
                fxLayout="row">
                <img class="img-no-access" src="assets/images/avatars/list-2.png">
                <div class="h2 text-no-access">{{'NO_ACCESS' | translate}}</div>
            </div>
            <div *ngIf="accessListData != undefined && accessListData.access != undefined" class="widget-group p-12"
                fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                <!-- ACCESS WIDGET -->
                <fuse-widget *ngFor="let access of accessListData.access" [@animate]="{value:'*',params:{y:'100%'}}"
                    class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <!-- Front -->
                    <div class="fuse-widget-front" style="cursor: pointer;" (click)="onAccessWidgetClick(access.id)">

                        <div fxLayout="row">

                            <div class="widget-img-container">
                                <img class="img-widget"
                                    src={{placePhotoService.getPlacePhotoURL(access.board.address.photo)}}>
                            </div>
                            <div class="widget-info-container" fxLayout="column">
                                <div class="grade">
                                    <span *ngIf="access.type_access.id == 1">{{'ADMINISTRATOR' | translate}}</span>
                                    <span *ngIf="access.type_access.id == 2">{{'MODERATOR' | translate}}</span>
                                    <span *ngIf="access.type_access.id == 3">{{'USER' | translate}}</span>
                                </div>
                                <div class="widget-information">
                                    <div>{{'LOCK' | translate}} {{access.board.name}}</div>
                                    <div class="mt-5p">{{access.board.address.address}}</div>
                                </div>

                                <button *ngIf="access.type_access.id == 2" mat-icon-button style="color: white;"
                                    class="fuse-widget-flip-button delete-button"
                                    (click)="openWantToDeleteOwnAccessDialog(access.board.name, access.id)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>

                        </div>

                    </div>
                    <!-- / Front -->

                </fuse-widget>
                <!-- / ACCESS WIDGET -->

            </div>
        </div>

    </div>
    <!-- / CONTENT -->

</div>
<!-- END -->

<ng-template #WantToDeleteOwnAccess>
    <h2 matDialogTitle>{{'DELETE_OWN_ACCESS_DIALOG' | translate}} {{selectedAccessBoardName}}{{'?' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose
                (click)="setDeleteOwnAccessButtonClickedToFalse()">{{'NO' | translate}}</button>
            <button mat-button matDialogClose (click)="deleteAccessById()">{{'YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #IncorrectFileType>
    <h2 matDialogTitle>{{'INCORRECT_FILE_TYPE_TITLE' | translate}}</h2>
    <p matDialogContent>{{'INCORRECT_FILE_TYPE_CONTENT' | translate}}</p>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%; margin-top: 24px;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'CANCEL' | translate}}</button>
            <button mat-button matDialogClose
                (click)="ProfilePhotoInput.click()">{{'CHOOSE_ANOTHER_FILE' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #FileTooBig>
    <h2 matDialogTitle>{{'FILE_TOO_BIG_TITLE' | translate}}</h2>
    <p matDialogContent>{{'FILE_TOO_BIG_CONTENT' | translate}}</p>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%; margin-top: 24px;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'CANCEL' | translate}}</button>
            <button mat-button matDialogClose
                (click)="ProfilePhotoInput.click()">{{'CHOOSE_ANOTHER_FILE' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>