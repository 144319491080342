import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FileInput } from 'ngx-material-file-input';
import { PlaceService } from './place.service';
import { ApiConfigData } from '../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class PlacePhotoService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  default_photo: string = "loft.jpg";
  apiUrl_1: string = this.api_endpoint + '/noauth/address/photo/';
  apiUrl_2: string = '/download';
  apiUpload: string = this.api_endpoint + '/api/address/photo/upload/';

  constructor(private httpClient: HttpClient) { }

  getPlacePhotoURL(placePhotoName: string): string {
    let name = placePhotoName || this.default_photo;
    return this.apiUrl_1 + name + this.apiUrl_2;
  }
  uploadPlacePhoto(idAddress: number, photo: FileInput) {
    const file = photo.files[0];
    const formData: FormData = new FormData();
    formData.append('photo', file, file.name);
    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });

    // this.placeService.places[this.placeService.getPlacesIndexById(idAddress)].photo

    return this.httpClient.post(
      this.apiUpload + idAddress + ".json?bearer=" + localStorage.getItem('token'),
      formData,
      {
        headers: headers
      }
    )
  }
}
