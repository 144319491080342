export const locale = {
    lang: 'fr',
    data: {
        'HEADER': {
            'TITLE_INVOICES': 'FACTURES'
        },
        'FEATURE_COMING_SOON': 'Fonctionnalité bientôt disponible !',
        'NO_INVOICE': 'Vous n\'avez aucune facture.',
        'INVOICE_NUMBER': 'Facture n°',
        'DATE': 'Date',
        'TOTAL_EXCL_VAT': 'Total HT',
        'TOTAL_INCL_VAT': 'Total TTC',
        'STATUS': 'Statut',
        'DOWNLOAD': 'Télécharger',
        'TOTAL_OF_INVOICES': 'Total des factures',
        'VALIDATED_ORDER': 'Commande validée',
        'IN_PAYMENT': 'En cours de paiement',
        'IN_THE_COURSE_OF_MANUFACTURE': 'En cours de fabrication',
        'IN_THE_COURSE_OF_DELIVERY': 'En cours de livraison',
        'WAITING': 'En attente',
        'DELIVERED': 'Livrée',
        'CANCELED': 'Annulée',
        'PARTIAL_DELIVERY': 'Livraison partielle',
        'MALFUNCTION_AFTER_SALE_SERVICE': 'Panne (SAV)',
        'WAITING_FOR_CUSTOMER_RETURN': 'En attente de retour client',
        'STANDARD_EXCHANGE': 'Échange standard',
        'REFUNDED': 'Remboursée',
        'TO_BE_REFUNDED': 'À rembourser',
        'PARCEL_RECEPTION_AFTER_SALES_SERVICE': 'Réception colis (SAV)',
        'IN_PREPARATION': 'En préparation',
        'IN_REPARATION': 'En réparation',
        'PAYMENT_VALIDATED': 'Paiement validé',
        'REPAIRS_COMPLETED': 'Réparations terminées',
        'CUSTOMER_RELATIONS': 'Relation client',
        'TYPE_OF_PAYMENT': 'Type de paiement',
        'SUBSCRIPTION': 'Abonnement',
        'VIA_ELOCKY_WEBSITE': 'Via le site Elocky',
        'BANK_TRANSFER': 'Virement',
        'DUTY_FREE_ORDER': 'Commade Hors Taxe',
        'CASH': 'Espèces',
        'CHEQUE': 'Chèques',
        'CREDIT_CARD': 'Carte bancaire',
        'MONEY_ORDER': 'Mandat',
        'OTHER': 'Autre',
    }
}