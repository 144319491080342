import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogData } from '../interfaces/LogData';
import { Observable } from 'rxjs';
import { AddressData } from '../interfaces/AddressData';
import { ExpiryLogsData } from '../interfaces/ExpiryLogsData';
import { ApiConfigData } from '../../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  AddressListApiUrl: string = '/api/address/list.json?bearer=';
  LogsApiUrl: string = '/api/log/all/';
  DeleteLogByIdUrl: string = '/api/log/delete/';
  DeleteAllLogsUrl: string = '/api/log/deleteAll/';
  EditExpiryLogsApiUrl: string = '/api/log/expiry/';

  constructor
    (
      private httpClient: HttpClient
    ) {

  }

  getUserAddresses(): Observable<AddressData> {
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<AddressData>
      (
        this.api_endpoint + this.AddressListApiUrl + token
      );
  }

  getAddressLogs(placeId: number, start: number): Observable<LogData> {
    let token: string = localStorage.getItem('token');

    return this.httpClient.get<LogData>
      (
        this.api_endpoint + this.LogsApiUrl + placeId + '/' + start + '.json?bearer=' + token
      );
  }

  deleteLogById(logId: number) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.delete
      (
        this.api_endpoint + this.DeleteLogByIdUrl + logId + '.json?bearer=' + token
      );
  }

  deleteAllLogs(placeId: number) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.delete
      (
        this.api_endpoint + this.DeleteAllLogsUrl + placeId + '.json?bearer=' + token
      );
  }

  editExpiryLogs(addressId: number, expiryLogsData: ExpiryLogsData) {
    let token: string = localStorage.getItem('token');

    return this.httpClient.put
      (
        this.api_endpoint + this.EditExpiryLogsApiUrl + addressId + '.json?bearer=' + token, expiryLogsData
      );
  }
}
