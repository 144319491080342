import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';

import { InvoicesData } from '../interfaces/InvoicesData';

import { fuseAnimations } from '@fuse/animations';
import { InvoicesService } from '../services/invoices.service';
import { AccessService } from '../../access/services/access.service';
import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'invoice-list',
    templateUrl: './invoice-list.component.html',
    styleUrls: ['./invoice-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class InvoiceListComponent implements OnInit {

    invoices: InvoicesData;
    displayedColumns = ['id', 'date', 'price_excl_VAT', 'price_incl_VAT', 'type_of_payment', 'status', 'download'];
    dataSource = new MatTableDataSource;
    isLoading: boolean = true;

    vat_value: number = 0.2;

    hourFormat: string;

    @ViewChild('FeatureComingSoon') FeatureComingSoon: TemplateRef<any>;

    constructor(
        private invoicesService: InvoicesService,
        private accessService: AccessService,
        private translateService: TranslateService,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService
    ) {
        this.hourFormat = this.accessService.getHourFormat(this.translateService.currentLang);
    }

    ngOnInit(): void {
        this.spinner.show();

        this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            this.hourFormat = this.accessService.getHourFormat(langChangeEvent.lang);
        });

        this.invoicesService.getInvoices()
            .subscribe(
                (response) => {
                    this.invoices = response;
                    for (let i = 0; i < this.invoices.invoice.length; i++) {
                        this.invoices.invoice[i].amount_excl_vat = parseFloat((this.invoices.invoice[i].amount - this.vat_value * this.invoices.invoice[i].amount).toFixed(2));
                        this.invoices.invoice[i].id_transformed = this.getInvoiceId(this.invoices.invoice[i].id, this.invoices.invoice[i].created);
                    }
                    this.dataSource = new MatTableDataSource(this.invoices.invoice);
                    this.isLoading = false;
                    this.spinner.hide();
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                    this.isLoading = false;
                    this.spinner.hide();
                }
            );
    }

    getInvoiceId(id: number, invoiceDate: string) { // Bricolage
        let invoiceDateTransformed: string[];
        invoiceDateTransformed = (invoiceDate.slice(0, 10)).split("-");
        return invoiceDateTransformed[0] + invoiceDateTransformed[1] + invoiceDateTransformed[2] + "_" + id;
    }

    getTotalCost(typeOfCost: string) {
        if (typeOfCost == 'incl_vat') {
            return parseFloat((this.invoices.invoice.map(t => t.amount).reduce((acc, value) => acc + value, 0)).toFixed(2));
        } else if (typeOfCost == 'excl_vat') {
            return parseFloat((this.invoices.invoice.map(t => t.amount_excl_vat).reduce((acc, value) => acc + value, 0)).toFixed(2));
        }
    }

    onDownloadButtonClick() {
        this.dialog.open(this.FeatureComingSoon);
    }
}

