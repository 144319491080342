import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeGetAccessList = new EventEmitter();

  subsVarGetAccessList: Subscription;

  constructor() { }

  onAddAccessRecuOrExep() {
    this.invokeGetAccessList.emit(); // invoque getAccessList de AccessManagementComponent
  }
}