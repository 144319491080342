import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ProfileService } from '../../../../core/ProfileService/profile.service';

import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from '../../../authentication/i18n/en';
import { locale as french } from '../../../authentication/i18n/fr';
import { locale as spanish } from '../../../authentication/i18n/es';

import { HttpErrorResponse } from '@angular/common/http';
import { ChangePasswordData } from '../../interfaces/ChangePasswordData';

import { confirmPasswordValidator } from '../../../authentication/register/register.component';

@Component({
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordDialogComponent implements OnInit {

  isLoading: boolean;

  changePasswordForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,

    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private profileService: ProfileService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
  }

  ngOnInit(): void {
    this.changePasswordForm = this._formBuilder.group({
      old_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    let changePasswordData: ChangePasswordData;
    changePasswordData = {
      'old_password': form.value.old_password,
      'password': form.value.password
    }
    this.profileService.changePassword(changePasswordData)
      .subscribe(
        () => {
          this.isLoading = false;
          alert(this.translateService.instant('CHANGE_PASSWORD_SUCCESS'));
          this.close();
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          if(err.error == 'wrong password'){
            alert(this.translateService.instant('WRONG_OLD_PASSWORD'));
          }
        }
      );
  }

  close() {
    this.dialogRef.close();
  }

}