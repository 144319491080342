export const locale = {
    lang: 'fr',
    data: {
       'GENERAL_INFORMATION':'Informations générales',
	   'FIRST_NAME':'Prénom',
	   'LAST_NAME':'Nom',
	   'PHONE_NUMBER': 'Numéro de téléphone',
	   'EMAIL': 'Adresse Mail',
	   'MODIFY':'Modifier',
	   'ACCESSES':'Accès',
	   'ADMINISTRATOR': 'Administrateur',
       'MODERATOR': 'Modérateur',
       'USER': 'Utilisateur',
	   'LOCK':'Serrure :',
	   'DELETE_OWN_ACCESS_DIALOG': 'Êtes-vous sûr de vouloir supprimer votre propre accès à la serrure :',
	   'YES': 'OUI',
	   'NO': 'NON',
	   'NO_ACCESS':'Vous n\'avez aucun accès.',
	   '?': ' ?',
	   'INCORRECT_FILE_TYPE_TITLE':'Format de fichier incorrect',
	   'INCORRECT_FILE_TYPE_CONTENT':'Veuillez sélectionner un fichier image.',
	   'FILE_TOO_BIG_TITLE':'Fichier trop volumineux',
	   'FILE_TOO_BIG_CONTENT':'La taille de l\'image doit être inférieure à 10 Mo.',
	   'CANCEL':'Annuler',
	   'CHOOSE_ANOTHER_FILE':'Choisir un autre fichier',  
	   'PASSWORD': 'Mot de Passe',
	   'CHANGE_PASSWORD':'Changer le mot de passe',
    }
};