<div id="gestion-lieux" class="page-layout simple tabbed " fxLayout="column">
  <!-- HEADER -->
  <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="secondary-text s-18">home</mat-icon>
        <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
        <span class="secondary-text">{{'TITLE_PLACES' | translate}}</span>
      </div>
      <div class="h1 mt-16" [@animate]="{value:'*',params:{x:'50px'}}">{{'TITLE_PLACES' | translate}}</div>

      <div fxLayout="row" class="menu" fxLayoutAlign="space-between">
          <div fxLayout="row" class="places-menu" *ngIf="current_place != null">
              <div class="selected-place">
                  {{ current_place.name }}
              </div>
              <div class="selected-place with-name">
                  {{ current_place.address }}
              </div>

              <button mat-icon-button class="place-selector"
                      [matMenuTriggerFor]="placesMenu"
                      aria-label="Select place">
                  <mat-icon>expand_more</mat-icon>
              </button>

              <mat-menu #placesMenu="matMenu">
                  <a [routerLink]="['../', place.id]"
                      mat-menu-item
                      *ngFor="let place of allPlacesAccessible">
                      <span>{{ place.name }}</span>
                  </a>
              </mat-menu>

          </div>
      </div>
    </div>
  </div>


  <div class="content">
    <mat-tab-group dynamicHeight>
      <mat-tab label="{{'TITLE_INFORMATIONS' | translate}}">
        <information-lieux
          *ngIf="current_place != null"
          [obsCurrent$]="obsCurrent$" >
        </information-lieux>
      </mat-tab>
      <mat-tab label="{{'TITLE_USER' | translate}}">
        <user-lieux
          *ngIf="current_place != null"
          [obsCurrent$]="obsCurrent$" >
        </user-lieux>
      </mat-tab>
      <!-- <mat-tab label="IFTT" >
        <iftt-lieux >
        </iftt-lieux>
      </mat-tab> -->
      <mat-tab label="{{'TITLE_ALARM' | translate}}">
        <alarm-lieux
          *ngIf="current_place != null"
          [obsCurrent$]="obsCurrent$" >
        </alarm-lieux>
      </mat-tab>
      <mat-tab label="{{'TITLE_CHECK' | translate}}">
        <check-in-out-lieux
          *ngIf="current_place != null"
          [obsCurrent$]="obsCurrent$" >
        </check-in-out-lieux>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
