export const locale = {
    lang: 'es',
    data: {
        'THE_MANAGEMENT_WEBSITE': 'El sitio de gestión',
        'LOGIN_TO_YOUR_ACCOUNT': 'INICIAR SESIÓN',
        'EMAIL': 'Dirección de e-mail',
        'EMAIL_IS_REQUIRED': 'La dirección de e-mail es necesaria',
        'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS': 'Por favor, introduzca una dirección de e-mail válida',
        'PASSWORD': 'Contraseña',
        'PASSWORD_IS_REQUIRED': 'La contraseña es necesaria',
        'PASSWORD_CONFIRM': 'Contraseña (Confirmación)',
        'PASSWORD_CONFIRMATION_IS_REQUIRED': 'La confirmación de la contraseña es necesaria',
        'PASSWORDS_MUST_MATCH': 'Las contraseñas deben ser idénticas',
        'REMEMBER_ME': 'Recuérdame',
        'FORGOT_PASSWORD': '¿Has olvidado la contraseña?',
        'LOGIN_UPPERCASE': 'INICIAR SESIÓN',
        'LOGIN_LOWERCASE': 'Iniciar sesión',
        'OR': 'O',
        'LOGIN_WITH_GOOGLE': 'Connectarse con Google',
        'LOGIN_WITH_FACEBOOK': 'Connectarse con Facebook',
        'DONT_HAVE_AN_ACCOUNT': '¿No tienes una cuenta?',
        'CREATE_AN_ACCOUNT_LOWERCASE': 'Crear una cuenta',
        'CREATE_AN_ACCOUNT_UPPERCASE': 'CREAR UNA CUENTA',
        'LAST_NAME': 'Apellido',
        'FIRST_NAME': 'Nombre',
        'LAST_NAME_IS_REQUIRED': 'El apellido es necesario',
        'FIRST_NAME_IS_REQUIRED': 'El nombre es necesario',
        'ACCEPT': 'Aceptar',
        'TERMS_AND_CONDITIONS': 'las condiciones de venta',
        'ALREADY_HAVE_AN_ACCOUNT': '¿Ya tienes una cuenta?',
        'PHONE_NUMBER': 'Número de teléfono',
        'PHONE_NUMBER_IS_REQUIRED': 'El número de teléfono es necesario',
        'PLEASE_ENTER_A_VALID_PHONE_NUMBER': 'Por favor, introduzca un número de teléfono válido',
        'RECOVER_YOUR_PASSWORD': 'RECUPERE SU CONTRASEÑA',
        'SEND_RESET_LINK': 'ENVIAR ENLACE DE REINICIO',
        'GO_BACK_TO_LOGIN': 'Volver al inicio de sesión',
        'INVALID_PHONE_NUMBER_1': 'Número de teléfono inválido',
        'INVALID_PHONE_NUMBER_2': 'El número de teléfono que introdujo es inválido.',
        'EXPLANATION_INVALID_PHONE_NUMBER': 'Por favor, seleccione su país en la lista (haga clic en la bandera), y luego, introduzca su número de teléfono.',
        'REGISTRATION_COMPLETED_SUCCESSFULLY_TITLE': '¡Registro completado con éxito!',
        'REGISTRATION_COMPLETED_SUCCESSFULLY_CONTENT': 'Le hemos enviado un correo electrónico de confirmación a la dirección de correo electrónico ',
        'EMAIL_ALREADY_EXISTS_TITLE': 'El correo electrónico ya existe',
        'EMAIL_ALREADY_EXISTS_CONTENT_1': 'La dirección de correo electrónico ',
        'EMAIL_ALREADY_EXISTS_CONTENT_2': ' ya está asociada a una cuenta.',
        'TRY_LATER_TITLE': 'Ha ocurrido un error',
        'TRY_LATER_CONTENT': 'Ha ocurrido un error. Por favor, inténtelo más tarde.',
        'RESET_YOUR_PASSWORD_TITLE': 'Restablece su contraseña',
        'RESET_YOUR_PASSWORD_CONTENT': 'Le hemos enviado un correo electrónico para restablecer su contraseña a la dirección de correo electrónico ',
        'EMAIL_ERROR_TITLE': 'Error',
        'EMAIL_ERROR_CONTENT_1': 'La dirección de correo electrónico ',
        'EMAIL_ERROR_CONTENT_2': ' no está asociada a ninguna cuenta.',
        'BAD_CREDENTIALS_TITLE': 'Error de inicio de sesión',
        'BAD_CREDENTIALS_CONTENT': 'La dirección de correo electrónico o la contraseña que ha introducido es incorrecta.',
        'BAD_CREDENTIALS_LOCK_SCREEN_CONTENT': 'La contraseña que ha introducido es incorrecta.',
        'YOUR_SESSION_IS_LOCKED': 'SU SESIÓN ESTÁ CERRADA',
        'YOUR_SESSION_IS_LOCKED_EXPLANATION': 'Debido a la inactividad, su sesión está bloqueada. Ingrese su contraseña para continuar.',
        'UNLOCK': 'DESBLOQUEAR',
        'ARE_YOU_NOT': '¿No es usted',
        '?': '?',
        'SAVE': 'Guardar',
        'NO_CHANGES': 'No se han hecho cambios.',
        'PROFILE': 'Perfil',
        'CHANGE_PASSWORD': 'Cambiar la contraseña',
        'OLD_PASSWORD': 'Contraseña antigua',
        'OLD_PASSWORD_IS_REQUIRED': 'La contraseña antigua es necesaria',
        'NEW_PASSWORD': 'Nueva contraseña',
        'NEW_PASSWORD_IS_REQUIRED': 'La nueva contraseña es necesaria',
        'NEW_PASSWORD_CONFIRM': 'Nueva contraseña (Confirmación)',
        'CHANGE_PASSWORD_SUCCESS': 'Modificación guardada con éxito.',
        'WRONG_OLD_PASSWORD': 'La contraseña antigua es incorrecta.',
    }
};