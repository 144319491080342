<div id="invoices" class="page-layout simple" fxLayout="column">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="space-between">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">{{'HEADER.TITLE_INVOICES' | translate}}</span>
            </div>
            <div class="h1 mt-16" [@animate]="{value:'*',params:{x:'50px'}}">{{'HEADER.TITLE_INVOICES' | translate}}
            </div>

        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">
        <invoice-list></invoice-list>
    </div>
    <!-- / CONTENT -->


</div>
<!-- END -->