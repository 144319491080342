import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { ScrollEventModule } from 'ngx-scroll-event';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MglTimelineModule } from 'angular-mgl-timeline';

import { FuseSharedModule } from '@fuse/shared.module';

import { TimelineComponent } from './timeline.component';
import { EditExpiryLogsComponent } from './dialogs/edit-expiry-logs/edit-expiry-logs.component';

const routes = [
    {
        path: 'timeline',
        component: TimelineComponent
    }
];

@NgModule({
    declarations: [
        TimelineComponent,
        EditExpiryLogsComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,

        ScrollEventModule,
        NgxSpinnerModule,
        MatDialogModule,

        BrowserAnimationsModule,
        MglTimelineModule,

        FuseSharedModule
    ],
    exports: [
        TimelineComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class TimelineModule {
}
