
  <form id="formAddPlace" [formGroup]="placeForm" (ngSubmit)="OnSendForm()">
    <h2 mat-dialog-title>{{'TITLE_CREATE_PLACE' | translate}}</h2>
    <div mat-dialog-content class="form-add-place">
      <div class="w100">
        <mat-form-field class="w100">
          <mat-label>{{'CREATE_PLACE_SEARCH' | translate}}</mat-label>
          <input  matInput
                  matGoogleMapsAutocomplete
                  (onAutocompleteSelected)="onAutocompleteSelected($event)"
                  (onLocationSelected)="onLocationSelected($event)">
        </mat-form-field>
      </div>
      <div class="w50">
        <mat-form-field>
          <mat-label>{{'INFORMATIONS_PLACES_NAME' | translate}}</mat-label>
          <input formControlName="name" matInput>
        </mat-form-field>
      </div>
      <div class="w50">
        <mat-form-field>
          <mat-label>{{'INFORMATIONS_PLACES_TIMEZONE' | translate}}</mat-label>
          <mat-select formControlName="timezone" required>
            <mat-option *ngFor="let zone of timezone" [value]="zone.name">
              {{ zone.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="error-text" [ngClass]="{'is-invalid': submitted && f.timezone.invalid}">{{'CREATE_PLACE_TIMEZONE_REQUIRED' | translate}}</div>
      </div>
      <div class="w100">

        <mat-form-field>
          <mat-label>{{'INFORMATIONS_PLACES_ADDRESS' | translate}}</mat-label>
          <input formControlName="address" required matInput>
        </mat-form-field>
        <div class="error-text" [ngClass]="{'is-invalid': submitted && f.address.invalid}">{{'CREATE_PLACE_ADDRESS_REQUIRED' | translate}}</div>
      </div>
      <div class="w100">
        <mat-form-field>
          <mat-label>{{'INFORMATIONS_PLACES_COMPLEMENT' | translate}}</mat-label>
          <input formControlName="complement" matInput>
        </mat-form-field>
      </div>
      <div class="w33">

        <mat-form-field>
          <mat-label>{{'INFORMATIONS_PLACES_CITY' | translate}}</mat-label>
          <input formControlName="city" required matInput>
        </mat-form-field>
        <div class="error-text" [ngClass]="{'is-invalid': submitted && f.city.invalid}">{{'CREATE_PLACE_CITY_REQUIRED' | translate}}</div>
      </div>
      <div class="w33">

        <mat-form-field>
          <mat-label>{{'INFORMATIONS_PLACES_COUNTRY' | translate}}</mat-label>
          <mat-select formControlName="country" required>
            <mat-option *ngFor="let country of country" [value]="country.name">
              {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="error-text" [ngClass]="{'is-invalid': submitted && f.country.invalid}">{{'CREATE_PLACE_COUNTRY_REQUIRED' | translate}}</div>
      </div>
      <div class="w33">

        <mat-form-field >
          <mat-label>{{'INFORMATIONS_PLACES_ZIP' | translate}}</mat-label>
          <input formControlName="zipcode" required matInput>
        </mat-form-field>
        <div class="error-text" [ngClass]="{'is-invalid': submitted && f.zipcode.invalid}">{{'CREATE_PLACE_ZIP_REQUIRED' | translate}}</div>
      </div>
      <input type="hidden" formControlName="latitude">
      <input type="hidden" formControlName="longitude">
    </div>
    <div mat-dialog-actions class="buttons">
      <button mat-button type="reset" (click)="onCloseDialog()">{{'CANCEL_BUTTON' | translate}}</button>
      <button mat-raised-button type="submit" color="primary">{{'SAVE_BUTTON' | translate}}</button>
    </div>
  </form>
