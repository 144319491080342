import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { CoreModule } from 'app/core/core.module';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { TimelineModule } from './main/timeline/timeline.module';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { AccessModule } from './main/access/access.module';
import { AccessManagementModule } from './main/accessManagement/access-management.module';
import { PlacesModule } from './main/places/places.module';
import { ProfileModule } from './main/profile/profile.module';

import { PlacePhotoService } from 'app/core/PlacesService/place-photo.service';
import { PlaceService } from 'app/core/PlacesService/place.service';
import { ProfileService } from 'app/core/ProfileService/profile.service';
import { AuthentificationService } from 'app/core/AuthService/authentification.service';
import { SnackbarService } from 'app/core/SnackbarService/snackbar.service';
import { InvoicesModule } from './main/invoices/invoices.module';


const appRoutes: Routes = [
    {
        path        : 'auth',
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.PagesModule)
    },
    {
        path      : '**',
        redirectTo: 'dashboard'
    },
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Google maps Autocomplete
        AgmCoreModule.forRoot({
          apiKey:'AIzaSyDFLPCGnnEgTn3Yx6rkkhkaH0CWCFLpe7Y',
          libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        CoreModule.forRoot(),
        LayoutModule,
        DashboardModule,
        TimelineModule,
        AccessModule,
        AccessManagementModule,
        PlacesModule,
        ProfileModule,
        InvoicesModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
      PlacePhotoService,
      PlaceService,
      ProfileService,
      AuthentificationService,
      SnackbarService
    ]
})
export class AppModule
{
}
