import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';

import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

import { QuickPanelCountdownService} from './quick-panel-countdown-service.service';

import { Subscription } from 'rxjs';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { DateTranslationService } from '../../../core/DateTranslationService/date-translation.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { locale as spanish } from './i18n/es';

import { Router } from '@angular/router';
 
const CountdownTimeUnits: Array<[string, number]> = [
    ['Y', 1000 * 60 * 60 * 24 * 365], // years
    ['M', 1000 * 60 * 60 * 24 * 30], // months
    ['D', 1000 * 60 * 60 * 24], // days
    ['H', 1000 * 60 * 60], // hours
    ['m', 1000 * 60], // minutes
    ['s', 1000], // seconds
    ['S', 1], // million seconds
  ];

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit
{
    @ViewChild('countdown', { static: false }) private counter: CountdownComponent;

    tokenExpirationDate: number;
    tokenCreationDate: number;
    config: CountdownConfig;

    clickEventsubscription:Subscription;

    date = Date.now();
    events: any[];
    notes: any[];
    settings: any;
    language: string;

    /**
     * Constructor
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor
    (
        private quickPanelCountdownService: QuickPanelCountdownService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private dateTranslationService: DateTranslationService,
        private translateService: TranslateService,
        private router: Router
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
        
        // Set the defaults
        this.date = Date.now();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
        this.clickEventsubscription=this.quickPanelCountdownService.getClickEvent().subscribe(()=>{
            this.setTokenValidityCountdown();
            })
    }
    
    ngOnInit(): void{
        if(localStorage.getItem('isAuthenticated') == 'true'){
            this.setTokenValidityCountdown();
        }

        this.language = this.dateTranslationService.setDateLanguage(this.translateService.currentLang);

        this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            this.language = this.dateTranslationService.setDateLanguage(langChangeEvent.lang);
        });
    }

    countdownFinished(event: any){
      if(localStorage.getItem('isAuthenticated') == 'true'){
        if(event.status == 3 || event.left <=0){   // as soon as the countdown is done (status = 3 or left = 0), we redirect the user to the lock screen
          this.router.navigate(['auth/lock']);
        }
      }
    }

    setTokenValidityCountdown(){

        this.tokenExpirationDate = parseInt(localStorage.getItem('token_expiration_date'));
        this.config = {

        leftTime: (this.tokenExpirationDate - Math.floor(Date.now()/1000)), 

        // format date is from https://github.com/cipchk/ngx-countdown/blob/master/src/app/components/demo.component.ts

        formatDate: ({ date, formatStr }) => {
          let duration = Number(date || 0);
          return CountdownTimeUnits.reduce((current, [name, unit]) => {
            if (current.indexOf(name) !== -1) {
              const v = Math.floor(duration / unit);
              duration -= v * unit;
              return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
                return v.toString().padStart(match.length, '0');
              });
            }
            return current;
          }, formatStr);
        },
        }  
    } 
}
