export const locale = {
    lang: 'en',
    data: {
        'HEADER': {
            'HELLO': 'Welcome back, ',
            '!': '!',
            'NO_PLACE': 'No place is associated with your account.',
            'ALARM': 'Alarm'
        },
        'CONTENT': {
            'HOME': 'Home',
            'BEACON': 'Beacon',
            'GATEWAY': 'Gateway',
            'IP_ADDRESS': 'IP Address:',
            'UNKNOWN':'unknown',
            'CONNECTED_OBJECTS':'Connected objects:',
            'NO_GATEWAYS':'No gateway is associated with this place.',
            'NO_LOCKS': 'No lock is associated with this place.',
            'LOCKS_NO_GATEWAY':'No gateway',
            'SLEEP_MODE':'Sleep mode:',
            'NORMAL':'normal',
            'LIGHT':'light',
            'DEEP':'deep',
            'BATTERY':'Battery:',
            'NO_BEACONS':'No beacon is associated with this place.',
            'TEMPERATURE':'Temperature :'
        }
    }
};
