import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, RouterEvent, Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { PlaceService } from '../../../core/PlacesService/place.service';
import { PlaceData } from '../interfaces/PlaceData';

import { Subscription, BehaviorSubject, Subject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as spanish } from '../i18n/es';

@Component({
  selector: 'gestion-lieux',
  templateUrl: './gestion-lieux.component.html',
  styleUrls: ['./gestion-lieux.component.scss'],
  animations: fuseAnimations
})
export class GestionLieuxComponent implements OnInit {
  
  place_id:number;
  current_place:PlaceData = null;
  allPlacesAccessible:PlaceData[];
  subListPlaces:Subscription;
  destroyed = new Subject<any>();

  obsCurrent:BehaviorSubject<PlaceData> = new BehaviorSubject(null);
  obsCurrent$:Observable<PlaceData> = this.obsCurrent.asObservable();

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _fuseConfigService: FuseConfigService,
    private translateService: TranslateService,
    private placeService: PlaceService,
    private _Activatedroute:ActivatedRoute,
    private router: Router) {
      this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: false
          }
        }
      };
    }

    ngOnInit(): void {
      // Permet de recharger le component lorsque l'on recharge la page :
      // par exemple lorsque qu'on change de lieux via le header, angular estime qu'on ne change
      // pas de component, donc de base il ne refresh pas. Grace à ça, on le force à refresh
      this.router.events.pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroyed)
      ).subscribe(() => {
        this.fetchData();
      }
    )
    this.fetchData()
  }

  fetchData() : void {
    this.place_id = parseInt(this._Activatedroute.snapshot.paramMap.get("id"));
    this.subListPlaces = this.placeService.getListPlaces().subscribe(
      places => {
        this.allPlacesAccessible = new Array<PlaceData>();
        if(places != null) {
          places.forEach(element => {
            if(element.id == this.place_id){
              this.current_place = element;
              this.obsCurrent.next(this.current_place);
            }
            if(this.placeService.isUserHadRightByState(element.state)){
              this.allPlacesAccessible.push(element);
            }
          });
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subListPlaces.unsubscribe();
  }

}
