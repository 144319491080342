export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'THEME_OPTIONS': 'Theme Options',
            'COLOR_THEMES': 'Color Themes',
            'LAYOUT_STYLES': 'Layout Styles',
            'LAYOUT_WIDTH': 'Layout Width',
            'NAVBAR': 'Navbar',
            'TOOLBAR': 'Toolbar',
            'FOOTER': 'Footer',
            'SIDE_PANEL': 'Side Panel',
            'CUSTOM_SCROLLBARS': 'Custom scrollbars'
        },
        'PARAMETER':{
            'POSITION': 'Position:',
            'VARIANT': 'Variant:',
            'VARIANT_VERTICAL': 'Variant (Vertical):',
            'PRIMARY_BACKGROUND': 'Primary background:',
            'SECONDARY_BACKGROUND': 'Secondary background:',
            'SECONDARY_BACKGROUND_VERTICAL':'Secondary background (Vertical):',
            'BACKGROUND_COLOR': 'Background color:',
            'COLOR': 'Color:'
        },
        'OPTION':{
            'ELOCKY_LIGHT': 'Elocky Light',
            'ELOCKY_DARK': 'Elocky Dark',
            'VERTICAL_LAYOUT_1': 'Vertical Layout #1',
            'VERTICAL_LAYOUT_2': 'Vertical Layout #2',
            'VERTICAL_LAYOUT_3': 'Vertical Layout #3',
            'HORIZONTAL_LAYOUT_1': 'Horizontal Layout #1',
            'FULLWIDTH': 'Fullwidth',
            'BOXED': 'Boxed',
            'HIDE': 'Hide',
            'FOLDED': 'Folded',
            'LEFT': 'Left',
            'RIGHT': 'Right',
            'STYLE_1': 'Style 1',
            'STYLE_2': 'Style 2',
            'ABOVE': 'Above',
            'BELOW_STATIC': 'Below Static',
            'BELOW_FIXED': 'Below Fixed',
            'USE_CUSTOM_BACKGROUND_COLOR': 'Use custom background color',
            'ENABLE_CUSTOM_SCROLLBARS': 'Enable custom scrollbars',
        }
    }
};
