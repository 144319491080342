import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { MatRadioChange } from '@angular/material/radio';

import { PlaceDataAccess } from '../../interfaces/PlaceDataAccess';
import { BoardData } from '../../interfaces/BoardData';
import { NewAccess } from '../../interfaces/NewAccess';
import { AccessData } from '../../interfaces/AccessData';

import { HttpErrorResponse } from '@angular/common/http';
import { AccessService } from '../../services/access.service';
import { EventEmitterService } from '../../services/event-emitter.service';

import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

@Component({
  templateUrl: './add-access-dialog.component.html',
  styleUrls: ['./add-access-dialog.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AddAccessDialogComponent implements OnInit {

  addresses = new Array<PlaceDataAccess>();
  addressesAddAccess = new Array<PlaceDataAccess>();
  accessData: AccessData;
  selectedAddress: PlaceDataAccess;
  selectedBoard: BoardData;
  tabIndex: number;

  addAccessForm: FormGroup;

  accessType: string;

  isLoading: boolean = false;

  constructor
    (
      public dialogRef: MatDialogRef<AddAccessDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,

      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private translateService: TranslateService,
      private accessService: AccessService,
      private eventEmitterService: EventEmitterService,
      private _formBuilder: FormBuilder

    ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    this.addresses = data.addresses;
    this.selectedAddress = data.selectedAddress;
    this.accessData = data.accessData;
    this.selectedBoard = data.selectedBoard;
    this.tabIndex = data.tabIndex;
  }

  ngOnInit() {

    /** 
     * Remplissage de l'objet addressesAddAccess.
     * Pour mettre en place le menu de sélection des lieux et des serrures pour la popup "Ajout d'une serrure", 
     * on a besoin d'afficher : 
     * - les lieux de l'utilisateur comportant des serrures (les lieux sans serrures sont ignorés).
     * - sur chacun de ces lieux, les serrures auxquelles l'utilisateur a un accès administrateur ou modérateur (les autres serrures sont ignorées).
     * Pour cela, on parcourt l'objet addresses qui vient de AccessComponent et on lui prélève les lieux et serrures qui 
     * remplissent les critères présentés juste avant. 
    **/

    let addressIndex: number = 0;
    let boardIndex: number = 0;

    for (let i = 0; i < this.addresses.length; i++) {
      if (this.addresses[i].board[0] != null) { // On prend uniquement les lieux comportant des serrures
        this.addressesAddAccess[addressIndex] = {
          'address': this.addresses[i].address,
          'name': this.addresses[i].name,
          'id': this.addresses[i].id,
          'board': [null]
        }
        boardIndex = 0;
        for (let j = 0; j < this.addresses[i].board.length; j++) {
          if (this.addresses[i].board[j].board_access.type_access.id == 1 || this.addresses[i].board[j].board_access.type_access.id == 2) {
            // On prend uniquement les serrures auxquelles l'utilisateur est soit administrateur soit modérateur (type_access.id == 1 ou 2)
            this.addressesAddAccess[addressIndex].board[boardIndex] = {
              'id': this.addresses[i].board[j].id,
              'name': this.addresses[i].board[j].name,
              'type_board': {
                'id': this.addresses[i].board[j].type_board.id,
              },
              'board_access': {
                'id': this.addresses[i].board[j].board_access.id,
                'type_access': {
                  'id': this.addresses[i].board[j].board_access.type_access.id,
                }
              }
            }
            boardIndex += 1;
          }
        }
        addressIndex += 1;
      }
    }

    if (this.tabIndex == 0) { // tabIndex = 0 correspond à l'onglet "Utilisateurs" 
      this.accessType = "user";
    } else if (this.tabIndex == 1) { // tabIndex = 1 correspond à l'onglet "Invités" 
      this.accessType = "guest";
    }
    this.initializeAddAccessForm(this.accessType);
  }


  initializeAddAccessForm(accessType: string) {
    if (accessType == 'user') {
      this.addAccessForm = this._formBuilder.group({
        access_type: ['user'],
        access_grade: ['3'],
        email: ['', [Validators.required, Validators.email]],
        name: [null],
      });
    } else if (accessType == 'guest') {
      this.addAccessForm = this._formBuilder.group({
        access_type: ['guest'],
        name: [null],
      });
    }
  }

  onPlaceSelection(placeId: number) {
    this.selectedAddress = this.addressesAddAccess[this.accessService.getPlaceIndexFromPlaceId(placeId, this.addressesAddAccess)];
    this.selectedBoard = this.accessService.initializeBoard(this.selectedAddress).selectedBoard;

    // On crée un event qui va appeler onPlaceSelection de AccessComponent. Ainsi, quand l'utilisateur sélectionne un lieu dans la popup, 
    // le lieu est aussi sélectionné sur la page principale.
    this.eventEmitterService.onAddAccessDialogComponentPlaceSelection(placeId);
  }

  onBoardSelection(boardId: number) {
    this.selectedBoard = this.selectedAddress.board[this.accessService.getBoardIndexFromBoardId(boardId, this.selectedAddress)];

    // On crée un event qui va appeler onBoardSelection de AccessComponent. Ainsi, quand l'utilisateur sélectionne une serrure dans la popup, 
    // la serrure est aussi sélectionnée sur la page principale.
    this.eventEmitterService.onAddAccessDialogComponentBoardSelection(boardId);
  }

  onRadioButtonValueChange(event: MatRadioChange) {
    this.accessType = event.value;
    this.initializeAddAccessForm(event.value);

    // On crée un event qui va appeler changeTabIndex(selectedAccessType) de AccessComponent. Ainsi, quand l'utilisateur sélectionne "accès utilisateur" 
    // ou "accès invité" dans la popup, l'onglet actif change sur la page principale.
    this.eventEmitterService.onAddAccessDialogComponentRadioButtonValueChange(event.value);
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    let newAccess: NewAccess;
    if (this.accessType == 'user') {
      newAccess = {
        'board': this.selectedBoard.id,
        'nameAccess': form.value.name,
        'referenceInvite': form.value.email,
        'typeAccess': parseInt(form.value.access_grade)
      };
    } else if (this.accessType == 'guest') {
      newAccess = {
        'board': this.selectedBoard.id,
        'nameAccess': form.value.name,
        'referenceInvite': 'invite',
        'typeAccess': 4
      };
    }
    this.accessService.addAccess(newAccess)
      .subscribe(
        () => {
          this.isLoading = false;
          this.close();
          // On invoque la fonction getAccessList() de AccessComponent pour voir apparaître le nouvel accès
          this.eventEmitterService.onAddAccessDialogComponentAddAccess();
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          if (err.error.errors[0] == "Error") {// Si l'addresse mail déjà existante (l'utilisateur a déjà un accès)
            alert(this.translateService.instant('POPUP.USER_ALREADY_HAVE_ACCESS_1') + form.value.email + this.translateService.instant('POPUP.USER_ALREADY_HAVE_ACCESS_2'));

          } else if (err.error.errors[0] == "user not exist") {// Si l'addresse mail n'est associée à aucun compte
            alert(this.translateService.instant('POPUP.USER_NOT_EXIST_1') + form.value.email + this.translateService.instant('POPUP.USER_NOT_EXIST_2'));
          }
        }
      );
  }

  close() {
    this.dialogRef.close();
  }

}
