import { FuseNavigation } from '@fuse/types';

//To custom buttons on the vertical navigation menu on the left, make changes here  

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'home',
                title    : 'Home',
                translate: 'NAV.HOME.TITLE',
                type     : 'item',
                icon     : 'home',
                url      : '/dashboard',
            },
            {
                id       : 'notifications',
                title    : 'Notifications',
                translate: 'NAV.NOTIFICATIONS.TITLE',
                type     : 'item',
                icon     : 'notifications',
                url      : '/timeline',
            },
            {
                id       : 'places',
                title    : 'Places',
                translate: 'NAV.PLACES.TITLE',
                type     : 'item',
                icon     : 'location_on',
                url      : '/places',
            },
            {
                id       : 'access',
                title    : 'Access',
                translate: 'NAV.ACCESS.TITLE',
                type     : 'item',
                icon     : 'vpn_key',
                url      : '/access',
            },
            {
                id       : 'invoices',
                title    : 'Invoices',
                translate: 'NAV.INVOICES.TITLE',
                type     : 'item',
                icon     : 'description',
                url      : '/invoices',
            }
        ]
    }
];
