export const locale = {
    lang: 'fr',
    data: {
        'HEADER': {
            'HELLO': 'Re-bonjour, ',
            '!': ' !',
            'NO_PLACE': 'Aucun lieu n\'est associé à votre compte.',
            'ALARM': 'Alarme'
        },
        'CONTENT': {
            'HOME': 'Accueil',
            'BEACON': 'Beacon',
            'GATEWAY': 'Passerelle',
            'IP_ADDRESS': 'Adresse IP :',
            'UNKNOWN':'inconnue',
            'CONNECTED_OBJECTS':'Objets connectés :',
            'NO_GATEWAYS':'Aucune passerelle n\'est associée à ce lieu.',
            'NO_LOCKS': 'Aucune serrure n\'est associée à ce lieu.',
            'LOCKS_NO_GATEWAY':'Aucune passerelle',
            'SLEEP_MODE':'Mode de veille :',
            'NORMAL':'normal',
            'LIGHT':'léger',
            'DEEP':'profond',
            'BATTERY':'Batterie :',
            'NO_BEACONS':'Aucun beacon n\'est associé à ce lieu.',
            'TEMPERATURE':'Température :'
        }
    }
};
