<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 matDialogTitle>{{'POPUP.COPY_ACCESS' | translate }}</h2>
    <button class="closeDialogButton" (click)="close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">

    <form [formGroup]="copyAccessForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>

        <div fxLayout="column">

            <mat-form-field appearance="outline">
                <mat-label>{{'POPUP.EMAIL' | translate}}</mat-label>
                <input matInput formControlName="email">
                <mat-icon matSuffix class="secondary-text">person_add</mat-icon>
                <mat-error *ngIf="copyAccessForm.get('email').hasError('required')">
                    {{'POPUP.EMAIL_IS_REQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="copyAccessForm.get('email').hasError('email')">
                    {{'POPUP.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate}}
                </mat-error>
            </mat-form-field>

            <!-- USER WIDGET -->
            <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                fxFlex.gt-xs="50" fxFlex.gt-md="25">

                <!-- Front -->
                <div class="fuse-widget-front">

                    <div class="access-to-copy">
                        <div class="text-access-to-copy">{{'POPUP.ACCESS_TO_COPY' | translate}}</div>
                    </div>

                    <div fxLayout="row">

                        <div class="img-container">
                            <img class="img-widget profile-img"
                                src="http://elocky-dev.develo.ovh/noauth/photo/{{selectedUserAccess.photo}}/download.json">
                        </div>

                        <div class="info-container" fxLayout="column" fxLayoutAlign="space-between">

                            <div class="access-widget-information" fxLayout="column">
                                <div class="h3">{{selectedUserAccess.firstName}} {{selectedUserAccess.lastName}}</div>
                                <div>
                                    <span
                                        *ngIf="selectedUserAccess.type_access.id == 1">{{'CONTENT.ADMINISTRATOR' | translate}}</span>
                                    <span
                                        *ngIf="selectedUserAccess.type_access.id == 2">{{'CONTENT.MODERATOR' | translate}}</span>
                                    <span *ngIf="selectedUserAccess.type_access.id == 3">{{'CONTENT.USER' | translate}}</span>
                                </div>
                            </div>

                            <div fxLayout="column" class="access-widget-information" style="font-size: 13px;">
                                <span>{{selectedUserAccess.board.address.address}}</span>
                                <span>{{selectedUserAccess.board.name}}</span>
                            </div>

                        </div>

                    </div>

                </div>
                <!-- / Front -->

            </fuse-widget>
            <!-- / USER WIDGET -->

            <button mat-raised-button type="submit" color="accent" class="submit-button copy-access-validate"
                aria-label="COPY ACCESS" [disabled]="copyAccessForm.invalid || isLoading">
                {{'POPUP.VALIDATE' | translate}}
            </button>

        </div>

    </form>

</mat-dialog-content>