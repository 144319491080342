import { Injectable } from '@angular/core';
import { ApiConfigData } from '../../../ApiConfigData';

@Injectable({
  providedIn: 'root'
})
export class UserPhotoService {

  api_endpoint: string = ApiConfigData.api_endpoint;

  apiUrl_1: string = this.api_endpoint + '/api/user/photo/';
  apiUrl_2: string = '/download.json?bearer=';

  apiUrl_1_NoAuth: string = this.api_endpoint + '/noauth/photo/';
  apiUrl_2_NoAuth: string = '/download.json';

  constructor
  (

  ) 
  {

  }

  getUserPhotoURL(){
    return this.apiUrl_1 + localStorage.getItem('photo') + this.apiUrl_2 + localStorage.getItem('token');
  }

  getNoAuthUserPhotoURL(){
    return this.apiUrl_1_NoAuth + localStorage.getItem('photo') + this.apiUrl_2_NoAuth;
  }

  getNoAuthUserPhotoURLWithParameter(photo: string){
    return this.apiUrl_1_NoAuth + photo + this.apiUrl_2_NoAuth;
  }
}