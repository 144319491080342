<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 matDialogTitle>{{'ACCESS_MANAGEMENT.ADD_A_ONE-TIME_ACCESS' | translate}}</h2>
    <button class="closeDialogButton" (click)="close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">

    <form [formGroup]="addAccessExepForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>

        <div fxLayout="column">

            <div fxLayout="row" class="add-access-form-field">
                <mat-radio-group formControlName="access_exep_type">
                    <mat-radio-button id="authorization" class="add-access-radio-button" value='1'>
                        {{'ACCESS_MANAGEMENT.AUTHORIZATION' | translate}}
                    </mat-radio-button>
                    <mat-radio-button id="prohibition" class="add-access-radio-button" value='0'>
                        {{'ACCESS_MANAGEMENT.PROHIBITION' | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="from-to-label h3">{{'ACCESS_MANAGEMENT.FROM' | translate}}</div>

            <div fxLayout="row">
                <mat-form-field appearance="outline">
                    <mat-label>{{'ACCESS_MANAGEMENT.START_DATE' | translate}}</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" [min]="minDate" [max]="maxDate"
                        formControlName="start_date" readonly>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                    <mat-error *ngIf="addAccessExepForm.get('start_date').hasError('required')">
                        {{'ACCESS_MANAGEMENT.START_DATE_IS_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="at-label h3">{{'SCHEDULES.AT' | translate}}</div>

                <mat-form-field appearance="outline">
                    <mat-label>{{'ACCESS_MANAGEMENT.START_TIME' | translate}}</mat-label>
                    <input *ngIf="hourFormat == '12'" matInput [ngxTimepicker]="start_time" [format]="12"
                        formControlName="start_time" readonly />
                    <input *ngIf="hourFormat == '24'" matInput [ngxTimepicker]="start_time" [format]="24"
                        formControlName="start_time" readonly />
                    <mat-icon matSuffix class="secondary-text">schedule</mat-icon>
                    <mat-error *ngIf="addAccessExepForm.get('start_time').hasError('required')">
                        {{'ACCESS_MANAGEMENT.START_TIME_IS_REQUIRED' | translate}}
                    </mat-error>
                    <ngx-material-timepicker #start_time [enableKeyboardInput]="true" [editableHintTmpl]="dialHint"
                        [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
                    </ngx-material-timepicker>
                </mat-form-field>
            </div>

            <div class="from-to-label h3">{{'ACCESS_MANAGEMENT.TO' | translate}}</div>

            <div fxLayout="row" class="add-access-form-field">
                <mat-form-field appearance="outline">
                    <mat-label>{{'ACCESS_MANAGEMENT.STOP_DATE' | translate}}</mat-label>
                    <input matInput [matDatepicker]="stopDatePicker" [min]="minDate" [max]="maxDate"
                        formControlName="stop_date" readonly>
                    <mat-datepicker-toggle matSuffix [for]="stopDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #stopDatePicker></mat-datepicker>
                    <mat-error *ngIf="addAccessExepForm.get('stop_date').hasError('required')">
                        {{'ACCESS_MANAGEMENT.STOP_DATE_IS_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="at-label h3">{{'SCHEDULES.AT' | translate}}</div>

                <mat-form-field appearance="outline">
                    <mat-label>{{'ACCESS_MANAGEMENT.STOP_TIME' | translate}}</mat-label>
                    <input *ngIf="hourFormat == '12'" matInput [ngxTimepicker]="stop_time" [format]="12"
                        formControlName="stop_time" readonly />
                    <input *ngIf="hourFormat == '24'" matInput [ngxTimepicker]="stop_time" [format]="24"
                        formControlName="stop_time" readonly />
                    <mat-icon matSuffix class="secondary-text">schedule</mat-icon>
                    <mat-error *ngIf="addAccessExepForm.get('stop_time').hasError('required')">
                        {{'ACCESS_MANAGEMENT.STOP_TIME_IS_REQUIRED' | translate}}
                    </mat-error>
                    <ngx-material-timepicker #stop_time [enableKeyboardInput]="true" [editableHintTmpl]="dialHint"
                        [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
                    </ngx-material-timepicker>
                </mat-form-field>
            </div>

            <button mat-raised-button type="submit" color="accent" class="submit-button" aria-label="CREATE ACCESS RECU"
                [disabled]="addAccessExepForm.invalid || isLoading">
                {{'POPUP.VALIDATE' | translate}}
            </button>

        </div>
    </form>

</mat-dialog-content>


<ng-template #dialHint>
    <p style="color: white;">{{'ACCESS_MANAGEMENT.TIME_HINT' | translate}}</p>
</ng-template>
<ng-template #cancelBtn>
    <button mat-raised-button style="margin-right: 10px;">{{'POPUP.CANCEL' | translate}}</button>
</ng-template>
<ng-template #confirmBtn>
    <button mat-raised-button>{{'POPUP.VALIDATE' | translate}}</button>
</ng-template>