import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig, googleConfig } from './Auth/auth-config';
import { AuthGuard } from './Auth/auth-guard.service';
import { AuthGuardWithForcedLogin } from './Auth/auth-guard-with-forced-login.service';
import { authModuleConfig } from './Auth/auth-module-config';
import { AuthService } from './Auth/auth.service';

export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    imports: [
        HttpClientModule,
        OAuthModule.forRoot(),
    ],
    providers: [
        AuthService,
        AuthGuard,
        AuthGuardWithForcedLogin
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                { provide: AuthConfig, useValue: authConfig },
                { provide: OAuthModuleConfig, useValue: authModuleConfig},
                { provide: OAuthStorage, useFactory: storageFactory }
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
        }
    }
}
