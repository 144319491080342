import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ExpiryLogsData } from '../../interfaces/ExpiryLogsData';
import { TimelineService } from '../../services/timeline.service';
import { HttpErrorResponse } from '@angular/common/http';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';

@Component({
  templateUrl: './edit-expiry-logs.component.html',
  styleUrls: ['./edit-expiry-logs.component.scss']
})
export class EditExpiryLogsComponent implements OnInit {

  expiryLogsForm: FormGroup;
  isLoading: boolean;
  addressId: number;

  constructor(
    public dialogRef: MatDialogRef<EditExpiryLogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private timelineService: TimelineService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    this.addressId = data.addressId;
  }

  ngOnInit(): void {
    this.expiryLogsForm = this._formBuilder.group({
      expiryDays: '1095'
    })
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    let expiryLogsData: ExpiryLogsData;
    expiryLogsData = {
      'expiryDays': parseInt(form.value.expiryDays)
    }
    this.timelineService.editExpiryLogs(this.addressId, expiryLogsData)
      .subscribe(
        () => {
          this.isLoading = false;
          this.close();
          alert(this.translateService.instant('EDIT_EXPIRY_LOGS.SUCCESS'));
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          console.log(err);
          alert(this.translateService.instant('ERROR'));
        }
      );
  }

  close() {
    this.dialogRef.close();
  }

}
