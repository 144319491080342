export const locale = {
	lang: 'en',
	data: {
		'TITLE_NOTIFICATIONS': 'NOTIFICATIONS',
		'NO_PLACE': 'No place is associated with your account.',
		':': ': ',
		'AT': 'at',
		'ERROR': 'An error occured, please try later.',
		'OPTIONS': 'Options',
		'DELETE_HISTORY': 'Delete history',
		'DELETE_LOG_DIALOG': 'Are you sure you want to delete this log?',
		'DELETE_HISTORY_DIALOG': 'Are you sure you want to delete all the history?',
		'YES': 'YES',
		'NO': 'NO',
		'CONFIGURATION': 'Configuration',
		'EDIT_EXPIRY_LOGS': {
			'TITLE': 'Delete history after',
			'VALIDATE': 'Validate',
			'CANCEL': 'Cancel',
			'SUCCESS': 'Change saved successfully.',
			'3_YEARS': '3 years',
			'1_YEAR': '1 year',
			'6_MONTHS': '6 months',
			'3_MONTHS': '3 months',
			'1_MONTH': '1 month',
			'1_WEEK': '1 week',
		},
		'LOGBOOK': {
			'NO_LOGS_AVAILABLE': 'No logs available',
			'UNKNOWN_LOG': 'Unknown log',
			'INTRUSION': 'Intrusion',
			'INTRUSION_LOCK': 'Movement detected - Lock',
			'INTRUSION_BEACON': 'Movement detected - Beacon',
			'ADD': 'Add',
			'ADD_BOX': 'Elocky Box added',
			'ADD_LOCK': 'Lock added',
			'ADD_BEACON': 'Beacon added',
			'ADD_ACCESS': 'Access added',
			'UPDATE': 'Update',
			'SOFTWARE_UPDATE': 'Software update',
			'LOCK_OPENED': 'Lock opened',
			'OPENING_FAILURE': 'Opening failure',
			'BADGE': 'Badge',
			'BADGE_OPENING': 'Badge opening',
			'UNAUTHORIZED_BADGE': 'Unauthorized badge',
			'PROHIBITED_BADGE': 'Prohibited badge',
			'UNREGISTERED_BADGE': 'Unregistered badge',
			'EMERGENCY_KEY': 'Emergency key',
			'REBOOT': 'Reboot',
			'RESET': 'Reset',
			'DECONFIGURATION': 'Deconfiguration',
			'CONFIGURATION': 'Configuration',
			'DEBUG': 'Debug',
			'ACCESS_NUMBER': 'Access number',
			'GUEST_ACCESS': 'Guest access',
			'SLEEP_MODE': {
				'SLEEP_MODE': 'Sleep mode',
				'NORMAL_SLEEP': 'Normal sleep',
				'LIGHT_SLEEP': 'Light sleep',
				'DEEP_SLEEP': 'Deep sleep',
				'NORMAL': 'normal',
				'LIGHT': 'light',
				'DEEP': 'deep',
			},
			'WAKEUP_MODE': {
				'WAKEUP_MODE': 'Wake-up mode',
				'SENSITIVE_WAKEUP': 'Sensitive wake-up',
				'NORMAL_WAKEUP': 'Normal wake-up',
				'HARD_WAKEUP': 'Hard wake-up',
				'SENSITIVE': 'sensitive',
				'NORMAL': 'normal',
				'HARD': 'hard',
			}
		}
	}
};