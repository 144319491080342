<div id="dashboard" class="page-layout simple right-sidebar" fxLayout="row">

    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>

        <!-- HEADER -->
        <div class="header accent p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayoutAlign="space-between start">

                <span class="mat-display-1 my-0 my-sm-24 welcome-message" [@animate]="{value:'*',params:{x:'50px'}}">
                    {{'HEADER.HELLO' | translate}}{{userName}}{{'HEADER.!' | translate}}
                </span>
                <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                    (click)="toggleSidebar('dashboard-right-sidebar-1')">
                    <mat-icon>menu</mat-icon>
                </button>

            </div>

            <div fxLayout="row">
                <div *ngIf="placesExist && selectedAddress.name != undefined" class="selected-place">
                    {{selectedAddress.name}}</div>

                <div *ngIf="placesExist && selectedAddress.name != undefined" class="selected-place-with-name">
                    {{selectedAddress.address}}</div>
                <div *ngIf="placesExist && selectedAddress.name == undefined" class="selected-place">
                    {{selectedAddress.address}}
                </div>
                <div *ngIf="!placesExist" class="selected-place">{{'HEADER.NO_PLACE' | translate}}</div>

                <button *ngIf="placesExist" mat-icon-button class="place-selector" [matMenuTriggerFor]="placesMenu"
                    aria-label="Select place">
                    <mat-icon>expand_more</mat-icon>
                </button>

                <button *ngIf="!placesExist" mat-icon-button class="place-selector-disabled"
                    [matMenuTriggerFor]="placesMenu" aria-label="Select place" [disabled]="!placesExist">
                    <mat-icon>expand_more</mat-icon>
                </button>

                <mat-menu #placesMenu="matMenu">
                    <button mat-menu-item *ngFor="let address of addresses ; let index = index"
                        (click)="onPlaceSelection(index)">
                        <span *ngIf="address.name != undefined">{{address.name}}</span>
                        <span *ngIf="address.name == undefined">{{address.address}}</span>
                    </button>
                </mat-menu>
                <div *ngIf="placesExist" fxLayout="column" fxLayoutAlign="space-between center" class="alarm">
                    <div class="h2 alarm-label">{{'HEADER.ALARM' | translate}}</div>
                    <button mat-icon-button class="alarm-button" (click)="editAlarmState()">
                        <ngx-spinner size="medium" color="white" bdColor="rgba(51,51,51,0)" type="ball-clip-rotate"
                            [fullScreen]="false"></ngx-spinner>
                        <mat-icon *ngIf="selectedPlaceAlarmState == 0" class="bell">notifications_off</mat-icon>
                        <mat-icon *ngIf="selectedPlaceAlarmState == 1" class="bell">notifications_active</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content">

            <mat-tab-group dynamicHeight>

                <mat-tab label="{{'CONTENT.HOME' | translate}}">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <div class="h2" *ngIf="locks[0] == undefined">{{'CONTENT.NO_LOCKS' | translate}}</div>

                        <!-- HOME WIDGET -->
                        <fuse-widget *ngFor="let lock of locks" [@animate]="{value:'*',params:{y:'100%'}}"
                            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="h3 serrure_name">{{lock.name}}</div>
                                    <img class="passerelle_img" *ngIf="lock.passerelle.name != null"
                                        src="assets/images/avatars/passerelle.png">
                                    <img class="passerelle_img" *ngIf="lock.passerelle.name == null"
                                        src="assets/images/avatars/no_connection.png">
                                </div>

                                <div *ngIf="lock.passerelle.name != null" class="passerelle_name">
                                    {{lock.passerelle.name}}
                                </div>
                                <div *ngIf="lock.passerelle.name == null" class="passerelle_name">
                                    {{'CONTENT.LOCKS_NO_GATEWAY' | translate}}
                                </div>

                                <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">

                                    <div class="molette_and_lock_container">
                                        <img width="25%" class="lock" src="assets/images/lock/lockClose.png">
                                        <img class="molette" src="assets/images/lock/cadena-ouvert.png">
                                    </div>
                                    <div *ngIf="lock.type_board.id == 3" class="h3 mode secondary-text font-weight-500">
                                        evy V2</div>
                                    <div *ngIf="lock.type_board.id == 1">
                                        <div *ngIf="lock.veille == undefined || lock.veille == 0"
                                            class="h3 mode secondary-text font-weight-500">
                                            {{'CONTENT.SLEEP_MODE' | translate}} {{'CONTENT.NORMAL' | translate}}
                                        </div>
                                        <div *ngIf="lock.veille == 1" class="h3 mode secondary-text font-weight-500">
                                            {{'CONTENT.SLEEP_MODE' | translate}} {{'CONTENT.LIGHT' | translate}}
                                        </div>
                                        <div *ngIf="lock.veille == 2" class="h3 mode secondary-text font-weight-500">
                                            {{'CONTENT.SLEEP_MODE' | translate}} {{'CONTENT.DEEP' | translate}}
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="lock.battery>=50" class="p-16 border-top widget-footer battery-blue"
                                    fxLayout="row" fxLayoutAlign="start center">
                                    <span class="h4 ml-8">
                                        {{'CONTENT.BATTERY' | translate}}
                                    </span>
                                    <span class="h4 ml-8">
                                        {{lock.battery}} %
                                    </span>
                                </div>
                                <div *ngIf="lock.battery<50 && lock.battery>=10"
                                    class="p-16 border-top widget-footer battery-purple" fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="h4 ml-8">
                                        {{'CONTENT.BATTERY' | translate}}
                                    </span>
                                    <span class="h4 ml-8">
                                        {{lock.battery}} %
                                    </span>
                                </div>
                                <div *ngIf="lock.battery<10" class="p-16 border-top widget-footer battery-orange"
                                    fxLayout="row" fxLayoutAlign="start center">
                                    <span class="h4 ml-8">
                                        {{'CONTENT.BATTERY' | translate}}
                                    </span>
                                    <span class="h4 ml-8">
                                        {{lock.battery}} %
                                    </span>
                                </div>


                            </div>
                            <!-- / Front -->
                        </fuse-widget>
                        <!-- / HOME WIDGET -->
                    </div>
                </mat-tab>

                <mat-tab label="{{'CONTENT.BEACON' | translate}}">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <div class="h2" *ngIf="beacons[0] == undefined">{{'CONTENT.NO_BEACONS' | translate}}</div>

                        <!-- BEACON WIDGET -->
                        <fuse-widget *ngFor="let beacon of beacons" [@animate]="{value:'*',params:{y:'100%'}}"
                            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front">
                                <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="h3">{{beacon.name}}</div>
                                </div>

                                <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                                    <img src="assets/images/avatars/thermos.png" style="max-width: 50%">
                                    <div class="h3 secondary-text font-weight-500" style="padding-top: 15px">
                                        {{'CONTENT.TEMPERATURE' | translate}}</div>
                                    <div *ngIf="beacon.temperature != undefined">{{beacon.temperature}} °C
                                    </div>
                                    <div *ngIf="beacon.temperature == undefined">{{'CONTENT.UNKNOWN' | translate}}
                                    </div>
                                </div>

                                <div *ngIf="beacon.battery>=50" class="p-16 border-top widget-footer battery-blue"
                                    fxLayout="row" fxLayoutAlign="start center">
                                    <span class="h4 ml-8">
                                        {{'CONTENT.BATTERY' | translate}}
                                    </span>
                                    <span class="h4 ml-8">
                                        {{beacon.battery}} %
                                    </span>
                                </div>
                                <div *ngIf="beacon.battery<50 && beacon.battery>=10"
                                    class="p-16 border-top widget-footer battery-purple" fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="h4 ml-8">
                                        {{'CONTENT.BATTERY' | translate}}
                                    </span>
                                    <span class="h4 ml-8">
                                        {{beacon.battery}} %
                                    </span>
                                </div>
                                <div *ngIf="beacon.battery<10" class="p-16 border-top widget-footer battery-orange"
                                    fxLayout="row" fxLayoutAlign="start center">
                                    <span class="h4 ml-8">
                                        {{'CONTENT.BATTERY' | translate}}
                                    </span>
                                    <span class="h4 ml-8">
                                        {{beacon.battery}} %
                                    </span>
                                </div>
                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / BEACON WIDGET -->
                    </div>
                </mat-tab>

                <mat-tab label="{{'CONTENT.GATEWAY' | translate}}">

                    <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" [@animateStagger]="{value:'50'}">

                        <div class="h2" *ngIf="gateways[0] == undefined">{{'CONTENT.NO_GATEWAYS' | translate}}</div>

                        <!-- GATEWAY WIDGET -->
                        <fuse-widget *ngFor="let gateway of gateways" [@animate]="{value:'*',params:{y:'100%'}}"
                            class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                            <!-- Front -->
                            <div class="fuse-widget-front">
                                <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="h3">{{gateway.name}}</div>
                                </div>

                                <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                                    <img src="assets/images/avatars/wifi.png" style="max-width: 50%">
                                    <div class="h3 secondary-text font-weight-500" style="padding-top: 15px">
                                        {{'CONTENT.IP_ADDRESS' | translate}}</div>
                                    <div *ngIf="gateway.address_ip_local != undefined">{{gateway.address_ip_local}}
                                    </div>
                                    <div *ngIf="gateway.address_ip_local == undefined">{{'CONTENT.UNKNOWN' | translate}}
                                    </div>
                                </div>

                                <div class="p-16 border-top widget-footer" style="background: #2EB4B3" fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="h4 ml-8">
                                        {{'CONTENT.CONNECTED_OBJECTS' | translate}}
                                    </span>
                                    <span class="h4 ml-8">
                                        {{gateway.nbObject}}
                                    </span>
                                </div>
                            </div>
                            <!-- / Front -->

                        </fuse-widget>
                        <!-- / GATEWAY WIDGET -->

                    </div>
                </mat-tab>

            </mat-tab-group>

        </div>
        <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->


    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar" name="dashboard-right-sidebar-1" position="right" lockedOpen="gt-md">
        <div class="place-img-container">
            <img class="place-img" src={{selectedPlaceAddressPhoto}}>
            <div class="overlay" (click)="onPlacePhotoClick()">
                <mat-icon class="magnifying-glass">search</mat-icon>
            </div>
        </div>

        <!-- SIDEBAR CONTENT -->
        <div class="content">

            <!-- WIDGET GROUP -->
            <div class="widget-group" fxLayout="column" fxFlex="100">

                <!-- NOW WIDGET -->
                <fuse-widget class="pb-0">

                    <!-- Front -->
                    <div class="fuse-widget-front">

                        <div class="pl-16 pr-8 py-16" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="h3">
                                {{ selectedPlaceDateNow | date : 'EEEE, HH:mm:ss' : selectedPlaceTimezone : language | titlecase}}
                            </div>
                        </div>

                        <div class="p-16 pb-24" fxLayout="column" fxLayoutAlign="center center">
                            <div class="h1 secondary-text">
                                <span>{{selectedPlaceDateNow | date: 'MMMM' : selectedPlaceTimezone : language | titlecase}}</span>
                            </div>

                            <div class="font-size-72 line-height-88 secondary-text font-weight-400">
                                {{selectedPlaceDateNow | date: 'd' : selectedPlaceTimezone : language}}
                            </div>

                            <div class="h1 secondary-text">
                                <span>{{selectedPlaceDateNow | date: 'y' : selectedPlaceTimezone : language}}</span>
                            </div>
                        </div>

                    </div>
                    <!-- / Front -->

                </fuse-widget>
                <!-- / NOW WIDGET -->

                <!-- WEATHER WIDGET -->
                <fuse-widget>

                    <!-- Front -->
                    <div class="fuse-widget-front">

                        <div class="pl-16 pr-8 py-16" fxLayout="row" fxLayoutAlign="space-between center">

                            <div class="h4" fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon class="secondary-text mr-8">place</mat-icon>
                                {{selectedPlaceCity}}
                            </div>

                        </div>

                        <div class="p-16 pb-32" fxLayout="column" fxLayoutAlign="center center">

                            <div fxLayout="row" fxLayoutAlign="center center">
                                <span class="mat-display-2 m-0 font-weight-300 secondary-text">
                                    {{selectedPlaceTemperature}}
                                </span>
                                <span class="font-size-48 font-weight-300 hint-text text-super ml-8">°</span>
                                <span class="mat-display-2 mb-0 font-weight-300 hint-text ng-binding">C</span>
                            </div>
                            <span>{{selectedPlaceWeatherDescription}}</span>
                        </div>

                        <div class="p-16 by" fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon fontSet="meteocons" fontIcon="icon-wind" class="s-16 secondary-text mr-8">
                                </mat-icon>
                                <span>
                                    {{selectedPlaceWindSpeed}}
                                </span>
                                <span class="secondary-text ml-5">
                                    m/s
                                </span>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon fontSet="meteocons" fontIcon="icon-compass" class="s-16 secondary-text mr-8">
                                </mat-icon>
                                <span>
                                    {{selectedPlaceWindDirection}}
                                </span>
                                <span class="secondary-text ml-5">
                                    °
                                </span>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon fontSet="meteocons" fontIcon="icon-rainy" class="s-16 secondary-text mr-8">
                                </mat-icon>
                                <span>
                                    {{selectedPlaceHumidity}}
                                </span>
                                <span class="secondary-text ml-5">
                                    %
                                </span>
                            </div>
                        </div>

                    </div>
                    <!-- / Front -->

                </fuse-widget>
                <!-- / WEATHER WIDGET -->
            </div>
            <!--  / WIDGET GROUP -->

        </div>
        <!-- / SIDEBAR CONTENT -->

    </fuse-sidebar>
    <!-- / SIDEBAR -->



</div>
<!-- / END -->

<ng-template #FullSizePlacePhoto>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 *ngIf="placesExist && selectedAddress.name != undefined" matDialogTitle>{{selectedAddress.name}} - {{selectedAddress.address}}</h2>
        <h2 *ngIf="placesExist && selectedAddress.name == undefined" matDialogTitle>{{selectedAddress.address}}</h2>
        <button class="closeDialogButton" mat-button matDialogClose>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <img matDialogContent class="place-img-dialog" src={{selectedPlaceAddressPhoto}}>
</ng-template>