import { Injectable } from '@angular/core';

import { registerLocaleData } from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

@Injectable({
  providedIn: 'root'
})
export class DateTranslationService {

  constructor() { }

setDateLanguage(language: string){
  switch(language){
      case 'fr':
          registerLocaleData(localeFr);
          return 'fr';
      case 'es':
          registerLocaleData(localeEs);
          return 'es';
      default:
          registerLocaleData(localeEn);
          return 'en';
        }
  }

}