import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserAccessData } from '../../interfaces/UserAccessData';
import { GuestAccessData } from '../../interfaces/GuestAccessData';
import { BadgeData } from '../../interfaces/BadgeData';

import { AccessService } from '../../services/access.service';

import { fuseAnimations } from '@fuse/animations';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { locale as spanish } from '../../i18n/es';


@Component({
  templateUrl: './schedules-dialog.component.html',
  styleUrls: ['./schedules-dialog.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class SchedulesDialogComponent {

  admin_address: string;
  address: string;
  board: string;
  typeOfUserOrObject: string;
  selectedUserAccess: UserAccessData;
  selectedGuestAccess: GuestAccessData;
  selectedBadge: BadgeData;

  hourFormat: string;

  constructor
    (
      public dialogRef: MatDialogRef<SchedulesDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,

      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private accessService: AccessService,
      private translateService: TranslateService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);

    this.admin_address = data.admin_address;
    this.address = data.address;
    this.board = data.board;
    this.typeOfUserOrObject = data.typeOfUserOrObject;
    
    if(data.typeOfUserOrObject == 'user'){
      this.selectedUserAccess = data.selectedUserAccess;

    } else if (data.typeOfUserOrObject == 'guest'){
      this.selectedGuestAccess = data.selectedGuestAccess;

    } else if (data.typeOfUserOrObject == 'badge'){
      this.selectedBadge = data.selectedBadge;
    }

    this.hourFormat = this.accessService.getHourFormat(this.translateService.currentLang);
  }


  close() {
    this.dialogRef.close();
  }

}
