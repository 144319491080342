<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 matDialogTitle>{{'PROFILE' | translate}}</h2>
    <button class="closeDialogButton" (click)="close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">

    <form [formGroup]="modifyProfileInformationForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>

        <div fxLayout="column">

            <mat-form-field appearance="outline">
                <mat-label>{{'LAST_NAME' | translate}}</mat-label>
                <input matInput formControlName="lastname">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>
                    {{'LAST_NAME_IS_REQUIRED' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'FIRST_NAME' | translate}}</mat-label>
                <input matInput formControlName="firstname">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>
                    {{'FIRST_NAME_IS_REQUIRED' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'EMAIL' | translate}}</mat-label>
                <input matInput formControlName="email">
                <mat-icon matSuffix class="secondary-text">email</mat-icon>
                <mat-error *ngIf="modifyProfileInformationForm.get('email').hasError('required')">
                    {{'EMAIL_IS_REQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="modifyProfileInformationForm.get('email').hasError('email')">
                    {{'PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'PHONE_NUMBER' | translate}}</mat-label>
                <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                <ngx-mat-intl-tel-input [preferredCountries]="['fr','gb', 'es','it','de','be']" [enableSearch]="true"
                    name="phone" formControlName="phoneNumber" #phoneNumber>
                </ngx-mat-intl-tel-input>
                <mat-error *ngIf="modifyProfileInformationForm.get('phoneNumber').hasError('required')">
                    {{'PHONE_NUMBER_IS_REQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="modifyProfileInformationForm.get('phoneNumber').hasError('validatePhoneNumber')">
                    {{'PLEASE_ENTER_A_VALID_PHONE_NUMBER' | translate}}
                </mat-error>
            </mat-form-field>

            <button mat-raised-button type="submit" color="accent" class="submit-button"
                aria-label="MODIFY PROFILE INFORMATION" [disabled]="modifyProfileInformationForm.invalid || isLoading">
                {{'SAVE' | translate}}
            </button>

        </div>
    </form>
</mat-dialog-content>