<div class="scrollToTopButton_container">
    <button mat-icon-button class="scrollToTopButton warn theme-options-button mat-elevation-z2"
        (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </button>
    <div class="overlay"></div>
</div>

<div detect-scroll (onScroll)="onScroll($event)" [bottomOffset]="1" [topOffset]="1" id="log"
    class="page-layout simple tabbed scroll" fxLayout="column">
    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="space-between">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text">{{'TITLE_NOTIFICATIONS' | translate}}</span>
            </div>
            <div class="h1 mt-16" [@animate]="{value:'*',params:{x:'50px'}}">{{'TITLE_NOTIFICATIONS' | translate}}</div>
            <div fxLayout="row" class="menu" fxLayoutAlign="space-between">
                <div fxLayout="row" class="places-menu">
                    <div *ngIf="placesExist && selectedAddress.name != undefined" class="selected-place">
                        {{selectedAddress.name}}</div>

                    <div *ngIf="placesExist && selectedAddress.name != undefined" class="selected-place-with-name">
                        {{selectedAddress.address}}</div>
                    <div *ngIf="placesExist && selectedAddress.name == undefined" class="selected-place">
                        {{selectedAddress.address}}
                    </div>
                    <div *ngIf="!placesExist" class="selected-place">{{'NO_PLACE' | translate}}</div>

                    <button *ngIf="placesExist" mat-icon-button class="place-selector" [matMenuTriggerFor]="placesMenu"
                        aria-label="Select place">
                        <mat-icon>expand_more</mat-icon>
                    </button>

                    <button *ngIf="!placesExist" mat-icon-button class="place-selector-disabled"
                        [matMenuTriggerFor]="placesMenu" aria-label="Select place" [disabled]="!placesExist">
                        <mat-icon>expand_more</mat-icon>
                    </button>

                    <mat-menu #placesMenu="matMenu">
                        <button mat-menu-item *ngFor="let address of addresses ; let index = index"
                            (click)="onPlaceSelection(index)">
                            <span *ngIf="address.name != undefined">{{address.name}}</span>
                            <span *ngIf="address.name == undefined">{{address.address}}</span>
                        </button>
                    </mat-menu>
                </div>
                <div>
                    <button mat-flat-button class="options-button" [matMenuTriggerFor]="optionsMenu"
                        aria-label="Select option">
                        {{'OPTIONS' | translate}}
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu #optionsMenu="matMenu">
                        <button mat-menu-item (click)="showWantToDeleteAllLogsDialog()">
                            <mat-icon>delete</mat-icon>
                            {{'DELETE_HISTORY' | translate}}
                        </button>
                        <button mat-menu-item (click)="showEditExpiryLogsDialog()">
                            <mat-icon>settings</mat-icon>
                            {{'CONFIGURATION' | translate}}
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <ngx-spinner name="spinnerDeleteAllLogs" class="spinnerDeleteAllLogs" size="medium" color="#ffff"
            bdColor="rgba(51,51,51,0)" type="ball-clip-rotate" [fullScreen]="false"></ngx-spinner>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <mgl-timeline [mobileWidthThreshold]="750">
        <div class="no_logs_container" *ngIf="logCards.length == 0 && isLoading == false">
            <div class="no_logs_text">{{'LOGBOOK.NO_LOGS_AVAILABLE' | translate}}</div>
        </div>
        <mgl-timeline-entry *ngFor="let logCard of logCards">
            <mgl-timeline-entry-header>
                <img class={{logCard.image.type}} src={{logCard.image.src}} alt={{logCard.title_top}}>
                <div id="timeline-entry-header-text-container">
                    <div id="timeline-entry-header-text">
                        {{logCard.title_top}}
                    </div>
                </div>
                <button mat-icon-button class="deleteLogButton"
                    (click)="showWantToDeleteLogByIdDialog($event, logCard.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mgl-timeline-entry-header>
            <mgl-timeline-entry-content>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>{{logCard.title_bottom}}</div>
                    <div *ngIf="hourFormat == '12'">{{logCard.date| date:"MM/dd/yyyy"}}
                        {{'AT' | translate }}
                        {{logCard.date| date:"hh:mm a"}}</div>
                    <div *ngIf="hourFormat == '24'">{{logCard.date| date:"dd/MM/yyyy"}}
                        {{'AT' | translate }}
                        {{logCard.date| date:"HH:mm"}}</div>
                </div>
            </mgl-timeline-entry-content>
            <mgl-timeline-entry-dot *ngIf="logCard.icon == 'report_problem'" style="background: #EFC41A !important;">
                <mat-icon class="icon-center">{{logCard.icon}}</mat-icon>
            </mgl-timeline-entry-dot>
            <mgl-timeline-entry-dot *ngIf="logCard.icon != 'report_problem'">
                <mat-icon class="icon-center">{{logCard.icon}}</mat-icon>
            </mgl-timeline-entry-dot>
            <mgl-timeline-entry-side>
                <div *ngIf="hourFormat == '12'">{{logCard.date| date:"MM/dd/yyyy"}}
                    {{'AT' | translate }}
                    {{logCard.date| date:"hh:mm a"}}</div>
                <div *ngIf="hourFormat == '24'">{{logCard.date| date:"dd/MM/yyyy"}}
                    {{'AT' | translate }}
                    {{logCard.date| date:"HH:mm"}}</div>
            </mgl-timeline-entry-side>
        </mgl-timeline-entry>
        <ngx-spinner name="spinnerLoadingNewLogs" class="spinner" size="medium" color="#2eb4b3"
            bdColor="rgba(51,51,51,0)" type="ball-clip-rotate" [fullScreen]="false"></ngx-spinner>
    </mgl-timeline>

    <!-- / CONTENT -->

</div>

<ng-template #WantToDeleteLogById>
    <h2 matDialogTitle>{{'DELETE_LOG_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'NO' | translate}}</button>
            <button mat-button matDialogClose (click)="deleteLogById()">{{'YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>

<ng-template #WantToDeleteAllLogs>
    <h2 matDialogTitle>{{'DELETE_HISTORY_DIALOG' | translate}}</h2>
    <mat-dialog-actions>
        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between">
            <button mat-button matDialogClose>{{'NO' | translate}}</button>
            <button mat-button matDialogClose (click)="deleteAllLogs()">{{'YES' | translate}}</button>
        </div>
    </mat-dialog-actions>
</ng-template>