export const locale = {
    lang: 'es',
    data: {
        'HEADER': {
            'HELLO': '¡Hola de nuevo, ',
            '!': '!',
            'NO_PLACE': 'No hay ningún lugar asociado a su cuenta.',
            'ALARM': 'Alarma'
        },
        'CONTENT': {
            'HOME': 'Inicio',
            'BEACON': 'Beacon',
            'GATEWAY': 'Pasarela',
            'IP_ADDRESS': 'Dirección IP :',
            'UNKNOWN':'desconocida',
            'CONNECTED_OBJECTS':'Objetos conectados :',
            'NO_GATEWAYS':'No hay ninguna pasarela asociada a este lugar.',
            'NO_LOCKS': 'No hay ninguna cerradura asociada a este lugar.',
            'LOCKS_NO_GATEWAY':'No hay pasarela',
            'SLEEP_MODE':'Modo de espera :',
            'NORMAL':'normal',
            'LIGHT':'ligero',
            'DEEP':'profundo',
            'BATTERY':'Batería :',
            'NO_BEACONS':'No hay ningún beacon asociado a este lugar.',
            'TEMPERATURE':'Temperatura :'
        }
    }
};
