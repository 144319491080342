import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { locale as spanish } from './i18n/es';

import { NgxSpinnerService } from 'ngx-spinner';

import { HttpErrorResponse } from '@angular/common/http';

import { AutoRedirectionService } from '../../core/AutoRedirectionService/auto-redirection.service';
import { AccessService } from './services/access.service';
import { DashboardService } from './../dashboard/dashboard.service';
import { EventEmitterService } from './services/event-emitter.service';
import { AccessDataSaveService } from './services/access-data-save.service';
import { ProfileService } from '../../core/ProfileService/profile.service';

import { DashboardData } from '../dashboard/interfaces/DashboardData';
import { PlaceDataAccess } from './interfaces/PlaceDataAccess';
import { BoardData } from './interfaces/BoardData';
import { UserAccessData } from './interfaces/UserAccessData';
import { AccessData } from './interfaces/AccessData';
import { GuestAccessData } from './interfaces/GuestAccessData';
import { BadgeData } from './interfaces/BadgeData';
import { ElectrokeyData } from './interfaces/ElectrokeyData';

import { SchedulesDialogComponent } from './dialogs/schedules-dialog/schedules-dialog.component';
import { AddAccessDialogComponent } from './dialogs/add-access-dialog/add-access-dialog.component';
import { CopyAccessDialogComponent } from './dialogs/copy-access-dialog/copy-access-dialog.component';

import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
    selector: 'access',
    templateUrl: './access.component.html',
    styleUrls: ['./access.component.scss', '../timeline/timeline.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AccessComponent implements OnInit, OnDestroy {
    dashboardData: DashboardData;
    accessData: AccessData;

    badges = new Array<BadgeData>();
    keys = new Array<ElectrokeyData>();

    subsVarTabIndex: Subscription;

    addresses = new Array<PlaceDataAccess>();

    selectedAddress: PlaceDataAccess;
    selectedBoard: BoardData;

    placesExist: boolean;
    boardsExist: boolean;
    badgesExist: boolean;
    keysExist: boolean;
    deleteOwnAccessButtonClicked: boolean = false;

    tabIndex: number;
    accessToDeleteId: number;
    accessToShareId: number;

    ShareGuestAccessMessage: string;

    isLoading: boolean;

    permAccessDate: string = '2069-01-01T12:00:00+00:00';

    @ViewChild('WantToDeleteAccessById') WantToDeleteAccessById: TemplateRef<any>;
    @ViewChild('WantToDeleteOwnAccess') WantToDeleteOwnAccess: TemplateRef<any>;
    @ViewChild('ShareGuestAccess') ShareGuestAccess: TemplateRef<any>;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseConfigService: FuseConfigService,
        private autoRedirectionService: AutoRedirectionService,
        private translateService: TranslateService,
        private accessService: AccessService,
        private dashboardService: DashboardService,
        private dialog: MatDialog,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private accessDataSaveService: AccessDataSaveService,
        private spinner: NgxSpinnerService,
        private profileService: ProfileService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, french, spanish);
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: false
                },
                toolbar: {
                    hidden: false
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: false
                }
            }
        };
    }

    ngOnInit(): void {
        // If the user is not authenticated, he is redirected to the login page.
        // If the user is authenticated but the token has expired, he is redirected to the "session locked" page.
        this.autoRedirectionService.redirectUserIfNecessary();

        // Ici, on gère les events générés dans la popup "Add Access" qui permettent de changer le lieu, la serrure, et l'onglet (Utilisateurs ou Invités) sur cette page
        // en même temps que l'utilisateur les change sur la popup.
        this.eventEmitterService.subsVarOnPlaceSelection = this.eventEmitterService.
            invokeOnPlaceSelection.subscribe((placeId: number) => {
                this.onPlaceSelection(placeId);
            });
        this.eventEmitterService.subsVarOnBoardSelection = this.eventEmitterService
            .invokeOnBoardSelection.subscribe((boardId: number) => {
                this.onBoardSelection(boardId);
            });
        this.eventEmitterService.subsVarChangeTabIndex = this.eventEmitterService
            .invokeChangeTabIndex.subscribe((selectedAccessType: string) => {
                this.changeTabIndex(selectedAccessType);
            });
        this.eventEmitterService.subsVarGetAccessList = this.eventEmitterService
            .invokeGetAccessList.subscribe(() => {
                this.getAccessList();
            });

        this.initializePage();
    }

    initializePage() {
        this.isLoading = true;
        this.spinner.show("spinnerLoadingContent");
        let boardIndex: number = 0;

        // Appel api dashboard pour récupérer les lieux et serrures de l’utilisateur
        this.dashboardService.getDashboardData()
            .subscribe(
                (response) => {
                    // On stocke la réponse dans un objet de type DashboardData pour s'en servir par la suite
                    this.dashboardData = response;

                    if (this.dashboardData.address == undefined) { // Si l'utilisateur n'a pas de lieu
                        this.placesExist = false;
                        this.isLoading = false;
                        this.spinner.hide("spinnerLoadingContent");
                    } else {
                        this.placesExist = true;
                        // On récupère le nom des lieux et serrures de l'utilisateur pour les afficher dans le menu
                        for (let i = 0; i < this.dashboardData.address.length; i++) {
                            this.addresses[i] = {
                                'address': this.dashboardData.address[i].address,
                                'name': null,
                                'id': this.dashboardData.address[i].id,
                                'board': [null]
                            }
                            if (this.dashboardData.address[i].admin_address[0] != undefined) {
                                this.addresses[i].name = this.dashboardData.address[i].admin_address[0].name;
                            }
                            boardIndex = 0;
                            for (let j = 0; j < this.dashboardData.address[i].board.length; j++) {
                                // On ne prend que les serrures (type_board.id == 1 --> serrure V1), (type_board.id == 3 --> serrure V2)
                                if (this.dashboardData.address[i].board[j].type_board.id == 1 || this.dashboardData.address[i].board[j].type_board.id == 3) {
                                    this.addresses[i].board[boardIndex] = {
                                        'id': this.dashboardData.address[i].board[j].id,
                                        'name': this.dashboardData.address[i].board[j].name,
                                        'type_board': {
                                            'id': this.dashboardData.address[i].board[j].type_board.id,
                                        },
                                        'board_access': {
                                            'id': this.dashboardData.address[i].board[j].board_access[0].id,
                                            'type_access': {
                                                'id': this.dashboardData.address[i].board[j].board_access[0].type_access.id,
                                            }
                                        }
                                    }
                                    boardIndex += 1;
                                }
                            }
                        }

                        if (localStorage.getItem('currentPlace') != null) {
                            // Si l'utilisateur a selectionné un lieu précédemment (dans la même page ou dans une autre page), 
                            // On initialise la page accès sur ce lieu. 
                            this.selectedAddress = this.addresses[this.accessService.getPlaceIndexFromPlaceId(parseInt(localStorage.getItem('currentPlace')), this.addresses)];
                        } else {
                            // Sinon, on initialise le lieu sur le premier lieu de l'utilisateur
                            this.selectedAddress = this.addresses[0];
                            localStorage.setItem('currentPlace', this.selectedAddress.id.toString());
                        }
                        // On initialise sur la première serrure du lieu si elle existe
                        this.selectedBoard = this.accessService.initializeBoard(this.selectedAddress).selectedBoard;

                        this.accessDataSaveService.updateSelectedAddress(this.selectedAddress);
                        this.accessDataSaveService.updateSelectedBoard(this.selectedBoard);

                        this.boardsExist = this.accessService.initializeBoard(this.selectedAddress).boardsExist;

                        // On récupère la liste des accès de cette serrure
                        this.getAccessList();

                        if (this.boardsExist && this.selectedBoard.type_board.id == 3 && this.selectedBoard.board_access.type_access.id != 3) { // Si la serrure est une V2 et que l'utilisateur est modérateur ou admin de la serrure
                            this.getBadgeList();
                            this.getElectrokeyList();
                        }

                        this.subsVarTabIndex = this.accessDataSaveService.currentTabIndex.subscribe(tabIndex => this.tabIndex = tabIndex);
                        if (this.tabIndex == null) {
                            this.tabIndex = 0;
                        }

                        this.isLoading = false;
                        this.spinner.hide("spinnerLoadingContent");
                    }
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                    this.isLoading = false;
                    this.spinner.hide("spinnerLoadingContent");
                }
            );
    }

    ngOnDestroy() {
        this.eventEmitterService.subsVarOnPlaceSelection.unsubscribe();
        this.eventEmitterService.subsVarOnBoardSelection.unsubscribe();
        this.eventEmitterService.subsVarChangeTabIndex.unsubscribe();
        this.eventEmitterService.subsVarGetAccessList.unsubscribe();

        this.subsVarTabIndex.unsubscribe();
    }

    onPlaceSelection(placeId: number) {
        localStorage.removeItem('currentBoard');
        localStorage.setItem('currentPlace', placeId.toString());

        this.selectedAddress = this.addresses[this.accessService.getPlaceIndexFromPlaceId(placeId, this.addresses)];
        this.selectedBoard = this.accessService.initializeBoard(this.selectedAddress).selectedBoard;

        this.accessDataSaveService.updateSelectedAddress(this.selectedAddress);
        this.accessDataSaveService.updateSelectedBoard(this.selectedBoard);

        this.boardsExist = this.accessService.initializeBoard(this.selectedAddress).boardsExist;

        this.getAccessList();

        if (this.boardsExist && this.selectedBoard.type_board.id == 3 && this.selectedBoard.board_access.type_access.id != 3) { // Si la serrure est une V2 et que l'utilisateur est modérateur ou admin de la serrure
            this.getBadgeList();
            this.getElectrokeyList();
        }
    }

    onBoardSelection(boardId: number) {
        localStorage.setItem('currentBoard', boardId.toString());
        this.selectedBoard = this.selectedAddress.board[this.accessService.getBoardIndexFromBoardId(boardId, this.selectedAddress)];
        this.accessDataSaveService.updateSelectedBoard(this.selectedBoard);
        this.getAccessList();
        if (this.selectedBoard.type_board.id == 3 && this.selectedBoard.board_access.type_access.id != 3) { // Si la serrure est une V2 et que l'utilisateur est modérateur ou admin de la serrure
            this.getBadgeList();
            this.getElectrokeyList();
        }
    }

    getAccessList() {
        this.accessData = null;
        if (this.selectedBoard != null) {
            this.isLoading = true;
            this.spinner.show("spinnerLoadingContent");
            this.accessService.getAccessListByLockId(this.selectedBoard.id)
                .subscribe(
                    (response) => {
                        this.accessData = response;
                        this.accessDataSaveService.updateAccessData(this.accessData);
                        this.isLoading = false;
                        this.spinner.hide("spinnerLoadingContent");
                    },
                    (err: HttpErrorResponse) => {
                        console.log(err);
                        this.isLoading = false;
                        this.spinner.hide("spinnerLoadingContent");
                    }
                );
        }
    }

    getBadgeList() {
        this.badges = null;
        this.isLoading = true;
        this.spinner.show("spinnerLoadingContent");
        this.accessService.getBadgeListByLockId(this.selectedBoard.id)
            .subscribe(
                (response) => {
                    this.badges = response;
                    if (this.badges[0].id != undefined) {
                        this.badgesExist = true;
                        // On détermine si les badges ont un accès permanent ou pas 
                        // (permAccessDate = '2069-01-01T12:00:00+00:00')
                        for (let i = 0; i < this.badges.length; i++) {
                            if (this.badges[i].state == 1
                                && this.badges[i].access_exep[0] != undefined
                                && this.badges[i].access_exep[0].stop == this.permAccessDate) {
                                this.badges[i].hasPermanentAccess = true;
                            } else {
                                this.badges[i].hasPermanentAccess = false;
                            }
                        }
                    } else {
                        this.badges = [];
                        this.badgesExist = false;
                    }
                    this.accessDataSaveService.updateBadges(this.badges);
                    this.isLoading = false;
                    this.spinner.hide("spinnerLoadingContent");
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                    this.isLoading = false;
                    this.spinner.hide("spinnerLoadingContent");
                }
            );
    }

    getElectrokeyList() {
        this.keys = null;
        this.isLoading = true;
        this.spinner.show("spinnerLoadingContent");
        this.accessService.getElectrokeyListByLockId(this.selectedBoard.id)
            .subscribe(
                (response) => {
                    this.keys = response;
                    if (this.keys[0].id != undefined) {
                        this.keysExist = true;
                        for (let i = 0; i < this.keys.length; i++) {
                            if (this.keys[i].data != null) {
                                this.keys[i].data_parsed = JSON.parse(this.keys[i].data);
                            }
                        }
                    } else {
                        this.keys = [];
                        this.keysExist = false;
                    }
                    this.accessDataSaveService.updateKeys(this.keys);
                    this.isLoading = false;
                    this.spinner.hide("spinnerLoadingContent");
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                    this.isLoading = false;
                    this.spinner.hide("spinnerLoadingContent");
                }
            );
    }

    openWantToDeleteAccess(AccessId: number) {
        this.accessToDeleteId = AccessId;
        this.dialog.open(this.WantToDeleteAccessById);
    }

    openWantToDeleteOwnAccess(AccessId: number) {
        this.deleteOwnAccessButtonClicked = true;
        this.accessToDeleteId = AccessId;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        this.dialog.open(this.WantToDeleteOwnAccess, dialogConfig);
    }

    setDeleteOwnAccessButtonClickedToFalse() {
        this.deleteOwnAccessButtonClicked = false;
    }

    deleteAccessById(deleteMode: string) {
        this.accessService.deleteAccessById(this.accessToDeleteId)
            .subscribe(
                () => {
                    if (deleteMode == 'deleteOtherUserAccess') {
                        this.getAccessList();
                    } else if (deleteMode == 'deleteOwnAccess') {
                        this.initializePage();
                    }
                    this.deleteOwnAccessButtonClicked = false;
                },
                (err: HttpErrorResponse) => {
                    console.log(err);
                    this.deleteOwnAccessButtonClicked = false;
                    if (err.error.errors[0] == "Error not moderator") {
                        alert("Error: not moderator");
                    }
                }
            );
    }

    onUserOrObjectWidgetClick(userOrObjectId: number, typeOfUserOrObject: string) {
        // Cette condition règle le bug suivant : quand on appuyait sur l'icon delete de son propre accès, 
        // la fonction onUserWidgetClick s'éxecutait en même temps ce qui n'est pas voulu.
        if (this.deleteOwnAccessButtonClicked == false) {

            let selectedUserAccess: UserAccessData;
            let selectedGuestAccess: GuestAccessData;

            let selectedBadge: BadgeData;
            let selectedKey: ElectrokeyData;

            // Si l'utilisateur est un administrateur (type_access.id == 1) ou un modérateur (type_access.id == 2)
            if (this.selectedBoard.board_access.type_access.id == 1 || this.selectedBoard.board_access.type_access.id == 2) {

                // Si un utilisateur, invité, un badge ou une electrokey est sélectionné(e), on ouvre la page AccessManagement
                this.accessDataSaveService.updateUserOrObjectId(userOrObjectId);
                this.accessDataSaveService.updateUserOrObjectType(typeOfUserOrObject);
                //Ici, on envoie userOrObjectId et typeOfUserOrObject dans le accessDataSaveService puis on initialise la page accessManagement en fonction de ces infos.
                this.router.navigate(['/access/accessManagement']);

            }
            // Si l'utilisateur n'est qu'un simple "utilisateur" (type_access.id == 3)
            else if (this.selectedBoard.board_access.type_access.id == 3) {
                if (typeOfUserOrObject == 'user') {
                    for (let i = 0; i < this.accessData.user.length; i++) {
                        if (this.accessData.user[i].id == userOrObjectId) {
                            selectedUserAccess = this.accessData.user[i];
                        }
                    }
                } else if (typeOfUserOrObject == 'guest') {
                    for (let i = 0; i < this.accessData.invite.length; i++) {
                        if (this.accessData.invite[i].id == userOrObjectId) {
                            selectedGuestAccess = this.accessData.invite[i];
                        }
                    }
                }
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = {
                    admin_address: this.selectedAddress.name,
                    address: this.selectedAddress.address,
                    board: this.selectedBoard.name,
                    typeOfUserOrObject: typeOfUserOrObject,
                };
                if (typeOfUserOrObject == 'user') {
                    dialogConfig.data.selectedUserAccess = selectedUserAccess;

                    if (selectedUserAccess.type_access.id == 3) {
                        dialogConfig.width = '80%';
                    }

                } else if (typeOfUserOrObject == 'guest') {
                    dialogConfig.data.selectedGuestAccess = selectedGuestAccess;
                    dialogConfig.width = '80%';
                }

                this.dialog.open(SchedulesDialogComponent, dialogConfig);
            }
        }
    }

    openAddAccessDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            addresses: this.addresses,
            selectedAddress: this.selectedAddress,
            accessData: this.accessData,
            selectedBoard: this.selectedBoard,
            tabIndex: this.tabIndex
        };
        this.dialog.open(AddAccessDialogComponent, dialogConfig);
    }

    openCopyAccessDialog(userId: number) {
        let selectedUserAccess: UserAccessData;

        for (let i = 0; i < this.accessData.user.length; i++) {
            if (this.accessData.user[i].id == userId) {
                selectedUserAccess = this.accessData.user[i];
            }
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            selectedUserAccess: selectedUserAccess
        };
        dialogConfig.minWidth = '25%';
        dialogConfig.disableClose = true;
        this.dialog.open(CopyAccessDialogComponent, dialogConfig);
    }

    openShareGuestAccessDialog(accessId: number) {
        this.accessToShareId = accessId;
        this.dialog.open(this.ShareGuestAccess);
    }

    getShareGuestAccessMessage(language: string) {
        let currentLang: string = this.translateService.currentLang;
        let selectedGuestAccess: GuestAccessData;

        for (let i = 0; i < this.accessData.invite.length; i++) {
            if (this.accessData.invite[i].id == this.accessToShareId) {
                selectedGuestAccess = this.accessData.invite[i];
            }
        }

        this.translateService.use(language);

        this.ShareGuestAccessMessage =
            this.translateService.instant('GUEST_MESSAGE.INTRO')
            + '%0D%0A%0D%0A' + this.translateService.instant('GUEST_MESSAGE.LOGIN') + selectedGuestAccess.num_access
            + '%0D%0A' + this.translateService.instant('GUEST_MESSAGE.ACCESS_CODE') + selectedGuestAccess.code_access
            + '%0D%0A%0D%0A' + this.translateService.instant('GUEST_MESSAGE.IOS_URL')
            + '%0D%0A' + this.translateService.instant('GUEST_MESSAGE.ANDROID_URL')

        this.translateService.use(currentLang);
    }

    changeTabIndex(selectedAccessType: string) {
        if (selectedAccessType == 'user') {
            this.tabIndex = 0;
        } else if (selectedAccessType == 'guest') {
            this.tabIndex = 1;
        }
    }

    onTabIndexChange(event: number) {
        this.tabIndex = event;
        this.accessDataSaveService.updateTabIndex(this.tabIndex);
    }
}
