<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 matDialogTitle>{{'CHANGE_PASSWORD' | translate}}</h2>
    <button class="closeDialogButton" (click)="close()" mat-button>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="dialog-content">

    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit(f)" #f="ngForm" novalidate>

        <div fxLayout="column">

            <mat-form-field appearance="outline">
                <mat-label>{{'OLD_PASSWORD' | translate}}</mat-label>
                <input matInput type="password" formControlName="old_password">
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error>
                    {{'OLD_PASSWORD_IS_REQUIRED' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'NEW_PASSWORD' | translate}}</mat-label>
                <input matInput type="password" formControlName="password">
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error>
                    {{'NEW_PASSWORD_IS_REQUIRED' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{'NEW_PASSWORD_CONFIRM' | translate}}</mat-label>
                <input matInput type="password" formControlName="passwordConfirm">
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error *ngIf="changePasswordForm.get('passwordConfirm').hasError('required')">
                    {{'PASSWORD_CONFIRMATION_IS_REQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="!changePasswordForm.get('passwordConfirm').hasError('required') &&
                changePasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                    {{'PASSWORDS_MUST_MATCH' | translate}}
                </mat-error>
            </mat-form-field>

            <button mat-raised-button type="submit" color="accent" class="submit-button" aria-label="CHANGE PASSWORD"
                [disabled]="changePasswordForm.invalid || isLoading">
                {{'SAVE' | translate}}
            </button>

        </div>

    </form>

</mat-dialog-content>