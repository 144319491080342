import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

import { NgxSpinnerModule } from 'ngx-spinner';

import { PlacesComponent } from './places.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreatePlaceComponent } from './create-place/create-place.component';
import { PlaceCardComponent } from './place-card/place-card.component';

import { GestionLieuxComponent } from './gestion-lieux/gestion-lieux.component';
import { PlaceMapComponent } from './place-map/place-map.component';
import { InformationComponent } from './gestion-lieux/information/information.component';
import { UserComponent } from './gestion-lieux/user/user.component';
import { IfttComponent } from './gestion-lieux/iftt/iftt.component';
import { AlarmComponent } from './gestion-lieux/alarm/alarm.component';
import { CheckInOutComponent } from './gestion-lieux/check-in-out/check-in-out.component';
import { ManagementGuardGuard } from './management-guard.guard';

const routes = [
    {
      path     : 'places',
      component: PlacesComponent
    },
    {
      path: 'places/gestion/:id',
      component: GestionLieuxComponent,
      onSameUrlNavigation: 'reload',
      canActivate: [ManagementGuardGuard]
    }
];


@NgModule({
  declarations: [
     PlacesComponent,
     CreatePlaceComponent,
     PlaceCardComponent,
     GestionLieuxComponent,
     PlaceMapComponent,
     InformationComponent,
     UserComponent,
     IfttComponent,
     AlarmComponent,
     CheckInOutComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDFLPCGnnEgTn3Yx6rkkhkaH0CWCFLpe7Y'
    }),

    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatGoogleMapsAutocompleteModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MaterialFileInputModule,

    NgxSpinnerModule,

    BrowserAnimationsModule
  ],
  exports: [
      PlacesComponent,
      CreatePlaceComponent
  ],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PlacesModule { }
